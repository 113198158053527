<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div class="row" v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="col-lg-12 col-md-12" v-else>
      <ManualDocumentContent> </ManualDocumentContent>
    </div>
  </div>
</template>

<script>
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import ManualDocumentContent from "./ManualDocument/ManualDocumentContent"
import formMixin from "@/mixins/formMixin"

export default {
  components: {
    ManualDocumentContent,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
}
</script>
