<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div v-else>
      <div class="row">
        <div
          v-for="(item, index) in dataBox"
          :key="index"
          class="col-lg-4 col-md-6"
        >
          <data-box :data="item"></data-box>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <doughnut-chart
            :timeOptions="timeOptions"
            id="searchMethod"
          ></doughnut-chart>
        </div>
        <div class="col-lg-8 col-md-12">
          <search-key-word :timeOptions="timeOptionsSearch"></search-key-word>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <area-background-chart-customer-action
            :timeOptions="timeOptions"
            id="customerActions"
          ></area-background-chart-customer-action>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataBox from "./Dashboard/DataBox"
import DoughnutChart from "./Dashboard/DoughnutChart"
import AreaBackgroundChartCustomerAction from "./Dashboard/AreaBackgroundChartCustomerAction"
import SearchKeyWord from "./Dashboard/SearchKeyWord"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"
import { freeTrialType, roleType } from "src/util/constants"

export default {
  components: {
    DataBox,
    DoughnutChart,
    AreaBackgroundChartCustomerAction,
    SearchKeyWord,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
  data() {
    return {
      dataBox: [
        {
          icon: "nc-globe",
          title: this.$t("screens.report.texts.views"),
          data: "",
          increase: "",
        },
        {
          icon: "nc-zoom-split",
          title: this.$t("screens.report.texts.searches"),
          data: "",
          increase: "",
        },
        {
          icon: "nc-tap-01",
          title: this.$t("screens.report.texts.actions"),
          data: "",
          increase: "",
        },
        {
          icon: "nc-user-run",
          title: this.$t("screens.report.texts.listingVisitYourWebsite"),
          data: "",
          increase: "",
        },
        {
          icon: "nc-world-2",
          title: this.$t("screens.report.texts.listingRequestDirections"),
          data: "",
          increase: "",
        },
        {
          icon: "nc-bell-55",
          title: this.$t("screens.report.texts.listingCallYou"),
          data: "",
          increase: "",
        },
      ],
      timeOptions: [
        {
          value: 7,
          label: this.$t("screens.report.texts.weekAgo"),
        },
        {
          value: 1,
          label: this.$t("screens.report.texts.monthAgo"),
        },
        {
          value: 3,
          label: this.$t("screens.report.texts.threeMonthAgo"),
        },
      ],
      timeOptionsSearch: [
        {
          value: 1,
          label: this.$t("screens.report.texts.monthAgo"),
        },
        {
          value: 3,
          label: this.$t("screens.report.texts.threeMonthAgo"),
        },
      ],
      FREETRIAL_EXPIRE: freeTrialType.FREETRIAL_EXPIRE,
      roleType: roleType,
    }
  },
  async created() {
    this.checkGoogleMyBusiness()
    await this.getBoxData()
  },
  computed: {
    getDataBox() {
      return this.$store.state.dashboard.boxData
    },
    searchKeyWordTotal() {
      if (
        String(this.getDataBox.search_keywords_total).startsWith("threshold_")
      ) {
        const numberStr = this.getDataBox.search_keywords_total.substring(10)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return "< " + number
        }
      } else if (
        String(this.getDataBox.search_keywords_total).startsWith("value_")
      ) {
        const numberStr = this.getDataBox.search_keywords_total.substring(6)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return number
        }
      }
      return this.getDataBox.search_keywords_total
    },
  },
  watch: {
    userIds(value) {
      if (value) {
        this.getBoxData()
      }
    },
  },
  methods: {
    async getBoxData() {
      try {
        this.$store.commit("setShowLoading", true)

        const data = {
          user_id: this.userIds[0]
            ? this.userIds[0].toString()
            : this.$store.state.auth.user.id.toString(),
        }
        await this.$store.dispatch("dashboard/getBoxData", data)

        this.dataBox[0].data = this.getDataBox.view_total
        this.dataBox[0].increase = this.getDataBox.percent_view_total
        this.dataBox[1].data = this.searchKeyWordTotal
        this.dataBox[1].increase = this.getDataBox.percent_search_keywords_total
        this.dataBox[2].data = this.getDataBox.action_total
        this.dataBox[2].increase = this.getDataBox.percent_action_total
        this.dataBox[3].data = this.getDataBox.action_website
        this.dataBox[3].increase = this.getDataBox.percent_action_website
        this.dataBox[4].data = this.getDataBox.action_driving_direction
        this.dataBox[4].increase =
          this.getDataBox.percent_action_driving_direction
        this.dataBox[5].data = this.getDataBox.action_phone
        this.dataBox[5].increase = this.getDataBox.percent_action_phone

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.dataBox.forEach((box) => {
          box.data = 0
          box.increase = ""
        })
        this.$store.commit("setShowLoading", false)
      }
    },
    checkGoogleMyBusiness() {
      if (
        this.$store.state.auth.role === this.roleType.FREETRIAL ||
        this.$store.state.auth.role === this.roleType.SHOP
      )
        if (this.$store.state.auth.connectGMB === false) {
          this.$router.push("google-my-business")
        }
    },
  },
}
</script>
