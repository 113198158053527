import NewsApi from "@/services/newsApi"

const api = new NewsApi()

const state = () => ({
  list: [],
  item: {},
})

const mutations = {
  setListNews(state, data) {
    state.list = data
  },
  setItemNew(state, data) {
    state.item = data
  },
}

const actions = {
  async addNews({ commit }, data) {
    return await api.addNews(data)
  },

  async updateNews({ commit }, { key, data }) {
    return await api.updateNews({ key, data })
  },

  async getNews({ commit }, userId) {
    return await api.getNews(userId).then((response) => {
      commit("setListNews", response.data.data)
    })
  },

  async getNew({ commit }, key) {
    return await api.getNew(key).then((response) => {
      commit("setItemNew", response.data.data)
    })
  },

  async delete({ commit }, { key, userId }) {
    return await api.deleteNew(key, userId)
  },
}

export default { namespaced: true, state, mutations, actions }
