<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ getNameAgency }}
      </h5>
    </div>
    <div class="card-body row">
      <div class="col-md-8 ml-3">
        <div class="row align-items-center">
          <div class="col-md-2">
            <span>{{ $t("screens.dashboard.texts.groupSelect") }}</span>
          </div>
          <div class="col-md-10">
            <el-select
              v-model="groupSelected"
              :placeholder="$t('screens.dashboard.texts.pleaseChoose')"
              clearable
              filterable
              :no-match-text="$t('screens.overviewAgency.label.noData')"
            >
              <el-option
                v-for="option in groupOptions"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="col-md-8 mt-4 ml-3">
        <div class="row align-items-center">
          <div class="col-md-2">
            <span>{{ $t("screens.dashboard.texts.listStore") }}</span>
          </div>
          <div class="col-md-5" style="display: inline-block">
            <fg-input
              class="input-sm"
              :placeholder="$t('screens.overviewManager.label.search')"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
              @keyup="inputSearch"
            >
            </fg-input>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-4">
        <div class="col-sm-12">
          <el-table
            ref="multipleTable"
            :data="queriedData"
            row-key="id"
            type="expand"
            :empty-text="$t('screens.overviewAgency.label.noData')"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :sortable="column.sortable"
            >
            </el-table-column>
            <el-table-column
              :sortable="true"
              min-width="80"
              align="center"
              :label="$t('screens.overviewManager.label.googleStatus')"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.google_status === 1" class="text-success">
                  {{ $t("screens.dashboard.texts.googleConnected") }}
                </span>
                <span v-else class="text-danger">
                  {{ $t("screens.dashboard.texts.googleDisconnected") }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              min-width="40"
              align="right"
            >
              <template slot-scope="props">
                <p-button
                  :disabled="props.row.google_status === 0"
                  type="info"
                  size="sm"
                  icon
                  :title="$t('screens.buttons.dashboard')"
                  @click="handleLike(props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="row pl-3 pr-3">
          <div class="col-sm-6 pagination-info mt-2">
            <p class="category">
              Showing {{ searchQuery ? from + 1 : from }} to {{ to }} of
              {{ total }} entries
            </p>
          </div>
          <div class="col-sm-6 mt-2">
            <pagination
              class="pull-right"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              :change-page="changePage(pagination.currentPage)"
            >
            </pagination>
          </div>
        </div>
      </div>

      <div class="col-md-12 ml-3">
        <p-button type="info" round @click="openModalCreateUpdate('create')">
          {{ $t("screens.dashboard.buttons.createGroupStore") }}
        </p-button>
        <p-button
          type="warning"
          :disabled="!groupSelected"
          round
          @click="openModalCreateUpdate('update')"
          class="ml-2"
        >
          {{ $t("screens.dashboard.buttons.updateGroupStore") }}
        </p-button>
        <p-button
          type="primary"
          round
          class="ml-2"
          @click="viewReport"
          :disabled="
            multipleSelection.length === 0 ||
            multipleSelection.filter((selected) => selected.status !== 0)
              .length === 0
          "
        >
          {{ $t("screens.dashboard.buttons.viewReport") }}
        </p-button>
      </div>

      <modal
        :show.sync="isGroupCreateShow"
        headerClasses="justify-content-center"
      >
        <h4 v-if="type === 'create'" slot="header" class="title title-up">
          {{ $t("screens.dashboard.buttons.createGroupStore") }}
        </h4>
        <h4 v-else slot="header" class="title title-up">
          {{ $t("screens.dashboard.buttons.updateGroupStore") }}
        </h4>
        <AdminAlert
          v-if="messages"
          :type="messages.type"
          :messages="messages.data"
        ></AdminAlert>
        <FormCreateUpdateGroupStore
          v-if="isGroupCreateShow"
          :type="type"
          :data="form"
          @valuePageGroup="changeValuePageGroup"
          @submit="submitForm"
        ></FormCreateUpdateGroupStore>
      </modal>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Checkbox } from "element-ui"
import { Modal, Pagination } from "src/components/UIComponents"
import FormCreateUpdateGroupStore from "./FormCreateUpdateGroupStore.vue"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Pagination,
    Modal,
    FormCreateUpdateGroupStore,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      messages: null,
      groupSelected: null,
      multipleSelection: [],
      groupOptions: [],
      searchQuery: "",
      resultSearchQuery: "",
      list: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0,
      },
      params: {
        limit: 5,
        page: 1,
      },
      valuePage: 1,
      valuePageGroup: 1,
      firstPage: 1,

      form: { groupID: null, name: "", stores: [] },
      type: "",
      isGroupCreateShow: false,
      selectAll: false,

      propsToSearch: [
        "id",
        "company_name",
        "name",
        "person_in_charge",
        "email",
        "google_status",
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 80,
          sortable: true,
        },
        {
          prop: "company_name",
          label: this.$t("screens.overviewAgency.label.companyName"),
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "name",
          label: this.$t("screens.overviewAgency.label.storeName"),
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "person_in_charge",
          label: this.$t("screens.overviewAgency.label.personInCharge"),
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "email",
          label: this.$t("screens.overviewAgency.label.emailAddress"),
          minWidth: 200,
          sortable: true,
        },
      ],
    }
  },
  computed: {
    pagedData() {
      return this.list
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        if (this.groupSelected) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.pagination.total = this.getPaginationByGroup?.total ?? 0
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.pagination.total = this.getPagination?.total ?? 0
        }
        return this.pagedData
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.resultSearchQuery = this.list.filter((row) => {
        let isIncluded = false
        for (const key of this.propsToSearch) {
          const rowValue = row[key]?.toString().toUpperCase() || ""
          if (rowValue.includes(this.searchQuery.toUpperCase())) {
            isIncluded = true
          }
        }
        return isIncluded
      })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.resultSearchQuery.length
      return this.checkSearchQuery
        ? this.resultSearchQuery.slice(this.from, this.to)
        : this.resultSearchQuery
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return this.checkSearchQuery ? highBound : this.getPagination?.to
    },
    from() {
      const formSearch =
        this.pagination.perPage * (this.pagination.currentPage - 1)
      return this.checkSearchQuery ? formSearch : this.getPagination?.from
    },
    totalStore() {
      return this.getPagination?.total
    },
    totalByGroup() {
      return this.getPaginationByGroup?.total
    },
    totalSearchQuery() {
      return this.resultSearchQuery.length
    },
    total() {
      return this.groupSelected
        ? this.totalByGroup
        : this.checkSearchQuery
        ? this.totalSearchQuery
        : this.totalStore
    },
    getPagination() {
      return this.$store.state.agency.pagination
    },
    getPaginationByGroup() {
      return this.$store.state.agency.paginationByGroup
    },
    getAgencies() {
      return this.$store.state.agencies.list
    },
    filterAgency() {
      return this.getAgencies.filter(
        (agency) => agency.id === Number(this.$route.params.id)
      )
    },
    getNameAgency() {
      return this.filterAgency[0]?.name
    },
    checkSearchQuery() {
      return this.searchQuery.length !== 0
    },
  },
  async created() {
    this.params = {
      limit: 5,
      page: this.value,
    }

    await this.$store.dispatch("agency/getListGroup", this.params)

    await this.$store.dispatch("groups/getModels", {
      agency: this.$route.params.id,
    })
    this.groupOptions = this.$store.state.groups.list
    this.$store.commit("agency/setListGroup", this.groupOptions)

    this.$store.commit("setShowLoading", true)
    await this.getListStoreManager(this.firstPage)
    this.$store.commit("setShowLoading", false)

    await this.$store.dispatch("agencies/getModels")
  },
  watch: {
    async groupSelected(value) {
      if (value) {
        this.getListStoreByGroup(value, this.valuePage)
      } else {
        this.getListStoreManager(this.valuePage)
      }
    },
    multipleSelection(value) {
      if (value.length !== this.list.length) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
    valuePage(currentPage) {
      if (currentPage !== this.firstPage) {
        // The selected group is different from the first page
        if (this.groupSelected) {
          this.getListStoreByGroup(this.groupSelected, currentPage)
          // paging data when search query
          if (this.checkSearchQuery) {
            this.list.slice(this.from, this.to)
          } else {
            this.getListStoreByGroup(this.groupSelected, currentPage)
          }
        } else {
          if (this.checkSearchQuery) {
            this.list.slice(this.from, this.to)
          } else {
            this.getListStoreManager(currentPage)
          }
        }
      } else {
        if (this.groupSelected) {
          this.getListStoreByGroup(this.groupSelected, this.firstPage)
          // paging data when search query
          if (this.checkSearchQuery) {
            this.list.slice(this.from, this.to)
          } else {
            this.getListStoreByGroup(this.groupSelected, this.firstPage)
          }
        } else {
          if (this.checkSearchQuery) {
            this.list.slice(this.from, this.to)
          } else {
            this.getListStoreManager(this.firstPage)
          }
        }
      }
    },
  },
  methods: {
    openModalCreateUpdate(type) {
      this.messages = null
      this.type = type
      if (type === "create") {
        this.form.groupID = ""
        this.form.name = ""
        this.form.stores = this.multipleSelection
      }

      if (type === "update") {
        this.form.groupID = this.getGroup(this.groupSelected)?.id
        this.form.name = this.getGroup(this.groupSelected)?.name
        this.form.stores = this.list
      }
      this.isGroupCreateShow = true
    },

    getGroup(id) {
      if (id) return this.groupOptions.find((item) => item.id === id)
      else return null
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    toggleSelection(rows) {
      if (this.selectAll === true) {
        this.$refs.multipleTable.clearSelection()
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    handleLike(row) {
      this.$store.commit("setUserIds", row.id)
      this.$router.push({ path: "/dashboard" })
    },

    async submitForm(type, form, groupLeader) {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        const groupList = form.stores.map((item) => item.id)
        const data = {
          name: form.name,
          leader_id: groupLeader,
          store_list: groupList.toString(),
          agency_id: this.$route.params.id,
        }
        if (type === "create") {
          await this.$store.dispatch("agency/createGroup", data)
        } else {
          await this.$store.dispatch("agency/updateGroup", {
            id: form.groupID,
            data: data,
          })

          this.getListStoreByGroup(this.groupSelected, this.valuePage)
        }
        await this.$store.dispatch("groups/getModels", {
          agency: this.$route.params.id,
        })

        this.params = {
          user_id: this.$route.params.id,
          limit: 5,
          page: this.valuePage,
        }
        await this.$store.dispatch("agency/getListStoreByManager", this.params)
        this.groupOptions = this.$store.state.groups.list
        this.messages = {
          data: this.$t("actions.messages.addGroupSuccess"),
          type: messageType.SUCCESS,
        }

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },

    viewReport() {
      const arr = this.multipleSelection.filter(
        (selected) => selected.status !== 0
      )

      if (this.multipleSelection.length <= 1) {
        this.$router.push({ path: "/report" })
      } else {
        this.$router.push({ path: "/report-multiple" })
      }

      this.$store.commit(
        "setUserIds",
        arr.map((selection) => selection.id)
      )
    },

    async getListStoreManager(page) {
      try {
        this.messages = null
        this.params = {
          user_id: this.$route.params.id,
          limit: 5,
          page: page,
        }
        await this.$store.dispatch("agency/getListStoreByManager", this.params)
        this.list = this.$store.state.agency.listStore
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async getListAllStoreManager() {
      try {
        this.messages = null
        this.params = {
          user_id: this.$route.params.id,
        }
        await this.$store.dispatch("agency/getListStoreByManager", this.params)
        this.list = this.$store.state.agency.listStore
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async getListStoreByGroup(id, page) {
      try {
        this.messages = null
        this.params = {
          limit: 5,
          page: page,
        }

        await this.$store.dispatch("agency/getListStoreByGroup", {
          groupId: id,
          params: this.params,
        })
        this.list = this.$store.state.agency.listStoreByGroup
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    inputSearch() {
      if (this.checkSearchQuery) {
        if (this.groupSelected) {
          this.getListStoreByGroup(this.groupSelected, this.valuePage)
        } else {
          this.getListAllStoreManager()
        }
      }
      this.getListStoreManager(this.firstPage)
    },

    changePage(value) {
      this.valuePage = value
    },

    changeValuePageGroup(value) {
      this.valuePageGroup = value
    },
  },
}
</script>

<style scoped>
.custom-checkbox {
  position: absolute;
  top: 18px;
  left: 25px;
  z-index: 10;
}
</style>
