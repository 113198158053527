<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        {{ $t("screens.dashboard.texts.manualDocumentTitle") }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <pdf :src="url"></pdf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import pdf from "vue-pdf"

export default {
  components: {
    pdf,
  },
  data() {
    return {
      messages: null,
      url: null,
    }
  },
  async created() {
    await this.getManualDocument()
  },
  methods: {
    async getManualDocument() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        await this.$store.dispatch("document/getManualDocument")
        const blob = new Blob([this.$store.state.document.document], {
          type: "application/pdf",
        })
        this.url = URL.createObjectURL(blob)
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
