import ProductApi from "@/services/productApi"

const api = new ProductApi()

const state = () => ({
  listProducts: [],
  listUpdates: [],
  listProductCategories: [],
  messages: {
    type: "",
    data: null,
  },
  item: {},
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
  },
})

const mutations = {
  setProducts(state, data) {
    state.listProducts = data
  },

  setItemProduct(state, data) {
    state.item = data
  },

  setProductCategories(state, data) {
    state.listProductCategories = data
  },

  deleteModel(state, { key, keyName }) {
    state.list = state.list.filter((x) => x[keyName] !== key)
  },

  delete(state, id) {
    state.listProducts = state.listProducts.filter(
      (product) => product.id !== id
    )
  },

  setMessages(state, messages) {
    state.messages = messages
  },

  clearMessages(state) {
    state.messages = { type: "", data: null }
  },
}

const actions = {
  async getProducts({ commit }, { type, userId }) {
    await api.getProducts({ type, userId }).then((res) => {
      commit("setProducts", res.data.data)
    })
  },

  async getProductById({ commit }, { type, key }) {
    return await api.getProductById({ type, key }).then((response) => {
      commit("setItemProduct", response.data.data)
    })
  },

  async getProductCategories({ commit }, { data }) {
    return await api.getProductCategories({ data }).then((response) => {
      commit("setProductCategories", response.data.data)
    })
  },

  async create({ commit }, data) {
    return await api.create(data)
  },

  async update({ commit }, { key, data }) {
    return await api.update({ key, data })
  },

  async delete({ commit }, { key, userId }) {
    return await api.delete(key, userId)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
