/* eslint-disable prettier/prettier */
import PublisherApi from "@/services/publisherApi"

const api = new PublisherApi()

const state = () => ({
  list: [],
  item: {},
  url: null,
})

const mutations = {
  setModels(state, data) {
    state.list = data
  },
  setModel(state, data) {
    state.item = data
  },
  setUrl(state, data) {
    state.url = data.urlAuth
  },
}

const actions = {
  async connect({ commit }, { data }) {
    await api.connect({ data }).then((response) => {
      commit("setUrl", response.data.data)
    })
  },

  async disconnect({ commit }, { data }) {
    await api.disconnect({ data })
  },

  async getPublisher({ commit }, { data }) {
    await api.getPublisher({ data }).then((response) => {
      commit("setModels", response.data.data)
    })
  },

  async syncGMB({ commit }, { data }) {
    await api.syncGMB({ data }).then((response) => {
      commit("setModel", response.data.data)
    })
  },

  async getFacebookPages({ commit }, { data }) {
    return await api.getFacebookPages({ data }).then((response) => {
      commit("setModels", response.data.data)
    })
  },
  async postFacebookPages({ commit }, { data }) {
    return await api.postFacebookPages({ data })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
