import Vue from "vue"
import Vuex from "vuex"
import auth from "./auth"
import document from "./document"
import publisher from "./publisher"
import payment from "./payment"
import agencies from "./agencies"
import customers from "./customers"
import stores from "./stores"
import groups from "./groups"
import entity from "./entity"
import agency from "./agency"
import groupsLeader from "./groupsLeader"
import offers from "./offers"
import events from "./events"
import media from "./media"
import products from "./products"
import news from "./news"
import post from "./post"
import postalCode from "./postalCode"
import dashboard from "./dashboard"
import report from "./report"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowLoading: false,
    breadcrumbs: [],
    userIds: null,
    userRole: null,
    userLogo: null,
    userObject: null,
    auth: auth,
  },
  mutations: {
    setShowLoading(state, flag) {
      state.isShowLoading = flag
    },
    setBreadcrumb(state, data) {
      state.breadcrumbs = data
    },
    setUserIds(state, data) {
      if (!data) {
        state.userIds = null
        localStorage.removeItem("userIds")

        return
      }

      state.userIds = data
      localStorage.setItem("userIds", data)
    },
    setUserRole(state, data) {
      if (!data) {
        state.userRole = null
        localStorage.removeItem("userRole")

        return
      }

      state.userRole = data
      localStorage.setItem("userRole", data)
    },
    setUserLogo(state, data) {
      if (!data) {
        state.userLogo = null
        localStorage.removeItem("userLogo")

        return
      }

      state.userLogo = data
      localStorage.setItem("userLogo", data)
    },
    setUserObject(state, data) {
      state.userObject = data
    },
  },
  getters: {
    userIds(state) {
      return state.userIds
    },
    userIdLogin(rootState) {
      return rootState.auth.user
    },
  },
  actions: {},
  modules: {
    auth,
    document,
    publisher,
    payment,
    agencies,
    customers,
    stores,
    groups,
    entity,
    agency,
    groupsLeader,
    offers,
    events,
    media,
    products,
    news,
    post,
    postalCode,
    dashboard,
    report,
  },
})
