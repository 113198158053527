import Swal from "sweetalert2"

export default {
  methods: {
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              )
            })
            .join("&")
      )
    },

    async checkAfterLogin() {
      if (this.$store.state.auth.token !== localStorage.getItem("token")) {
        await Swal.fire({
          icon: "warning",
          text: this.$t("actions.messages.loginError"),
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/setting-account"
          }
        })
      }
    },
  },
}
