<template>
  <FadeTransition :duration="300">
    <div class="c-loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions"

export default {
  components: {
    FadeTransition,
  },
}
</script>
