<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div class="row" v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="col-lg-12 col-md-12" v-else>
      <ConnectAccountList> </ConnectAccountList>
    </div>
  </div>
</template>

<script>
import ConnectAccountList from "./ConnectAccount/ConnectAccountList"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"

export default {
  components: {
    ConnectAccountList,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
}
</script>
