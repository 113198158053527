import EntityApi from "@/services/entityApi"

const api = new EntityApi()

const state = () => ({
  basicInformation: "",
  categories: "",
  contactWebsitesApps: "",
  businessDetails: "",
  locations: [],
  item: {},
  prefectures: [],
})

const mutations = {
  setCategories(state, data) {
    state.categories = data
  },

  setBasicInformation(state, data) {
    state.basicInformation = data
  },

  setContactWebsitesApps(state, data) {
    state.contactWebsitesApps = data
  },

  setBusinessDetails(state, data) {
    state.businessDetails = data
  },

  setLocations(state, data) {
    state.locations = data
  },

  setLocation(state, data) {
    state.location = data
  },

  setCitationStatus(state, data) {
    state.item = data
  },

  setPrefectures(state, data) {
    state.prefectures = data
  },
}

const actions = {
  async getCategories({ commit }, { data }) {
    await api.getCategories({ data }).then((response) => {
      commit("setCategories", response.data.data)
    })
  },

  async getBasicInformation({ commit }, { data }) {
    await api.getBasicInformation({ data }).then((response) => {
      commit("setBasicInformation", response.data.data.core_information)
      commit("setContactWebsitesApps", response.data.data.contact_website_apps)
      commit("setBusinessDetails", response.data.data.business_details)
    })
  },

  async putBasicInformation({ commit }, { data }) {
    await api.putBasicInformation({ data }).then((response) => {
      commit("setBasicInformation", response.data.data)
    })
  },

  async putContactWebsitesApps({ commit }, { data }) {
    await api.putContactWebsitesApps({ data })
  },

  async putBusinessDetails({ commit }, { data }) {
    await api.putBusinessDetails({ data })
  },

  async getLocations({ commit }, { data }) {
    await api.getLocations({ data }).then((response) => {
      commit("setLocations", response.data.data.locations)
    })
  },

  async postLocations({ commit }, { data }) {
    await api.postLocations({ data }).then((response) => {
      commit("setCitationStatus", response.data.data)
    })
  },

  async getPrefectures({ commit }, { data }) {
    await api.getPrefectures({ data }).then((response) => {
      commit("setPrefectures", response.data.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
