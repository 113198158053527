<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <h4 class="card-title">
          {{ $t("screens.dashboard.texts.connectAccountTitle") }}
        </h4>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <el-table
            header-row-class-name="text-primary"
            :data="list"
            style="width: 100%"
            :span-method="arraySpanMethod"
          >
            <el-table-column
              min-width="300"
              :label="$t('screens.dashboard.texts.publisher')"
            >
              <template slot-scope="scope">
                <img :src="scope.row.logo_url" width="60" height="60" alt="" />
                <span class="ml-2">
                  {{ scope.row.publisher_name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="300"
              :label="$t('screens.dashboard.texts.entity')"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index !== 2">
                  <a v-if="scope.row.location_name" class="text-info">
                    {{ scope.row.location_name }}
                  </a>
                  <p
                    v-if="scope.row.postal_code"
                    class="connect-account-list-text"
                  >
                    {{ scope.row.postal_code }}
                  </p>
                  <p
                    v-if="scope.row.address_lines"
                    class="connect-account-list-text"
                  >
                    {{ scope.row.address_lines }}
                  </p>
                  <p
                    v-if="scope.row.store_code"
                    class="connect-account-list-text text-muted"
                  >
                    {{ $t("screens.coreInformation.entityID") }}:
                    {{ scope.row.store_code }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="120"
              :label="$t('screens.dashboard.texts.status')"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.row.status === connectionStatusType.DISCONNECT"
                  class="text-danger"
                >
                  <i
                    :class="
                      !FREETRIAL_ALLOW_SNS.includes(scope.row.publisher_id)
                        ? classTypeIcon
                        : 'fa fa-times text-danger'
                    "
                    aria-hidden="true"
                  ></i>
                  <span
                    class="ml-2"
                    v-if="getUser() !== FREETRIAL_AVAILABILITY"
                  >
                    {{ $t("screens.dashboard.texts.disconnect") }}
                  </span>
                  <span
                    class="ml-2"
                    v-else-if="getUser() === FREETRIAL_AVAILABILITY"
                  >
                    {{
                      FREETRIAL_ALLOW_SNS.includes(scope.row.publisher_id)
                        ? $t("screens.dashboard.texts.disconnect")
                        : $t("screens.dashboard.texts.disable")
                    }}
                  </span>
                  <span class="ml-2" v-else>
                    {{ $t("screens.dashboard.texts.disable") }}
                  </span>
                </div>
                <div
                  v-else-if="
                    scope.row.status === connectionStatusType.CONNECTED
                  "
                  class="text-danger"
                >
                  <i class="fa fa-times text-danger" aria-hidden="true"></i>
                  <span class="ml-2">
                    {{ $t("screens.dashboard.texts.disconnect") }}
                  </span>
                </div>
                <div
                  v-else-if="scope.row.status === connectionStatusType.SYNC"
                  class="text-success"
                >
                  <i class="fa fa-check text-success" aria-hidden="true"></i>
                  <span class="ml-2">
                    {{ $t("screens.dashboard.texts.sync") }}
                  </span>
                </div>
                <div
                  v-else-if="
                    scope.row.status === connectionStatusType.SUBMITTED
                  "
                  class="text-warning"
                >
                  <i class="fa fa-refresh text-warning" aria-hidden="true"></i>
                  <span class="ml-2">
                    {{ $t("screens.dashboard.texts.submitted") }}
                  </span>
                </div>
                <div
                  v-else-if="scope.row.status === connectionStatusType.ERROR"
                  class="text-danger"
                >
                  <i class="fa fa fa-times text-danger" aria-hidden="true"></i>
                  <span class="ml-2">
                    {{ $t("screens.dashboard.texts.error") }}
                  </span>
                </div>
                <div
                  v-else-if="
                    scope.row.status === connectionStatusType.REQUESTING
                  "
                  class="text-primary"
                >
                  <i
                    class="fa fa fa-refresh text-primary"
                    aria-hidden="true"
                  ></i>
                  <span class="ml-2">
                    {{ $t("screens.dashboard.texts.requesting") }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="120"
              class-name="action-buttons td-actions"
              :label="$t('screens.dashboard.texts.actions')"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.status === connectionStatusType.DISCONNECT ||
                    scope.row.status === connectionStatusType.ERROR
                  "
                >
                  <p-button
                    type="success"
                    :disabled="handleTrial(scope.row.publisher_id)"
                    :title="$t('screens.dashboard.buttons.sync')"
                    size="sm"
                    @click="handleConnect(scope.row.publisher_key)"
                  >
                    {{ $t("screens.dashboard.buttons.sync") }}
                  </p-button>
                </div>

                <p-button
                  v-else-if="
                    scope.row.status === connectionStatusType.SUBMITTED ||
                    scope.row.status === connectionStatusType.SYNC ||
                    scope.row.status === connectionStatusType.REQUESTING
                  "
                  type="success"
                  :disabled="handleTrial(scope.row.publisher_id)"
                  :title="$t('screens.dashboard.buttons.disconnect')"
                  size="sm"
                  @click="
                    handleDisconnect(
                      scope.row.publisher_id,
                      scope.row.entity_id
                    )
                  "
                >
                  {{ $t("screens.dashboard.buttons.disconnect") }}
                </p-button>
              </template>
            </el-table-column>
            <el-table-column
              min-width="300"
              align="left"
              :label="$t('screens.dashboard.texts.statusDetail')"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.status === connectionStatusType.ERROR">
                  <div v-for="(l, i) in scope.row.log" :key="i">
                    <p>{{ l }}</p>
                  </div>
                </div>
                <span> </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="col-sm-6 pagination-info mt-2">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div> -->
        <!-- <div class="col-sm-6 mt-2">
          <pagination
            class="pull-right"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { Table, TableColumn } from "element-ui"
import { convertMessage } from "src/util/commons"
import {
  messageType,
  connectionStatusType,
  freeTrialType,
  FREETRIAL_ALLOW_SNS,
  citationType,
} from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import Swal from "sweetalert2"

Vue.use(Table)
Vue.use(TableColumn)

export default {
  components: {},
  mixins: [formMixin],
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      list: [],
      messages: null,
      connectionStatusType: connectionStatusType,
      classTypeIcon: null,
      FREETRIAL_ALLOW_SNS: FREETRIAL_ALLOW_SNS,
      FREETRIAL_AVAILABILITY: freeTrialType.FREETRIAL_AVAILABILITY,
      citationType: citationType,
    }
  },
  computed: {
    pagedData() {
      return this.list.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = this.list.length
        return this.pagedData
      }
      const result = this.list.filter((row) => {
        let isIncluded = false
        for (const key of this.propsToSearch) {
          const rowValue = row[key].toString()
          if (rowValue && rowValue.includes(this.searchQuery)) {
            isIncluded = true
          }
        }
        return isIncluded
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.list.length
      return this.list.length
    },
    getUrl() {
      return this.$store.state.publisher.url
    },
  },
  async created() {
    this.$store.commit("setShowLoading", true)
    await this.getPublisher()
    const item = this.$store.state.entity.item
    this.initContent(item)
    this.checkShowIcon()
  },
  methods: {
    handleUnlock() {
      this.$router.replace("/register-payment")
    },
    handleConnect(publisherKey) {
      this.connect(publisherKey)
    },
    handleDisconnect(publisherId, entityId) {
      this.disconnect(publisherId, entityId)
    },
    async getPublisher() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("publisher/getPublisher", { data })
        this.list = this.$store.state.publisher.list

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response?.data?.errors) {
          Swal.fire({
            icon: messageType.ERROR,
            text: convertMessage(err.response.data.errors).join(", "),
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.errors.500"),
            showConfirmButton: false,
            timer: 2000,
          })
        }
      }
    },
    async connect(publisherKey) {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        const data = {
          publisher_key: publisherKey,
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("publisher/connect", { data })
        if (data.publisher_key === "facebook") {
          data.publisher_key = "instagram"
          await this.$store.dispatch("publisher/connect", { data })
        }
        window.open(this.getUrl, "_self")

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response?.data?.errors) {
          Swal.fire({
            icon: messageType.ERROR,
            text: convertMessage(err.response.data.errors).join(", "),
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.errors.500"),
            showConfirmButton: false,
            timer: 2000,
          })
        }
      }
    },
    async disconnect(publisherId, entityId) {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          publisher_id: String(publisherId),
          entity_id: String(entityId),
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("publisher/disconnect", { data })
        if (data.publisher_id === "2") {
          data.publisher_id = "3"
          await this.$store.dispatch("publisher/disconnect", { data })
        }
        await this.getPublisher()
        this.$store.commit("setShowLoading", false)
        await Swal.fire({
          icon: messageType.SUCCESS,
          text: this.$t("actions.messages.disconnectSuccess"),
          showConfirmButton: false,
          timer: 2000,
        })
        this.$router.go(0)
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response?.data?.errors) {
          Swal.fire({
            icon: messageType.ERROR,
            text: convertMessage(err.response.data.errors).join(", "),
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.errors.500"),
            showConfirmButton: false,
            timer: 2000,
          })
        }
      }
    },
    checkShowIcon() {
      if (this.getUser() === this.FREETRIAL_AVAILABILITY) {
        this.classTypeIcon = "el-icon-warning"
      } else this.classTypeIcon = "fa fa-times text-danger"
    },
    async initContent(item) {
      const index = this.list.findIndex(
        (obj) => obj.publisher_key === item.publisher_key
      )
      if (index !== -1) {
        this.list[index].status = item.status
      }
    },
    handleTrial(id) {
      return (
        (this.getUser() === this.FREETRIAL_AVAILABILITY &&
          !FREETRIAL_ALLOW_SNS.includes(id)) ||
        (this.isTrial && !FREETRIAL_ALLOW_SNS.includes(id))
      )
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1 || rowIndex === 2) {
        if (
          columnIndex === 1 ||
          columnIndex === 2 ||
          columnIndex === 3 ||
          columnIndex === 4
        ) {
          return [2, 1]
        } else {
          return [1, 1]
        }
      }
    },
  },
}
</script>
<style scoped>
/deep/ .el-table tr:nth-child(2) td.el-table__cell:first-child,
.el-table tr:nth-child(2) th.el-table__cell.is-leaf:first-child {
  border-bottom: 1px solid transparent;
}

/deep/ .el-table tr:nth-child(3) td.el-table__cell:nth-last-child(-n+3),
.el-table tr:nth-child(3) th.el-table__cell.is-leaf:nth-last-child(-n+3) {
  display: none;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: unset;
}
</style>
