import $axios from "@/plugins/axios"
import { postType } from "@/util/constants"
export default class EventsApi {
  async getListEvent(userId) {
    return await $axios({
      method: "GET",
      url: `/posts/${postType.EVENT}?user_id=${userId}`,
    })
  }

  async getModel(key) {
    return await $axios({
      method: "GET",
      url: `/posts/${postType.EVENT}/${key}`,
    })
  }

  async create(data) {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    return await $axios({
      method: "POST",
      url: `/posts/events`,
      data,
      config,
    })
  }

  async update({ key, data }) {
    return await $axios({
      method: "POST",
      url: `/posts/events/${key}`,
      data,
    })
  }

  async delete(key, userId) {
    return await $axios({
      method: "DELETE",
      url: `/posts/events/${key}?user_id=` + userId,
    })
  }
}
