<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="card" v-else>
      <div class="card-header">
        <h4 class="card-title">
          {{ $t("screens.knowledgeTag.title") }}
        </h4>
      </div>
      <div class="card-body">
        <div class="knowledge-tag">
          <!-- <div class="__title">
          {{ $t("screens.knowledgeTag.wrapper") }}
        </div>
        <div class="__reasons">
          <div class="__reason">
            <img
              src="https://www.yext.com/s/schema/public/images/icon-structured-data.svg"
              alt=""
            />
            <p class="__text">
              {{ $t("screens.knowledgeTag.descriptionStructured") }}
            </p>
          </div>
          <div class="__reason">
            <img
              src="https://www.yext.com/s/schema/public/images/icon-sync.svg"
              alt=""
            />
            <p class="__text">
              {{ $t("screens.knowledgeTag.descriptionSync") }}
            </p>
          </div>
          <div class="__reason">
            <img
              src="https://www.yext.com/s/schema/public/images/icon-dashboard.svg"
              alt=""
            />
            <p class="__text">
              {{ $t("screens.knowledgeTag.descriptionDashboard") }}
            </p>
          </div>
        </div> -->
          <div class="__buttons">
            <el-button
              class="add-knowledge"
              type="info"
              @click="gotoHref('add-knowledge-tag')"
              >{{
                $t("screens.knowledgeTag.buttons.AddKnowledgeTagsToWebsite")
              }}</el-button
            >
            <el-button class="check-schema" @click="openModalMarkup">
              {{ $t("screens.knowledgeTag.buttons.CheckSchemaMarkup") }}
            </el-button>
          </div>
        </div>
      </div>

      <knowledge-tag-modal-verify-markup
        ref="verifyMarkup"
      ></knowledge-tag-modal-verify-markup>
    </div>
  </div>
</template>

<script>
import { Button } from "element-ui"
import VueExcelXlsx from "vue-excel-xlsx"
import Vue from "vue"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"
import KnowledgeTagModalVerifyMarkup from "./KnowledgeTag/KnowledgeTagModalVerifyMarkup.vue"

Vue.use(VueExcelXlsx)

export default {
  components: {
    [Button.name]: Button,
    PayRequired,
    ConfirmMailPayRequired,
    KnowledgeTagModalVerifyMarkup,
  },
  mixins: [formMixin],
  data() {
    return {
      lastDay: 30,
      images: [
        {
          url: "https://www.yext.com/s/schema/public/images/icon-structured-data.svg",
        },
        {
          url: "https://www.yext.com/s/schema/public/images/icon-sync.svg",
        },
        {
          url: "https://www.yext.com/s/schema/public/images/icon-dashboard.svg",
        },
      ],
      tableData: [
        {
          url: "https://fiddle.jshell.net/",
          date: "2016-05-03",
        },
        {
          url: "https://gtm-msr.appspot.com/",
          date: "2016-04-03",
        },
      ],
      tableColumns: [
        {
          prop: "url",
          label: "url",
          field: "url",
          width: 140,
          sortable: true,
        },
        {
          prop: "date",
          field: "date",
          label: this.$t("screens.knowledgeTag.lastActivityDate"),
          width: 60,
          sortable: true,
        },
      ],
    }
  },
  methods: {
    gotoHref(href) {
      this.$router.push(href)
    },
    openModalMarkup() {
      this.$refs.verifyMarkup.isVerifyMarkup = true
    },
  },
}
</script>

<style></style>
