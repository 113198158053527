<template>
  <div class="col-lg-6 col-md-6 ml-auto mr-auto forgot-password-page">
    <ValidationObserver ref="forgot-password" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <card type="login" class="forgot-password-page-card">
          <h3 slot="header" class="header text-center">
            {{ $t("screens.dashboard.texts.forgotPassword") }}
          </h3>

          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="email"
              v-model="form.email"
              placeholder="Eメール"
              :hasSuccess="passed"
              type="email"
              addon-left-icon="nc-icon nc-email-85"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              :error="failed ? $t('screens.validate.onlyEmail') : null"
            ></fg-input>
          </ValidationProvider>

          <br />

          <p-button
            native-type="submit"
            slot="footer"
            type="warning"
            round
            block
            class="col-lg-4 col-md-4 ml-auto mr-auto"
          >
            {{ $t("screens.buttons.confirm") }}
          </p-button>
        </card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Card, Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    Card,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        email: "",
      },
      messages: null,
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        await this.$store.dispatch("auth/forgotPassword", this.form)
        this.messages = {
          data: this.$t("actions.messages.forgotPasswordSuccess"),
          type: messageType.SUCCESS,
        }
        this.$store.commit("setShowLoading", false)
        this.resetForm("form", "forgot-password")
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
