export default {
  validate: {
    endDateError: "終了日及び投稿削除予約は現在以降の日付を指定してくさい。",
  },
  messages: {
    commons: {
      createSuccess: "アイテムが正常に作成された",
      updateSuccess: "項目が正常に更新される",
      deleteSuccess: "アイテムが正常に削除された",
    },
    registerInformationSuccess:
      "申し込みを受け付けました。登録メールアドレスにメールを送信しますので、メールをご確認ください。",
    forgotPasswordSuccess: "パスワードリセットのメールを送信しました。",
    changePasswordSuccess: "パスワードが正常に変更されました。",
    disconnectSuccess: "同期が解除しました。",
    syncSuccess: "同期が完了しました。",
    syncFacebookSuccess: "Facebookが正常に同期される",
    registerPaymentSuccess: "カード情報が登録されました。",
    registerPaymentFailure: "トークン取得に失敗しました。",
    isAddStore: "がグループに追加されます。",
    addGroupSuccess: "グループの作成が成功しました。",
    updateGroupSuccess: "グループが正常に更新される",
    changePaymentMethodSuccess: "支払い方法を変更しました。",
    changePaymentMethodPlan: "お支払いプランを変更しました。",
    pleaseEnterCardInforCorrect: "カード情報を正しく入力してください。",
    pleaseEnterCardInfor: "カード情報を入力してください。",
    infoUpdateSuccess: "情報が更新されました。",
    uploadImageSuccess: "写真・動画のアップロードが成功しました。",
    uploadImageError: "写真・動画のアップロードが失敗しました。",
    uploadImage20MbError: "20MB以下の動画をアップロードしてください",
    postSuccess: "投稿が作成されました。",
    updatePostSuccess: "投稿が更新されました。",
    postError: "投稿の作成が失敗しました。",
    dashboardError: "データが読み込めません。",
    dashboardNotData: "選択した期間のデータがまだ十分ではありません。",
    loginError:
      "すでに別のアカウントでログインしているから操作はダメです。ログアウトしてから再チャレンジしてください。",
    accessTokenError: "アクセストークンの有効期限が切れました。",
    viewReportError: [
      "選択した店舗一覧にはGoogleに未連携店舗があります。 レポートの閲覧のため、Googleに連携するのは必要です。",
      "下記の店舗をご確認ください。",
    ],
    registerPaymentCardError: "カード情報が正しくありません。",
    registerPaymentError:
      "お支払いが完了できませんでした。詳しくはメールで送信しました。ご確認お願いします。",
    fileFormatError: "指定できないファイル形式です",
    fileResolutionError: "解像度が大きすぎます（小さすぎます）",
    fileSizeError: "サイズが大きすぎます（小さすぎます）",
    fileResolutionSmallerError: ["解像度が小さすぎます", "以上"],
    fileResolutionBiggerError: ["解像度が大きすぎます", "未満"],
    fileSizeSmallerError: ["サイズが小さすぎます", "以上"],
    fileSizeBiggerError: ["サイズが大きすぎます", "未満"],
    fileDurationSmallerError: ["は少なくとも", "秒でなければなりません。"],
    fileDurationBiggerError: ["は", "秒を超えてはいけません。"],
  },
  errors: {
    500: "何らかのエラーが発生しました",
  },
}
