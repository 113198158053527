import $axios from "@/plugins/axios"
export default class GroupsLeaderApi {
  async getListGroupsByLeader(params) {
    return await $axios.get("/group-leader/groups", params)
  }

  async getListStoresByLeader(params) {
    return await $axios.get("/group-leader/stores", params)
  }

  async getListStoresByGroup({ group, params }) {
    return await $axios({
      method: "GET",
      url: `/group-leader/groups/${group}/stores`,
      params,
    })
  }

  async getListStoreByLeaderId(params) {
    return await $axios({
      method: "GET",
      url: "/group-leader/stores",
      params,
    })
  }
}
