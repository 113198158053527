<template>
  <div>
    <ValidationObserver ref="register-information" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <h3 slot="header" class="header text-center">
          {{ $t("screens.registerInformation.texts.title") }}
        </h3>

        <ValidationProvider
          :name="$t('screens.registerInformation.forms.fullName')"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group col-lg-10 col-md-10 ml-auto mr-auto">
            <label>
              {{ $t("screens.registerInformation.forms.fullName") }}
              <span class="text-danger">*</span>
            </label>
            <fg-input
              v-model="form.full_name"
              :placeholder="
                $t('screens.registerInformation.forms.fullNamePlaceholder')
              "
              type="text"
              addon-left-icon="nc-icon nc-single-02"
              :error="errors[0]"
            ></fg-input>
          </div>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('screens.registerInformation.forms.companyName')"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group col-lg-10 col-md-10 ml-auto mr-auto">
            <label>
              {{ $t("screens.registerInformation.forms.companyName") }}
              <span class="text-danger">*</span>
            </label>
            <fg-input
              v-model="form.company_name"
              type="text"
              addon-left-icon="nc-icon nc-briefcase-24"
              :error="errors[0]"
            ></fg-input>
          </div>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('screens.registerInformation.forms.storeName')"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group col-lg-10 col-md-10 ml-auto mr-auto">
            <label>
              {{ $t("screens.registerInformation.forms.storeName") }}
              <span class="text-danger">*</span>
            </label>
            <fg-input
              v-model="form.store_name"
              type="text"
              addon-left-icon="nc-icon nc-shop"
              :error="errors[0]"
            ></fg-input>
          </div>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('screens.registerInformation.forms.email')"
          rules="required|email"
          v-slot="{ errors }"
        >
          <div class="form-group col-lg-10 col-md-10 ml-auto mr-auto">
            <label>
              {{ $t("screens.registerInformation.forms.email") }}
              <span class="text-danger">*</span>
            </label>
            <fg-input
              name="email"
              v-model="form.email"
              type="email"
              addon-left-icon="nc-icon nc-email-85"
              :error="errors[0]"
            ></fg-input>
          </div>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('screens.registerInformation.forms.phone')"
          v-slot="{ errors }"
        >
          <div class="form-group col-lg-10 col-md-10 ml-auto mr-auto">
            <label>
              {{ $t("screens.registerInformation.forms.phone") }}
            </label>
            <fg-input
              v-model="form.phone"
              type="text"
              :placeholder="
                $t('screens.registerInformation.forms.phonePlaceholder')
              "
              addon-left-icon="nc-icon nc-tablet-2"
              :error="errors[0]"
            ></fg-input>
          </div>
        </ValidationProvider>

        <div class="col-lg-10 col-md-10 ml-auto mr-auto">
          <p class="register-information-terms--text">
            {{ $t("screens.registerInformation.texts.question") }}
          </p>
          <el-select
            class="register-select"
            v-model="form.knowledge"
            :placeholder="$t('screens.registerInformation.forms.default')"
          >
            <el-option
              v-for="option in knowledgeOptions"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </div>

        <br />

        <div
          slot="footer"
          class="typography-line mb-3 register-information-terms col-lg-8 col-md-8 ml-auto mr-auto"
        ></div>
        <p-button
          native-type="submit"
          slot="footer"
          type="primary"
          class="col-lg-4 col-md-4 ml-auto mr-auto"
          round
          block
        >
          {{ $t("screens.registerInformation.buttons.submit") }}
        </p-button>
      </form>
    </ValidationObserver>
    <modal
      class="custom-modal"
      :show.sync="isConfirm"
      headerClasses="justify-content-center"
      v-if="isConfirm === true"
    >
      <h4 slot="header" class="title title-up">
        {{ $t("screens.registerInformation.texts.confirmInformation") }}
      </h4>
      <confirm-register
        :formConfirm="form"
        :registerType="registerType"
        @closeModal="isConfirm = false"
      />
    </modal>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import { Button } from "src/components/UIComponents"
import { registerType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import ConfirmRegister from "../ConfirmRegister.vue"

export default {
  mixins: [formMixin],
  components: {
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    ConfirmRegister,
  },
  data() {
    return {
      form: {
        full_name: "",
        company_name: "",
        store_name: "",
        email: "",
        phone: "",
        knowledge: "",
        agent_code: "0000",
      },
      knowledgeOptions: [
        {
          label: this.$t("screens.registerInformation.forms.search"),
          value: "search",
        },
        {
          label: this.$t("screens.registerInformation.forms.article"),
          value: "article",
        },
        {
          label: this.$t("screens.registerInformation.forms.advertisement"),
          value: "advertisement",
        },
        {
          label: this.$t("screens.registerInformation.forms.acquaintance"),
          value: "acquaintance",
        },
        {
          label: this.$t("screens.registerInformation.forms.sale"),
          value: "sale",
        },
        {
          label: this.$t("screens.registerInformation.forms.other"),
          value: "other",
        },
      ],
      registerType: registerType.STORE,
      isConfirm: false,
    }
  },
  methods: {
    onSubmit() {
      this.isConfirm = true
    },
  },
}
</script>

<style scoped>
.register-select {
  width: 100%;
  margin-top: 5px;
}

.register-select >>> input {
  background-color: transparent !important;
}

.register-select >>> input::placeholder {
  color: #66615b !important;
}

.register-select >>> .el-select__caret {
  color: #66615b !important;
}
</style>
