<template>
  <div>
    <el-row>
      <el-col
        :span="5"
        v-for="(product, index) in products"
        :key="product.id"
        :offset="index > 0 ? 1 : 0"
      >
        <el-card :body-style="{ padding: '0px' }">
          <div class="image">
            <span class="status">pending</span>
            <img
              src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
            />
          </div>
          <div style="padding: 8px" class="content">
            <p>{{ product.productName }}</p>
            <p>¥{{ product.minimumPrice }} - ¥{{ product.maximumPrice }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Row, Col, Card, Button } from "element-ui"
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Button.name]: Button,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.el-row {
  .el-card__body {
    .card .image {
      width: 100px;
      height: 100px;
    }

    .image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .status {
        position: absolute;
        border: 1px solid gray;
        background: #fff;
        color: #000;
        border-radius: 5px;
        padding: 0 6px 0 6px;
        left: 6px;
        top: 6px;
        font-weight: bold;
      }
    }
  }
}
</style>
