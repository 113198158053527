<template>
  <div>
    <tabs value="Description" tab-content-classes="text-center">
      <tab-pane
        :title="$t('screens.dashboard.texts.allProduct')"
        key="allProduct"
        v-if="products"
      >
        <product-list :products="products"></product-list>
      </tab-pane>
      <tab-pane
        v-for="(cat, idx) in categories"
        :title="cat.category"
        :key="idx"
      >
        <el-row v-for="(product, index) in cat.products" :key="product.id">
          <el-col :span="5" :offset="index > 0 ? 1 : 0">
            <el-card :body-style="{ padding: '0px' }">
              <div class="image">
                <span class="status">pending</span>
                <img
                  src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                />
              </div>
              <div style="padding: 8px" class="content">
                <p>{{ product.productName }}</p>
                <p>¥{{ product.minimumPrice }} - ¥{{ product.maximumPrice }}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import { Tabs, TabPane } from "src/components/UIComponents"
import { Row, Col, Card, Button } from "element-ui"
import ProductList from "./ProductList.vue"

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Button.name]: Button,
    TabPane,
    Tabs,
    ProductList,
  },
  data() {
    return {
      products: [
        {
          id: 1,
          productName: "name test 1",
          category: "category 1",
          minimumPrice: 12,
          maximumPrice: 24,
        },
        {
          id: 2,
          productName: "name test 2",
          category: "category 2",
          minimumPrice: 13,
          maximumPrice: 26,
        },
      ],
      categories: [],
      keyNames: [],
      keyValues: [],
      productCat: [],
    }
  },
  created() {
    this.productsByCategory()
  },
  methods: {
    productsByCategory() {
      this.categories = this.formatProducts(this.products)
    },
    formatProducts(products) {
      const categories = [
        ...new Set(products.map((product) => product.category)),
      ]

      return categories.reduce((acc, category) => {
        const _products = products.filter(
          (product) => product.category === category
        )
        return [...acc, { category: category, products: _products }]
      }, [])
    },
  },
}
</script>
<style lang="scss" scoped>
.el-row {
  .el-card__body {
    .card .image {
      width: 100px;
      height: 100px;
    }

    .image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      .status {
        position: absolute;
        border: 1px solid gray;
        background: #fff;
        color: #000;
        border-radius: 5px;
        padding: 0 6px 0 6px;
        left: 6px;
        top: 6px;
        font-weight: bold;
      }
    }
  }
}
</style>
