import { roleType } from "src/util/constants"
import moment from "moment"

export default {
  data() {
    return {
      roleType: roleType,
    }
  },
  methods: {
    resetForm(formName, formObserver) {
      this[formName] = {}
      this.$nextTick(() => {
        this.$refs[formObserver].reset()
      })
    },
    generatorImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          const data = event.target.result
          resolve(data)
        }
        reader.readAsDataURL(file)
      })
    },
    handlerUploadFile(events) {
      const { files } = events.target
      if (!files.length) return null
      return files[0]
    },
    getUser() {
      let permissionView = 0
      if (this.$store.state.auth.user) {
        if (this.checkPaymentFail === false && this.checkFreeTrial === true) {
          permissionView = 1
          return permissionView
        } else {
          permissionView = 2
          return permissionView
        }
      }
      return 99
    },
    async getLogo() {
      if (this.$store.state.auth.user.role === roleType.MANAGER) {
        await this.$store.dispatch("stores/getModels")
        const founded = this.$store.state.stores.list.find(
          (store) => this.userIds[0] === store.id
        )
        this.$store.commit("setUserObject", founded)

        if (founded?.role === roleType.AGENCY) {
          this.$store.commit("setUserRole", founded?.role)
        } else {
          this.$store.commit("setUserRole", null)
        }
        this.$store.commit("setUserLogo", founded?.agency_logo)
      } else if (this.$store.state.auth.user.role === roleType.AGENCY) {
        await this.$store.dispatch("agency/getListStore")
        const founded = this.$store.state.agency.listStore.find(
          (store) => this.userIds[0] === store.id
        )
        this.$store.commit("setUserObject", founded)

        if (founded?.role === roleType.AGENCY) {
          this.$store.commit("setUserRole", founded?.role)
        } else {
          this.$store.commit("setUserRole", null)
        }
        this.$store.commit("setUserLogo", founded?.agency_logo)
      } else {
        this.$store.commit("setUserRole", null)
        this.$store.commit(
          "setUserLogo",
          this.$store.state.auth.user.agency_logo
        )
      }
    },
    getExtension(path) {
      const basename = path.split(/[\\/]/).pop()
      const pos = basename.lastIndexOf(".")

      if (basename === "" || pos < 1) return ""

      return basename.slice(pos + 1)
    },
    validateEarlier(value) {
      return (
        moment(
          new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })
        ).format("YYYY-MM-DD HH:mm:ss") > value
      )
    },
  },

  computed: {
    isMultipleReport() {
      return (
        this.$route.name === "Report" ||
        this.$route.name === "ReportMultiple" ||
        this.$route.name === "Request Change Information" ||
        this.$route.name === "Posts" ||
        this.$route.name === "Post"
      )
    },
    isSingleReport() {
      return (
        this.$route.name === "Dashboard" ||
        this.$route.name === "Overview Agencies" ||
        this.$route.name === "Change Payment Method" ||
        this.$route.name === "Connect Account" ||
        this.$route.name === "Connect Location" ||
        this.$route.name === "Manual Document" ||
        this.$route.name === "Register Payment" ||
        this.$route.name === "Basic Information" ||
        this.$route.name === "Knowledge Tag" ||
        this.$route.name === "Add Knowledge Tag" ||
        this.$route.name === "Manual Document" ||
        this.$route.name === "Media"
      )
    },
    checkActiveRoute() {
      return (
        this.$route.name === "Overview" ||
        this.$route.name === "Overview Agencies" ||
        this.$route.name === "Change Password" ||
        this.$route.name === "Setting Account" ||
        this.$route.name === "Manual Document"
      )
    },
    userIds() {
      return this.$store.getters.userIds
        ? JSON.parse("[" + this.$store.getters.userIds + "]")
        : []
    },
    checkFreeTrial() {
      return (
        this.$store.state.auth.user?.role === roleType.FREETRIAL &&
        this.$store.state.auth.user.in_trial === false
      )
    },
    checkPaymentFail() {
      return this.$store.state.auth.user?.payment_fail
    },
    isTrial() {
      return this.$store.state.auth.user?.role === roleType.FREETRIAL
    },
    userRole() {
      return this.$store.state.userRole
    },
    userLogo() {
      return this.$store.state.userLogo
        ? this.$store.state.userLogo
        : "https://semantic-ui.com/images/wireframe/image.png"
    },
    isCreditCard() {
      return (
        this.$store.state.payment.paymentMethod?.name !==
        this.$t("screens.registerInformation.texts.creditCard")
      )
    },
    authUser() {
      return this.$store.state.auth.user
    },
    isLeader() {
      return this.$store.state.auth.user?.is_leader
    },
  },
}
