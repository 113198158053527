<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          v-if="display"
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          ></i>
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button v-if="display" @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>

      <div
        v-if="!conditionDisplaySelectShop"
        style="background: transparent; height: 52px; width: 10px"
      ></div>

      <div
        class="navbar-shop"
        v-if="conditionDisplaySelectShop"
        style="margin: 10px 0"
      >
        <!-- multiple select -->
        <el-select
          v-show="isMultipleReport"
          v-model="storeMultiSelected"
          multiple
          collapse-tags
          class="select-primary multiple-select"
          size="small"
          placeholder="CHOOSE SHOP"
          filterable
          :no-match-text="$t('screens.overviewAgency.label.noData')"
          @change="handleChangeMultiSelect(storeMultiSelected)"
        >
          <el-option
            v-for="option in storesOptions"
            class="select-primary"
            :key="option.id"
            :label="option.name"
            :value="option.id"
            :disabled="option.status === 0"
          >
          </el-option>
        </el-select>

        <!--single select -->
        <el-select
          v-show="isSingleReport"
          v-model="storeSingleSelected"
          clearable
          class="select-primary single-select"
          size="small"
          placeholder="CHOOSE SHOP"
          filterable
          :no-match-text="$t('screens.overviewAgency.label.noData')"
          @change="handleChangeSingleSelect(storeSingleSelected)"
        >
          <el-option
            class="select-primary"
            v-for="option in storesOptions"
            :key="option.id"
            :label="option.name"
            :value="option.id"
            :disabled="
              (!option.in_trial && option.role === 0) || option.status === 0
            "
          >
          </el-option>
        </el-select>

        <!-- handle dashboard & report -->
        <el-button
          v-show="isMultipleReport || isSingleReport"
          class="ml-2"
          type="info"
          round
          @click="clickSetReport"
        >
          <span>
            {{ $t("screens.dashboard.texts.select") }}
          </span>
        </el-button>
      </div>
    </div>

    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <drop-down
          icon="nc-icon nc-bell-55"
          tag="li"
          position="right"
          direction="none"
          class="nav-item dropdown"
        >
          <a
            slot="title"
            slot-scope="{ isOpen }"
            class="nav-link dropdown-toggle"
            :class="{ active: checkActiveRoute }"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
          >
            <i class="nc-icon nc-badge"></i>
            <p>
              <span class="d-md-block mr-2">
                {{ $t("screens.dashboard.buttons.settingAccount") }}
              </span>
            </p>
          </a>

          <a
            class="dropdown-item"
            href="https://help.lighten-meo.com"
            target="_blank"
            rel="https://help.lighten-meo.com"
          >
            <i class="nc-icon nc-book-bookmark dropdown-item--link mr-2"></i>
            <span class="dropdown-item--link">
              {{ $t("screens.dashboard.buttons.manual") }}
            </span>
          </a>
          <div class="dropdown-item" @click="openChangePassword">
            <i class="nc-icon nc-lock-circle-open dropdown-item--link mr-2"></i>
            <span class="dropdown-item--link">
              {{ $t("screens.dashboard.buttons.changePassword") }}
            </span>
          </div>
          <div class="dropdown-item" @click="openSettingAccount">
            <i class="nc-icon nc-circle-10 dropdown-item--link mr-2"></i>
            <span class="dropdown-item--link">
              {{ $t("screens.dashboard.buttons.changeInformation") }}
            </span>
          </div>
          <router-link
            v-if="
              getRole === roleType.AGENCY ||
              getRole === roleType.MANAGER ||
              isLeader === true
            "
            class="dropdown-item"
            to="/overview"
          >
            <i class="nc-icon nc-refresh-69 dropdown-item--link mr-2"></i>
            <span class="dropdown-item--link">
              {{ $t("screens.dashboard.buttons.switchAccount") }}
            </span>
          </router-link>
        </drop-down>

        <li class="nav-item">
          <a class="nav-link btn-magnify" @click="logout">
            <i class="nc-icon nc-button-power"></i>
            <p>
              <span class="d-md-block">
                {{ $t("screens.dashboard.buttons.logout") }}
              </span>
            </p>
          </a>
        </li>

        <!-- <el-select
          class="select-danger language-select"
          size="small"
          placeholder="Single Select"
          v-model="language"
        >
          <el-option
            v-for="option in languages"
            class="select-danger nav-item"
            :value="option.value"
            :label="option.label"
            :key="option.label"
          >
          </el-option>
        </el-select> -->
      </ul>
    </template>

    <change-password-update-form
      ref="modalChangePassword"
    ></change-password-update-form>

    <setting-account-update-form
      ref="modalSettingInformation"
    ></setting-account-update-form>
  </navbar>
</template>

<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents"
import { IMAGE_SIZE, roleType, messageType } from "src/util/constants"
import { Select, Option, Button } from "element-ui"
import { convertMessage } from "src/util/commons"
import formMixin from "@/mixins/formMixin"
import routerMixin from "@/mixins/routerMixin"
import ChangePasswordUpdateForm from "../Views/Dashboard/ChangePassword/ChangePasswordUpdateForm.vue"
import SettingAccountUpdateForm from "../Views/Dashboard/SettingAccount/SettingAccountUpdateForm.vue"

export default {
  mixins: [formMixin, routerMixin],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    Navbar,
    NavbarToggleButton,
    ChangePasswordUpdateForm,
    SettingAccountUpdateForm,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      roleType: roleType,
      storesOptions: [],
      storeMultiSelected: [],
      storeSingleSelected: "",
      toPathRoute: "",
      isMultipleSelect: false,
      languages: [
        { label: "日本語", value: 0 },
        { label: "Tiếng Việt", value: 1 },
        { label: "Français", value: 2 },
        { label: "Thailand", value: 3 },
      ],
      language: 0,
      isShow: false,
      IMAGE_SIZE: IMAGE_SIZE,
      display: false,
    }
  },
  props: {
    title: {
      type: String,
      default: "",
      description: "Sidebar title",
    },
  },
  created() {
    if (this.$route.path) {
      this.toPathRoute = this.$route
    }

    if (this.getRole === roleType.MANAGER) {
      this.getStoresManager()
    }

    if (this.getRole === roleType.AGENCY) {
      this.getStoresAgency()
    }

    if (this.isLeader === true) {
      this.getStoresGroupLeader()
    }

    this.getLogo()
  },
  computed: {
    userName() {
      return this.$store.state.auth.user?.name
    },
    getRole() {
      return this.$store.state.auth.user?.role
    },
    getListStoreSelected() {
      return this.$store.state.report.listStoreSelected
    },
    conditionDisplaySelectShop() {
      return (
        (this.getRole === roleType.MANAGER ||
          this.getRole === roleType.AGENCY ||
          this.isLeader === true) &&
        this.toPathRoute.name !== "Overview" &&
        this.toPathRoute.name !== "Overview Agencies"
      )
    },
  },
  watch: {
    $route: {
      async handler(to, from) {
        // handle connect gmb
        if (
          to.name !== "Connect Location" &&
          to.name !== "Connect Account" &&
          to.name !== "Overview" &&
          to.name !== "Overview Agencies" &&
          to.name !== "Change Password" &&
          to.name !== "Setting Account" &&
          to.name !== "Manual Document" &&
          to.name !== "Register Payment" &&
          to.name !== "Facebook"
        ) {
          if (this.$store.state.auth.connectGMB === false) {
            this.$router.push("google-my-business")
          }
        }

        await this.checkAfterLogin()
        await this.$store.dispatch("auth/refresh")
        await this.getLogo()

        // handle sidebar display
        if (
          to.name === "Overview" ||
          to.name === "Overview Agencies" ||
          to.name === "Change Password" ||
          to.name === "Setting Account" ||
          to.name === "Manual Document"
        ) {
          if (
            this.getRole === roleType.AGENCY ||
            this.getRole === roleType.MANAGER ||
            this.isLeader === true
          ) {
            this.display = false
          } else {
            this.display = true
          }
        } else {
          this.display = true
        }
        this.toPathRoute = to

        // handle selection store
        if (this.userIds.length > 0) {
          if (this.userIds.length > 1) {
            this.storeMultiSelected = this.userIds
          } else {
            this.storeSingleSelected = Number(this.userIds)
            this.storeMultiSelected = this.userIds
          }
        } else {
          this.storeSingleSelected = null
          this.storeMultiSelected = []
        }
      },
      immediate: true,
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize()
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar
    },
    async logout() {
      this.$store.commit("setShowLoading", true)
      await this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$store.commit("auth/postLogout")
          this.$router.replace("/login")
        })
        .catch(() => {
          this.$store.commit("auth/postLogout")
          this.$router.replace("/login")
        })
      this.$store.commit("setUserIds", null)
      this.$store.commit("setShowLoading", false)
    },
    async getStoresManager() {
      try {
        this.messages = null
        await this.$store.dispatch("stores/getModels")
        this.storesOptions = this.$store.state.stores.list
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },
    async getStoresAgency() {
      try {
        this.messages = null
        await this.$store.dispatch("agency/getListStore")
        this.storesOptions = this.$store.state.agency.listStore
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },
    async getStoresGroupLeader() {
      try {
        this.messages = null
        const params = {
          order_by: "id,asc",
        }
        await this.$store.dispatch("groupsLeader/getListStoresByLeader", params)
        this.storesOptions = this.$store.state.groupsLeader.listStoresByLeader
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },
    async clickSetReport() {
      const lengthSelected = 1
      this.getLogo()

      if (
        this.toPathRoute.name === "Report" ||
        this.toPathRoute.name === "ReportMultiple" ||
        this.toPathRoute.name === "Request Change Information" ||
        this.$route.name === "Post" ||
        this.$route.name === "Posts"
      ) {
        this.$store.commit("setUserIds", this.storeMultiSelected)
      } else {
        this.$store.commit("setUserIds", this.storeSingleSelected)
      }

      switch (this.toPathRoute.name) {
        case undefined:
        case "Overview":
        case "Manual Document":
        case "Change Password":
        case "Setting Account":
          if (this.storeSingleSelected) {
            this.$router.push("dashboard").catch((err) => err)
          }
          break
        case "Report":
          if (this.storeMultiSelected.length > lengthSelected) {
            this.$router.push("report-multiple").catch((err) => err)
          } else {
            this.$router.push("report").catch((err) => err)
          }
          break
        case "ReportMultiple":
          if (this.storeMultiSelected.length <= lengthSelected) {
            this.$router.push("report").catch((err) => err)
          }
          break
      }
    },
    handleChangeSingleSelect(value) {
      this.storeSingleSelected = value
    },
    handleChangeMultiSelect(value) {
      this.storeMultiSelected = value

      this.$store.commit("report/setListStoreSelected", value)
    },
    openChangePassword() {
      this.$refs.modalChangePassword.isChangePassword = true
    },
    openSettingAccount() {
      this.$refs.modalSettingInformation.isSettingInformation = true
    },
  },
}
</script>

<style scoped>
.active {
  color: #51cbce !important;
}

.is-round {
  padding: 7px 19px;
}

.language-select {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.language-select >>> .el-input__inner {
  max-width: 160px !important;
}

@media only screen and (max-width: 600px) {
  .single-select >>> .el-input__inner {
    max-width: 160px !important;
  }

  .multiple-select >>> .el-input__inner {
    max-width: 160px !important;
  }

  .multiple-select >>> .el-select__tags {
    max-width: 93px !important;
  }
}

@media only screen and (min-width: 992px) {
  .single-select >>> .el-input__inner {
    max-width: 100% !important;
  }

  .multiple-select >>> .el-input__inner {
    max-width: 100% !important;
  }

  .multiple-select >>> .el-select__tags {
    max-width: 140px !important;
  }
}
</style>
