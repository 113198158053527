export default {
  domain: {
    meoSystem: `<p>この利用規約（以下「本規約」といいます。）は、Porto株式会社（以下「弊社」といいます。）が開発し、各販売代理店がカスタマイズし提供する『MEO-System.net』（以下「本システム」といいます。）をお客様に使用していただく前提となる条件を記載したものです。本システムのご利用前に、必ず本規約の全文をよくお読みください。</p>

    <p>本規約は民法548条の2が定める定型約款に該当し、本システムのアカウント発行を受けた場合には、本規約の各条項に同意したものとみなされますので、ご了承ください。</p>

    <p><b>第１条&nbsp;&nbsp; &nbsp;（使用許諾等）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様が本規約に同意し遵守されることを条件として、本システムを使用する非独占的権利をお客様に許諾いたします。ただし、かかる許諾は、お客様が第三者に対し再使用許諾する権利を含むものではありません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関する著作権その他の知的財産権は、弊社に帰属します。前項による許諾により、本システムに関する著作権その他の知的財産権を弊社からお客様に譲渡するものではありません。</p>

    <p><b>第２条&nbsp;&nbsp; &nbsp;（使用条件）</b><br />
    １ 弊社は、お客様が本システムを弊社が定める動作環境条件に適合するお客様のパソコンやスマートフォン等の端末（以下「端末」といいます。）にて使用することを許諾いたします。なお、弊社は、本システムがすべての端末に対応することを保証するものではありません。<br />
    ２ お客様が本システムを利用するにあたり使用する各種SNSアカウントは、お客様の責任において作成および管理運用するものとし、それら各種SNSアカウントに対して弊社は一切の責任を負いません</p>

    <p><b>第３条&nbsp;&nbsp; &nbsp;（禁止事項）</b><br />
    お客様が、以下の各号の行為を行うことは禁止いたします。お客様が以下の各号の行為を行いまたは行うおそれがある場合、弊社は、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本システムのソースコード、構造、アイデア等を解析する行為<br />
    ２&nbsp;&nbsp; &nbsp;本システムの複製、改変、翻案または他のソフトウェアと結合等する行為<br />
    ３&nbsp;&nbsp; &nbsp;本システムに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊する行為<br />
    ４&nbsp;&nbsp; &nbsp;本システムを第三者に送信可能な状態でネットワーク上に蓄積する行為、また本システムを第三者に送信する行為<br />
    ５&nbsp;&nbsp; &nbsp;本システムまたは本システムに付されている著作権表示およびその他の権利表示を除去または変更する行為<br />
    ６&nbsp;&nbsp; &nbsp;本システムおよび本規約に基づく本システムの使用権を第三者に再許諾、譲渡、移転等の処分をする行為<br />
    ７&nbsp;&nbsp; &nbsp;本システムの譲渡、転売、貸与・レンタルに類する行為、または中古取引をする行為<br />
    ８&nbsp;&nbsp; &nbsp;弊社がお客様に提供する本システムの識別情報（アカウント等）を第三者へ開示・提供する行為<br />
    ９&nbsp;&nbsp; &nbsp;法令に違反する行為または犯罪行為に関連する行為<br />
    １０&nbsp;&nbsp; &nbsp;公序良俗に反する行為<br />
    １１&nbsp;&nbsp; &nbsp;反社会的勢力等に対して直接または間接に利益を供与する行為<br />
    １２&nbsp;&nbsp; &nbsp;その他、弊社が不適切と判断する行為</p>

    <p><b>第４条&nbsp;&nbsp; &nbsp;（本システムの提供の停止等）</b><br />
    弊社は、以下のいずれかの事由が生じた場合、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムに関わる通信機器設備等のメンテナンス、修理等を定期的または緊急に行う場合<br />
    ２&nbsp;&nbsp; &nbsp;地震、落雷等の天災地変、火災・停電、戦争・暴動・内乱、疫病その他の伝染病、法令の改廃制定、公権力による命令処分、労働争議その他の不可抗力により、本システムの提供が困難である場合<br />
    ３&nbsp;&nbsp; &nbsp;アクセス過多、その他予期せぬ要因で本システムに負荷が集中し、本システムの提供が困難である場合<br />
    ４&nbsp;&nbsp; &nbsp;その他、弊社が本システムの停止または中断が必要であると判断した場合</p>

    <p><b>第５条&nbsp;&nbsp; &nbsp;（情報の送信および取扱い）</b><br />
    １&nbsp;&nbsp; &nbsp;お客様が本システムを使用することにより、本システムの開発元である弊社（商号：Porto株式会社、所在地：東京都港区南青山二丁目2番15号 ウィン青山942）に対し、次の情報が送信される場合があります。<br />
    ①&nbsp;&nbsp; &nbsp;お客様がGoogle Business Profileの情報（検索キーワードおよび分析結果を含みます）<br />
    ②&nbsp;&nbsp; &nbsp;お客様がご使用の端末情報<br />
    ③&nbsp;&nbsp; &nbsp;お客様のSNS登録情報のうちユーザーIDおよびパスワード<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の情報その他お客様から取得したお客様の個人情報について、弊社のプライバシーポリシー（https://porto-japan.com/privacy-policy/）に従って適切に保有・管理し、本システムその他の弊社の製品の品質向上を目的として利用します。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、本システムを使用することによって弊社に第1項の情報が送信されること、ならびに弊社のプライバシーポリシーに従って当該情報およびお客様の個人情報が取り扱われることに同意したものとします。</p>

    <p><b>第６条&nbsp;&nbsp; &nbsp;（フィードバック）</b><br />
    お客様は、本システムに関するコメント、提案および推奨（修正、改良、改善など）（以下、総称して「フィードバック」といいます。）を弊社に提供することができます。この場合、フィードバックにかかわる著作権（著作権法第27条および第28条の権利その他の権利を含みます。）、特許権、実用新案権、意匠権、商標権等の知的財産権その他一切の権利は、フィードバックの提供と同時に、フィードバックを行ったお客様から弊社に譲渡したものとみなし、弊社は、一切の対価を払うことなく使用等の一切の行為をすることができるものとします。</p>

    <p><b>第７条&nbsp;&nbsp; &nbsp;（有効期間）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は、お客様が本システムを使用している期間中、適用されます。<br />
    ２&nbsp;&nbsp; &nbsp;お客様が、本規約のいずれかの条項に違反した場合、または弊社の著作権その他の知的財産権を侵害した場合には、弊社はお客様への本システムの使用許諾を解除することができます。<br />
    ３&nbsp;&nbsp; &nbsp;前項によりお客様の使用許諾が解除された場合には、お客様は、本システムの使用をすみやかに停止するものとし、その他、弊社の指示があればこれに従うものとします。</p>

    <p><b>第８条&nbsp;&nbsp; &nbsp;（保証範囲）</b><br />
    １&nbsp;&nbsp; &nbsp;本システムは現状有姿でお客様に提供されるものであり、弊社は、明示的であるか黙示的であるかを問わず、契約不適合責任を負わず、および本システムの機能、性能、使用の結果、その正確性、信頼性（誤作動を起こさないことを含みます。）その他一切の保証を行ないません。また弊社は、本システムの使用により、お客様の端末が誤動作を起こさないことを保証するものではありません。本システムの選択、使用効果または使用結果についてのすべての責任は、お客様の負担とさせていただきます。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、本システムのバグその他を補修する義務および本システムを改良または改善する義務を負いません。ただし、弊社は、弊社の判断により、お客様に本システムのアップデート版またはバージョンアップ情報等を提供する場合があります。この場合、かかるアップデート版またはバージョンアップ情報等も本システムとして扱い、これらにも本規約が適用されます。<br />
    ３&nbsp;&nbsp; &nbsp;弊社は、弊社および各販売代理店が必要と判断した場合に限り、本システム（体験版製品または機能限定版製品を除きます。）に関する弊社所定のサポートを提供します。<br />
    ４&nbsp;&nbsp; &nbsp;弊社は、お客様が弊社または弊社の販売代理店との間で締結する契約に基づき本システムを使用することができる期間（以下「契約期間」といいます。）が終了するまで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が本システムを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ５&nbsp;&nbsp; &nbsp;本システムに含まれるリスティング対象は各メディアが開発したものであり、弊社は、契約期間終了まで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が各メディアを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ６&nbsp;&nbsp; &nbsp;いかなる場合であっても、弊社は、本システムがお客様の特定の利用目的に適合することや、特定の結果の実現を保証するものではありません。</p>

    <p><b>第９条&nbsp;&nbsp; &nbsp;（免責）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、本規約の各条項に従って制限された限度においてのみ、本システムについての責任を負うものとします。弊社は、本規約の各条項において保証しないとしている事項、責任を負わないとしている事項、お客様の責任としている事項については、債務不履行責任、不法行為責任その他責任原因を問わず、一切の責任を負いません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関連して、お客様と第三者との間において生じた取引、連絡、紛争等について、弊社は一切の責任を負いません。<br />
    ３&nbsp;&nbsp; &nbsp;本システムは、システムの一部に外部システムを利用する場合があります。外部システムの利用ができなくなった場合、本システムの利用も不可能となる場合がありますが、弊社はそれによってお客様に生じた損害について、一切の責任を負いません。<br />
    ４&nbsp;&nbsp; &nbsp;弊社の責めに帰すべき事由によって本システムに関してお客様に損害が生じた場合であっても、弊社は、弊社に故意または重過失がある場合にのみ損害賠償責任を負うものとします。<br />
    ５&nbsp;&nbsp; &nbsp;前項の場合において弊社が負う賠償責任の範囲は、本システムの代金相当額（期間課金による代金支払いの場合は、当該期間課金の最小単位の額）を超えて賠償する責任を負わないこととします。また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないこととします。<br />
    ６&nbsp;&nbsp; &nbsp;本規約において弊社の責任を免除または限定する規定が、民法、消費者契約法その他の法令により無効または合意しなかったものとみなされた場合は、弊社は、お客様に対して、お客様に実際に生じた直接的かつ現実の損害を賠償する責任を負うものとします。</p>

    <p><b>第１０条&nbsp;&nbsp; &nbsp;（損害賠償）</b><br />
    お客様は、本規約に違反した場合、または、本システムの使用に関連して弊社に損害を与えた場合、弊社に生じた損害を賠償する責任を負うものとします。</p>

    <p><b>第１１条&nbsp;&nbsp; &nbsp;（秘密保持）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本システムに関連して弊社がお客様に対して秘密である旨を指定して開示した非公知の情報を秘密に取り扱うものとします。</p>

    <p><b>第１２条&nbsp;&nbsp; &nbsp;（権利義務の譲渡禁止）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本規約上の地位および本規約に基づくお客様の権利または義務について、第三者に対し、譲渡、承継、担保設定その他の処分をすることはできません。</p>

    <p><b>第１３条&nbsp;&nbsp; &nbsp;（本規約の変更）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様の一般の利益に適合する場合のほか、社会情勢、経済事情、税制の変動等の諸般の状況の変化、法令の変更、本システムに関する実情の変化その他相当の事由があると認められる場合には、民法548条の4の規定に基づいて、本システムの目的の範囲内で、本システムの内容、条件その他本規約の内容を変更できるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の定めに基づいて本規約の変更を行う場合は、変更後の本規約の内容を、弊社webサイト上に表示する方法、または弊社の定める方法によりお客様に通知する方法で周知するものとし、相当な期間を経過した日から、変更後の利用規約は適用されるものとします。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、第1項の定めに基づき本規約が変更された後において本システムの利用継続を望まない場合、前項に定める変更後の利用規約が適用される日までの間、弊社が定める方法により、本システムの解約を申し出ることができます。この期間内に解約の申し出をしなかった場合には、お客様は、本規約の変更に同意したものとみなされます。</p>

    <p><b>第１４条&nbsp;&nbsp; &nbsp;（分離可能性）</b><br />
    本規約のいずれかの条項またはその一部が、民法、消費者契約法その他の法令により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

    <p><b>第１５条&nbsp;&nbsp; &nbsp;（その他）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は日本法に準拠し、日本法に従って解釈されるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;本システムおよび本規約に関連する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

    <p>令和５年９月１日制定</p>`,
    lighten: `<p>この利用規約（以下「本規約」といいます。）は、Porto株式会社（以下「弊社」といいます。）が開発した『LIGHTEN』（以下「本システム」といいます。）をお客様に使用していただく前提となる条件を記載したものです。本システムのご利用前に、必ず本規約の全文をよくお読みください。</p>

    <p>本規約は民法548条の2が定める定型約款に該当し、本システムのアカウント発行を受けた場合には、本規約の各条項に同意したものとみなされますので、ご了承ください。</p>

    <p><b>第１条&nbsp;&nbsp; &nbsp;（使用許諾等）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様が本規約に同意し遵守されることを条件として、本システムを使用する非独占的権利をお客様に許諾いたします。ただし、かかる許諾は、お客様が第三者に対し再使用許諾する権利を含むものではありません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関する著作権その他の知的財産権は、弊社に帰属します。前項による許諾により、本システムに関する著作権その他の知的財産権を弊社からお客様に譲渡するものではありません。</p>

    <p><b>第２条&nbsp;&nbsp; &nbsp;（使用条件）</b><br />
    １ 弊社は、お客様が本システムを弊社が定める動作環境条件に適合するお客様のパソコンやスマートフォン等の端末（以下「端末」といいます。）にて使用することを許諾いたします。なお、弊社は、本システムがすべての端末に対応することを保証するものではありません。<br />
    ２ お客様が本システムを利用するにあたり使用する各種SNSアカウントは、お客様の責任において作成および管理運用するものとし、それら各種SNSアカウントに対して弊社は一切の責任を負いません</p>

    <p><b>第３条&nbsp;&nbsp; &nbsp;（禁止事項）</b><br />
    お客様が、以下の各号の行為を行うことは禁止いたします。お客様が以下の各号の行為を行いまたは行うおそれがある場合、弊社は、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本システムのソースコード、構造、アイデア等を解析する行為<br />
    ２&nbsp;&nbsp; &nbsp;本システムの複製、改変、翻案または他のソフトウェアと結合等する行為<br />
    ３&nbsp;&nbsp; &nbsp;本システムに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊する行為<br />
    ４&nbsp;&nbsp; &nbsp;本システムを第三者に送信可能な状態でネットワーク上に蓄積する行為、また本システムを第三者に送信する行為<br />
    ５&nbsp;&nbsp; &nbsp;本システムまたは本システムに付されている著作権表示およびその他の権利表示を除去または変更する行為<br />
    ６&nbsp;&nbsp; &nbsp;本システムおよび本規約に基づく本システムの使用権を第三者に再許諾、譲渡、移転等の処分をする行為<br />
    ７&nbsp;&nbsp; &nbsp;本システムの譲渡、転売、貸与・レンタルに類する行為、または中古取引をする行為<br />
    ８&nbsp;&nbsp; &nbsp;弊社がお客様に提供する本システムの識別情報（アカウント等）を第三者へ開示・提供する行為<br />
    ９&nbsp;&nbsp; &nbsp;法令に違反する行為または犯罪行為に関連する行為<br />
    １０&nbsp;&nbsp; &nbsp;公序良俗に反する行為<br />
    １１&nbsp;&nbsp; &nbsp;反社会的勢力等に対して直接または間接に利益を供与する行為<br />
    １２&nbsp;&nbsp; &nbsp;その他、弊社が不適切と判断する行為</p>

    <p><b>第４条&nbsp;&nbsp; &nbsp;（本システムの提供の停止等）</b><br />
    弊社は、以下のいずれかの事由が生じた場合、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムに関わる通信機器設備等のメンテナンス、修理等を定期的または緊急に行う場合<br />
    ２&nbsp;&nbsp; &nbsp;地震、落雷等の天災地変、火災・停電、戦争・暴動・内乱、疫病その他の伝染病、法令の改廃制定、公権力による命令処分、労働争議その他の不可抗力により、本システムの提供が困難である場合<br />
    ３&nbsp;&nbsp; &nbsp;アクセス過多、その他予期せぬ要因で本システムに負荷が集中し、本システムの提供が困難である場合<br />
    ４&nbsp;&nbsp; &nbsp;その他、弊社が本システムの停止または中断が必要であると判断した場合</p>

    <p><b>第５条&nbsp;&nbsp; &nbsp;（情報の送信および取扱い）</b><br />
    １&nbsp;&nbsp; &nbsp;お客様が本システムを使用することにより、本システムの開発元である弊社（商号：Porto株式会社、所在地：東京都港区南青山二丁目2番15号 ウィン青山942）に対し、次の情報が送信される場合があります。<br />
    ①&nbsp;&nbsp; &nbsp;お客様がGoogle Business Profileの情報（検索キーワードおよび分析結果を含みます）<br />
    ②&nbsp;&nbsp; &nbsp;お客様がご使用の端末情報<br />
    ③&nbsp;&nbsp; &nbsp;お客様のSNS登録情報のうちユーザーIDおよびパスワード<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の情報その他お客様から取得したお客様の個人情報について、弊社のプライバシーポリシー（https://porto-japan.com/privacy-policy/）に従って適切に保有・管理し、本システムその他の弊社の製品の品質向上を目的として利用します。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、本システムを使用することによって弊社に第1項の情報が送信されること、ならびに弊社のプライバシーポリシーに従って当該情報およびお客様の個人情報が取り扱われることに同意したものとします。</p>

    <p><b>第６条&nbsp;&nbsp; &nbsp;（フィードバック）</b><br />
    お客様は、本システムに関するコメント、提案および推奨（修正、改良、改善など）（以下、総称して「フィードバック」といいます。）を弊社に提供することができます。この場合、フィードバックにかかわる著作権（著作権法第27条および第28条の権利その他の権利を含みます。）、特許権、実用新案権、意匠権、商標権等の知的財産権その他一切の権利は、フィードバックの提供と同時に、フィードバックを行ったお客様から弊社に譲渡したものとみなし、弊社は、一切の対価を払うことなく使用等の一切の行為をすることができるものとします。</p>

    <p><b>第７条&nbsp;&nbsp; &nbsp;（有効期間）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は、お客様が本システムを使用している期間中、適用されます。<br />
    ２&nbsp;&nbsp; &nbsp;お客様が、本規約のいずれかの条項に違反した場合、または弊社の著作権その他の知的財産権を侵害した場合には、弊社はお客様への本システムの使用許諾を解除することができます。<br />
    ３&nbsp;&nbsp; &nbsp;前項によりお客様の使用許諾が解除された場合には、お客様は、本システムの使用をすみやかに停止するものとし、その他、弊社の指示があればこれに従うものとします。</p>

    <p><b>第８条&nbsp;&nbsp; &nbsp;（保証範囲）</b><br />
    １&nbsp;&nbsp; &nbsp;本システムは現状有姿でお客様に提供されるものであり、弊社は、明示的であるか黙示的であるかを問わず、契約不適合責任を負わず、および本システムの機能、性能、使用の結果、その正確性、信頼性（誤作動を起こさないことを含みます。）その他一切の保証を行ないません。また弊社は、本システムの使用により、お客様の端末が誤動作を起こさないことを保証するものではありません。本システムの選択、使用効果または使用結果についてのすべての責任は、お客様の負担とさせていただきます。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、本システムのバグその他を補修する義務および本システムを改良または改善する義務を負いません。ただし、弊社は、弊社の判断により、お客様に本システムのアップデート版またはバージョンアップ情報等を提供する場合があります。この場合、かかるアップデート版またはバージョンアップ情報等も本システムとして扱い、これらにも本規約が適用されます。<br />
    ３&nbsp;&nbsp; &nbsp;弊社は、弊社が必要と判断した場合に限り、本システム（体験版製品または機能限定版製品を除きます。）に関する弊社所定のサポートを提供します。<br />
    ４&nbsp;&nbsp; &nbsp;弊社は、お客様が弊社または弊社の販売代理店との間で締結する契約に基づき本システムを使用することができる期間（以下「契約期間」といいます。）が終了するまで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が本システムを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ５&nbsp;&nbsp; &nbsp;本システムに含まれるリスティング対象は各メディアが開発したものであり、弊社は、契約期間終了まで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が各メディアを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ６&nbsp;&nbsp; &nbsp;いかなる場合であっても、弊社は、本システムがお客様の特定の利用目的に適合することや、特定の結果の実現を保証するものではありません。</p>

    <p><b>第９条&nbsp;&nbsp; &nbsp;（免責）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、本規約の各条項に従って制限された限度においてのみ、本システムについての責任を負うものとします。弊社は、本規約の各条項において保証しないとしている事項、責任を負わないとしている事項、お客様の責任としている事項については、債務不履行責任、不法行為責任その他責任原因を問わず、一切の責任を負いません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関連して、お客様と第三者との間において生じた取引、連絡、紛争等について、弊社は一切の責任を負いません。<br />
    ３&nbsp;&nbsp; &nbsp;本システムは、システムの一部に外部システムを利用する場合があります。外部システムの利用ができなくなった場合、本システムの利用も不可能となる場合がありますが、弊社はそれによってお客様に生じた損害について、一切の責任を負いません。<br />
    ４&nbsp;&nbsp; &nbsp;弊社の責めに帰すべき事由によって本システムに関してお客様に損害が生じた場合であっても、弊社は、弊社に故意または重過失がある場合にのみ損害賠償責任を負うものとします。<br />
    ５&nbsp;&nbsp; &nbsp;前項の場合において弊社が負う賠償責任の範囲は、本システムの代金相当額（期間課金による代金支払いの場合は、当該期間課金の最小単位の額）を超えて賠償する責任を負わないこととします。また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないこととします。<br />
    ６&nbsp;&nbsp; &nbsp;本規約において弊社の責任を免除または限定する規定が、民法、消費者契約法その他の法令により無効または合意しなかったものとみなされた場合は、弊社は、お客様に対して、お客様に実際に生じた直接的かつ現実の損害を賠償する責任を負うものとします。</p>

    <p><b>第１０条&nbsp;&nbsp; &nbsp;（損害賠償）</b><br />
    お客様は、本規約に違反した場合、または、本システムの使用に関連して弊社に損害を与えた場合、弊社に生じた損害を賠償する責任を負うものとします。</p>

    <p><b>第１１条&nbsp;&nbsp; &nbsp;（秘密保持）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本システムに関連して弊社がお客様に対して秘密である旨を指定して開示した非公知の情報を秘密に取り扱うものとします。</p>

    <p><b>第１２条&nbsp;&nbsp; &nbsp;（権利義務の譲渡禁止）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本規約上の地位および本規約に基づくお客様の権利または義務について、第三者に対し、譲渡、承継、担保設定その他の処分をすることはできません。</p>

    <p><b>第１３条&nbsp;&nbsp; &nbsp;（本規約の変更）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様の一般の利益に適合する場合のほか、社会情勢、経済事情、税制の変動等の諸般の状況の変化、法令の変更、本システムに関する実情の変化その他相当の事由があると認められる場合には、民法548条の4の規定に基づいて、本システムの目的の範囲内で、本システムの内容、条件その他本規約の内容を変更できるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の定めに基づいて本規約の変更を行う場合は、変更後の本規約の内容を、弊社webサイト上に表示する方法、または弊社の定める方法によりお客様に通知する方法で周知するものとし、相当な期間を経過した日から、変更後の利用規約は適用されるものとします。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、第1項の定めに基づき本規約が変更された後において本システムの利用継続を望まない場合、前項に定める変更後の利用規約が適用される日までの間、弊社が定める方法により、本システムの解約を申し出ることができます。この期間内に解約の申し出をしなかった場合には、お客様は、本規約の変更に同意したものとみなされます。</p>

    <p><b>第１４条&nbsp;&nbsp; &nbsp;（分離可能性）</b><br />
    本規約のいずれかの条項またはその一部が、民法、消費者契約法その他の法令により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

    <p><b>第１５条&nbsp;&nbsp; &nbsp;（その他）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は日本法に準拠し、日本法に従って解釈されるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;本システムおよび本規約に関連する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

    <p>令和５年９月９日制定</p>`,
    default: `<p>この利用規約（以下「本規約」といいます。）は、Porto株式会社（以下「弊社」といいます。）が開発し、各販売代理店がカスタマイズし提供する『MEO-System.net』（以下「本システム」といいます。）をお客様に使用していただく前提となる条件を記載したものです。本システムのご利用前に、必ず本規約の全文をよくお読みください。</p>

    <p>本規約は民法548条の2が定める定型約款に該当し、本システムのアカウント発行を受けた場合には、本規約の各条項に同意したものとみなされますので、ご了承ください。</p>

    <p><b>第１条&nbsp;&nbsp; &nbsp;（使用許諾等）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様が本規約に同意し遵守されることを条件として、本システムを使用する非独占的権利をお客様に許諾いたします。ただし、かかる許諾は、お客様が第三者に対し再使用許諾する権利を含むものではありません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関する著作権その他の知的財産権は、弊社に帰属します。前項による許諾により、本システムに関する著作権その他の知的財産権を弊社からお客様に譲渡するものではありません。</p>

    <p><b>第２条&nbsp;&nbsp; &nbsp;（使用条件）</b><br />
    １ 弊社は、お客様が本システムを弊社が定める動作環境条件に適合するお客様のパソコンやスマートフォン等の端末（以下「端末」といいます。）にて使用することを許諾いたします。なお、弊社は、本システムがすべての端末に対応することを保証するものではありません。<br />
    ２ お客様が本システムを利用するにあたり使用する各種SNSアカウントは、お客様の責任において作成および管理運用するものとし、それら各種SNSアカウントに対して弊社は一切の責任を負いません</p>

    <p><b>第３条&nbsp;&nbsp; &nbsp;（禁止事項）</b><br />
    お客様が、以下の各号の行為を行うことは禁止いたします。お客様が以下の各号の行為を行いまたは行うおそれがある場合、弊社は、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本システムのソースコード、構造、アイデア等を解析する行為<br />
    ２&nbsp;&nbsp; &nbsp;本システムの複製、改変、翻案または他のソフトウェアと結合等する行為<br />
    ３&nbsp;&nbsp; &nbsp;本システムに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊する行為<br />
    ４&nbsp;&nbsp; &nbsp;本システムを第三者に送信可能な状態でネットワーク上に蓄積する行為、また本システムを第三者に送信する行為<br />
    ５&nbsp;&nbsp; &nbsp;本システムまたは本システムに付されている著作権表示およびその他の権利表示を除去または変更する行為<br />
    ６&nbsp;&nbsp; &nbsp;本システムおよび本規約に基づく本システムの使用権を第三者に再許諾、譲渡、移転等の処分をする行為<br />
    ７&nbsp;&nbsp; &nbsp;本システムの譲渡、転売、貸与・レンタルに類する行為、または中古取引をする行為<br />
    ８&nbsp;&nbsp; &nbsp;弊社がお客様に提供する本システムの識別情報（アカウント等）を第三者へ開示・提供する行為<br />
    ９&nbsp;&nbsp; &nbsp;法令に違反する行為または犯罪行為に関連する行為<br />
    １０&nbsp;&nbsp; &nbsp;公序良俗に反する行為<br />
    １１&nbsp;&nbsp; &nbsp;反社会的勢力等に対して直接または間接に利益を供与する行為<br />
    １２&nbsp;&nbsp; &nbsp;その他、弊社が不適切と判断する行為</p>

    <p><b>第４条&nbsp;&nbsp; &nbsp;（本システムの提供の停止等）</b><br />
    弊社は、以下のいずれかの事由が生じた場合、お客様に事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。<br />
    １&nbsp;&nbsp; &nbsp;本システムに関わる通信機器設備等のメンテナンス、修理等を定期的または緊急に行う場合<br />
    ２&nbsp;&nbsp; &nbsp;地震、落雷等の天災地変、火災・停電、戦争・暴動・内乱、疫病その他の伝染病、法令の改廃制定、公権力による命令処分、労働争議その他の不可抗力により、本システムの提供が困難である場合<br />
    ３&nbsp;&nbsp; &nbsp;アクセス過多、その他予期せぬ要因で本システムに負荷が集中し、本システムの提供が困難である場合<br />
    ４&nbsp;&nbsp; &nbsp;その他、弊社が本システムの停止または中断が必要であると判断した場合</p>

    <p><b>第５条&nbsp;&nbsp; &nbsp;（情報の送信および取扱い）</b><br />
    １&nbsp;&nbsp; &nbsp;お客様が本システムを使用することにより、本システムの開発元である弊社（商号：Porto株式会社、所在地：東京都港区南青山二丁目2番15号 ウィン青山942）に対し、次の情報が送信される場合があります。<br />
    ①&nbsp;&nbsp; &nbsp;お客様がGoogle Business Profileの情報（検索キーワードおよび分析結果を含みます）<br />
    ②&nbsp;&nbsp; &nbsp;お客様がご使用の端末情報<br />
    ③&nbsp;&nbsp; &nbsp;お客様のSNS登録情報のうちユーザーIDおよびパスワード<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の情報その他お客様から取得したお客様の個人情報について、弊社のプライバシーポリシー（https://porto-japan.com/privacy-policy/）に従って適切に保有・管理し、本システムその他の弊社の製品の品質向上を目的として利用します。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、本システムを使用することによって弊社に第1項の情報が送信されること、ならびに弊社のプライバシーポリシーに従って当該情報およびお客様の個人情報が取り扱われることに同意したものとします。</p>

    <p><b>第６条&nbsp;&nbsp; &nbsp;（フィードバック）</b><br />
    お客様は、本システムに関するコメント、提案および推奨（修正、改良、改善など）（以下、総称して「フィードバック」といいます。）を弊社に提供することができます。この場合、フィードバックにかかわる著作権（著作権法第27条および第28条の権利その他の権利を含みます。）、特許権、実用新案権、意匠権、商標権等の知的財産権その他一切の権利は、フィードバックの提供と同時に、フィードバックを行ったお客様から弊社に譲渡したものとみなし、弊社は、一切の対価を払うことなく使用等の一切の行為をすることができるものとします。</p>

    <p><b>第７条&nbsp;&nbsp; &nbsp;（有効期間）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は、お客様が本システムを使用している期間中、適用されます。<br />
    ２&nbsp;&nbsp; &nbsp;お客様が、本規約のいずれかの条項に違反した場合、または弊社の著作権その他の知的財産権を侵害した場合には、弊社はお客様への本システムの使用許諾を解除することができます。<br />
    ３&nbsp;&nbsp; &nbsp;前項によりお客様の使用許諾が解除された場合には、お客様は、本システムの使用をすみやかに停止するものとし、その他、弊社の指示があればこれに従うものとします。</p>

    <p><b>第８条&nbsp;&nbsp; &nbsp;（保証範囲）</b><br />
    １&nbsp;&nbsp; &nbsp;本システムは現状有姿でお客様に提供されるものであり、弊社は、明示的であるか黙示的であるかを問わず、契約不適合責任を負わず、および本システムの機能、性能、使用の結果、その正確性、信頼性（誤作動を起こさないことを含みます。）その他一切の保証を行ないません。また弊社は、本システムの使用により、お客様の端末が誤動作を起こさないことを保証するものではありません。本システムの選択、使用効果または使用結果についてのすべての責任は、お客様の負担とさせていただきます。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、本システムのバグその他を補修する義務および本システムを改良または改善する義務を負いません。ただし、弊社は、弊社の判断により、お客様に本システムのアップデート版またはバージョンアップ情報等を提供する場合があります。この場合、かかるアップデート版またはバージョンアップ情報等も本システムとして扱い、これらにも本規約が適用されます。<br />
    ３&nbsp;&nbsp; &nbsp;弊社は、弊社および各販売代理店が必要と判断した場合に限り、本システム（体験版製品または機能限定版製品を除きます。）に関する弊社所定のサポートを提供します。<br />
    ４&nbsp;&nbsp; &nbsp;弊社は、お客様が弊社または弊社の販売代理店との間で締結する契約に基づき本システムを使用することができる期間（以下「契約期間」といいます。）が終了するまで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が本システムを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ５&nbsp;&nbsp; &nbsp;本システムに含まれるリスティング対象は各メディアが開発したものであり、弊社は、契約期間終了まで、お客様が本システムを使用できるよう最大限の努力をします。ただし、やむを得ない事由によりお客様が各メディアを使用できなくなった場合も、弊社はその責任を負いません。<br />
    ６&nbsp;&nbsp; &nbsp;いかなる場合であっても、弊社は、本システムがお客様の特定の利用目的に適合することや、特定の結果の実現を保証するものではありません。</p>

    <p><b>第９条&nbsp;&nbsp; &nbsp;（免責）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、本規約の各条項に従って制限された限度においてのみ、本システムについての責任を負うものとします。弊社は、本規約の各条項において保証しないとしている事項、責任を負わないとしている事項、お客様の責任としている事項については、債務不履行責任、不法行為責任その他責任原因を問わず、一切の責任を負いません。<br />
    ２&nbsp;&nbsp; &nbsp;本システムに関連して、お客様と第三者との間において生じた取引、連絡、紛争等について、弊社は一切の責任を負いません。<br />
    ３&nbsp;&nbsp; &nbsp;本システムは、システムの一部に外部システムを利用する場合があります。外部システムの利用ができなくなった場合、本システムの利用も不可能となる場合がありますが、弊社はそれによってお客様に生じた損害について、一切の責任を負いません。<br />
    ４&nbsp;&nbsp; &nbsp;弊社の責めに帰すべき事由によって本システムに関してお客様に損害が生じた場合であっても、弊社は、弊社に故意または重過失がある場合にのみ損害賠償責任を負うものとします。<br />
    ５&nbsp;&nbsp; &nbsp;前項の場合において弊社が負う賠償責任の範囲は、本システムの代金相当額（期間課金による代金支払いの場合は、当該期間課金の最小単位の額）を超えて賠償する責任を負わないこととします。また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないこととします。<br />
    ６&nbsp;&nbsp; &nbsp;本規約において弊社の責任を免除または限定する規定が、民法、消費者契約法その他の法令により無効または合意しなかったものとみなされた場合は、弊社は、お客様に対して、お客様に実際に生じた直接的かつ現実の損害を賠償する責任を負うものとします。</p>

    <p><b>第１０条&nbsp;&nbsp; &nbsp;（損害賠償）</b><br />
    お客様は、本規約に違反した場合、または、本システムの使用に関連して弊社に損害を与えた場合、弊社に生じた損害を賠償する責任を負うものとします。</p>

    <p><b>第１１条&nbsp;&nbsp; &nbsp;（秘密保持）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本システムに関連して弊社がお客様に対して秘密である旨を指定して開示した非公知の情報を秘密に取り扱うものとします。</p>

    <p><b>第１２条&nbsp;&nbsp; &nbsp;（権利義務の譲渡禁止）</b><br />
    お客様は、弊社の事前の書面による承諾を得た場合を除き、本規約上の地位および本規約に基づくお客様の権利または義務について、第三者に対し、譲渡、承継、担保設定その他の処分をすることはできません。</p>

    <p><b>第１３条&nbsp;&nbsp; &nbsp;（本規約の変更）</b><br />
    １&nbsp;&nbsp; &nbsp;弊社は、お客様の一般の利益に適合する場合のほか、社会情勢、経済事情、税制の変動等の諸般の状況の変化、法令の変更、本システムに関する実情の変化その他相当の事由があると認められる場合には、民法548条の4の規定に基づいて、本システムの目的の範囲内で、本システムの内容、条件その他本規約の内容を変更できるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;弊社は、前項の定めに基づいて本規約の変更を行う場合は、変更後の本規約の内容を、弊社webサイト上に表示する方法、または弊社の定める方法によりお客様に通知する方法で周知するものとし、相当な期間を経過した日から、変更後の利用規約は適用されるものとします。<br />
    ３&nbsp;&nbsp; &nbsp;お客様は、第1項の定めに基づき本規約が変更された後において本システムの利用継続を望まない場合、前項に定める変更後の利用規約が適用される日までの間、弊社が定める方法により、本システムの解約を申し出ることができます。この期間内に解約の申し出をしなかった場合には、お客様は、本規約の変更に同意したものとみなされます。</p>

    <p><b>第１４条&nbsp;&nbsp; &nbsp;（分離可能性）</b><br />
    本規約のいずれかの条項またはその一部が、民法、消費者契約法その他の法令により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

    <p><b>第１５条&nbsp;&nbsp; &nbsp;（その他）</b><br />
    １&nbsp;&nbsp; &nbsp;本規約は日本法に準拠し、日本法に従って解釈されるものとします。<br />
    ２&nbsp;&nbsp; &nbsp;本システムおよび本規約に関連する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

    <p>令和５年９月１日制定</p>`,
  },
}
