<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        {{ $t("screens.addKnowledgeTag.customizeMarkup") }}
      </h4>
      <span>{{ $t("screens.addKnowledgeTag.editorDescription[0]") }}</span>
      <a href="https://schema.org" target="_blank">schema.org</a>
      <span>{{ $t("screens.addKnowledgeTag.editorDescription[1]") }}</span>
      <div>
        <ol>
          <li>{{ $t("screens.addKnowledgeTag.editorDescription[2]") }}</li>
          <li>{{ $t("screens.addKnowledgeTag.editorDescription[3]") }}</li>
          <li>{{ $t("screens.addKnowledgeTag.editorDescription[4]") }}</li>
        </ol>
      </div>
    </div>
    <div class="card-body">
      <prism-editor
        class="my-editor"
        v-model="code"
        :highlight="highlighter"
        line-numbers
      ></prism-editor>
      <div class="row ml-1 actions">
        <p-button :disabled="isSaved" type="info" round>
          {{ $t("screens.buttons.save") }}
        </p-button>
        <p-button
          class="ml-2"
          :disabled="isSaved"
          @click="resetCode"
          type="default"
          round
        >
          {{ $t("screens.buttons.reset") }}
        </p-button>
        <router-link class="ml-2" to="/add-knowledge-tag">
          {{ $t("screens.buttons.cancel") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor"
import "vue-prism-editor/dist/prismeditor.min.css" // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"
import "prismjs/themes/prism-tomorrow.css" // import syntax highlighting styles

export default {
  components: {
    PrismEditor,
  },
  data() {
    return {
      code: `{
  "@context": "https://schema.org",
  "@id": "$meta.id",
  "@type": "$meta.schemaTypes",
  "acceptsReservations": "$reservationUrl.url",
  "address": "$address",
  "areaServed": "$serviceArea.places",
  "brand": "$brands",
  "description": "$description",
  "email": "$emails[0]",
  "faxNumber": "$fax",
  "foundingDate": "$yearEstablished",
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "$yextDisplayCoordinate.latitude",
    "longitude": "$yextDisplayCoordinate.longitude"
  },
  "hasMenu": "$menuUrl.url",
  "image": "$logo.image.url",
  "logo": "$logo.image.url",
  "makesOffer": "$offers",
  "name": "$name",
  "openingHoursSpecification": "$hours",
  "paymentAccepted": "$paymentOptions",
  "telephone": "$mainPhone",
  "url": "$websiteUrl.url"
}`,
      isSaved: true,
    }
  },
  watch: {
    code(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.isSaved = false
      }
    },
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js) // languages.<insert language> to return html with markup
    },
    async resetCode() {
      await Object.assign(this.$data, this.$options.data.apply(this))
      this.isSaved = true
    },
  },
}
</script>

<style lang="scss" scoped>
// required class
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

// not required:
.height-300 {
  height: 300px;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
