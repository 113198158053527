<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <!-- Payment Plan -->
      <div class="card">
        <div class="card-body" style="margin: 0 15px">
          <div class="row">
            <div class="col-md-12">
              <h4>
                {{ $t("screens.paymentSetting.selectPayment") }}
              </h4>
            </div>
            <div class="col-md-12">
              <ValidationObserver ref="change-plan" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <div class="row">
                    <div class="col-md-2">
                      <fg-input>
                        <el-select v-model="plan.name">
                          <el-option
                            v-for="item in getPaymentPlan"
                            :key="item.key"
                            :label="item.name"
                            :value="item.key"
                            :change="handleChange(plan)"
                          >
                            <span class="float-left">
                              {{ item.name }}
                            </span>
                            <span
                              class="float-right"
                              style="color: #8492a6; font-size: 13px"
                            >
                              {{ item.price }}
                            </span>
                          </el-option>
                        </el-select>
                      </fg-input>
                      <div class="mt-2">
                        <a
                          :href="linkPlan"
                          class="text-primary"
                          target="_blank"
                        >
                          {{ $t("screens.paymentSetting.linkForPlan") }}
                        </a>
                      </div>
                      <p-button native-type="submit" type="info" round>
                        {{ $t("screens.paymentSetting.update") }}
                      </p-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-if="getPaymentSelectedPlan.key">
        <!-- Method payment -->
        <div class="card-body" style="margin: 0 15px">
          <change-method @selectValue="handleSelect"></change-method>
        </div>

        <!-- Credit card -->
        <div class="card-body">
          <div class="col-md-12">
            <h4 class="card-title">
              {{ $t("screens.paymentSetting.registerCreditCard") }}
            </h4>
          </div>
          <div v-if="!isEmpty(item)" class="col-md-12">
            <register-payment-credit-card-list
              :item="item"
            ></register-payment-credit-card-list>
          </div>
          <div v-else class="col-md-12">
            <register-payment-credit-card></register-payment-credit-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeMethod from "./PaymentMethod/ChangeMethod"
import RegisterPaymentCreditCard from "./RegisterPayment/RegisterPaymentCreditCard"
import RegisterPaymentCreditCardList from "./RegisterPayment/RegisterPaymentCreditCardList"
import { Select, Option } from "element-ui"
import {
  messageType,
  planPriceType,
  paymentStatusType,
  roleType,
} from "src/util/constants"
import { convertMessage } from "src/util/commons"
import formMixin from "@/mixins/formMixin"
import payment from "@/util/payment"
import Swal from "sweetalert2"

export default {
  mixins: [formMixin],
  components: {
    ChangeMethod,
    RegisterPaymentCreditCard,
    RegisterPaymentCreditCardList,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  setup(props, context) {
    const selected = (event) => {
      context.emit("customChange", event.target.value)
    }
    return {
      selected,
    }
  },
  data() {
    return {
      item: {},
      plan: {
        name: null,
        key: null,
        price: null,
        amount: null,
      },
      planPriceType: planPriceType,
      paymentStatusType: paymentStatusType,
      paymentPlan: [],
      roleType: roleType,
      linkPlan: "https://help.lighten-meo.com/",
    }
  },
  async created() {
    this.showCreditCard()
    this.showPaymentPlan()
    this.showPaymentSelectedPlan()
  },
  computed: {
    getPaymentMethod() {
      return this.$store.state.payment.paymentMethod
    },

    getPaymentButtonDisabled() {
      return this.$store.state.payment.paymentMethod?.payment_btn_disable
    },

    getPaymentPlan() {
      return this.$store.state.payment.plan
    },

    getPaymentSelectedPlan() {
      return this.$store.state.payment.paymentSelectedPlan
    },

    formatGetPaymentSelectedPlan() {
      return Object.values(this.getPaymentSelectedPlan)
    },

    getUserObject() {
      return this.$store.state.userObject
    },

    getPlanHandleChange() {
      return this.getPaymentPlan.filter(
        (plan) => plan.name.toLowerCase() === this.plan.name.toLowerCase()
      )
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.name === "Register Payment") {
          if (to.query.stt_id === paymentStatusType.SUCCESS) {
            Swal.fire({
              icon: messageType.SUCCESS,
              title: "Your payment is successful!",
              text: "Thank you for your payment. An automated payment receipt will be sent to your registered email.",
              showConfirmButton: false,
              timer: 5000,
            })
          } else if (to.query.stt_id === paymentStatusType.FAIL) {
            Swal.fire({
              icon: messageType.ERROR,
              text: this.$t("actions.messages.registerPaymentError"),
              showConfirmButton: false,
              timer: 5000,
            })
          }
        }
      },
      immediate: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.selected === "credit_card" &&
      !this.item &&
      this.checkPaymentFail === false &&
      this.checkFreeTrial === true
    ) {
      alert(this.$t("actions.messages.pleaseEnterCardInfor"))
      next(false)
    } else {
      next()
    }
  },
  methods: {
    handleSelect(param) {
      this.selected = param
    },

    handleChange(value) {
      this.plan = value
    },

    async showCreditCard() {
      try {
        this.messages = null
        await this.$store.dispatch("payment/showCreditCard", {
          user_id: this.userIds,
        })
        this.item = this.$store.state.payment.item
      } catch (err) {
        this.item = {}
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async showPaymentPlan() {
      try {
        this.messages = null

        await this.$store.dispatch("payment/getPaymentPlan", {
          user_id: this.userIds[0],
        })
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async showPaymentSelectedPlan() {
      try {
        this.messages = null

        await this.$store.dispatch("payment/getPaymentSelectedPlan", {
          user_id: this.userIds[0],
        })
        this.plan = this.getPaymentSelectedPlan

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        const form = {
          payment_name: "credit_card",
          payment_plan: this.plan.name,
          user_id: this.userIds[0],
        }

        await this.$store.dispatch("payment/changePaymentPlan", form)
        this.$store.commit("setShowLoading", false)

        await Swal.fire({
          icon: messageType.SUCCESS,
          text: this.$t("actions.messages.changePaymentMethodPlan"),
          showConfirmButton: false,
          timer: 2000,
        })
        this.$router.go(0)
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response?.data?.errors) {
          Swal.fire({
            icon: messageType.ERROR,
            text: convertMessage(err.response.data.errors).join(", "),
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.errors.500"),
            showConfirmButton: false,
            timer: 2000,
          })
        }
      }
    },

    checkout() {
      if (
        this.authUser.role === this.roleType.FREETRIAL ||
        this.authUser.role === this.roleType.SHOP
      ) {
        const data = {
          user_id: this.userIds[0],
          email: this.authUser.email,
          name: this.authUser.name,
          amount: this.formatGetPaymentSelectedPlan[3],
          date: this.authUser.created_at,
        }
        payment.methods.f_submit(data)
      } else {
        const data = {
          user_id: this.userIds[0],
          email: this.getUserObject.email,
          name: this.getUserObject.name,
          amount: this.formatGetPaymentSelectedPlan[3],
          date: this.authUser.created_at,
        }
        payment.methods.f_submit(data)
      }
    },

    isEmpty(obj) {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false
        }
      }

      return JSON.stringify(obj) === JSON.stringify({})
    },
  },
}
</script>

<style scoped>
.el-select-dropdown .el-popper {
  min-width: 354px !important;
}
</style>
