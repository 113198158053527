var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('modal',{staticClass:"custom-modal",attrs:{"show":_vm.isSettingInformation,"headerClasses":"justify-content-center"},on:{"update:show":function($event){_vm.isSettingInformation=$event}}},[_c('h4',{staticClass:"title center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.settingAccountTitle"))+" ")]),_c('div',[_c('ValidationObserver',{ref:"change-setting-account",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.messages)?_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.namePersonCharge'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"full-name","type":"text","label":_vm.$t('screens.dashboard.forms.namePersonCharge'),"placeholder":_vm.$t('screens.dashboard.forms.namePersonCharge'),"error":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.companyName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"company-name","type":"text","label":_vm.$t('screens.dashboard.forms.companyName'),"placeholder":_vm.$t('screens.dashboard.forms.companyName'),"error":errors[0]},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.storeName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"store-name","type":"text","label":_vm.$t('screens.dashboard.forms.storeName'),"placeholder":_vm.$t('screens.dashboard.forms.storeName'),"error":errors[0]},model:{value:(_vm.form.store_name),callback:function ($$v) {_vm.$set(_vm.form, "store_name", $$v)},expression:"form.store_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"email","type":"email","label":_vm.$t('screens.dashboard.forms.email'),"placeholder":_vm.$t('screens.dashboard.forms.email'),"error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"phone","type":"text","label":_vm.$t('screens.dashboard.forms.phone'),"placeholder":_vm.$t('screens.dashboard.forms.phone'),"error":errors[0]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1)]),_c('div',{staticClass:"text-center"},[_c('p-button',{attrs:{"native-type":"submit","type":"info","round":""}},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.buttons.update"))+" ")])],1),_c('div',{staticClass:"clearfix"})],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }