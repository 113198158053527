<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div v-if="userRole === roleType.AGENCY" class="logo">
      <div
        class="simple-text logo-mini"
        style="cursor: pointer; object-position: center; object-fit: cover"
        @click="openUploadLogo"
      >
        <div class="logo-img">
          <img :src="userLogo" alt="" />
        </div>
      </div>
      <router-link v-if="title" class="simple-text logo-normal" to="/">
        {{ title }}
      </router-link>
    </div>
    <div v-else class="logo">
      <div class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="userLogo" alt="" />
        </div>
      </div>
      <router-link v-if="title" class="simple-text logo-normal" to="/">
        {{ title }}
      </router-link>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in checkSideBarLink"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
    <modal
      class="upload-avatar"
      :show.sync="isShow"
      headerClasses="justify-content-center"
    >
      <h4 slot="header" class="title title-up">
        {{ $t("screens.dashboard.texts.uploadAvatar") }}
      </h4>
      <div class="upload-avatar-body">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onUploadFile)">
            <label for="image">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.image')"
                :rules="`image|size:${IMAGE_SIZE}`"
                ref="logo_url"
                v-slot="{ errors }"
              >
                <fg-input
                  id="image"
                  name="image"
                  type="file"
                  hidden
                  @change="uploadAvatar"
                />
                <input v-model="form.logo" hidden />
                <p class="upload-avatar--error" v-if="errors">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>

              <img class="upload-avatar-image" :src="logoUrlString" alt="" />
            </label>
          </form>
        </ValidationObserver>
      </div>
      <template slot="footer">
        <div class="left-side">
          <p-button type="info" @click="onUploadFile" link>
            {{ $t("screens.dashboard.buttons.saveUpload") }}
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="default" link @click="isShow = false">
            {{ $t("screens.dashboard.buttons.cancelUpload") }}
          </p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { Tabs, TabPane } from "element-ui"
import { IMAGE_SIZE, roleType, messageType } from "src/util/constants"
import { convertMessage, objToFormData } from "src/util/commons"
import formMixin from "@/mixins/formMixin"
import { Modal } from "src/components/UIComponents"

export default {
  mixins: [formMixin],
  components: {
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    Modal,
  },
  props: {
    title: {
      type: String,
      default: "",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        const acceptedValues = ["white", "brown", "black"]
        return acceptedValues.indexOf(value) !== -1
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        const acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ]
        return acceptedValues.indexOf(value) !== -1
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      IMAGE_SIZE: IMAGE_SIZE,
      activeName: "",
      roleType: roleType,
      logoUrlString: "",
      form: {
        logo: "",
      },
      isShow: false,
      lengthStoreSelected: 1,
      filterSideBarLinks: [],
      listManagers: [],
      listAgencies: [],
      listGroupsLeaders: [],
      listUsers: [],

      sideBarLinksMultipleStore: [
        {
          name: this.$t("screens.menu.report"),
          icon: "nc-icon nc-paper",
          path: "/report",
        },
        {
          name: this.$t("screens.menu.postManagement"),
          icon: "nc-icon nc-tile-56",
          children: [
            {
              name: this.$t("screens.menu.post"),
              path: "/create-post",
              icon: "nc-icon nc-simple-add",
            },
          ],
        },
      ],
    }
  },
  created() {
    if (this.getRole === roleType.MANAGER) {
      this.$store.dispatch("customers/getModels")
    }

    if (this.getRole === roleType.AGENCY) {
      this.$store.dispatch("agency/getListStore")
    }

    if (this.getRole === roleType.SHOP && this.getLeader === true) {
      this.$store.dispatch("groupsLeader/getListStoresByLeader")
    }
  },
  computed: {
    getRole() {
      return this.$store.state.auth.user?.role
    },
    getLeader() {
      return this.$store.state.auth.user?.is_leader
    },
    userIds() {
      return this.$store.getters.userIds
        ? JSON.parse("[" + this.$store.getters.userIds + "]")
        : []
    },
    getManagers() {
      return this.$store.state.customers.list
    },
    getAgencies() {
      return this.$store.state.agency.listStore
    },
    getGroupLeaders() {
      return this.$store.state.groupsLeader.listStoresByLeader
    },
    filterCurrentUser() {
      return this.listUsers.filter(
        (listUser) => listUser.id === this.userIds[0]
      )
    },
    getAgentCode() {
      return this.filterCurrentUser[0]?.agent_code
    },
    filterPaymentSetting() {
      return this.filterSideBarLinks.filter(
        (link) => link.path !== "/register-payment"
      )
    },
    checkAgentCodeLogin() {
      return (
        this.authUser?.agent_code === "0000" || this.authUser?.agent_code === ""
      )
    },
    checkAgentCodeChooseUser() {
      return this.getAgentCode === "0000" || this.getAgentCode === ""
    },
    checkRoleAgentCode() {
      return this.getRole === roleType.SHOP ||
        this.getRole === roleType.FREETRIAL
        ? this.checkAgentCodeLogin
        : this.checkAgentCodeChooseUser
    },
    checkSideBarLink() {
      return this.checkRoleAgentCode
        ? this.filterSideBarLinks
        : this.filterPaymentSetting
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  watch: {
    isShow() {
      this.logoUrlString = this.userLogo
      this.$refs.logo_url.errors = []
    },
    $route: {
      async handler(to, from) {
        // handle sidebar
        if (to.name === "Report") {
          if (this.userIds.length > 1) {
            this.$router.push("/report-multiple")
            this.filterSideBarLinks = this.sideBarLinksMultipleStore
          } else {
            this.filterSideBarLinks = this.sidebarLinks
          }
        } else if (to.name === "ReportMultiple") {
          if (this.userIds.length > 1) {
            this.filterSideBarLinks = this.sideBarLinksMultipleStore
          } else {
            this.$router.push("/report")
            this.filterSideBarLinks = this.sidebarLinks
          }
        } else if (
          to.name === "Request Change Information" ||
          to.name === "Post" ||
          to.name === "Posts"
        ) {
          if (this.userIds.length > 1) {
            this.filterSideBarLinks = this.sideBarLinksMultipleStore
          } else {
            this.filterSideBarLinks = this.sidebarLinks
          }
        } else {
          this.filterSideBarLinks = this.sidebarLinks
        }
      },
      immediate: true,
    },
    getManagers() {
      this.listUsers = [...this.getManagers]
    },
    getAgencies() {
      this.listUsers = [...this.getAgencies]
    },
    getGroupLeaders() {
      this.listUsers = [...this.getGroupLeaders]
    },
  },
  methods: {
    openUploadLogo() {
      this.isShow = true
    },
    closeUploadLogo() {
      this.isShow = false
    },
    async uploadAvatar(e) {
      this.form.logo = this.handlerUploadFile(e)
      this.logoUrlString = await this.generatorImage(this.form.logo)
      const provider = this.$refs.logo_url
      return provider.validate()
    },
    async onUploadFile() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = objToFormData({
          logo: this.form.logo,
          user_id: this.userIds[0],
        })
        await this.$store.dispatch("agency/updateLogo", { data: data })
        await this.$store.dispatch("auth/getUserInfo")
        this.isShow = false
        this.$router.go(0)
        this.messages = {
          data: this.$t("actions.messages.commons.updateSuccess"),
          type: messageType.SUCCESS,
        }
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
    async initScrollBarAsync() {
      const isWindows = navigator.platform.startsWith("Win")
      if (!isWindows) {
        return
      }
      const PerfectScroll = await import("perfect-scrollbar")
      PerfectScroll.initialize(this.$refs.sidebarScrollArea)
    },
  },
  mounted() {
    // this.initScrollBarAsync()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
}
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
