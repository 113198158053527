import AuthApi from "@/services/authApi"

const api = new AuthApi()

const state = () => ({
  isLoggedIn: false,
  token: null,
  user: null,
  userInfo: null,
  email: null,
  connectGMB: null,
  agreeTerm: false,
})

const mutations = {
  setUser(state, data) {
    state.userInfo = data
  },

  setEmail(state, data) {
    state.email = data
  },

  postLogin(state, data) {
    const token = data.token
    localStorage.setItem("token", token)
    state.isLoggedIn = true
    state.token = token
    state.connectGMB = data.connect_GMB
    state.user = data.user
  },

  postLogout(state) {
    state.isLoggedIn = false
    state.token = null
    state.connectGMB = null
    state.user = null
    localStorage.removeItem("token")
    localStorage.removeItem("userIds")
    localStorage.removeItem("userLogo")
  },

  setAgreeTerm(state, data) {
    state.agreeTerm = data
  },
}

const actions = {
  async getUserInfo({ commit }) {
    await api.getUserInfo().then((response) => {
      commit("setUser", response.data.data)
    })
  },

  async login({ commit }, data) {
    await api.login(data).then((response) => {
      commit("postLogin", response.data.data)
    })
  },

  async refresh({ commit }) {
    const token = localStorage.getItem("token")
    if (!token) {
      commit("postLogout")
      return
    }

    await api
      .refresh()
      .then((response) => {
        commit("postLogin", response.data.data)
      })
      .catch(() => {
        commit("postLogout")
      })
  },

  async logout({ commit }) {
    await api
      .logout()
      .then(() => {
        commit("postLogout")
      })
      .catch(() => {
        commit("postLogout")
      })
  },

  async forgotPassword({ commit }, data) {
    await api.forgotPassword(data)
  },

  async resetPassword({ commit }, data) {
    await api.resetPassword(data)
  },

  async registrationForm({ commit }, data) {
    await api.registrationForm(data)
  },

  async registerAccount({ commit }, data) {
    await api.registerAccount(data)
  },

  async registerVerifyAccount({ commit }, data) {
    await api.registerVerifyAccount(data).then((response) => {
      commit("setEmail", response.data.data.email)
    })
  },

  async changePassword({ commit }, data) {
    await api.changePassword(data)
  },

  async changeInformation({ commit }, data) {
    await api.changeInformation(data).then((response) => {
      commit("setUser", response.data.data)
    })
  },

  async postAgreeTerm({ commit }, data) {
    commit("setAgreeTerm", data)
  },

  async confirmTerm({ commit }, data) {
    await api.confirmTerm(data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
