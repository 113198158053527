<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications transition-name="notification-list" transition-mode="out-in">
    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import { mapState, mapActions } from "vuex"
import "sweetalert2/dist/sweetalert2.css"
import "vue-notifyjs/themes/default.css"
import commons from "@/mixins/commons"
import { domainType } from "src/util/constants"
export default {
  metaInfo() {
    return {
      title: this.title,
      link: this.link,
    }
  },
  data() {
    return {
      title: "",
      link: [],
      domainType,
    }
  },
  computed: {
    ...mapState("agency", ["prefix"]),
  },
  mixins: [commons],
  async created() {
    this.$store.commit("setUserIds", localStorage.getItem("userIds"))
    this.$store.commit("setUserRole", localStorage.getItem("userRole"))
    this.$store.commit("setUserLogo", localStorage.getItem("userLogo"))
    await this.checkMeta()
  },
  methods: {
    ...mapActions("agency", ["getAgencyPrefix"]),
    checkImageUrl(imageUrl) {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
        img.src = imageUrl
      })
    },
    async checkMeta() {
      const fullUrl = window.location.toString()
      // Extracts the favicon URL and page title from the current URL.
      const { faviconUrl, agencyName, domainSystem } =
        this.getAgencyName(fullUrl)
      // Retrieves the agency prefix based on the extracted agency name.
      try {
        // If successful, updates the title property. Otherwise, sets the title to "LIGHTEN".
        await this.getAgencyPrefix({ prefix: agencyName })
        // Updates the page title
        this.title = this.prefix.title
      } catch (error) {
        if (domainSystem === this.domainType.MEO_SYSTEM.key) {
          // The domain system is "meo-system". Set the title to "MEO-SYSTEM" and update the link property accordingly.
          this.title = this.domainType.MEO_SYSTEM.title
          this.link = [
            {
              rel: "icon",
              type: "image/png",
              href: this.domainType.MEO_SYSTEM.url,
            },
          ]
        } else if (domainSystem === this.domainType.LIGHTEN.key) {
          // The domain system is "lighten-meo". Set the title to "LIGHTEN" and update the link property accordingly.
          this.title = this.domainType.LIGHTEN.title
          this.link = [
            {
              rel: "icon",
              type: "image/png",
              href: this.domainType.LIGHTEN.url,
            },
          ]
        }
      }
      // Checks if the favicon URL is valid by loading the image asynchronously.
      this.checkImageUrl(faviconUrl).then((isValid) => {
        if (isValid) {
          // Updates favicon URL if the favicon is valid.
          this.link = [
            {
              rel: "icon",
              type: "image/png",
              href: faviconUrl,
            },
          ]
        }
      })
      // Returns the updated meta information.
      return this.meta
    },
  },
}
</script>
<style lang="scss"></style>
