<template>
  <div class="card area-background-chart">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-7">
          <fg-input>
            <el-select
              class="select-info"
              v-model="time"
              @change="handleChange"
            >
              <el-option
                v-for="(option, i) in timeOptions"
                :value="option.value"
                :label="option.label"
                :key="i"
              >
              </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h6 class="big-title">
        {{ $t("screens.report.texts.chartCustomerActionsPriod") }}
      </h6>
      <div v-if="message" class="center-error-container">
        <div class="text-danger center-error-message">
          {{ message }}
        </div>
      </div>
      <canvas :id="id" width="400" height="110"></canvas>
    </div>
    <div class="card-footer">
      <div class="legend">
        <template v-for="(item, index) in chartBorderColor">
          <i
            :key="index"
            class="fa fa-circle"
            :style="`color: ${item.borderColor}`"
          ></i>
          <span :key="item.borderColor" class="mr-20px">
            {{ item.label }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import Chart from "chart.js"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    timeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userId: "",
      cloneInputs: {},
      businessBookings: [],
      businessConversations: [],
      businessDirectionRequests: [],
      businessFoodOrders: [],
      callClicks: [],
      websiteClicks: [],

      chartBorderColor: [
        {
          label: this.$t("screens.report.texts.listingVisitYourWebsite"),
          borderColor: "#34A853",
        },
        {
          label: this.$t("screens.report.texts.listingRequestDirections"),
          borderColor: "#AFB42A",
        },
        {
          label: this.$t("screens.report.texts.listingCallYou"),
          borderColor: "#4285FA",
        },
        {
          label: this.$t("screens.report.texts.listingConversations"),
          borderColor: "#ea9999",
        },
        {
          label: this.$t("screens.report.texts.listingBookings"),
          borderColor: "#ffe599",
        },
        {
          label: this.$t("screens.report.texts.listingFoodOrders"),
          borderColor: "#f9cb9c",
        },
      ],

      time: 1,
      message: null,
    }
  },
  created() {
    this.handleChange()
  },
  watch: {
    userIds(value) {
      if (value) {
        this.handleChange()
      }
    },
  },
  methods: {
    async handleChange(time) {
      try {
        this.message = null
        const data = {
          user_id: this.userIds[0]
            ? this.userIds[0].toString()
            : this.$store.state.auth.user.id.toString(),
        }

        if (time) {
          this.time = time
        }
        const timeRange = this.time

        await this.$store.dispatch("dashboard/getReactionsGraph", {
          timeRange: timeRange,
          data,
        })

        this.cloneInputs = this.$store.state.dashboard.reactionsGraph

        const labels = Object.keys(this.cloneInputs.business_bookings)
        this.businessBookings = Object.values(
          this.cloneInputs.business_bookings
        )
        this.businessConversations = Object.values(
          this.cloneInputs.business_conversations
        )
        this.businessDirectionRequests = Object.values(
          this.cloneInputs.business_direction_requests
        )
        this.businessFoodOrders = Object.values(
          this.cloneInputs.business_food_orders
        )
        this.callClicks = Object.values(this.cloneInputs.call_clicks)
        this.websiteClicks = Object.values(this.cloneInputs.website_clicks)

        const ctx = document.getElementById(this.id).getContext("2d")
        if (this.myChart) {
          this.myChart.destroy()
        }

        this.myChart = new Chart(ctx, {
          type: "line",
          data: {
            labels: labels,
            datasets: [
              {
                label: this.$t("screens.report.texts.listingVisitYourWebsite"),
                borderColor: "#34A853",
                backgroundColor: "#CDEAD5",
                pointRadius: 0,
                borderWidth: 4,
                data: this.websiteClicks,
              },
              {
                label: this.$t("screens.report.texts.listingRequestDirections"),
                borderColor: "#AFB42A",
                backgroundColor: "#EBECCB",
                pointRadius: 0,
                borderWidth: 4,
                data: this.businessDirectionRequests,
              },
              {
                label: this.$t("screens.report.texts.listingCallYou"),
                borderColor: "#4285FA",
                backgroundColor: "#D0E1FC",
                pointRadius: 0,
                borderWidth: 4,
                data: this.callClicks,
              },
              {
                label: this.$t("screens.report.texts.listingConversations"),
                borderColor: "#ea9999",
                backgroundColor: "#f8e0e0",
                pointRadius: 0,
                borderWidth: 4,
                data: this.businessConversations,
              },
              {
                label: this.$t("screens.report.texts.listingBookings"),
                borderColor: "#ffe599",
                backgroundColor: "#fff7e0",
                pointRadius: 0,
                borderWidth: 4,
                data: this.businessBookings,
              },
              {
                label: this.$t("screens.report.texts.listingFoodOrders"),
                borderColor: "#f9cb9c",
                backgroundColor: "#fdefe1",
                pointRadius: 0,
                borderWidth: 4,
                data: this.businessFoodOrders,
              },
            ],
          },
          options: {
            tooltips: {
              enabled: true,
              intersect: false,
            },

            legend: {
              display: false,
            },

            scales: {
              yAxes: [
                {
                  ticks: {
                    fontColor: "#9f9f9f",
                    beginAtZero: false,
                    maxTicksLimit: 5,
                  },
                  gridLines: {
                    drawBorder: false,
                    zeroLineColor: "transparent",
                    color: "rgba(255,255,255,0.05)",
                  },
                  stacked: false,
                },
              ],

              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(255,255,255,0.1)",
                    zeroLineColor: "transparent",
                    display: false,
                  },
                  ticks: {
                    padding: 20,
                    fontColor: "#9f9f9f",
                  },
                },
              ],
            },
          },
        })
      } catch (error) {
        this.message = this.$t("actions.messages.dashboardError")
        if (this.myChart) {
          this.myChart.destroy()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.area-background-chart {
  .card-header {
    padding: 25px 30px 0 !important;
  }

  .select-info.el-select .el-input input {
    padding: 5px 23px;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .select-info.el-select .el-input .el-input__icon {
    display: flex;
    height: 29px !important;
    justify-content: center;
    align-items: center;
  }

  .select-info {
    width: 170px !important;
  }

  .mr-20px {
    margin-right: 20px;
  }
}
</style>
