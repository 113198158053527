<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="card" v-else>
      <div class="card-header">
        <h4 class="card-title ml-3">
          {{ $t("screens.coreInformation.update") }}
        </h4>
      </div>
      <div class="card-body">
        <core-information />
      </div>
      <div class="card-body">
        <business-details-information />
      </div>
      <div class="card-body">
        <contact-websites-apps />
      </div>
      <div class="card-body">
        <h4 class="card-title ml-3">
          {{ $t("screens.basicInformation.buttons.addImage") }}
        </h4>
        <p-button
          @click="handleAddMedia"
          class="media-content-add ml-3"
          type="info"
          round
        >
          {{ $t("screens.basicInformation.buttons.addImage") }}
        </p-button>
      </div>
    </div>
  </div>
</template>

<script>
import CoreInformation from "./BasicInformation/CoreInformation"
import BusinessDetailsInformation from "./BasicInformation/BusinessDetailsInformation"
import ContactWebsitesApps from "./BasicInformation/ContactWebsitesApps"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"

export default {
  components: {
    CoreInformation,
    BusinessDetailsInformation,
    ContactWebsitesApps,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
  methods: {
    handleAddMedia() {
      this.$router.push("/media")
    },
  },
}
</script>
