var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-content-wrapper"},[(_vm.messages)?_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}):_vm._e(),_c('div',{staticClass:"media-content-container"},[_c('div',{staticClass:"card media-content-card ml-3"},[_vm._m(0),_c('div',{staticClass:"card-body media-content-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("screens.media.texts.title"))+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.$t("screens.media.texts.description"))+" ")]),_c('p-button',{staticClass:"media-content-add",attrs:{"type":"primary","round":""},on:{"click":_vm.openModalMedia}},[_vm._v(" "+_vm._s(_vm.$t("screens.media.buttons.addMedia"))+" ")])],1)])]),_c('div',{staticClass:"media-content-album"},_vm._l((_vm.listMedia),function(file,index){return _c('div',{key:file.id,staticClass:"media-content-box",on:{"click":function($event){return _vm.openCurrentMedia(index)}}},[(
          _vm.imageExtensionType.includes(
            _vm.getExtension(file.photo_url).toLowerCase()
          )
            ? file.photo_url
            : ''
        )?_c('img',{staticClass:"media-content-photos",attrs:{"src":file.photo_url,"alt":""}}):(
          _vm.videoExtensionType.includes(
            _vm.getExtension(file.photo_url).toLowerCase()
          )
            ? {
                mp4: file.photo_url,
                props: {
                  autoplay: false,
                  controls: true,
                },
              }
            : ''
        )?_c('div',[_c('video',{staticClass:"media-content-photos",attrs:{"src":((file.photo_url) + "#t=0.5"),"alt":""}}),_vm._m(1,true)]):_c('div',[(file.gmb_media_type === _vm.mediaGmbType.PHOTO)?_c('img',{staticClass:"media-content-photos",attrs:{"src":file.photo_url,"referrerpolicy":"no-referrer"}}):(file.gmb_media_type === _vm.mediaGmbType.VIDEO)?_c('div',[_c('video',{staticClass:"media-content-photos",attrs:{"poster":file.photo_url,"src":file.photo_url,"referrerpolicy":"no-referrer"}}),_vm._m(2,true)]):_vm._e()])])}),0),_c('transition',{attrs:{"name":"modal"}},[(_vm.isCurrentMedia)?_c('div',{staticClass:"modal"},[(_vm.isCurrentMedia)?_c('div',{staticClass:"modal-overlay",on:{"click":function($event){_vm.isCurrentMedia = false}}}):_vm._e(),_c('div',{staticClass:"modal-wrapper"},[_c('button',{staticClass:"modal-close",on:{"click":function($event){_vm.isCurrentMedia = false}}},[_c('i',{staticClass:"fa fa-arrow-left modal-close--icon fa-xl",attrs:{"aria-hidden":"true"}})]),_c('button',{staticClass:"media-content-remove",on:{"click":function($event){return _vm.showSwalDelete()}}},[_c('i',{staticClass:"fa fa-trash media-content-remove--icon fa-xl",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"modal-container"},[_c('swiper',{ref:"mySwiper",staticClass:"swiper media-content-slides",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.listMedia),function(file){return _c('swiper-slide',{key:file.id},[(
                  _vm.imageExtensionType.includes(
                    _vm.getExtension(file.photo_url).toLowerCase()
                  )
                    ? file.photo_url
                    : ''
                )?_c('img',{staticClass:"media-content-slide",attrs:{"src":file.photo_url,"alt":""}}):(
                  _vm.videoExtensionType.includes(
                    _vm.getExtension(file.photo_url).toLowerCase()
                  )
                    ? {
                        mp4: file.photo_url,
                      }
                    : ''
                )?_c('video',{staticClass:"media-content-slide",attrs:{"controls":"","src":((file.photo_url) + "#t=0.5"),"alt":""}}):_c('div',[(file.gmb_media_type === _vm.mediaGmbType.PHOTO)?_c('img',{staticClass:"media-content-slide",attrs:{"src":file.photo_url,"referrerpolicy":"no-referrer"}}):(file.gmb_media_type === _vm.mediaGmbType.VIDEO)?_c('video',{staticClass:"media-content-slide",attrs:{"poster":file.photo_url,"src":file.photo_url,"referrerpolicy":"no-referrer"}}):_vm._e()])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)])]):_vm._e()]),_c('modal',{attrs:{"show":_vm.isMedia,"headerClasses":"justify-content-center"},on:{"update:show":function($event){_vm.isMedia=$event}}},[_c('h4',{staticClass:"title title-up",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.media.texts.uploadMedia"))+" ")]),_c('div',{staticClass:"row"},[_c('el-upload',{staticClass:"upload-demo custom-upload col-md-12",attrs:{"limit":10,"list-type":"picture","drag":"","multiple":"","auto-upload":false,"action":"#","on-change":_vm.handleChange,"show-file-list":false,"file-list":_vm.form.photos,"accept":"image/*,video/*"}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_c('p',[_vm._v(_vm._s(_vm.$t("screens.dashboard.texts.dropFile")))]),_c('em',[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.clickUpload"))+" ")])])])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header media-content-header"},[_c('img',{staticClass:"media-content-image card-title",attrs:{"src":require("@/assets/images/media/video.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-content-box-play"},[_c('i',{staticClass:"fa fa-play-circle-o",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-content-box-play"},[_c('i',{staticClass:"fa fa-play-circle-o",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }