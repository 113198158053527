import screens from "./lang/ja/screens"

export default [
  {
    name: `${screens.menu.dashboard}`,
    icon: "nc-icon nc-bank",
    path: "/dashboard",
  },
  {
    name: `${screens.menu.report}`,
    icon: "nc-icon nc-paper",
    path: "/report",
  },
  {
    name: `${screens.menu.storeInformationManagement}`,
    icon: "nc-icon nc-shop",
    children: [
      {
        name: `${screens.menu.basicInformation}`,
        icon: "fa fa-info-circle",
        path: "/basic-information",
      },
      {
        name: `${screens.menu.knowledgeTag}`,
        icon: "fa fa-lightbulb-o",
        path: "/knowledge-tag",
      },
      {
        name: `${screens.menu.listingAccount}`,
        icon: "fa fa-list",
        path: "/connect-account",
      },
    ],
  },
  {
    name: `${screens.menu.postManagement}`,
    icon: "nc-icon nc-tile-56",
    children: [
      {
        name: `${screens.menu.media}`,
        icon: "nc-icon nc-album-2",
        path: "/media",
      },
      {
        name: `${screens.menu.postList}`,
        path: "/posts",
        icon: "fa fa-plus",
      },
    ],
  },
  {
    name: `${screens.dashboard.buttons.paymentSetting}`,
    icon: "nc-icon nc-credit-card",
    path: "/register-payment",
  },
  // {
  //   name: "Account",
  //   icon: "nc-icon nc-badge",
  //   children: [
  //     {
  //       name: "Connect Account",
  //       path: "/admin/connect-account",
  //     },
  //   ],
  // },
  // {
  //   name: "Payment",
  //   icon: "nc-icon nc-credit-card",
  //   children: [
  //     {
  //       name: "Register Credit Card",
  //       path: "/admin/register-payment",
  //     },
  //     {
  //       name: "Payment Method",
  //       path: "/admin/change-payment-method",
  //     },
  //   ],
  // },
  // {
  //   name: "Pages",
  //   icon: "nc-icon nc-book-bookmark",
  //   children: [
  //     {
  //       name: "Timeline",
  //       path: "/pages/timeline",
  //     },
  //     {
  //       name: "User Profile",
  //       path: "/pages/user",
  //     },
  //     {
  //       name: "Login",
  //       path: "/login",
  //     },
  //     {
  //       name: "Register",
  //       path: "/register",
  //     },
  //     {
  //       name: "Lock",
  //       path: "/lock",
  //     },
  //     {
  //       name: "Report-insights",
  //       path: "/report-insights",
  //     },
  //   ],
  // },
  // {
  //   name: "Components",
  //   icon: "nc-icon nc-layout-11",
  //   children: [
  //     {
  //       name: "Buttons",
  //       path: "/components/buttons",
  //     },
  //     {
  //       name: "Grid System",
  //       path: "/components/grid-system",
  //     },
  //     {
  //       name: "Panels",
  //       path: "/components/panels",
  //     },
  //     {
  //       name: "Sweet Alert",
  //       path: "/components/sweet-alert",
  //     },
  //     {
  //       name: "Notifications",
  //       path: "/components/notifications",
  //     },
  //     {
  //       name: "Icons",
  //       path: "/components/icons",
  //     },
  //     {
  //       name: "Typography",
  //       path: "/components/typography",
  //     },
  //   ],
  // },
  // {
  //   name: "Forms",
  //   icon: "nc-icon nc-ruler-pencil",
  //   children: [
  //     {
  //       name: "Regular Forms",
  //       path: "/forms/regular",
  //     },
  //     {
  //       name: "Extended Forms",
  //       path: "/forms/extended",
  //     },
  //     {
  //       name: "Validation Forms",
  //       path: "/forms/validation",
  //     },
  //     {
  //       name: "Wizard",
  //       path: "/forms/wizard",
  //     },
  //   ],
  // },
  // {
  //   name: "Table List",
  //   icon: "nc-icon nc-single-copy-04",
  //   collapsed: true,
  //   children: [
  //     {
  //       name: "Regular Tables",
  //       path: "/table-list/regular",
  //     },
  //     {
  //       name: "Extended Tables",
  //       path: "/table-list/extended",
  //     },
  //     {
  //       name: "Paginated Tables",
  //       path: "/table-list/paginated",
  //     },
  //   ],
  // },
  // {
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   children: [
  //     {
  //       name: "Google Maps",
  //       path: "/maps/google",
  //     },
  //     {
  //       name: "Full Screen Maps",
  //       path: "/maps/full-screen",
  //     },
  //     {
  //       name: "Vector Maps",
  //       path: "/maps/vector-map",
  //     },
  //   ],
  // },
  // {
  //   name: "Widgets",
  //   icon: "nc-icon nc-box",
  //   path: "/admin/widgets",
  // },
  // {
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   path: "/charts",
  // },
  // {
  //   name: "Calendar",
  //   icon: "nc-icon nc-calendar-60",
  //   path: "/calendar",
  // },
]
