import { domainType } from "src/util/constants"

export default {
  methods: {
    /**
     * Extracts the agency name, page title, favicon URL, and determines if the domain has a subdomain.
     * @param {string} domain - The domain from which to extract the agency name and determine subdomain.
     * @returns {Object} - An object containing the extracted agency name, page title, favicon URL, and subdomain information.
     */
    getAgencyName(domain) {
      const url = new URL(domain) // Create a new URL object using the provided domain
      const subdomains = url.hostname.split(".") // Split the hostname into an array of subdomains

      // Assuming the agency name is always the first subdomain
      const agencyName = subdomains[0] // Variable to store the extracted agency name
      const domainSystem = this.getDomainSystem(subdomains) // Variable to store the subdomain using reverse
      const faviconUrl = `${process.env.VUE_APP_FAVICON_URL}${agencyName}/favicon.png` // Construct the favicon URL using the agency name

      // Return an object with the extracted information
      return {
        agencyName,
        domainSystem,
        faviconUrl,
      }
    },
    /**
     * Returns a domain system identifier based on the presence of keywords in subdomains.
     * @param {Array} subdomains - An array containing subdomain strings.
     * @returns {string} - The domain system identifier ("lighten-meo", "meo-system") or "unknown".
     */
    getDomainSystem(subdomains) {
      for (let i = 0; i < subdomains.length - 1; i++) {
        if (subdomains[i].includes(domainType.LIGHTEN.key)) {
          return "lighten-meo"
        } else if (subdomains[i].includes(domainType.MEO_SYSTEM.key)) {
          return "meo-system"
        }
      }

      // If no matching keyword is found in any subdomain, return "unknown".
      return "unknown"
    },
  },
}
