import $axios from "@/plugins/axios"

export default class PublisherApi {
  async connect({ data }) {
    return await $axios({
      method: "GET",
      url:
        "/publisher/connect?publisher_key=" +
        data.publisher_key +
        "&user_id=" +
        data.user_id,
    })
  }

  async disconnect({ data }) {
    return await $axios({
      method: "PUT",
      url: "/publisher/disconnect",
      data,
    })
  }

  async getPublisher({ data }) {
    return await $axios({
      method: "GET",
      url: "/publisher?user_id=" + data.user_id,
    })
  }

  async syncGMB({ data }) {
    return await $axios({
      method: "POST",
      url: "/publisher/google-my-business/sync",
      data,
    })
  }

  async getFacebookPages({ data }) {
    return await $axios({
      method: "GET",
      url: "/publisher/facebook/pages?user_id=" + data.user_id,
    })
  }

  async postFacebookPages({ data }) {
    return await $axios({
      method: "POST",
      url: "/publisher/facebook/connect-page",
      data,
    })
  }
}
