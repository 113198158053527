export default async function auth({ next, to, router, store }) {
  if (!window.localStorage.getItem("token")) {
    return next({
      name: "Login",
    })
  } else if (!store.state.auth.isLoggedIn) {
    await store.dispatch("auth/refresh")
    if (!store.state.auth.isLoggedIn) {
      return next({
        name: "Login",
      })
    }
  }

  // check roles
  if (to.meta.roles) {
    if (to.meta.roles.includes(store.state.auth.user.role)) {
      return next()
    } else {
      return next({
        name: "Dashboard",
      })
    }
  }

  return next()
}
