<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <ChangeMethod></ChangeMethod>
    </div>
  </div>
</template>

<script>
import ChangeMethod from "./PaymentMethod/ChangeMethod"

export default {
  components: {
    ChangeMethod,
  },
}
</script>
