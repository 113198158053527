import PostalCodeApi from "@/services/postalCodeApi"

const api = new PostalCodeApi()

const state = () => ({
  item: {},
})

const mutations = {
  setPostalCode(state, data) {
    state.item = data
  },
}

const actions = {
  async getPostalCode({ commit }, { code }) {
    await api.getPostalCode({ code }).then((response) => {
      commit("setPostalCode", response.data.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
