import $axios from "@/plugins/axios"
export default class MediaApi {
  async getListMedia(userId) {
    return await $axios.get(`/photos?user_id=${userId}`)
  }

  async uploadMedia(data) {
    return await $axios.post("/photos/upload", data)
  }

  async deleteMedia({ id, userId }) {
    return await $axios.delete(`/photos/${id}?user_id=${userId}`)
  }
}
