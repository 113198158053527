<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="row" v-else>
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{ $t("screens.dashboard.texts.manageProducts") }}
            </h4>
            <div>
              <el-button type="primary" @click="openModal">{{
                $t("screens.dashboard.texts.addProduct")
              }}</el-button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <products></products>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
        class="custom-modal"
        :show.sync="isAddProduct"
        headerClasses="justify-content-center"
      >
        <h4 slot="header" class="title title-up">
          {{ $t("screens.dashboard.texts.addProduct") }}
        </h4>
        <div>
          <product-content></product-content>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Products from "./Product/Products"
import { Button } from "element-ui"
import { Modal } from "src/components/UIComponents"
import ProductContent from "./Product/ProductContent.vue"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"

export default {
  components: {
    [Button.name]: Button,
    Products,
    Modal,
    ProductContent,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
  methods: {
    openModal() {
      this.isAddProduct = true
    },
  },
}
</script>
