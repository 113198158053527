<template>
  <div class="card">
    <modal
      :show.sync="isSettingInformation"
      headerClasses="justify-content-center"
      class="custom-modal"
    >
      <h4 slot="header" class="title center">
        {{ $t("screens.dashboard.texts.settingAccountTitle") }}
      </h4>
      <div>
        <ValidationObserver
          ref="change-setting-account"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <AdminAlert
              v-if="messages"
              :type="messages.type"
              :messages="messages.data"
            ></AdminAlert>

            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.namePersonCharge')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    name="full-name"
                    type="text"
                    :label="$t('screens.dashboard.forms.namePersonCharge')"
                    :placeholder="
                      $t('screens.dashboard.forms.namePersonCharge')
                    "
                    v-model="form.name"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.companyName')"
                  v-slot="{ errors }"
                >
                  <fg-input
                    name="company-name"
                    type="text"
                    :label="$t('screens.dashboard.forms.companyName')"
                    :placeholder="$t('screens.dashboard.forms.companyName')"
                    v-model="form.company_name"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.storeName')"
                  v-slot="{ errors }"
                >
                  <fg-input
                    name="store-name"
                    type="text"
                    :label="$t('screens.dashboard.forms.storeName')"
                    :placeholder="$t('screens.dashboard.forms.storeName')"
                    v-model="form.store_name"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.email')"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <fg-input
                    name="email"
                    type="email"
                    :label="$t('screens.dashboard.forms.email')"
                    :placeholder="$t('screens.dashboard.forms.email')"
                    v-model="form.email"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.phone')"
                  v-slot="{ errors }"
                >
                  <fg-input
                    name="phone"
                    type="text"
                    :label="$t('screens.dashboard.forms.phone')"
                    :placeholder="$t('screens.dashboard.forms.phone')"
                    v-model="form.phone"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="text-center">
              <p-button native-type="submit" type="info" round>
                {{ $t("screens.dashboard.buttons.update") }}
              </p-button>
            </div>
            <div class="clearfix"></div>
          </form>
        </ValidationObserver>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from "src/components/UIComponents"
import { convertMessage, objToFormData } from "src/util/commons"
import { IMAGE_SIZE, messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import defaultAvatar from "@/assets/images/default-avatar.png"

export default {
  mixins: [formMixin],
  components: {
    Modal,
  },
  data() {
    return {
      form: {
        name: "",
        company_name: "",
        store_name: "",
        email: "",
        phone: "",
        logo_url: "",
      },
      logoUrlString: "",
      isShow: false,
      IMAGE_SIZE: IMAGE_SIZE,
      messages: null,
      isSettingInformation: false,
    }
  },
  computed: {
    getAvatar() {
      return this.logoUrlString ? this.logoUrlString : defaultAvatar
    },
    getUserInfo() {
      return this.$store.state.auth.userInfo
    },
  },
  async created() {
    await this.$store.dispatch("auth/getUserInfo")
    this.form = { ...this.getUserInfo }
    this.logoUrlString = this.getUserInfo.logo_url
  },
  methods: {
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        const data = objToFormData(this.form)
        await this.$store.dispatch("auth/changeInformation", data)
        this.messages = {
          data: this.$t("actions.messages.commons.updateSuccess"),
          type: messageType.SUCCESS,
        }

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },

    openUploadAvatar() {
      this.isShow = true
    },

    closeUploadAvatar() {
      this.isShow = false
    },

    async uploadAvatar(e) {
      this.form.logo_url = this.handlerUploadFile(e)
      this.logoUrlString = await this.generatorImage(this.form.logo_url)
      const provider = this.$refs.logo_url
      return provider.validate()
    },
  },
}
</script>

<style lang="css" scoped>
.custom-modal >>> .modal-content {
  width: 110%;
}

.custom-modal >>> .modal-body {
  padding: 20px 40px 10px 40px;
}

@media screen and (max-width: 767px) {
  .custom-modal >>> .modal-content {
    width: 100%;
  }
}
</style>
