export const validatorNumber = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val)
}

export const validatorUrl = (val) => {
  const regex =
    /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g
  return regex.test(val)
}

export const validatorCreditCard = (val) => {
  const regex = /^.{16}$/
  return regex.test(val)
}
