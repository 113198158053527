<template>
  <div class="col-lg-6 col-md-6 ml-auto mr-auto reset-password-page">
    <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <card type="login" class="reset-password-page-card">
          <h3 slot="header" class="header text-center">パスワードの再設定</h3>

          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
            vid="newPass"
          >
            <fg-input
              name="password"
              v-model="form.password"
              placeholder="パスワード"
              type="password"
              addon-left-icon="nc-icon nc-key-25"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              :error="errors[0]"
            ></fg-input>
          </ValidationProvider>

          <ValidationProvider
            name="password"
            rules="required|min:8|confirmed:newPass"
            v-slot="{ errors }"
          >
            <fg-input
              name="password-confirmation"
              v-model="form.password_confirmation"
              placeholder="パスワードの確認"
              type="password"
              addon-left-icon="nc-icon nc-key-25"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              :error="errors[0]"
            ></fg-input>
          </ValidationProvider>

          <br />

          <p-button
            native-type="submit"
            slot="footer"
            type="warning"
            class="col-lg-4 col-md-4 ml-auto mr-auto"
            round
            block
          >
            リセット
          </p-button>
        </card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Card, Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"

export default {
  components: {
    Card,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
      },
      messages: null,
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          ...this.form,
          token: this.$route.query.token,
          email: this.$route.query.email,
        }
        await this.$store.dispatch("auth/resetPassword", data)
        this.$router.replace("/login")
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
