import { serialize } from "object-to-formdata"
import moment from "moment"
import heic2any from "heic2any"
import i18n from "@/plugins/i18n"
import { imageExtensionType, videoExtensionType } from "@/util/constants"

export const objToFormData = (object) => {
  const options = {
    allowEmptyArrays: true,
  }
  return serialize(object, options)
}

export function convertMessage(messages) {
  const arr = Object.values(messages)
  return arr.flat()
}

export const reverseObj = (obj) => {
  const newObj = {}
  Object.keys(obj)
    .sort()
    .reverse()
    .forEach((key) => {
      newObj[key] = obj[key]
    })
  return newObj
}

/**
 * Determines whether a URL points to an image or a video based on the file extension.
 *
 * @param {string} url - The URL to be checked.
 * @returns {string} Returns a string indicating the type of the URL's content.
 *   Possible return values:
 *   - "image" if the URL points to an image (file extension matches image extensions).
 *   - "video" if the URL points to a video (file extension matches video extensions).
 *   - "unknown" if the URL's content type is not recognized as an image or a video.
 *
 * @example
 * const url1 = "https://example.com/image.jpg";
 * const url2 = "https://example.com/video.mp4";
 * console.log(isImageOrVideo(url1)); // Output: "image"
 * console.log(isImageOrVideo(url2)); // Output: "video"
 *
 * @note
 * This function relies on the file extension of the URL to determine the content type.
 * It may not cover all possible image and video formats, and the accuracy depends on
 * the correctness of the file extensions in the URL. For better accuracy, consider using
 * more advanced techniques, like fetching the actual content type from the server.
 */
export function isImageOrVideo(url) {
  const imageExtensions = imageExtensionType
  const videoExtensions = videoExtensionType

  const urlExtension = String(url).split(".").pop().toLowerCase()

  if (imageExtensions.includes(urlExtension)) {
    return "image"
  } else if (videoExtensions.includes(urlExtension)) {
    return "video"
  } else {
    return "unknown"
  }
}

/**
 * Get the resolution (width and height) of an image file without displaying the image.
 * This function reads the file data, creates an Image object, and resolves with the image's resolution.
 * If there is an error while reading or loading the image, the Promise will be rejected with an error message.
 *
 * @param {File} file - The image file for which to determine the resolution.
 * @returns {Promise<{width: number, height: number}>} A Promise that resolves with an object containing the width and height of the image.
 * @throws {Error} If there is an error while reading or loading the image.
 */
export function getImageResolution(url) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = "Anonymous" // Optional, allows cross-origin requests
    image.onload = function () {
      const resolution = {
        width: image.naturalWidth,
        height: image.naturalHeight,
      }
      resolve(resolution)
    }
    image.onerror = function () {
      reject(new Error("Error loading the image. Please make sure it is a valid image URL."))
    }
    // Handle cases where the image loading takes too long
    image.onabort = function () {
      reject(new Error("Image loading aborted. The file may be too large."))
    }
    image.src = url
  })
}

/**
 * Get the resolution (width and height) of a video file.
 * This function reads the file data, creates a Video object, and resolves with the video's resolution.
 * If there is an error while reading or loading the video, the Promise will be rejected with an error message.
 *
 * @param {File} file - The video file for which to determine the resolution.
 * @returns {Promise<{width: number, height: number}>} A Promise that resolves with an object containing the width and height of the video.
 * @throws {Error} If there is an error while reading or loading the video.
 */
export function getVideoResolution(url) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.preload = "metadata"
    video.onloadedmetadata = function () {
      const resolution = {
        width: video.videoWidth,
        height: video.videoHeight,
      }
      const duration = video.duration
      resolve({ resolution, duration })
    }
    video.onerror = function () {
      reject(new Error("Unable to load video or get resolution"))
    }
    video.src = url
  })
}

/**
 * Retrieves the resolution of an image by its URL.
 *
 * @param {string} url - The URL of the image.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the width and height of the image.
 *                              If an error occurs (e.g., image not found), rejects with an error.
 */
export function getResolutionByUrl(url) {
  return new Promise((resolve, reject) => {
    // Create a new Image object
    const img = new Image()

    // Set the source URL of the image
    img.src = url

    // Define the onload callback to execute when the image is successfully loaded
    img.onload = function () {
      // Extract the natural width and height of the image
      const resolution = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      }

      // Resolve the promise with the resolution object
      resolve(resolution)
    }

    // Define the onerror callback to execute when an error occurs during image loading
    img.onerror = function () {
      // Throw an error to reject the promise
      reject(new Error("Error loading image"))
    }
  })
}

/**
 * Retrieves the resolution of a TIFF image by its URL.
 *
 * @param {string} url - The URL of the TIFF image.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the width and height of the image.
 *                              If an error occurs (e.g., image not found, or it's not a TIFF file), rejects with an error.
 */
export function getTIFFResolution(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      try {
        const tiff = new Tiff({ buffer: event.target.result })

        // Define the onload callback to execute when the TIFF is successfully loaded
        tiff.onload = function () {
          // Extract the width and height of the TIFF image
          const resolution = {
            width: tiff.width(),
            height: tiff.height(),
          }

          // Resolve the promise with the resolution object
          resolve(resolution)
        }

        // Define the onerror callback to execute when an error occurs during TIFF loading
        tiff.onerror = function () {
          // Throw an error to reject the promise
          reject(new Error("Error loading TIFF image"))
        }
      } catch (error) {
        reject(new Error("Invalid TIFF file"))
      }
    }

    reader.onerror = function () {
      reject(new Error("Error reading TIFF file"))
    }

    reader.readAsArrayBuffer(file)
  })
}

/**
 * Create an empty file with a specified size.
 *
 * @param {string} fileName - The desired name for the new empty file.
 * @param {string} mimeType - The MIME type of the file (e.g., "text/plain", "application/octet-stream").
 * @param {number} sizeInBytes - The desired size of the empty file in bytes.
 * @returns {File} The new empty File object.
 */
export function createEmptyFileWithSize(fileName, mimeType, sizeInBytes) {
  const emptyData = new Uint8Array(sizeInBytes)
  const blob = new Blob([emptyData], { type: mimeType })
  const file = new File([blob], fileName, { type: mimeType })
  return file
}

/**
 * Check if a URL contains a domain.
 *
 * @param {string} urlString - The URL to check.
 * @returns {boolean} Returns true if the URL contains a domain; otherwise, returns false.
 */
export function hasDomain(urlString) {
  try {
    const url = new URL(urlString)
    return !!url.hostname
  } catch (error) {
    return false
  }
}

/**
 * Formats the given date and time string to the format "YYYY/MM/DD HH:mm:ss".
 * Uses the Moment.js library for parsing and formatting.
 *
 * @param {string} dateTime - The date and time string to be formatted.
 *                           It should be in a valid date-time format parsable by Moment.js.
 * @returns {string} The formatted date and time string in the format "YYYY/MM/DD HH:mm:ss".
 * @throws {Error} If the input `dateTime` is not in a valid format that can be parsed by Moment.js.
 *
 * @example
 * const dateTimeString = "2023-08-02T12:34:56";
 * const formattedDateTime = formatDateTime(dateTimeString);
 * console.log(formattedDateTime); // Output: "2023/08/02 12:34:56"
 */
export function formatDateTime(dateTime) {
  return moment(dateTime).format("YYYY/MM/DD HH:mm:ss")
}

/**
 * Formats a date object or timestamp into a string with the format "YYYY/MM/DD".
 *
 * @param {Date|number|string} date - The date object, timestamp, or date string to format.
 * @returns {string} The formatted date string in the "YYYY/MM/DD" format.
 */
export function formatDate(date) {
  return moment(date).format("YYYY/MM/DD")
}

/**
 * Formats a time object or timestamp into a string with the format "HH:mm:ss".
 *
 * @param {Date|number|string} time - The time object, timestamp, or time string to format.
 * @returns {string} The formatted time string in the "HH:mm:ss" format.
 */
export function formatTime(time) {
  return moment(time).format("HH:mm:ss")
}

/**
 * Checks if an object is empty.
 * An object is considered empty if it has no enumerable properties (keys).
 *
 * @param {Object} obj - The object to be checked for emptiness.
 * @returns {boolean} Returns true if the object is empty, otherwise returns false.
 *
 * @example
 * const myObject = { a: 1, b: 2 };
 * const isEmpty = isObjectEmpty(myObject);
 * console.log(isEmpty); // Output: false
 *
 * @example
 * const emptyObject = {};
 * const isEmpty2 = isObjectEmpty(emptyObject);
 * console.log(isEmpty2); // Output: true
 */
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0
}

/**
 * Generates a random 13-digit UID number.
 *
 * The UID number is composed of a Unix timestamp in milliseconds,
 * representing the number of milliseconds since January 1, 1970 (UTC),
 * followed by a 5-digit random suffix to ensure uniqueness.
 *
 * @returns {string} A string representing the random 13-digit UID number.
 *
 * @example
 * const uid = generateRandomUID();
 * console.log(uid); // Output: A random 13-digit number like "1691045593713"
 */
export function generateRandomUID() {
  const timestamp = new Date().getTime() // Get the current timestamp in milliseconds
  const randomSuffix = Math.floor(Math.random() * 100000) // Generate a random 5-digit number

  // Combine the timestamp with the random suffix and pad with leading zeros if necessary
  const uid = `${timestamp}${randomSuffix.toString().padStart(5, "0")}`

  return uid
}

/**
 * Checks if a given filename has a .heic extension.
 *
 * @param {string} filename - The filename to check for the .heic extension.
 * @returns {string | null} - The MIME type "image/heic" if the filename has a .heic extension, or null if it doesn't.
 */
export function checkForHEICExtension(filename) {
  // Use a regular expression to check if the filename ends with ".heic"
  const heicExtensionRegex = /\.heic$/i // Case-insensitive

  if (heicExtensionRegex.test(filename)) {
    return "heic"
  } else {
    return null // Return null if it doesn't have the .heic extension
  }
}

/**
 * Converts a HEIC file to a JPEG image.
 *
 * @param {File} file - The HEIC file to convert.
 * @returns {Promise<string | null>} - A Promise that resolves with a URL to the converted JPEG image or null if there was an error.
 */
export async function convertHEICToJPEG(file) {
  try {
    const response = await fetch(file)
    const blob = await response.blob()
    const conversionResult = await heic2any({
      blob,
      toType: "image/jpeg",
    })

    // Create a URL for the converted image
    const imageUrl = URL.createObjectURL(conversionResult)

    return imageUrl
  } catch (error) {
    return null
  }
}

/**
 * Checks if a given filename has a .tiff or .tif extension.
 *
 * @param {string} filename - The filename to check for the .tiff or .tif extension.
 * @returns {string | null} - The MIME type "image/tiff" if the filename has a .tiff or .tif extension, or null if it doesn't.
 */
export function checkForTIFFExtension(filename) {
  // Use a regular expression to check if the filename ends with ".tiff" or ".tif" (case-insensitive)
  const tiffExtensionRegex = /\.tiff$|\.tif$/i

  if (tiffExtensionRegex.test(filename)) {
    return "tiff"
  } else {
    return null // Return null if it doesn't have the .tiff or .tif extension
  }
}

/**
 * Converts a TIFF file to a PNG image.
 *
 * @param {File} file - The TIFF file to convert.
 * @returns {Promise<string | null>} - A Promise that resolves with a URL to the converted PNG image or null if there was an error.
 */
export async function convertTIFF(file) {
  try {
    const reader = new FileReader()
    const blob = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result)
      reader.readAsArrayBuffer(file)
    })

    // eslint-disable-next-line no-undef
    const tiff = new Tiff({ buffer: blob })
    const canvas = tiff.toCanvas()
    const dataURL = canvas.toDataURL("image/tiff")

    return dataURL
  } catch (error) {
    console.error("Error converting TIFF:", error)
    return null
  }
}

/**
 * Extracts the file extension from a given filename.
 *
 * @param {string} filename - The filename from which to extract the file extension.
 * @returns {string} - The file extension (including the dot) or an empty string if not found.
 */
export function getExtFromName(filename) {
  // Find the last dot (.) in the filename, indicating the extension
  const dotIndex = filename.lastIndexOf(".")

  // Return the extension if found, otherwise an empty string
  return dotIndex !== -1 ? filename.slice(dotIndex + 1) : ""
}
