<template>
  <div class="panel">
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.panel {
  position: relative;
  background-color: #f4f3ef;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  margin: 0 auto;
}
</style>
