<template>
  <div class="wrapper">
    <side-bar
      v-if="display"
      type="sidebar"
      :sidebar-links="$sidebar.sidebarLinks"
      background-color="white"
    >
    </side-bar>

    <div class="main-panel" :class="{ 'custom-main': !display }">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>

    <AdminLoading v-if="isLoading"></AdminLoading>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue"
import ContentFooter from "./ContentFooter.vue"
import DashboardContent from "./Content.vue"
import { roleType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },

  data() {
    return {
      roleType: roleType,
      display: false,
    }
  },

  watch: {
    $route: {
      handler(value) {
        if (
          (value.name === "Overview" ||
            value.name === "Overview Agencies" ||
            value.name === "Change Password" ||
            value.name === "Setting Account" ||
            value.name === "Manual Document") &&
          !value.query.key
        ) {
          if (
            this.getRole === roleType.AGENCY ||
            this.getRole === roleType.MANAGER ||
            this.isLeader === true
          ) {
            this.display = false
          } else {
            this.display = true
          }
        } else {
          this.display = true
        }
      },
      immediate: true,
    },
  },

  computed: {
    isLoading() {
      return this.$store.state.isShowLoading
    },
    getRole() {
      return this.$store.state.auth.user?.role
    },
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>

<style scoped>
.custom-main {
  width: 100%;
}
</style>
