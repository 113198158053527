<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)" ref="post-form">
      <div class="row">
        <div class="col-md-6">
          <PostUpload
            :name="name"
            v-model="form.file"
            :max="getLimit"
            :rule="getRule"
          />
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <label class="control-label">
                {{ $t("screens.dashboard.forms.writeYourPost") }}
              </label>
              <ValidationProvider
                :name="$t('screens.dashboard.forms.writeYourPost')"
                rules="required"
                v-slot="{ errors }"
              >
                <div
                  class="form-group"
                  :class="{ 'has-danger': errors.length > 0 }"
                >
                  <textarea
                    class="form-control"
                    v-model="form.content"
                    rows="1"
                  ></textarea>
                  <span class="custom-input--error" v-if="errors">
                    {{ errors[0] }}
                  </span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <!-- SNS Google Business Profile -->
          <div v-if="name === shareType.GBP">
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">
                  {{ $t("screens.dashboard.forms.addAButton") }}
                </label>
                <fg-input>
                  <el-select
                    size="large"
                    v-model="form.button_type"
                    :label="$t('screens.dashboard.forms.addAButton')"
                  >
                    <el-option
                      v-for="option in getButtonOptions"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>
            <div
              class="row"
              v-if="form.button_type === buttonOptions[0].value"
            ></div>
            <div
              class="row"
              v-else-if="form.button_type === buttonOptions[5].value"
            >
              <div class="col-md-12">
                <label class="control-label">
                  {{ $t("screens.dashboard.forms.phoneNumber") }}
                </label>
                <fg-input
                  type="text"
                  v-model="form.phone_number"
                  disabled
                ></fg-input>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <label class="control-label">
                  {{ $t("screens.dashboard.forms.linkForYourButton") }}
                </label>
                <ValidationProvider
                  name="url"
                  :rules="{
                    required: true,
                    regex:
                      /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g,
                  }"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    :error="errors[0]"
                    name="url"
                    v-model="form.button_link"
                  ></fg-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <label v-if="name === shareType.GBP" class="control-label mb-5 mt-2">
            {{ $t("screens.postSNS.texts.gbp") }}
          </label>
          <label class="control-label el-row--flex is-justify-center">
            {{ $t("screens.dashboard.texts.titlePublish") }}
            <span v-if="name !== shareType.INSTAGRAM">{{
              $t("screens.dashboard.texts.titleDelete")
            }}</span>
          </label>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">
                  {{ $t("screens.dashboard.forms.dateTimePublish") }}
                </label>
                <el-date-picker
                  :name="$t('screens.dashboard.forms.dateTimePublish')"
                  v-model="form.date_time_publish"
                  type="datetime"
                  class="custom-date-picker"
                  format="yyyy/MM/dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="startDateOptions"
                />
              </div>
            </div>
          </div>
          <div class="row" v-if="name !== shareType.INSTAGRAM">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">
                  {{ $t("screens.dashboard.forms.dateTimeRemove") }}
                </label>
                <el-date-picker
                  :name="$t('screens.dashboard.forms.dateTimeRemove')"
                  v-model="form.date_time_remove"
                  type="datetime"
                  class="custom-date-picker"
                  format="yyyy/MM/dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="endDateOptions"
                />
              </div>
            </div>
          </div>
          <label
            v-if="name === shareType.INSTAGRAM"
            class="control-label mb-5 mt-2"
          >
            {{ $t("screens.postSNS.texts.instagramDelete") }}
          </label>
        </div>
      </div>
      <div class="row el-row--flex is-justify-between">
        <div class="col-md-6">
          <p-button type="info" round @click="goBack">
            {{ $t("screens.registerInformation.buttons.back") }}
          </p-button>
        </div>
        <div class="col-md-6 pull-right">
          <div class="pull-right">
            <p-button type="info" round @click="clearForm" class="mr-2">
              {{ $t("screens.dashboard.buttons.clearPost") }}
            </p-button>
            <p-button
              native-type="submit"
              type="info"
              round
              :loading="isLoading"
              :disabled="isLoading || isDisabled"
            >
              <i v-if="isLoading" class="el-icon-loading"></i>
              {{ $t("screens.dashboard.buttons.post") }}
            </p-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Upload, DatePicker, Select, Option } from "element-ui"
import PostUpload from "./PostUpload.vue"
import {
  convertMessage,
  isObjectEmpty,
  generateRandomUID,
  isImageOrVideo,
  createEmptyFileWithSize,
  hasDomain,
  getExtFromName,
} from "src/util/commons"
import {
  imageType,
  videoType,
  shareType,
  fileNumberType,
  snsExtensionType,
} from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import moment from "moment"
import { mapState, mapActions } from "vuex"

export default {
  mixins: [formMixin],

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    [DatePicker.name]: DatePicker,
    PostUpload,
  },

  props: {
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      startDateOptions: {
        shortcuts: [
          {
            text: this.$t("screens.dashboard.texts.today"),
            onClick(picker) {
              picker.$emit("pick", new Date())
            },
          },
          {
            text: this.$t("screens.dashboard.texts.yesterday"),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", date)
            },
          },
          {
            text: this.$t("screens.dashboard.texts.aWeekAgo"),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", date)
            },
          },
        ],
      },
      endDateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
        shortcuts: [
          {
            text: this.$t("screens.dashboard.texts.today"),
            onClick(picker) {
              picker.$emit("pick", new Date())
            },
          },
          {
            text: this.$t("screens.dashboard.texts.yesterday"),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", date)
            },
          },
          {
            text: this.$t("screens.dashboard.texts.aWeekAgo"),
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", date)
            },
          },
        ],
      },
      form: {
        content: "",
        button_type: 0,
        button_link: null,
        phone_number: "",
        date_time_publish: "",
        date_time_remove: "",
        file: [],
      },
      buttonOptions: [
        {
          value: 0,
          label: this.$i18n.t("screens.addNewsOptions.none"),
        },
        {
          value: 1,
          label: this.$i18n.t("screens.addNewsOptions.book"),
        },
        {
          value: 2,
          label: this.$i18n.t("screens.addNewsOptions.orderOnline"),
        },
        {
          value: 4,
          label: this.$i18n.t("screens.addNewsOptions.learnMore"),
        },
        {
          value: 5,
          label: this.$i18n.t("screens.addNewsOptions.signUp"),
        },
        {
          value: 6,
          label: this.$i18n.t("screens.addNewsOptions.callNow"),
        },
      ],
      messages: null,
      imageType,
      videoType,
      shareType,
      fileNumberType,
      snsExtensionType,
      isEndDate: false,
      isRemoveDate: false,
      isLoading: false,
      isDisabled: false,
    }
  },

  watch: {
    "form.button_type"(value) {
      if (value === this.buttonOptions[5].value) {
        this.form.phone_number = this.getBasicInformation.main_phone
      }
    },
    "form.date_time_remove"(value) {
      this.isRemoveDate = !!this.validateEarlier(
        moment(value).format("YYYY-MM-DD HH:mm:ss")
      )
    },
    async item(value) {
      this.form.content = value.content
      this.form.button_type = value.button_type
      this.form.button_link = value.button_link
      this.form.phone_number = value.phone_number
      this.form.date_time_publish = value.publish_date
      this.form.date_time_remove = value.delete_date
      this.form.file = value.media_url.map((url) => {
        const name = url.split("/").pop()
        const size = 10001
        const percentage = 0
        const uid = generateRandomUID()
        const type =
          isImageOrVideo(url) === "image"
            ? `image/${getExtFromName(name)}`
            : `video/${getExtFromName(name)}`
        const file = createEmptyFileWithSize(name, type, size)

        return {
          status: "ready",
          name,
          size,
          percentage,
          uid,
          raw: file,
          url,
        }
      })
      this.setFiles(this.form.file)
    },
    async files(value) {
      await this.handleMediasBySns(value)
    },
    async active(value) {
      await this.handleMediasBySns(this.files)
      this.form = this.$store.state.post.form
    },
    errors(value) {
      if (value.length > 0) {
        this.isDisabled = true
        return true
      }
    },
    form: {
      handler(newVal) {
        this.setForm(newVal)
        if (this.form.content) {
          this.isDisabled = false
        } else {
          this.isDisabled = true
          return true
        }
        if (
          this.form.button_type &&
          this.form.button_type !== this.buttonOptions[0].value &&
          this.form.button_type !== this.buttonOptions[5].value
        ) {
          if (this.form.button_link) {
            this.isDisabled = false
          } else {
            this.isDisabled = true
            return true
          }
        } else {
          this.isDisabled = false
        }
        if (this.errors.length > 0) {
          this.isDisabled = true
          return true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState("post", ["item", "files", "active", "errors"]),
    userIds() {
      return this.$store.getters.userIds
    },
    getBasicInformation() {
      return this.$store.state.entity.basicInformation
    },
    filterCallNow() {
      return this.buttonOptions.filter(
        (button) => button.value !== this.buttonOptions[5].value
      )
    },
    getButtonOptions() {
      return this.getBasicInformation.main_phone
        ? this.buttonOptions
        : this.filterCallNow
    },
    getRule() {
      switch (this.name) {
        case this.shareType.GBP:
          return this.snsExtensionType.GBP
        case this.shareType.FACEBOOK:
          return this.snsExtensionType.FACEBOOK
        case this.shareType.INSTAGRAM:
          return this.snsExtensionType.INSTAGRAM
        case this.shareType.TWITTER:
          return this.snsExtensionType.TWITTER
        default:
          return "image/*, video/*"
      }
    },
    getLimit() {
      switch (this.name) {
        case this.shareType.GBP:
          return this.fileNumberType.GBP
        case this.shareType.FACEBOOK:
          return this.fileNumberType.FACEBOOK
        case this.shareType.INSTAGRAM:
          return this.fileNumberType.INSTAGRAM
        case this.shareType.TWITTER:
          return this.fileNumberType.TWITTER
        default:
          return 1
      }
    },
  },

  methods: {
    ...mapActions("post", ["create", "setFiles", "setForm", "resetState"]),
    async onSubmit() {
      this.isLoading = true
      try {
        if (this.errors.length > 0) return
        this.handleMediasBySns(this.files)
        const data = {
          user_id: this.userIds,
          content: this.form.content,
          button_type: this.form.button_type,
          button_link: this.form.button_link,
          phone_number: this.form.phone_number,
          shared_sns_type: this.name,
          publish_date: this.form.date_time_publish,
          delete_date: this.form.date_time_remove,
          medias: this.form.file.map((file) =>
            hasDomain(file.url) ? file.url : file.raw
          ),
        }
        const formData = new FormData()
        formData.append("user_id", data.user_id ?? "")
        formData.append("content", data.content ?? "")
        formData.append("button_type", data.button_type ?? "")
        formData.append("button_link", data.button_link ?? "")
        formData.append("phone_number", data.phone_number ?? "")
        formData.append("shared_sns_type", data.shared_sns_type ?? "")
        formData.append("publish_date", data.publish_date ?? "")
        formData.append("delete_date", data.delete_date ?? "")
        data.medias.forEach((media, index) =>
          formData.append("medias[" + index + "]", media)
        )
        await this.create({ data: formData })
        this.$store.commit("setShowLoading", false)
        this.$notify({
          message: this.$t("actions.messages.postSuccess"),
          type: "success",
        })
      } catch (err) {
        if (err.response?.data?.errors) {
          this.$notify({
            message: convertMessage(err.response.data.errors).join("\r\n"),
            type: "danger",
          })
        } else {
          this.$notify({
            message: this.$t("actions.messages.postError"),
            type: "danger",
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    isObjectEmpty(value) {
      return isObjectEmpty(value)
    },
    handleMediasBySns(value) {
      switch (this.active) {
        case this.shareType.GBP:
          this.form.file = value.slice(0, this.fileNumberType.GBP)
          break
        case this.shareType.FACEBOOK:
          this.form.file = value.slice(0, this.fileNumberType.FACEBOOK)
          break
        case this.shareType.INSTAGRAM:
          this.form.file = value.slice(0, this.fileNumberType.INSTAGRAM)
          break
        case this.shareType.TWITTER:
          this.form.file = value.slice(0, this.fileNumberType.TWITTER)
          break
        default:
          this.form.file = value.slice(0, 1)
          break
      }
    },
    goBack() {
      this.$router.push("/posts")
    },
    clearForm() {
      this.resetForm("form", "post-form")
      this.resetState()
    },
  },

  destroyed() {
    this.resetState()
  },
}
</script>

<style scoped>
.custom-upload >>> .el-upload--picture-card {
  display: inline-table;
  line-height: 0;
  order: 1;
  width: 100%;
}

.custom-upload >>> .el-upload-dragger {
  width: 100%;
  border: none;
}

.custom-upload >>> .el-upload-list--picture-card {
  display: block;
  order: 2;
  text-align: left;
  margin-top: 20px;
}

.custom-date-picker >>> input {
  padding-left: 32px;
  background-color: transparent;
}

.custom-date-picker >>> .el-input__prefix,
.el-input__suffix {
  color: #66615b;
}

.custom-date-picker {
  width: 100%;
}

.custom-modal >>> .modal-header {
  border-bottom: 1px solid transparent;
}

.custom-modal >>> .modal-body {
  padding: 0;
}
</style>
