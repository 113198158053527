<template>
  <div>
    <h6 class="ml-3">
      {{ $t("screens.coreInformation.contactWebsitesAndApps") }}
    </h6>
    <ValidationObserver ref="core-information" v-slot="{ handleSubmit }">
      <form class="form-horizontal" @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <fieldset>
            <div class="form-group">
              <label
                @click="addEmail(forms.email)"
                class="col-sm-6 control-label pointer"
              >
                <i class="nc-icon nc-simple-add"></i>
                {{ $t("screens.dayOfWeek.addAnother") }}
              </label>
              <div
                v-for="(item, index) in forms.email"
                :key="index"
                class="col-sm-10"
                style="margin-top: 10px"
              >
                <ValidationProvider
                  :name="$t('screens.coreInformation.emails')"
                  rules="email"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <fg-input
                      type="text"
                      :name="$t('screens.coreInformation.emails')"
                      v-model="item.value"
                      :error="errors[0]"
                      class="col-sm-11"
                    ></fg-input>
                    <label
                      @click="removeEmail(forms.email, index)"
                      class="pointer col-sm-1 flex-center custom-label"
                    >
                      {{ $t("screens.dayOfWeek.delete") }}
                    </label>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.websiteUrl") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  rules="url"
                  v-slot="{ errors }"
                  :name="$t('screens.coreInformation.websiteUrl')"
                >
                  <fg-input
                    type="text"
                    v-model="forms.website_url"
                    placeholder="url"
                    :error="errors[0]"
                  ></fg-input>
                </ValidationProvider>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="text-left col-sm-10">
          <p-button native-type="submit" type="info" round>
            {{ $t("screens.basicInformation.buttons.updateInformation") }}
          </p-button>
        </div>
      </form>
    </ValidationObserver>

    <AdminAlert
      v-if="messages"
      :type="messages.type"
      :messages="messages.data"
    />
  </div>
</template>
<script>
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import { url } from "src/util/validations/validations"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  data() {
    return {
      forms: {
        email: [],
        website_url: "",
      },
      messages: null,
      url,
    }
  },
  watch: {
    "$store.state.entity.contactWebsitesApps": {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            if (value.email) {
              this.forms.email = value.email
            }
            this.forms.website_url = value.website_url.url
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    addEmail(list) {
      list.push({ value: "" })
    },

    removeEmail(list, index) {
      list.splice(index, 1)
    },

    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        let data = {
          user_id: this.userIds[0],
          email:
            this.forms.email.length !== 0
              ? "[" +
                String(
                  this.forms.email.map(
                    (email) => (email = "'" + email.value + "'")
                  ) + "]"
                )
              : null,
          website_url: this.forms.website_url,
        }
        await this.$store.dispatch("entity/putContactWebsitesApps", {
          data,
        })
        data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("entity/getBasicInformation", {
          data,
        })
        this.messages = {
          data: this.$t("actions.messages.infoUpdateSuccess"),
          type: messageType.SUCCESS,
        }
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>

<style scoped>
.custom-label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
</style>
