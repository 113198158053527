<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="row" v-else>
      <div class="col-lg-4 col-md-12">
        <report-bar-chart
          :labels="labelsBarChart"
          :title="listingOnMaps.title"
          :datasets="listingOnMaps.datasets"
          :message="message"
        ></report-bar-chart>
      </div>

      <div class="col-lg-4 col-md-12">
        <report-bar-chart
          :labels="labelsBarChart"
          :title="discoverySearch.title"
          :datasets="discoverySearch.datasets"
          :message="message"
        ></report-bar-chart>
      </div>

      <div class="col-lg-4 col-md-12">
        <report-bar-chart
          :labels="labelsBarChart"
          :title="customerActions.title"
          :datasets="customerActions.datasets"
          :message="message"
        ></report-bar-chart>
      </div>

      <div class="col-md-12">
        <card>
          <div class="row">
            <div class="col-lg-9 col-md-12">
              <h6 class="card-title text-center report-big-title">
                {{ $t("screens.report.texts.specifyThePeriods") }}
              </h6>
              <div class="row custom-slider">
                <div class="col-lg-1 col-md-2 text-center">
                  {{ $t("screens.dashboard.texts.monthTwoYears") }}
                </div>
                <vue-slider
                  class="col-lg-10 col-md-8 w-100"
                  v-model="monthsValue"
                  :data="monthsData"
                  :data-value="'id'"
                  :data-label="'name'"
                  :hide-label="true"
                  :process="true"
                  :marks="true"
                ></vue-slider>
                <div class="col-lg-1 col-md-2 text-center">
                  {{ $t("screens.dashboard.texts.monthPresent") }}
                </div>
              </div>
            </div>

            <div
              v-if="isTrial"
              class="col-lg-3 col-md-12 text-right"
              style="padding: 18px"
            >
              <p-button
                type="info"
                class="col-lg-4 col-md-5"
                size="sm"
                @click="downloadBeforeCheck"
                round
                outline
                :disabled="isTrial"
                :class="{ 'opacity-03': isTrial }"
              >
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
                {{ $t("screens.report.buttons.pdf") }}
              </p-button>
              <p-button
                type="info"
                class="col-lg-4 col-md-5 ml-2"
                size="sm"
                @click="downloadBeforeCheck"
                round
                outline
                :disabled="isTrial"
                :class="{ 'opacity-03': isTrial }"
              >
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
                {{ $t("screens.report.buttons.csv") }}
              </p-button>
            </div>

            <div v-else class="col-lg-3 col-md-12 text-right p-3">
              <p-button
                type="info"
                class="col-lg-4 col-md-5"
                size="sm"
                @click="generateReport"
                round
                outline
                :disabled="isTrial"
                :class="{ 'opacity-03': isTrial }"
              >
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
                {{ $t("screens.report.buttons.pdf") }}
              </p-button>
              <span class="m-1"></span>
              <p-button
                type="info"
                class="col-lg-4 col-md-5"
                size="sm"
                round
                outline
                @click="exportCsv"
                :disabled="isTrial"
                :class="{ 'opacity-03': isTrial }"
              >
                <template v-if="isCsvExporting">
                  <i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
                </template>
                <template v-else>
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                </template>
                {{ $t("screens.report.buttons.csv") }}
              </p-button>
            </div>

            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="false"
              :preview-modal="true"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-content-width="800px"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <card>
                  <div class="row">
                    <section class="pdf-item">
                      <div style="width: 800px">
                        <report-bar-chart
                          :labels="labelsBarChart"
                          :title="discoverySearch.title"
                          :datasets="discoverySearch.datasets"
                        ></report-bar-chart>
                      </div>

                      <div style="width: 800px">
                        <report-bar-chart
                          :labels="labelsBarChart"
                          :title="listingOnMaps.title"
                          :datasets="listingOnMaps.datasets"
                        ></report-bar-chart>
                      </div>
                    </section>

                    <div class="html2pdf__page-break" />

                    <section class="pdf-item">
                      <div style="width: 800px; margin-top: 100px">
                        <report-bar-chart
                          :labels="labelsBarChart"
                          :title="customerActions.title"
                          :datasets="customerActions.datasets"
                        ></report-bar-chart>
                      </div>

                      <div style="width: 800px">
                        <report-stacked-chart
                          :labels="labelsStackedChart"
                          :title="customerSearchBusiness.title"
                          :datasets="customerSearchBusiness.datasets"
                        ></report-stacked-chart>
                      </div>
                    </section>

                    <div class="html2pdf__page-break" />

                    <section class="pdf-item">
                      <div style="width: 800px; margin-top: 100px">
                        <report-stacked-chart-single
                          :labels="labelsStackedChart"
                          :title="customerViewBusiness.title"
                          :datasets="customerViewBusiness.datasets"
                        ></report-stacked-chart-single>
                      </div>

                      <div style="width: 800px">
                        <report-stacked-chart
                          :labels="labelsStackedChart"
                          :title="customerActionsPriod.title"
                          :datasets="customerActionsPriod.datasets"
                        ></report-stacked-chart>
                      </div>
                    </section>
                  </div>
                </card>
              </section>
            </vue-html2pdf>

            <div class="col-md-12">
              <report-stacked-chart
                :labels="labelsStackedChart"
                :title="customerSearchBusiness.title"
                :datasets="customerSearchBusiness.datasets"
                :message="message"
              ></report-stacked-chart>
            </div>

            <div class="col-md-12">
              <report-stacked-chart-single
                :labels="labelsStackedChart"
                :title="customerViewBusiness.title"
                :datasets="customerViewBusiness.datasets"
                :message="message"
              ></report-stacked-chart-single>
            </div>

            <div class="col-md-12">
              <report-stacked-chart
                :labels="labelsStackedChart"
                :title="customerActionsPriod.title"
                :datasets="customerActionsPriod.datasets"
                :message="message"
              ></report-stacked-chart>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf"
import Card from "../../../UIComponents/Cards/Card.vue"
import ReportBarChart from "./Report/ReportBarChart.vue"
import ReportStackedChart from "./Report/ReportStackedChart.vue"
import ReportStackedChartSingle from "./Report/ReportStackedChartSingle.vue"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import Swal from "sweetalert2"
import formMixin from "@/mixins/formMixin"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/antd.css"
export default {
  components: {
    VueHtml2pdf,
    Card,
    ReportBarChart,
    ReportStackedChart,
    ReportStackedChartSingle,
    PayRequired,
    ConfirmMailPayRequired,
    VueSlider,
  },

  mixins: [formMixin],

  data() {
    return {
      monthsValue: [-6, 0],
      monthsData: [],
      message: null,
      isCsvExporting: false,
      rangeSlider: [0, 12],
      rangeDate: [],
      labelsBarChart: [],
      labelsStackedChart: [],

      customerActions: {
        title: this.$t("screens.report.texts.chartCustomerActions"),
        datasets: [
          {
            label: this.$t("screens.report.texts.monthLastYear"),
            borderColor: "#4cbdd7",
            fill: true,
            backgroundColor: "#4cbdd7",
            hoverBorderColor: "#4cbdd7",
            borderWidth: 8,
            data: [0, 0, 0],
          },
          {
            label: this.$t("screens.report.texts.monthThisYear"),
            borderColor: "#ea4335",
            fill: true,
            backgroundColor: "#ea4335",
            hoverBorderColor: "#ea4335",
            borderWidth: 8,
            data: [0, 0, 0],
          },
        ],
        debounce: null,
      },
      discoverySearch: {
        title: this.$t("screens.report.texts.chartDiscoverySearch"),
        datasets: [
          {
            label: this.$t("screens.report.texts.monthLastYear"),
            borderColor: "#4cbdd7",
            fill: true,
            backgroundColor: "#4cbdd7",
            hoverBorderColor: "#4cbdd7",
            borderWidth: 8,
            data: [0, 0, 0],
          },
          {
            label: this.$t("screens.report.texts.monthThisYear"),
            borderColor: "#ea4335",
            fill: true,
            backgroundColor: "#ea4335",
            hoverBorderColor: "#ea4335",
            borderWidth: 8,
            data: [0, 0, 0],
          },
        ],
      },
      listingOnMaps: {
        title: this.$t("screens.report.texts.chartListingOnMaps"),
        datasets: [
          {
            label: this.$t("screens.report.texts.monthLastYear"),
            borderColor: "#4cbdd7",
            fill: true,
            backgroundColor: "#4cbdd7",
            hoverBorderColor: "#4cbdd7",
            borderWidth: 8,
            data: [0, 0, 0],
          },
          {
            label: this.$t("screens.report.texts.monthThisYear"),
            borderColor: "#ea4335",
            fill: true,
            backgroundColor: "#ea4335",
            hoverBorderColor: "#ea4335",
            borderWidth: 8,
            data: [0, 0, 0],
          },
        ],
      },
      customerSearchBusiness: {
        title: this.$t("screens.report.texts.chartCustomerSearchBusiness"),
        datasets: [
          {
            label: this.$t("screens.report.texts.desktopMaps"),
            borderColor: "#0f9d58",
            fill: true,
            backgroundColor: "#0f9d58",
            hoverBorderColor: "#0f9d58",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.mobileMaps"),
            borderColor: "#ea4335",
            fill: true,
            backgroundColor: "#ea4335",
            hoverBorderColor: "#ea4335",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.desktopSearch"),
            borderColor: "#4285f4",
            fill: true,
            backgroundColor: "#4285f4",
            hoverBorderColor: "#4285f4",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.mobileSearch"),
            borderColor: "#ff9900",
            fill: true,
            backgroundColor: "#ff9900",
            hoverBorderColor: "#ff9900",
            borderWidth: 8,
            data: [],
          },
        ],
      },
      customerViewBusiness: {
        title: this.$t("screens.report.texts.chartCustomerViewBusiness"),
        datasets: [
          {
            label: "",
            borderColor: "#e8710a",
            fill: true,
            backgroundColor: "#e8710a",
            hoverBorderColor: "#e8710a",
            borderWidth: 8,
            data: [],
          },
        ],
      },
      customerActionsPriod: {
        title: this.$t("screens.report.texts.chartCustomerActionsPriod"),
        datasets: [
          {
            label: this.$t("screens.report.texts.listingVisitYourWebsite"),
            borderColor: "#34A853",
            fill: true,
            backgroundColor: "#34A853",
            hoverBorderColor: "#34A853",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingRequestDirections"),
            borderColor: "#AFB42A",
            fill: true,
            backgroundColor: "#AFB42A",
            hoverBorderColor: "#AFB42A",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingCallYou"),
            borderColor: "#4285FA",
            fill: true,
            backgroundColor: "#4285FA",
            hoverBorderColor: "#4285FA",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingConversations"),
            borderColor: "#ea9999",
            fill: true,
            backgroundColor: "#ea9999",
            hoverBorderColor: "#ea9999",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingBookings"),
            borderColor: "#ffe599",
            fill: true,
            backgroundColor: "#ffe599",
            hoverBorderColor: "#ffe599",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingFoodOrders"),
            borderColor: "#f9cb9c",
            fill: true,
            backgroundColor: "#f9cb9c",
            hoverBorderColor: "#f9cb9c",
            borderWidth: 8,
            data: [],
          },
        ],
      },
    }
  },

  watch: {
    monthsValue: {
      handler(value) {
        this.debounceSliderChangeHandler(value)
      },
    },
    userIds(value) {
      if (value) {
        this.getSingleStoreReport()
      }
    },
  },

  async created() {
    this.getLastYear()
    this.getLastThreeMonths()
    this.getNumberNextMonthNamesWithYear(1)
    await this.sliderChangeHandler([-6, 0])
    await this.getSingleStoreReport()
  },
  methods: {
    handleUnlock() {
      this.$router.replace("/register-payment")
    },
    downloadBeforeCheck() {
      if (this.$store.state.auth.user) {
        if (this.$store.state.auth.user.role === 0) {
          Swal.fire({
            title: this.$t("screens.paymentRequired.text.title"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("screens.paymentRequired.text.direct"),
            cancelButtonText: this.$t("screens.paymentRequired.text.cancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              this.handleUnlock()
            }
          })
        }
      }
    },
    getLastThreeMonths() {
      for (let index = 2; index >= 0; index--) {
        const d = new Date()
        d.setMonth(d.getMonth() - index)
        this.labelsBarChart.pop(String(d.getMonth() + "月"))
      }
    },
    getNumberNextMonthNamesWithYear(number) {
      const now = new Date()
      let month = now.getMonth()
      let year = now.getFullYear()

      const names = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ]

      const res = []
      for (let i = 0; i < number; ++i) {
        this.labelsStackedChart.push(year + "/" + names[month])
        if (++month === 12) {
          month = 0
          ++year
        }
      }
      return res
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    getDataCsv() {
      const temp = []
      for (let i = 0; i < this.labelsStackedChart.length; i++) {
        temp[i] = {
          Day: this.labelsStackedChart[i],
          Direct: this.customerSearchBusiness.datasets[0].data[i],
          Discovery: this.customerSearchBusiness.datasets[1].data[i],
          Branded: this.customerSearchBusiness.datasets[2].data[i],
          "Listing on Search": this.customerViewBusiness.datasets[0].data[i],
          "Listing on Maps": this.customerViewBusiness.datasets[1].data[i],
          "Visit your website": this.customerActionsPriod.datasets[0].data[i],
          "Request directions": this.customerActionsPriod.datasets[1].data[i],
          "Call you": this.customerActionsPriod.datasets[2].data[i],
        }
      }
      return temp
    },

    getTitleCsv() {
      return (
        "Report From " +
        this.labelsStackedChart[0] +
        " To " +
        this.labelsStackedChart[this.labelsStackedChart.length - 1]
      )
    },
    debounceSliderChangeHandler(value) {
      this.sliderChangeHandler(value)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        this.$store.commit("setShowLoading", true)
        await this.getReportByAdjustmentPeriod()
        this.$store.commit("setShowLoading", false)
      }, 600)
    },
    sliderChangeHandler(value) {
      // [0,0]

      const curDate = new Date()
      const startDate = new Date()
      const endDate = new Date()

      startDate.setMonth(startDate.getMonth() - value[0] * -1)
      endDate.setMonth(endDate.getMonth() - value[1] * -1)

      const startYear = startDate.getFullYear()
      const startMonth = startDate.getMonth() + 1
      const startDay = "01"
      const startDateFmt =
        startYear +
        "-" +
        (startMonth < 10 ? "0" + startMonth : startMonth) +
        "-" +
        startDay

      const endYear = endDate.getFullYear()
      const endMonth = endDate.getMonth() + 1
      let endDay = this.getDays(endYear, endMonth)
      if (
        (value[0] === 0 && value[1] === 0) ||
        curDate.getMonth() + 1 === endMonth
      ) {
        endDay = curDate.getDate()

        endDay = endDay < 10 ? "0" + endDay : endDay
      }

      const endDateFmt =
        endYear +
        "-" +
        (endMonth < 10 ? "0" + endMonth : endMonth) +
        "-" +
        endDay

      this.rangeDate = [startDateFmt, endDateFmt]
    },
    getDays(year, month) {
      return new Date(year, month, 0).getDate()
    },
    showChart(data) {
      const dataShow = []
      for (const [key, value] of Object.entries(data)) {
        dataShow[key] = []
        dataShow[key].search_graph = []
        dataShow[key].impressions_graph = []
        dataShow[key].reactions_graph = []
        dataShow[key].months = []
        for (const [month, totalVisit] of Object.entries(value)) {
          dataShow[key].months.push(parseInt(month) + "月")
          dataShow[key].search_graph.push(parseInt(totalVisit.search_graph))
          dataShow[key].impressions_graph.push(
            parseInt(totalVisit.impressions_graph)
          )
          dataShow[key].reactions_graph.push(
            parseInt(totalVisit.reactions_graph)
          )
        }
      }

      this.fillDiscoverySearch(dataShow)
      this.fillListingOnMap(dataShow)
      this.fillCustomerActions(dataShow)

      this.labelsBarChart = dataShow.current_year.months
    },
    showChartByAdjustmentPeriod(data) {
      const dataShow = []

      // 月
      dataShow.months = []
      // お客様がどのようにあなたのビジネスを検索しているか
      dataShow.business_impressions_desktop_maps = []
      dataShow.business_impressions_mobile_maps = []
      dataShow.business_impressions_desktop_search = []
      dataShow.business_impressions_mobile_search = []
      // ビジネスプロフィールの表示につながった検索数
      dataShow.search_keywords = []
      // ビジネスプロフィールを閲覧したユーザー数
      dataShow.business_bookings = []
      dataShow.business_conversations = []
      dataShow.business_direction_requests = []
      dataShow.business_food_orders = []
      dataShow.call_clicks = []
      dataShow.website_clicks = []

      for (const [month, value] of Object.entries(data)) {
        dataShow.months.unshift(month)
        dataShow.business_impressions_desktop_maps.unshift(
          parseInt(value.business_impressions_desktop_maps)
        )
        dataShow.business_impressions_mobile_maps.unshift(
          parseInt(value.business_impressions_mobile_maps)
        )
        dataShow.business_impressions_desktop_search.unshift(
          parseInt(value.business_impressions_desktop_search)
        )
        dataShow.business_impressions_mobile_search.unshift(
          parseInt(value.business_impressions_mobile_search)
        )
        dataShow.search_keywords.unshift(parseInt(value.search_keywords))
        dataShow.business_bookings.unshift(parseInt(value.business_bookings))
        dataShow.business_conversations.unshift(
          parseInt(value.business_conversations)
        )
        dataShow.business_direction_requests.unshift(
          parseInt(value.business_direction_requests)
        )
        dataShow.business_food_orders.unshift(
          parseInt(value.business_food_orders)
        )
        dataShow.call_clicks.unshift(parseInt(value.call_clicks))
        dataShow.website_clicks.unshift(parseInt(value.website_clicks))
      }

      this.fillChartDataByAdjustmentPeriod(dataShow, "customer_search")
      this.fillChartDataByAdjustmentPeriod(dataShow, "customer_view")
      this.fillChartDataByAdjustmentPeriod(dataShow, "action")
    },

    fillDiscoverySearch(dataFill) {
      const discoverySearchDataSets = [...this.discoverySearch.datasets]
      discoverySearchDataSets[0].data = dataFill.latest_year.search_graph
      discoverySearchDataSets[1].data = dataFill.current_year.search_graph
      this.discoverySearch.datasets = discoverySearchDataSets
    },

    fillListingOnMap(dataFill) {
      const listingOnMapDataSets = [...this.listingOnMaps.datasets]
      listingOnMapDataSets[0].data = dataFill.latest_year.impressions_graph
      listingOnMapDataSets[1].data = dataFill.current_year.impressions_graph
      this.listingOnMaps.datasets = listingOnMapDataSets
    },

    fillCustomerActions(dataFill) {
      const customerActionsDataSets = [...this.customerActions.datasets]
      customerActionsDataSets[0].data = dataFill.latest_year.reactions_graph
      customerActionsDataSets[1].data = dataFill.current_year.reactions_graph
      this.customerActions.datasets = customerActionsDataSets
    },

    fillChartDataByAdjustmentPeriod(dataFill, obj) {
      if (obj === "customer_search") {
        const customerSearchBusinessDataSets = [
          ...this.customerSearchBusiness.datasets,
        ]
        customerSearchBusinessDataSets[0].data =
          dataFill.business_impressions_desktop_maps
        customerSearchBusinessDataSets[1].data =
          dataFill.business_impressions_mobile_maps
        customerSearchBusinessDataSets[2].data =
          dataFill.business_impressions_desktop_search
        customerSearchBusinessDataSets[3].data =
          dataFill.business_impressions_mobile_search
        this.customerSearchBusiness.datasets = customerSearchBusinessDataSets
      } else if (obj === "customer_view") {
        const customerViewBusinessDataSets = [
          ...this.customerViewBusiness.datasets,
        ]
        customerViewBusinessDataSets[0].data = dataFill.search_keywords
        this.customerViewBusiness.datasets = customerViewBusinessDataSets
      } else if (obj === "action") {
        const customerActionsPriodDataSets = [
          ...this.customerActionsPriod.datasets,
        ]
        customerActionsPriodDataSets[0].data = dataFill.website_clicks
        customerActionsPriodDataSets[1].data =
          dataFill.business_direction_requests
        customerActionsPriodDataSets[2].data = dataFill.call_clicks
        customerActionsPriodDataSets[3].data = dataFill.business_conversations
        customerActionsPriodDataSets[4].data = dataFill.business_bookings
        customerActionsPriodDataSets[5].data = dataFill.business_food_orders
        this.customerActionsPriod.datasets = customerActionsPriodDataSets
      }

      this.labelsStackedChart = dataFill.months
    },

    async getReportByAdjustmentPeriod() {
      try {
        this.message = null
        await this.$store.dispatch("report/getSingleStoreReportByTime", {
          user_id: this.userIds
            ? this.userIds.toString()
            : this.$store.state.auth.user.id.toString(),
          start_date: this.rangeDate[0],
          end_date: this.rangeDate[1],
        })

        this.showChartByAdjustmentPeriod(
          this.$store.state.report.singleStoreReportByTime
        )
      } catch (err) {
        this.message = this.$t("actions.messages.dashboardError")
        this.$store.commit("setShowLoading", false)
      }
    },

    async exportCsv() {
      const self = this

      if (this.isCsvExporting) {
        return
      }

      if (!this.rangeDate[0] || !this.rangeDate[1]) {
        return self.$notify({
          message: this.$t("screens.report.texts.specifyThePeriodsMissing"),
          type: "danger",
        })
      }

      this.isCsvExporting = true

      try {
        const isExportMultipleStore = this.userIds

        let response
        if (isExportMultipleStore.length > 1) {
          response = await this.$store
            .dispatch("report/exportCsvForMultipleStore", {
              user_id: this.userIds,
              start_date: this.rangeDate[0],
              end_date: this.rangeDate[1],
            })
            .catch((error) => {
              return self.$notify({
                message: error.response.data.errors[0][0],
                type: "danger",
              })
            })
        } else {
          response = await this.$store
            .dispatch("report/exportCsvForSingleStore", {
              user_id: this.userIds
                ? this.userIds.toString()
                : this.$store.state.auth.user.id.toString(),
              start_date: this.rangeDate[0],
              end_date: this.rangeDate[1],
            })
            .catch((error) => {
              return self.$notify({
                message: error.response.data.errors[0][0],
                type: "danger",
              })
            })
        }
        this.isCsvExporting = false

        if (response.status_code !== 200) {
          return
        }

        window.location.href = response.data.csv_url
      } catch (e) {
        this.isCsvExporting = false
      }
    },
    async getSingleStoreReport() {
      try {
        this.message = null
        this.$store.commit("setShowLoading", true)
        await this.$store.dispatch("report/getSingleStoreReportByFixedPeriod", {
          user_id: this.userIds
            ? this.userIds.toString()
            : this.$store.state.auth.user.id.toString(),
          fixed_report: true,
        })
        this.showChart(this.$store.state.report.singleStoreReportByFixedPeriod)
        await this.getReportByAdjustmentPeriod()
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.message = this.$t("actions.messages.dashboardError")
        this.$store.commit("setShowLoading", false)
      }
    },
    getLastYear() {
      const theMonths = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ]

      const now = new Date()
      const pastYear = now.getFullYear() - 2
      now.setFullYear(pastYear)

      for (let i = 0; i < 25; i++) {
        const future = new Date(now.getFullYear(), now.getMonth() + i, 1)
        const month = theMonths[future.getMonth()]
        const year = future.getFullYear()
        this.monthsData.push({
          id: -24 + i,
          name: month + "/" + year,
        })
      }
    },
  },
}
</script>
