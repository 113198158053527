var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 ml-auto mr-auto login-page"},[_c('ValidationObserver',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('card',{staticClass:"login-card",attrs:{"type":"signup"}},[_c('h3',{staticClass:"header text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.login.texts.title"))+" ")]),(_vm.messages)?_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.login.forms.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"col-lg-10 col-md-10 ml-auto mr-auto disable-dbl-tap-zoom",attrs:{"name":"email","placeholder":_vm.$t('screens.login.forms.email'),"hasSuccess":passed,"type":"email","addon-left-icon":"nc-icon nc-single-02","error":failed
                ? _vm.$t('screens.login.forms.email') +
                  _vm.$t('screens.validate.required')
                : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.login.forms.password'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{staticClass:"col-lg-10 col-md-10 ml-auto mr-auto disable-dbl-tap-zoom",attrs:{"name":"password","placeholder":_vm.$t('screens.login.forms.password'),"hasSuccess":passed,"type":"password","addon-left-icon":"nc-icon nc-key-25","error":failed
                ? _vm.$t('screens.login.forms.password') +
                  _vm.$t('screens.validate.required')
                : null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('br'),_c('p-button',{staticClass:"col-lg-4 col-md-4 ml-auto mr-auto",attrs:{"slot":"footer","native-type":"submit","type":"warning","round":"","block":""},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("screens.login.buttons.submit"))+" ")]),_c('div',{staticClass:"mt-2 login-forgot-password",attrs:{"slot":"footer"},slot:"footer"},[_c('router-link',{staticClass:"login-forgot-password--text",attrs:{"to":"/forgot-password"}},[_vm._v(" ForgotPassword？ ")])],1),_c('div',{staticClass:"mt-5 login-register col-lg-8 col-md-8 ml-auto mr-auto",attrs:{"slot":"footer"},slot:"footer"},[_c('span',{staticClass:"login-register--text"},[_vm._v(" "+_vm._s(_vm.$t("screens.login.texts.haveAccount"))+" ")]),_c('router-link',{staticClass:"login-register--link",attrs:{"to":"/register-information"}},[_vm._v(" "+_vm._s(_vm.$t("screens.login.texts.register"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }