<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="card" v-else>
      <div class="card-header">
        <h4 class="card-title">
          {{ $t("screens.media.texts.title") }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <media-content></media-content>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaContent from "./Media/MediaContent.vue"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"

export default {
  components: {
    MediaContent,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
}
</script>

<style></style>
