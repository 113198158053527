import $axios from "@/plugins/axios"
export default class EntityApi {
  async getCategories({ data }) {
    return await $axios({
      method: "GET",
      url: "/entity/categories?user_id=" + data.user_id,
    })
  }

  async getBasicInformation({ data }) {
    return await $axios({
      method: "GET",
      url: "/entity/basic-information?user_id=" + data.user_id,
    })
  }

  async putBasicInformation({ data }) {
    return await $axios({
      method: "PUT",
      url: "/entity/basic-information",
      data,
    })
  }

  async putContactWebsitesApps({ data }) {
    return await $axios({
      method: "PUT",
      url: "/entity/contact-websites-apps",
      data,
    })
  }

  async putBusinessDetails({ data }) {
    return await $axios({
      method: "PUT",
      url: "/entity/business-details",
      data,
    })
  }

  async getLocations({ data }) {
    return await $axios({
      method: "GET",
      url: "/entity/locations?user_id=" + data.user_id,
    })
  }

  async postLocations({ data }) {
    return await $axios({
      method: "POST",
      url: "/entity/location",
      data,
    })
  }

  async getPrefectures({ data }) {
    return await $axios({
      method: "GET",
      url: "/entity/prefectures?user_id=" + data.user_id,
    })
  }
}
