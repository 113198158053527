import EventsApi from "@/services/eventsApi"

const api = new EventsApi()

const state = () => ({
  list: [],
  messages: {
    type: "",
    data: null,
  },
  item: {},
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
  },
})

const mutations = {
  setListEvent(state, { data }) {
    state.list = data.data
    state.pagination = data.meta
  },

  setModel(state, data) {
    state.item = data
  },

  deleteModel(state, { key, keyName }) {
    state.list = state.list.filter((x) => x[keyName] !== key)
  },

  setMessages(state, messages) {
    state.messages = messages
  },

  clearMessages(state) {
    state.messages = { type: "", data: null }
  },
}

const actions = {
  async getListEvent({ commit }, userId) {
    await api.getListEvent(userId).then((res) => {
      commit("setListEvent", res)
    })
  },

  async getModel({ commit }, key) {
    return await api.getModel(key).then((res) => {
      commit("setModel", res.data.data)
    })
  },

  async create({ commit }, data) {
    return await api.create(data)
  },

  async update({ commit }, { key, data }) {
    return await api.update({ key, data })
  },

  async delete({ commit }, { key, userId }) {
    return await api.delete(key, userId)
  },
}

export default { namespaced: true, state, mutations, actions }
