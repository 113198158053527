<template>
  <div class="blank-layout">
    <div
      class="wrapper wrapper-full-page full-page-background blank-layout-background"
      style="
        background: url(static/img/background/background-login.jpg) no-repeat
          center center fixed;
      "
    >
      <div class="full-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="blank-layout-content">
          <div class="container blank-layout-container">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>

    <AdminLoading v-if="isLoading"></AdminLoading>
    <AgreeTerm />
  </div>
</template>

<script>
import AgreeTerm from "../Views/Auth/AgreeTerm"
import { mapState } from "vuex"

export default {
  components: {
    AgreeTerm,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoading() {
      return this.$store.state.isShowLoading
    },
  },
}
</script>
