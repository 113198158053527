<template>
  <div class="row">
    <div class="col-md-12">
      <ValidationObserver ref="change-password" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <div class="custom-upload-container">
            <el-upload
              :limit="1"
              class="upload-demo"
              list-type="picture-card"
              drag
              multiple
              :auto-upload="false"
              action="#"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :show-file-list="false"
              :file-list="form.files"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <p>{{ $t("screens.dashboard.texts.dropFile") }}</p>
                <em>
                  {{ $t("screens.dashboard.texts.clickUpload") }}
                </em>
              </div>
            </el-upload>
            <div class="">
              <ul
                v-for="(file, index) in form.files"
                :key="index"
                class="el-upload-list el-upload-list--picture-card"
              >
                <li
                  v-if="
                    file.raw.type === 'image/png' ||
                    file.raw.type === 'image/jpeg'
                  "
                  tabindex="0"
                  class="el-upload-list__item is-ready"
                >
                  <img
                    :src="file.url"
                    alt=""
                    class="el-upload-list__item-thumbnail"
                  />
                  <a class="el-upload-list__item-name">
                    <i class="el-icon-document"> </i>
                  </a>
                  <label class="el-upload-list__item-status-label">
                    <i class="el-icon-upload-success el-icon-check"></i>
                  </label>
                  <i class="el-icon-close"> </i>
                  <i class="el-icon-close-tip"> 按 delete 键可删除 </i>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete">
                      <i class="el-icon-delete" @click="removeFile(index)"></i>
                    </span>
                  </span>
                </li>
                <li
                  v-else-if="file.raw.type === 'video/mp4'"
                  tabindex="0"
                  class="el-upload-list__item is-ready"
                >
                  <video
                    :src="file.url"
                    alt=""
                    class="el-upload-list__item-thumbnail"
                  />
                  <a class="el-upload-list__item-name">
                    <i class="el-icon-document"> </i>
                  </a>
                  <label class="el-upload-list__item-status-label">
                    <i class="el-icon-upload-success el-icon-check"></i>
                  </label>
                  <i class="el-icon-close"> </i>
                  <i class="el-icon-close-tip"> 按 delete 键可删除 </i>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete">
                      <i class="el-icon-delete" @click="removeFile(index)"></i>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 ml-auto mr-auto">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.productName')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input
                  type="text"
                  :placeholder="$t('screens.dashboard.forms.productName')"
                  v-model="form.productName"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 ml-auto mr-auto">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.selectACategory')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input :error="errors[0]">
                  <el-select
                    size="large"
                    :placeholder="$t('screens.dashboard.forms.selectACategory')"
                    v-model="form.category"
                    @change="handleChangeCategory(form.category)"
                  >
                    <el-option
                      v-for="item in categoryOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div
            class="row mt-3"
            v-if="form.category === categoryOptions[0].value"
          >
            <div class="col-md-12 ml-auto mr-auto">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.productCategory')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input
                  type="text"
                  :placeholder="$t('screens.dashboard.forms.productCategory')"
                  v-model="form.productCategory"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 ml-auto mr-auto">
              <div class="form-group">
                <fg-input
                  type="text"
                  :placeholder="$t('screens.dashboard.forms.showPriceRange')"
                  class="d-flex justify-content-between"
                >
                  <label>{{
                    $t("screens.dashboard.forms.showPriceRange")
                  }}</label>
                  <label>
                    <el-switch v-model="form.priceRange"> </el-switch>
                  </label>
                </fg-input>
                <fg-input
                  v-if="form.priceRange === false"
                  type="text"
                  v-model="form.productPrice"
                  :placeholder="$t('screens.dashboard.forms.productPrice')"
                >
                </fg-input>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="form.priceRange === true">
            <div class="col-md-6 ml-auto">
              <fg-input
                type="text"
                :placeholder="$t('screens.dashboard.forms.minimumPrice')"
                v-model="form.minimumPrice"
              >
              </fg-input>
            </div>
            <div class="col-md-6 mr-auto">
              <fg-input
                type="text"
                :placeholder="$t('screens.dashboard.forms.maximumPrice')"
                v-model="form.maximumPrice"
              >
              </fg-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 ml-auto mr-auto">
              <div class="form-group">
                <textarea
                  class="form-control"
                  v-model="form.productDescription"
                  :placeholder="
                    $t('screens.dashboard.forms.productDescription')
                  "
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 ml-auto mr-auto mt-3">
              <fg-input>
                <el-select
                  size="large"
                  :placeholder="$t('screens.dashboard.forms.addAButton')"
                  v-model="form.valueButton"
                  @change="handleChangeButton(form.valueButton)"
                >
                  <el-option
                    v-for="item in buttonOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div
            class="row mt-3"
            v-if="form.valueButton !== 0 && form.valueButton !== null"
          >
            <div class="col-md-12 ml-auto mr-auto">
              <fg-input
                type="text"
                :placeholder="$t('screens.dashboard.forms.linkForYourButton')"
                v-model="form.linkForButton"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 ml-auto mr-auto">
              <p-button native-type="submit" type="info" round>
                {{ $t("screens.dashboard.buttons.post") }}
              </p-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  Upload,
  DatePicker,
  TimeSelect,
  Select,
  Option,
  Switch,
  Button,
} from "element-ui"
import { addProductOptions, categoryProductOptions } from "src/util/constants"

export default {
  components: {
    [Upload.name]: Upload,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Button.name]: Button,
  },
  data() {
    return {
      imageUrl: "",
      isPreview: false,
      form: {
        files: [],
        productName: null,
        category: null,
        productCategory: null,
        priceRange: false,
        productPrice: null,
        minimumPrice: null,
        maximumPrice: null,
        productDescription: null,
        valueButton: null,
        labelButtolabelButton: null,
        linkForButton: null,
      },
      categoryOptions: categoryProductOptions,
      buttonOptions: addProductOptions,
      messages: null,
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.form.files = fileList
    },
    handleRemove(file, fileList) {
      this.form.files = fileList
    },
    removeFile(index) {
      const file = this.form.files.findIndex((item, i) => i === index)
      const fileList = this.form.files.filter((item, i) => i !== index)
      this.handleRemove(file, fileList)
    },
    handleChangeButton(value) {
      this.form.valueButton = value
      const result = this.buttonOptions.find(
        (item) => item.value === this.form.valueButton
      )
      this.form.labelButton = result.label
    },
    handleChangeCategory(value) {
      this.form.category = value
    },
  },
}
</script>

<style scoped>
.custom-upload-container {
  margin-bottom: 55px;
  display: flex;
  flex-direction: column;
}

.upload-demo >>> .el-upload-dragger {
  width: 400px;
  height: 147px;
}
</style>
