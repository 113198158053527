import DocumentApi from "@/services/documentApi"

const api = new DocumentApi()

const state = () => ({
  document: null,
})

const mutations = {
  setDocument(state, data) {
    state.document = data
  },
}

const actions = {
  async getManualDocument({ commit }) {
    await api.getManualDocument().then((response) => {
      commit("setDocument", response.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
