import Swal from "sweetalert2"
import i18n from "../plugins/i18n"

export default async function auth({ next, router, store }) {
  if (store.state.auth.isLoggedIn) {
    return router.push("/")
  } else if (window.localStorage.getItem("token")) {
    await store.dispatch("auth/refresh")
    if (store.state.auth.isLoggedIn) {
      await Swal.fire({
        icon: "warning",
        text: i18n.t("actions.messages.loginError"),
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          store.commit("auth/postLogout")
        }
      })
      return router.push("/")
    }
  }
  return next()
}
