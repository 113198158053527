<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div class="row">
          <div class="col-md-9">
            <h6 class="card-title text-center report-big-title">
              {{ $t("screens.report.texts.specifyThePeriods") }}
            </h6>
            <div class="row custom-slider">
              <div class="col-md-1 text-center">
                {{ $t("screens.dashboard.texts.monthTwoYears") }}
              </div>
              <vue-slider
                class="col-md-8 w-100"
                v-model="monthsValue"
                :data="monthsData"
                :data-value="'id'"
                :data-label="'name'"
                :hide-label="true"
                :process="true"
                :marks="true"
              ></vue-slider>
              <div class="col-md-1 text-center">
                {{ $t("screens.dashboard.texts.monthPresent") }}
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-12 text-right p-3">
            <p-button
              type="info"
              class="col-lg-4 col-md-5"
              size="sm"
              @click="generateReport"
              round
              outline
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
              {{ $t("screens.report.buttons.pdf") }}
            </p-button>
            <span class="m-1"></span>
            <p-button
              type="info"
              class="col-lg-4 col-md-5"
              size="sm"
              round
              outline
              @click="exportCsv"
            >
              <template v-if="isCsvExporting">
                <i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
              </template>
              <template v-else>
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </template>
              {{ $t("screens.report.buttons.csv") }}
            </p-button>
          </div>

          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-content-width="800px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <card>
                <div class="row">
                  <section class="pdf-item">
                    <div style="width: 800px">
                      <report-stacked-chart
                        :labels="labelsStackedChart"
                        :title="customerSearchBusiness.title"
                        :datasets="customerSearchBusiness.datasets"
                      ></report-stacked-chart>
                    </div>
                    <div style="width: 800px; margin-top: 150px">
                      <report-stacked-chart-single
                        :labels="labelsStackedChart"
                        :title="customerViewBusiness.title"
                        :datasets="customerViewBusiness.datasets"
                      ></report-stacked-chart-single>
                    </div>
                  </section>
                  <div class="html2pdf__page-break" />
                  <section class="pdf-item">
                    <div style="width: 800px; margin-top: 150px">
                      <report-stacked-chart
                        :labels="labelsStackedChart"
                        :title="customerActionsPriod.title"
                        :datasets="customerActionsPriod.datasets"
                      ></report-stacked-chart>
                    </div>
                  </section>
                </div>
              </card>
            </section>
          </vue-html2pdf>

          <div class="col-md-12">
            <report-stacked-chart
              :labels="labelsStackedChart"
              :title="customerSearchBusiness.title"
              :datasets="customerSearchBusiness.datasets"
              :message="message"
            ></report-stacked-chart>
          </div>

          <div class="col-md-12">
            <report-stacked-chart-single
              :labels="labelsStackedChart"
              :title="customerViewBusiness.title"
              :datasets="customerViewBusiness.datasets"
              :message="message"
            ></report-stacked-chart-single>
          </div>

          <div class="col-md-12">
            <report-stacked-chart
              :labels="labelsStackedChart"
              :title="customerActionsPriod.title"
              :datasets="customerActionsPriod.datasets"
              :message="message"
            ></report-stacked-chart>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf"
import { Slider } from "element-ui"
import Card from "../../../UIComponents/Cards/Card.vue"
import ReportStackedChart from "./Report/ReportStackedChart.vue"
import ReportStackedChartSingle from "./Report/ReportStackedChartSingle.vue"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/antd.css"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    VueHtml2pdf,
    [Slider.name]: Slider,
    Card,
    ReportStackedChart,
    ReportStackedChartSingle,
    VueSlider,
  },
  data() {
    return {
      monthsValue: [-6, 0],
      message: null,
      monthsData: [],
      isCsvExporting: false,
      rangeSlider: [0, 12],
      rangeDate: [],
      labelsBarChart: [],
      cloneInputs: {},
      storeName: [],
      storeValue: [],
      labelsStackedChart: [],
      // お客様がどのようにあなたのビジネスを検索しているか
      business_impressions_desktop_maps: [],
      business_impressions_mobile_maps: [],
      business_impressions_desktop_search: [],
      business_impressions_mobile_search: [],
      // ビジネスプロフィールの表示につながった検索数
      search_keywords: [],
      // ビジネスプロフィールを閲覧したユーザー数
      business_bookings: [],
      business_conversations: [],
      business_direction_requests: [],
      business_food_orders: [],
      call_clicks: [],
      website_clicks: [],
      customerSearchBusiness: {
        title: this.$t("screens.report.texts.chartCustomerSearchBusiness"),
        datasets: [
          {
            label: this.$t("screens.report.texts.desktopMaps"),
            borderColor: "#0f9d58",
            fill: true,
            backgroundColor: "#0f9d58",
            hoverBorderColor: "#0f9d58",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.mobileMaps"),
            borderColor: "#ea4335",
            fill: true,
            backgroundColor: "#ea4335",
            hoverBorderColor: "#ea4335",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.desktopSearch"),
            borderColor: "#4285f4",
            fill: true,
            backgroundColor: "#4285f4",
            hoverBorderColor: "#4285f4",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.mobileSearch"),
            borderColor: "#ff9900",
            fill: true,
            backgroundColor: "#ff9900",
            hoverBorderColor: "#ff9900",
            borderWidth: 8,
            data: [],
          },
        ],
        debounce: null,
      },
      customerViewBusiness: {
        title: this.$t("screens.report.texts.chartCustomerViewBusiness"),
        datasets: [
          {
            label: "",
            borderColor: "#e8710a",
            fill: true,
            backgroundColor: "#e8710a",
            hoverBorderColor: "#e8710a",
            borderWidth: 8,
            data: [],
          },
        ],
      },
      customerActionsPriod: {
        title: this.$t("screens.report.texts.chartCustomerActionsPriod"),
        datasets: [
          {
            label: this.$t("screens.report.texts.listingVisitYourWebsite"),
            borderColor: "#34A853",
            fill: true,
            backgroundColor: "#34A853",
            hoverBorderColor: "#34A853",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingRequestDirections"),
            borderColor: "#AFB42A",
            fill: true,
            backgroundColor: "#AFB42A",
            hoverBorderColor: "#AFB42A",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingCallYou"),
            borderColor: "#4285FA",
            fill: true,
            backgroundColor: "#4285FA",
            hoverBorderColor: "#4285FA",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingConversations"),
            borderColor: "#ea9999",
            fill: true,
            backgroundColor: "#ea9999",
            hoverBorderColor: "#ea9999",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingBookings"),
            borderColor: "#ffe599",
            fill: true,
            backgroundColor: "#ffe599",
            hoverBorderColor: "#ffe599",
            borderWidth: 8,
            data: [],
          },
          {
            label: this.$t("screens.report.texts.listingFoodOrders"),
            borderColor: "#f9cb9c",
            fill: true,
            backgroundColor: "#f9cb9c",
            hoverBorderColor: "#f9cb9c",
            borderWidth: 8,
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    getListStoreSelected() {
      return this.$store.state.report.listStoreSelected
    },
  },
  async created() {
    this.sliderChangeHandler([-6, 0])
    this.getLastYear()
    await this.getMultipleReport()
  },
  watch: {
    monthsValue(value) {
      this.debounceSliderChangeHandler(value)
    },
    userIds(value) {
      const lengthMintMultiple = 1
      if (value && value.length > lengthMintMultiple) {
        this.getMultipleReport()
      }
    },
  },
  methods: {
    getLastYear() {
      const theMonths = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ]

      const now = new Date()
      const pastYear = now.getFullYear() - 2
      now.setFullYear(pastYear)

      for (let i = 0; i < 25; i++) {
        const future = new Date(now.getFullYear(), now.getMonth() + i, 1)
        const month = theMonths[future.getMonth()]
        const year = future.getFullYear()
        this.monthsData.push({
          id: -24 + i,
          name: month + "/" + year,
        })
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },

    getTitleCsv() {
      return (
        "Report From " +
        this.labelsStackedChart[0] +
        " To " +
        this.labelsStackedChart[this.labelsStackedChart.length - 1]
      )
    },

    async getMultipleReport() {
      try {
        this.message = null
        this.$store.commit("setShowLoading", true)

        const data = {
          user_id: this.userIds,
          start_date: this.rangeDate[0],
          end_date: this.rangeDate[1],
        }
        await this.$store.dispatch("report/getMultipleReport", { data })

        this.cloneInputs = this.$store.state.report.multipleStoreReport
        this.storeName = Object.keys(this.cloneInputs)
        this.storeValue = Object.values(this.cloneInputs)

        this.labelsStackedChart = this.storeName

        // お客様がどのようにあなたのビジネスを検索しているか
        this.business_impressions_desktop_maps = []
        this.business_impressions_mobile_maps = []
        this.business_impressions_desktop_search = []
        this.business_impressions_mobile_search = []
        // ビジネスプロフィールの表示につながった検索数
        this.search_keywords = []
        // ビジネスプロフィールを閲覧したユーザー数
        this.business_bookings = []
        this.business_conversations = []
        this.business_direction_requests = []
        this.business_food_orders = []
        this.call_clicks = []
        this.website_clicks = []

        this.labelsStackedChart.forEach((item, index) => {
          this.business_impressions_desktop_maps.push(
            this.storeValue[index].business_impressions_desktop_maps
          )
          this.business_impressions_mobile_maps.push(
            this.storeValue[index].business_impressions_mobile_maps
          )
          this.business_impressions_desktop_search.push(
            this.storeValue[index].business_impressions_desktop_search
          )
          this.business_impressions_mobile_search.push(
            this.storeValue[index].business_impressions_mobile_search
          )
          this.search_keywords.push(this.storeValue[index].search_keywords)
          this.business_bookings.push(this.storeValue[index].business_bookings)
          this.business_conversations.push(
            this.storeValue[index].business_conversations
          )
          this.business_direction_requests.push(
            this.storeValue[index].business_direction_requests
          )
          this.business_food_orders.push(
            this.storeValue[index].business_food_orders
          )
          this.call_clicks.push(this.storeValue[index].call_clicks)
          this.website_clicks.push(this.storeValue[index].website_clicks)
        })
        // お客様がどのようにあなたのビジネスを検索しているか
        const datasetsSearch = [...this.customerSearchBusiness.datasets]
        datasetsSearch[0].data = this.business_impressions_desktop_maps
        datasetsSearch[1].data = this.business_impressions_mobile_maps
        datasetsSearch[2].data = this.business_impressions_desktop_search
        datasetsSearch[3].data = this.business_impressions_mobile_search
        // ビジネスプロフィールの表示につながった検索数
        const datasetsView = [...this.customerViewBusiness.datasets]
        datasetsView[0].data = this.search_keywords
        // ビジネスプロフィールを閲覧したユーザー数
        const datasetsActions = [...this.customerActionsPriod.datasets]
        datasetsActions[0].data = this.website_clicks
        datasetsActions[1].data = this.business_direction_requests
        datasetsActions[2].data = this.call_clicks
        datasetsActions[3].data = this.business_conversations
        datasetsActions[4].data = this.business_bookings
        datasetsActions[5].data = this.business_food_orders

        this.customerSearchBusiness.datasets = datasetsSearch
        this.customerViewBusiness.datasets = datasetsView
        this.customerActionsPriod.datasets = datasetsActions

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.message = this.$t("actions.messages.dashboardError")
        this.$store.commit("setShowLoading", false)
      }
    },

    debounceSliderChangeHandler(value) {
      this.sliderChangeHandler(value)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        this.$store.commit("setShowLoading", true)
        await this.getMultipleReport(value)
        this.$store.commit("setShowLoading", false)
      }, 600)
    },

    sliderChangeHandler(value) {
      // [0,0]

      const curDate = new Date()
      const startDate = new Date()
      const endDate = new Date()

      startDate.setMonth(startDate.getMonth() - value[0] * -1)
      endDate.setMonth(endDate.getMonth() - value[1] * -1)

      const startYear = startDate.getFullYear()
      const startMonth = startDate.getMonth() + 1
      const startDay = "01"
      const startDateFmt =
        startYear +
        "-" +
        (startMonth < 10 ? "0" + startMonth : startMonth) +
        "-" +
        startDay

      const endYear = endDate.getFullYear()
      const endMonth = endDate.getMonth() + 1
      let endDay = this.getDays(endYear, endMonth)
      if (
        (value[0] === 0 && value[1] === 0) ||
        curDate.getMonth() + 1 === endMonth
      ) {
        endDay = curDate.getDate()

        endDay = endDay < 10 ? "0" + endDay : endDay
      }

      const endDateFmt =
        endYear +
        "-" +
        (endMonth < 10 ? "0" + endMonth : endMonth) +
        "-" +
        endDay

      this.rangeDate = [startDateFmt, endDateFmt]
    },

    getDays(year, month) {
      return new Date(year, month, 0).getDate()
    },

    async exportCsv() {
      const self = this

      if (this.isCsvExporting) {
        return
      }

      if (!this.rangeDate[0] || !this.rangeDate[1]) {
        return self.$notify({
          message: this.$t("screens.report.texts.specifyThePeriodsMissing"),
          type: "danger",
        })
      }

      this.isCsvExporting = true

      try {
        const isExportMultipleStore = this.userIds

        let response
        if (isExportMultipleStore.length > 1) {
          response = await this.$store
            .dispatch("report/exportCsvForMultipleStore", {
              user_id: this.userIds,
              start_date: this.rangeDate[0],
              end_date: this.rangeDate[1],
            })
            .catch((error) => {
              return self.$notify({
                message: error.response.data.errors[0][0],
                type: "danger",
              })
            })
        } else {
          response = await this.$store
            .dispatch("report/exportCsvForSingleStore", {
              user_id: this.$store.state.auth.user.id.toString(),
              start_date: this.rangeDate[0],
              end_date: this.rangeDate[1],
            })
            .catch((error) => {
              return self.$notify({
                message: error.response.data.errors[0][0],
                type: "danger",
              })
            })
        }
        this.isCsvExporting = false

        if (response.status_code !== 200) {
          return
        }

        window.location.href = response.data.csv_url
      } catch (e) {
        this.isCsvExporting = false
      }
    },
  },
}
</script>
