import OffersApi from "@/services/offersApi"

const api = new OffersApi()

const state = () => ({
  list: [],
})

const mutations = {
  setListOffer(state, data) {
    state.list = data
  },
}

const actions = {
  async addOffer({ commit }, data) {
    return await api.addOffer(data)
  },

  async getOffer({ commit }, userId) {
    return await api.getOffer(userId).then((response) => {
      commit("setListOffer", response.data.data)
    })
  },

  async delete({ commit }, { key, userId }) {
    return await api.deleteOffer(key, userId)
  },

  async updateOffer({ commit }, { id, data }) {
    return await api.updateOffer(id, data)
  },
}

export default { namespaced: true, state, mutations, actions }
