<template>
  <card>
    <template slot="header">
      <h6 class="card-title text-center report-big-title">
        {{ title }}
      </h6>
    </template>
    <div v-if="message" style="height: 382px">
      <div class="text-danger center-error-message">
        {{ message }}
      </div>
    </div>
    <bar-chart
      v-else
      style="height: 40vh"
      :labels="labels"
      :height="100"
      :extra-options="activityChart.options"
      :datasets="datasets"
    >
    </bar-chart>
    <div class="legend" style="margin-top: 20px">
      <span v-for="(dataset, index) in datasets" :key="index">
        <i class="fa fa-circle" :style="{ color: dataset.borderColor }"></i>
        <span style="margin-right: 20px">
          {{ dataset.label }}
        </span>
      </span>
    </div>
  </card>
</template>

<script>
import { Card } from "src/components/UIComponents"
import BarChart from "src/components/UIComponents/Charts/BarChart"

const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
}

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    legends: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: "",
    },
  },
  components: {
    Card,
    BarChart,
  },
  data() {
    return {
      activityChart: {
        options: {
          tooltips: tooltipOptions,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  fontColor: "#9F9F9F",
                  fontStyle: "bold",
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 20,
                },
                gridLines: {
                  zeroLineColor: "9f9f9f",
                  display: true,
                  drawBorder: false,
                  color: "#9F9F9F",
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                barPercentage: 0.4,
                gridLines: {
                  zeroLineColor: "white",
                  display: false,
                  drawBorder: false,
                  color: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9F9F9F",
                  fontStyle: "bold",
                },
              },
            ],
          },
        },
      },
    }
  },
}
</script>
