<template>
  <SlideYUpTransition>
    <div v-if="!isClosed" class="fade show text-center" :class="isType">
      <button
        type="button"
        aria-hidden="true"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i class="nc-icon nc-simple-remove" @click="closedMessage"></i>
      </button>
      <div v-if="typeof messages === 'string'">
        <p class="alert-messages">{{ messages }}</p>
      </div>
      <div v-else-if="typeof messages === 'object'">
        <div v-for="(message, index) in messages" :key="index">
          <p class="alert-messages">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<script>
import { SlideYUpTransition } from "vue2-transitions"

export default {
  components: {
    SlideYUpTransition,
  },

  props: {
    type: {
      type: String,
      default: "primary",
    },
    messages: {
      type: [Array, String],
      default: () => [],
    },
  },

  data() {
    return {
      isClosed: false,
    }
  },

  computed: {
    isType() {
      switch (this.type) {
        case "primary":
          return "alert alert-primary"
          break
        case "info":
          return "alert alert-info"
          break
        case "success":
          return "alert alert-success"
          break
        case "warning":
          return "alert alert-warning"
          break
        case "error":
          return "alert alert-danger"
          break
        default:
          return "alert alert-primary"
      }
    },
  },

  methods: {
    closedMessage(e) {
      this.isClosed = true
      this.$emit("closed", e)
    },
  },
}
</script>
