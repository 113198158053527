<template>
  <div class="col-lg-6 col-md-6 ml-auto mr-auto register-information-page">
    <card type="signup" class="register-information-card">
      <register-information-store></register-information-store>
    </card>
  </div>
</template>

<script>
import { Card } from "src/components/UIComponents"
import RegisterInformationStore from "./RegisterInformation/RegisterInformationStore.vue"

export default {
  components: {
    Card,
    RegisterInformationStore,
  },
}
</script>

<style scoped>
.tabs-custom {
  margin-top: 25px;
}

.tabs-custom >>> .tab-space {
  padding: 20px 0 20px;
}

.tabs-custom >>> .header {
  margin-bottom: 0;
}
</style>
