import ReportApi from "@/services/reportApi"
import { reverseObj } from "@/util/commons"

const api = new ReportApi()

const state = () => ({
  listStoreSelected: [],
  multipleStoreReport: {},
  singleStoreReportByFixedPeriod: {},
  singleStoreReportByTime: {},
})

const mutations = {
  setListStoreSelected(state, data) {
    state.listStoreSelected = data
  },

  setMultipleStoreReport(state, data) {
    state.multipleStoreReport = data
  },

  setSingleStoreReportByFixedPeriod(state, data) {
    state.singleStoreReportByFixedPeriod = data
  },

  setSingleStoreReportByTime(state, data) {
    state.singleStoreReportByTime = data
  },
}

const actions = {
  async exportCsvForSingleStore({ commit }, data) {
    return await api.exportCsvForSingleStore(data).then((response) => {
      return response.data
    })
  },
  async exportCsvForMultipleStore({ commit }, data) {
    return await api.exportCsvForMultipleStore(data).then((response) => {
      return response.data
    })
  },

  async getMultipleReport({ commit }, { data }) {
    await api.getMultipleReport({ data }).then((response) => {
      commit("setMultipleStoreReport", response.data.data)
    })
  },

  async getSingleStoreReportByFixedPeriod({ commit }, data) {
    return await api
      .getSingleStoreReportByFixedPeriod(data)
      .then((response) => {
        commit("setSingleStoreReportByFixedPeriod", response.data.data)
      })
  },

  async getSingleStoreReportByTime({ commit }, data) {
    return await api.getSingleStoreReportByTime(data).then((response) => {
      const months = reverseObj(response.data.data)
      commit("setSingleStoreReportByTime", months)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
