import Vue from "vue"
import VueI18n from "vue-i18n"
import { ja } from "@/lang/ja/index"

Vue.use(VueI18n)

// config languages
const messages = {
  ja,
}

// config vue-i18n
export default new VueI18n({
  locale: "ja",
  messages,
  fallbackLocale: "ja",
})
