import $axios from "@/plugins/axios"

export default class ReportApi {
  async exportCsvForSingleStore(data) {
    return await $axios.post("/report/single-store/csv", data)
  }

  async exportCsvForMultipleStore(data) {
    return await $axios.post("/report/multiple-store/csv", data)
  }

  async getMultipleReport({ data }) {
    return await $axios({
      method: "POST",
      url: "/report/multiple-store/adjustment-period",
      data,
    })
  }

  async getSingleStoreReportByFixedPeriod(data) {
    return await $axios.post("/report/single-store/fixed-period", data)
  }

  async getSingleStoreReportByTime(data) {
    return await $axios.post("/report/single-store/adjustment-period", data)
  }
}
