import $axios from "@/plugins/axios"
import { postType } from "@/util/constants"

export default class OffersApi {
  async addOffer(data) {
    return await $axios({
      method: "POST",
      url: "/posts/offers",
      data,
    })
  }

  async updateOffer(id, data) {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    return await $axios({
      method: "POST",
      url: `/posts/offers/${id}`,
      data,
      config,
    })
  }

  async deleteOffer(offerId, userId) {
    return await $axios.delete(`/posts/offers/${offerId}?user_id=` + userId)
  }

  async getOffer(userId) {
    return await $axios({
      method: "GET",
      url: `/posts/${postType.OFFER}?user_id=${userId}`,
    })
  }
}
