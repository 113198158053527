<template>
  <div class="card search-key-word">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-7">
          <fg-input>
            <el-select
              class="select-info"
              v-model="time"
              @change="handleChange"
            >
              <el-option
                v-for="option in timeOptions"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row" v-if="!message">
        <div class="col-lg-6 col-md-12 search-wrapper">
          <h2 class="search-total">
            {{ searchKeyWordTotal }}
          </h2>
          <p>
            <i class="fa fa-search" aria-hidden="true"></i>
            {{ $t("screens.report.texts.searchTotal") }}
          </p>
          <div class="search-information">
            <div>{{ $t("screens.report.texts.searchDescriptions")[0] }}</div>
            <div>{{ $t("screens.report.texts.searchDescriptions")[1] }}</div>
          </div>
        </div>
      </div>
      <div v-if="message" class="text-danger center-error-message">
        {{ message }}
      </div>
      <div v-if="cloneInputs" class="row search-card">
        <div
          class="col-lg-6 col-md-12 search-table"
          v-if="Object.keys(cloneInputs).length > 0"
        >
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr
                  v-for="(item, index) in Object.keys(cloneInputs).slice(0, 5)"
                  :key="index"
                >
                  <td width="25">{{ ++index }}.</td>
                  <td style="word-break: break-all">{{ item }}</td>
                  <td width="50" class="text-right pr-10">
                    {{ formatSearchKeywords(cloneInputs[item]) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 search-table"
          style="border-left: 1px solid #dee2e6"
          v-if="Object.keys(cloneInputs).length > 5"
        >
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr
                  v-for="(item, index) in Object.keys(cloneInputs).slice(5, 10)"
                  :key="index"
                >
                  <td width="25">{{ 6 + index }}.</td>
                  <td style="word-break: break-all">{{ item }}</td>
                  <td width="50" class="text-right pr-10">
                    {{ formatSearchKeywords(cloneInputs[item]) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {
    timeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userId: "",
      cloneInputs: {},
      cloneInputsSort: [],
      cloneInputsBack: {},
      time: 1,
      message: null,
      searchKeyWords: {
        Name1: 101,
        Name2: 102,
        Name3: 103,
        Name4: 104,
        Name5: 105,
        Name6: 106,
        Name7: 107,
        Name8: 108,
        Name9: 109,
        Name10: 110,
      },
    }
  },
  watch: {
    userIds(value) {
      if (value) {
        this.handleChange()
      }
    },
  },
  computed: {
    searchKeywords() {
      return this.$store.state.dashboard.searchKeywords
    },
    searchKeyWordTotal() {
      if (
        String(this.searchKeywords.search_keywords_total).startsWith(
          "threshold_"
        )
      ) {
        const numberStr =
          this.searchKeywords.search_keywords_total.substring(10)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return "< " + new Intl.NumberFormat("en-US").format(number)
        }
      } else if (
        String(this.searchKeywords.search_keywords_total).startsWith("value_")
      ) {
        const numberStr = this.searchKeywords.search_keywords_total.substring(6)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return new Intl.NumberFormat("en-US").format(number)
        }
      }
      return "0"
    },
  },
  created() {
    this.handleChange()
  },
  methods: {
    async handleChange(time) {
      try {
        this.message = null
        const data = {
          user_id: this.userIds[0]
            ? this.userIds[0].toString()
            : this.$store.state.auth.user.id.toString(),
        }
        if (time) {
          this.time = time
        }
        const timeRange = this.time
        await this.$store.dispatch("dashboard/getSearchKeywords", {
          timeRange: timeRange,
          data,
        })
        this.cloneInputs = this.searchKeywords.search_keywords
        if (Object.keys(this.cloneInputs).length === 0) {
          this.message = this.$t("actions.messages.dashboardNotData")
        }
      } catch (error) {
        this.message = this.$t("actions.messages.dashboardError")
      }
    },
    formatSearchKeywords(value) {
      if (String(value).startsWith("threshold_")) {
        const numberStr = value.substring(10)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return "< " + new Intl.NumberFormat("en-US").format(number)
        }
      } else if (String(value).startsWith("value_")) {
        const numberStr = value.substring(6)
        const number = parseInt(numberStr, 10)
        if (!isNaN(number)) {
          return new Intl.NumberFormat("en-US").format(number)
        }
      }
      return "0"
    },
  },
}
</script>

<style lang="scss">
.search-key-word {
  min-height: 550px;

  .card-header {
    padding: 25px 30px 0 !important;
  }

  .select-info.el-select .el-input input {
    padding: 5px 23px;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .select-info.el-select .el-input .el-input__icon {
    display: flex;
    height: 29px !important;
    justify-content: center;
    align-items: center;
  }

  .select-info {
    width: 170px !important;
  }

  .select-info.el-select-dropdown__item.selected {
    color: white !important;
  }

  .el-select-dropdown__item.selected.select-info {
    color: white !important;
  }

  .search-wrapper {
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .search-total {
    margin-bottom: 5px;
  }

  .search-information {
    margin-top: 30px;
  }

  .search-card {
    padding: 0 20px;
  }

  .search-table {
    margin-top: 20px;

    .table-responsive {
      overflow: inherit;
      padding-bottom: 0px;

      .table {
        margin-bottom: 0px;

        td {
          border-top: 1px solid white;
        }
      }
    }

    .p-0 {
      padding: 0px;
    }

    .pr-10 {
      padding-right: 10px;
    }
  }
}
</style>
