import CRUDApi from "../services/CRUDApi"

export default class CRUD {
  constructor(prefix) {
    const api = new CRUDApi(prefix)

    this.state = {
      prefix,
      list: [],
      messages: {
        type: "",
        data: null,
      },
      item: {},
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: 10,
      },
    }

    this.mutations = {
      setModels(state, { data }) {
        state.list = data.data
        const meta = data?.meta

        if (meta) {
          state.pagination = { ...data.meta }
        }
      },

      setModel(state, data) {
        state.item = data
      },

      deleteModel(state, { key, keyName }) {
        state.list = state.list.filter((x) => x[keyName] !== key)
      },

      setMessages(state, messages) {
        state.messages = messages
      },

      clearMessages(state) {
        state.messages = { type: "", data: null }
      },
    }

    this.actions = {
      async getModels({ commit }, params = {}) {
        await api.getList(params).then((res) => {
          commit("setModels", res)
        })
      },

      async getModel({ commit }, key) {
        return await api.getModel(key).then((res) => {
          commit("setModel", res.data.data)
        })
      },

      async create({ commit }, data) {
        return await api.create(data)
      },

      async update({ commit }, { key, data }) {
        return await api.update({ key, data })
      },

      async delete({ commit }, key) {
        return await api.delete(key)
      },
    }
  }
}
