import AgencyApi from "@/services/agencyApi"

const api = new AgencyApi()

const state = () => ({
  listGroup: [],
  listStore: [],
  listStoreByGroup: [],
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 5,
  },
  paginationByGroup: {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 5,
  },
  prefix: {},
})

const mutations = {
  setListGroup(state, data) {
    state.listGroup = data
  },

  setListStore(state, { data }) {
    state.listStore = data.data
    const meta = data?.meta ?? null

    if (meta) {
      state.pagination = { ...data.meta }
    }
  },

  setListStoreByGroup(state, { data }) {
    state.listStoreByGroup = data.data
    const meta = data?.meta ?? null

    if (meta) {
      state.paginationByGroup = { ...data.meta }
    }
  },

  createGroup(state, { data }) {
    state.listGroup.push(data.data)
  },

  updateGroup(state, data) {
    state.listGroup = state.listGroup.map((e) => {
      if (e.id === data.id) e = data
      return e
    })
  },

  setAgencyPrefix(state, data) {
    state.prefix = data
  },
}

const actions = {
  async getListGroup({ commit }, params = {}) {
    await api.getListGroup(params).then((response) => {
      commit("setListGroup", response.data.data)
    })
  },

  async getListStore({ commit }, params = {}) {
    await api.getListStore(params).then((response) => {
      commit("setListStore", response)
    })
  },

  async getListStoreByManager({ commit }, params = {}) {
    await api.getListStoreByManager(params).then((response) => {
      commit("setListStore", response)
    })
  },

  async getListStoreByGroup({ commit }, { groupId, params }) {
    await api.getListStoreByGroup(groupId, params).then((response) => {
      commit("setListStoreByGroup", response)
    })
  },

  async createGroup({ commit }, data) {
    await api.createGroup(data).then((response) => {
      commit("createGroup", response)
    })
  },

  async updateGroup({ commit }, { id, data }) {
    await api.updateGroup(id, data).then((response) => {
      commit("updateGroup", response.data.data)
    })
  },

  async updateLogo({ commit }, { data }) {
    await api.updateLogo({ data })
  },

  async getAgencyPrefix({ commit }, { prefix }) {
    await api.getAgencyPrefix({ prefix }).then((response) => {
      commit("setAgencyPrefix", response.data.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
