export default {
  login: {
    texts: {
      title: "ログイン",
      forgotPassword: "ForgotPassword?",
      haveAccount: "アカウントをお持ちではありませんか？",
      register: "新しいものを作成する",
    },
    forms: {
      email: "Eメール",
      password: "パスワード",
    },
    buttons: {
      submit: "ログイン",
    },
  },
  registerInformation: {
    texts: {
      title: "申し込みフォーム",
      terms:
        "利用規約に記載されている条件を読み、同意する場合は登録をクリックしてください。",
      question: "どのようにして当社のサービスを知りましたか?",
      store: "店舗",
      agency: "代理店",
      confirmInformation: "情報を確認する",
      creditCard: "クレジットカード",
      backLogin: "ログイン画面に戻る",
    },
    forms: {
      fullName: "氏名",
      fullNamePlaceholder: "名字と名前の間にスペースを入れてください",
      companyName: "会社名",
      storeName: "店铺名",
      email: "メールアドレス",
      phone: "電話番号",
      phonePlaceholder: "ハイフンなし",
      default: "お選びください",
      search: "インターネット検索",
      article: "プレスリリース・記事",
      advertisement: "WEB広告",
      acquaintance: "知人から聞いて",
      sale: "弊社営業",
      other: "その他",
    },
    buttons: {
      back: "戻る",
      send: " 送信",
      submit: "申し込み",
    },
  },
  registerAgency: {
    texts: {
      title: "申し込みフォーム",
      terms:
        "利用規約に記載されている条件を読み、同意する場合は登録をクリックしてください。",
    },
    label: {
      fullName: "代理店様ご担当者名",
      companyName: "お客様会社名",
      agencyName: "店舗名",
      email: "メールアドレス",
      phone: "電話番号",
      code: "代理店コード",
    },
    forms: {
      fullName: "名字と名前の間にスペースを入れてください",
      phone: "ハイフンなし",
    },
    buttons: {
      submit: "申し込み",
    },
  },
  registerAccount: {
    texts: {
      title: "アカウント登録",
    },
    forms: {
      email: "Eメール",
      password: "パスワード",
      passwordConfirmation: "パスワードの確認",
    },
    buttons: {
      submit: "登録",
      register: "登録に戻る",
      redirect: "でオートリダイレクト",
    },
  },
  dashboard: {
    texts: {
      settingAccountTitle: "ユーザープロファイルの編集",
      uploadAvatar: "ここにロゴをアップロードしてください。",
      addGroupTitle: "グループ追加",
      changePasswordTitle: "パスワードを変更する",
      connectAccountTitle: "リスティング",
      publisher: "メディア",
      entity: "対象",
      status: "ステータス",
      statusDetail: "ステータス詳細",
      actions: "アクション",
      disconnect: "未同期",
      connected: "同期済",
      sync: "同期済",
      submitted: "処理中",
      error: "同期されていません",
      requesting: "同期申請中",
      connectWith: " ",
      neutral: "全て",
      manualDocumentTitle: "マニュアル文書",
      importGmb: "Googleマイビジネスへのリストアカウントのインポート",
      locationId: "ロケハンID",
      locationName: "地名",
      address: "アドレス",
      postalCode: "郵便番号",
      openStatus: "オープン状態",
      paymentMethodsTitle: "支払い方法を選択する",
      groupSelect: "グループ選択",
      listStore: "店舗一覧",
      storeName: "店舗名",
      groupLeader: "グループ管理",
      postTitle: "投稿",
      postListTitle: "SNS投稿",
      all: "すべて",
      offer: "特典",
      whatNew: "最新情報",
      event: "イベント投稿",
      product: "商品",
      dropFile: "ここに写真或いは動画をドラッグする。",
      clickUpload: "又はパソコンからアップロード。",
      preview: "プレビュー",
      allProduct: "すべての商品",
      manageProducts: "商品を管理する",
      addProduct: "商品を追加",
      edit: "編集",
      postShare: "他のネットワークで投稿を共有する",
      expiresOn: "期限切れ",
      delete: "消す",
      titleDelete:
        "一度削除すると、この投稿を復元することはできません。この投稿に関連するインサイトも削除されます。",
      disable: "無効",
      showCode: "店舗でこのコードを提示する",
      pleaseChoose: "選んでください",
      administrativeArea: "都道府県",
      ward: "区",
      agencyGroupStoreTitle: "グループ店",
      toDashboard: "ダッシュボード画面へ",
      toReport: "レポート画面へ",
      select: "選択",
      today: "本日",
      yesterday: "昨日",
      aWeekAgo: "一週間前",
      titlePublish: "投稿予約",
      titleDelete: "・投稿削除",
      connectAccountLocationTitle: "位置",
      connectAccountLocationName: "地名",
      connectAccountLocationOpenInfo: "ステータス",
      connectAccountLocationPhoneNumber: "電話番号",
      connectAccountLocationAddress: "住所",
      connectAccountLocationUrl: "ウエブサイト",
      premium: "プレミアム",
      standard: "スタンダード",
      googleMyBusiness: [
        "Please click the button below to start linking your Google Business Profile account with this tool's account.",
        "If you do not link, you will not be able to use the functions of the tool.",
      ],
      requestChangeConfirm: "情報変更の確認",
      requestChangeDescription:
        "変更した情報はGoogle検索、Googleマップに反映する前に、Googleで検証されます。",
      monthPresent: "現在",
      monthTwoYears: "2年前",
      facebookTitle: "Facebookページ",
      facebookID: "ID",
      facebookPageName: "ページ名",
      facebookInstagram: "連携済みInstagramアカウント",
      facebookInstagramYes: "あり",
      facebookInstagramNo: "なし",
      googleConnected: "連携済",
      googleDisconnected: "未連携",
      forgotPassword: "パスワードリセット",
      noData: "データなし",
    },
    buttons: {
      connect: "連携",
      disconnect: "同期解除",
      sync: "同期",
      manual: "マニュアル",
      switchAccount: "店舗切替",
      settingAccount: "アカウント設定",
      logout: "ログアウト",
      update: "アップデート",
      changePassword: "パスワード変更",
      changeInformation: "情報変更",
      addGroup: "グループ追加",
      document: "ドキュメント",
      google: "インポートグーグル",
      register: "登録",
      cancel: "キャンセル",
      registerCreditCard: "クレジットカードを登録する",
      paymentSetting: "支払設定",
      listing: "リスティング",
      createGroupStore: "グループ作成",
      updateGroupStore: "グループ編集",
      viewReport: "レポートを見る",
      delete: "消す",
      create: "生み出す",
      post: "投稿",
      addTime: "時間を追加する",
      preview: "プレビュー",
      viewPostOnSearch: "検索でビューポスト",
      publish: "公開",
      add: "追加",
      viewOn: "見るオン",
      noThanks: "いいえ、結構です",
      remove: "取り除く",
      cancelUpload: "キャンセル",
      saveUpload: "保存",
      back: "Back",
      checkout: "支払い",
      addPost: "新規投稿",
      clearPost: "クリア",
    },
    forms: {
      name: "フルネーム",
      namePersonCharge: "担当者名",
      companyName: "会社名",
      storeName: "店舗名",
      email: "メールアドレス",
      phone: "電話番号",
      image: "画像",
      currentPassword: "現在のパスワード",
      newPassword: "新しいパスワード",
      confirmPassword: "パスワードの確認",
      cardNumber: "カード番号",
      cardName: "名義",
      cardMonth: "有効期限（月）",
      cardYear: "有効期限（年）",
      securityCode: "セキュリティーコード",
      groupName: "グループ名",
      selectStore: "店舗選択",
      offerTitle: "オファータイトル",
      offerDetail: " 投稿内容（任意）",
      couponCode: "クーポンコード（任意）",
      redeemOffer: "クーポンを引き換えるリンク（任意）",
      termsAndConditions: "利用規則＆条件（任意）",
      eventTitle: "イベントタイトル",
      eventDetail: "投稿内容（任意）",
      startDate: "開始日",
      startTime: "開始時間",
      endDate: "終了日",
      endTime: "終了時間",
      writeYourPost: "投稿内容",
      optional: "オプション",
      dateTimePublish: "投稿公開予約",
      dateTimeRemove: "投稿削除予約",
      productName: "商品名",
      productCategory: "商品カテゴリ",
      productDescription: "商品説明",
      selectACategory: "カテゴリーを選ぶ",
      selectANewCategory: "新しいカテゴリを選択してください",
      showPriceRange: "価格帯を表示",
      productPriceError: "商品価格",
      productPrice: "商品価格 (JPY)",
      minimumPrice: "最低価格 (JPY)",
      maximumPrice: "最高価格 (JPY)",
      addAButton: "ボタン追加",
      linkForYourButton: "ボタンのリンク",
      phoneNumber: "電話番号",
    },
  },
  overviewAgency: {
    title: "グループ選択",
    groupTitle: "アカウント選択",
    buttons: {
      group: "グループを参照",
      edit: "編集",
      delete: "削除",
      selection: "選択",
    },
    label: {
      companyName: "会社名",
      storeName: "店舗名",
      personInCharge: "担当者",
      emailAddress: "メールアドレス",
      googleStatus: "Google",
      groupName: "グループ名",
      selectGroupLeader: "グループリーダーを選択",
      groupLeader: "グループリーダー",
      search: "キーワード検索",
      noData: "データなし",
    },
  },
  overviewManager: {
    title: "マネジャー",
    customerTitle: "顧客",
    agencyTitle: "代理店",
    buttons: {
      edit: "編集",
      delete: "削除",
      selection: "選択",
    },
    label: {
      customerName: "店舗名",
      agencyCode: "代理店コード",
      companyName: "会社名",
      storeName: "店舗名",
      personInCharge: "担当者",
      mailAddress: "メールアドレス",
      agencyName: "代理店名",
      paymentMethod: "支払方法",
      paymentStatus: "支払状況",
      googleStatus: "Google",
      applicationName: "アプリ名",
      logo: "ロゴ",
      search: "キーワード検索",
    },
  },
  paymentSetting: {
    changePaymentMethod: "支払方法",
    paymentName: "お支払い名",
    registerCreditCard: "カード情報の登録",
    bill: "請求書",
    update: "変更",
    messageSuccess: "支払い方法を変更しました。",
    paymentStandard: "6980円/月",
    paymentPremium: "20000円/月",
    selectPayment: "プランを選択してください。",
    linkForPlan: "マニュアルはこちら",
  },
  overviewShop: {
    selection: "グループ選択",
    blankSelection: "グループドロップダウンリスト",
    title: "店舗一覧",
    label: {
      customerName: "店舗名",
      contract: "契約情報",
      usage_information: "利用情報",
      search: "キーワード検索",
      email: "Eメール",
    },
    buttons: {
      viewReport: "レポートを見る",
    },
  },
  overviewStore: {
    title: "店舗",
    buttons: {
      view: "見る",
      download: "ダウンロード",
    },
    label: {
      name: "サービス",
      contract: "契約情報",
      usage_information: "利用情報",
      search: "キーワード検索",
      email: "Eメール",
    },
  },
  menu: {
    logo: "ロゴ",
    dashboard: "ダッシュボード",
    report: "レポート",
    storeInformationManagement: "店舗情報管理",
    basicInformation: "店舗情報",
    knowledgeTag: "ナレッジタグ",
    image: "写真",
    media: "Google写真投稿",
    postManagement: "投稿管理",
    post: "投稿",
    postList: "SNS投稿",
    product: "商品",
    listingAccount: "リスティング",
  },
  coreInformation: {
    update: "店舗情報",
    coreInformation: "基本情報",
    primaryCategory: "プライマリカテゴリ",
    additionalCategories: "追加のカテゴリ",
    postalCode: "郵便番号",
    prefecture: "県",
    locality: "地方",
    address1: "住所1",
    ward: "区",
    address2: "住所2",
    displayAddress: "表示アドレス",
    save: "保存",
    address3: "住所3",
    mapMarker: "マップマーカー",
    mainPhone: "メイン電話",
    featuredMessage: "注目のメッセージ",
    entityID: "店舗ID",
    description: "説明",
    contactWebsitesAndApps: "連絡先、ウェブサイト、アプリ",
    descriptionContact: "別のURLで表示するのをサポートしないサイトの場合",
    emails: "メール",
    menuUrl: "メニューURL",
    orderUrl: "注文URL",
    reservationUrl: "予約 URL",
    displayUrl: "表示URL",
    websiteUrl: "ウェブサイトのURL",
    localPhone: "市内電話",
    alternatePhone: "代替電話",
    tollFreePhone: "フリーダイヤル",
    mobilePhone: "携帯電話",
    faxNumber: "ファクス番号",
    TTYNumber: "TTY番号",
    iOSAppUrl: "iOSアプリのURL",
    androidAppUrl: "AndroidアプリのURL",
    COVID19InformationUrl: "COVID-19情報URL",
    errorMessageUrl: "URLフィールドは必須です",
    radioFirst: "普通URLのみ利用する。",
    radioLast: "表示URLのみ利用する。",
    ruleMap:
      "このMAPおよびピンの位置情報は、本システムに登録されている位置情報の確認用であるため、Google Bussines Profile等のSNSには反映されません。",
  },
  businessDetailsInformation: {
    businessDetails: "事業内容",
    description: "説明",
    hours: "時間",
    holidayHours: "時間（祝日）",
    temporarilyClosed: "時間（一時休業）",
    reOpenDate: "再開日",
    additionalHoursText: "追加時間テキスト",
    phoneticName: "ふりがな",
    paymentOptions: "支払いオプション",
    priceRange: "価格帯",
    geomodifier: "ジオモディファイア",
    impressum: "印象",
    pickupAndDeliveryServices: "集配サービス",
    neighborhood: "近所",
    selectTime: "時間を選択",
    selectDateAndTime: "日付と時刻を選択します",
    pickADay: "日を選ぶ",
    singleSelect: "シングルセレクト",
    multipleSelect: "複数選択",
    unspecified: "不特定",
    inStorePickup: "店内受け取り",
    curbsidePickup: "カーブサイドピックアップ",
    pickupNotOffered: "ピックアップは提供されていません",
    delivery: "配達",
    sameDayDelivery: "当日配達",
    noContactDelivery: "無連絡配達",
    deliveryNotOffered: "配達は提供されません",
    ruleTwitter: "160文字以上の文章はTwitterに同期できません。",
  },
  dayOfWeek: {
    days: ["月", "火", "水", "木", "金", "土", "日"],
    Mon: "月",
    Tue: "火",
    Wed: "水",
    Thu: "木",
    Fri: "金",
    Sat: "土",
    Sun: "日",
    to: "-",
    open: "開店",
    closed: "定休日",
    _24Hours: "24時間営業",
    specifyHours: "時間を指定する",
    addAnother: "別の追加",
    delete: "削除",
  },
  basicInformation: {
    title: "基本情報",
    texts: {
      dropTheFileHereOr: "ここにファイルをドロップするか、",
      clickToUpload: "をクリックしてアップロードしてください。",
      countrySelectorLabel: "国番号",
      countrySelectorError: "国を選ぶ",
      phoneNumberLabel: "電話番号",
      example: "例：",
    },
    buttons: {
      addImage: "写真を追加",
      updateInformation: "更新",
      select: "選択する",
    },
  },
  knowledgeTag: {
    title: "ナレッジタグ",
    wrapper: "なぜページにナレッジタグを追加する必要があるのですか？",
    descriptionStructured:
      "構造化データにより、検索エンジンはあなたの情報が何を意味するのかを理解し、潜在的な顧客により有益な方法でそれを提示することができます",
    descriptionSync:
      "Yextは、構造化データに準拠し、schema.orgの最新の標準と、Yextの最新の知識を最新の状態に保ちます。",
    descriptionDashboard:
      "Yextはページ上のアナリティクスを追跡し、プラットフォームで顧客の行動などを表示できるようにします",
    recentActivity: "最近の活動",
    lastActivityDate: "最終活動日",
    last: "最後",
    days: "の日々",
    export: "輸出",
    verifySchemaMarkup: "Schema.orgマークアップを確認する",
    descriptionVerify:
      "schema.orgのマークアップが正しいことを確認するには、GoogleのリッチリザルトテストにウェブサイトのURLを入力します。",
    enterYourWebsiteURL: "ウェブサイトのURLを入力してください",
    buttons: {
      AddKnowledgeTagsToWebsite: "Webサイトにナレッジタグを追加する",
      CheckSchemaMarkup: "Schema.orgマークアップを確認してください",
      onGoogle: "Googleで",
    },
  },
  addKnowledgeTag: {
    title: "ナレッジタグを追加する",
    description:
      "Webサイトの情報を最新の状態に保ち、ナレッジタグを使用して検索を最適化します。",
    learnMore: "ナレッジタグの詳細",
    knowledgeTagInformation: "知識タグ情報",
    selectEntity: "エンティティを選択",
    selectEntityDescription: "ナレッジタグを追加するエンティティを選択します。",
    analyticsSettings: "分析設定",
    analyticsDescription: [
      {
        title: "ピクセルを有効にする",
        description:
          "Yext Analyticsでは、ピクセルタグを使用して、ナレッジタグを使用するページのページ訪問数やクリック数などのメトリックをキャプチャし、YextAnalyticsレポートおよびダッシュボードでこれらのメトリックを表示できるようにする必要があります。タグベースのコンバージョントラッキングにCookieを使用するには、ピクセルを有効にする必要があります。",
      },
      {
        title: "ピクセルを有効にする",
        description:
          "タグベースのコンバージョントラッキングでは、ナレッジタグを使用するページのクリックでコンバージョンを達成するWebサイト訪問者を照合するためにCookieを使用する必要があります。",
      },
    ],
    alwaysOn: "常にオン",
    alwaysOff: "常にオフ",
    perUserOptIn: "ユーザーごとのオプトイン",
    copyPasteScriptTag: "スクリプトタグのコピー/貼り付け",
    copyPasteScriptTagDescription: [
      "次の",
      "タグをコピーして、終了タグ",
      "の前に貼り付けます。",
    ],
    addEmbedCodesForRelevantFields: "関連フィールドの埋め込みコードを追加する",
    addEmbedCodesForRelevantFieldsDescription:
      "ナレッジグラフを使用して使用するフィールドの埋め込みコードをコピーして、ページの関連する場所に貼り付けます。",
    addEmbedCodesForRelevantFieldsView:
      "ドキュメントのフィールドの完全なリストを表示する",
    entityField: "エンティティフィールド",
    embedCode: "埋め込みコード",
    confirmInstallation: "インストールを確認する",
    confirmInstallationDescription:
      "URLをスキャンして、インストールが成功したことを確認します。",
    needMoreHelp: "もっと助けが必要ですか？",
    needMoreHelpDescription: [
      "JSFiddleの例",
      "ナレッジタグのドキュメント",
      "サーバー側の実装をお探しですか？ APIをチェックしてください",
    ],
    disablePixels: "ピクセルを無効にする",
    enablePixels: "ピクセルを有効にする",
    modalDescription: [
      "Yext Analyticsでは、トラッキングピクセルを使用して、ナレッジタグを使用するページのページ訪問数やクリック数などのメトリックをキャプチャし、YextAnalyticsレポートおよびダッシュボードでこれらのメトリックを表示できるようにする必要があります。",
      "ナレッジタグを介してトラッキングピクセルの使用を有効にすることにより、トラッキングピクセルの使用がプライバシーポリシー、利用規約、および適用法に準拠していることを確認する責任があることを認め、同意するものとします。ナレッジタグを介したトラッキングピクセルの使用は、Yext製品規約に準拠します。",
    ],
    modalDescriptionCenter: [
      "「常にオフ」を選択すると、これらのトラッキングピクセルは無効になります。つまり、Yextはナレッジタグを使用するページから分析をキャプチャしないため、分析レポートとダッシュボードにこれらの分析は表示されません。ピクセルトラッキングがオフになっている場合、コンバージョントラッキングは使用できないことに注意してください。",
      "「常にオフ」を選択すると、これらのトラッキングピクセルは無効になります。つまり、Yextはナレッジタグから分析をキャプチャしないため、分析レポートとダッシュボードにこれらの分析は表示されません。ピクセルトラッキングがオフになっている場合、コンバージョントラッキングは使用できないことに注意してください。",
      `「ユーザーごとのオプトイン」を選択する場合は、ナレッジタグを同意管理ソリューションに接続して、訪問者が同意した場合にのみYextトラッキングピクセルが使用されるようにする必要があります。

      「ユーザーごとのオプトイン」を選択すると、オプトアウトしたユーザーのページビューやクリックをキャプチャしないため、[ページの概要]画面や[分析]タブにあるものを含む分析レポートとダッシュボードが影響を受ける可能性があることに注意してください。`,
    ],
    customizeMarkup: "schema.orgマークアップのカスタマイズ",
    editorDescription: [
      "以下のエディター内のコンテンツを、あなたのウェブサイトの ",
      " JSON-LDコンテンツに置き換えてください。以下の手順に従ってください。",
      "必要に応じて、以下の'@type'の値を適切なschema.org Typeに置き換えてください。",
      "ページ上のコンテンツに基づいて、そのタイプのプロパティを追加します。",
      "フィールドリファレンスを使って、これらのPropertyに値を割り当てる。",
    ],
    tagName: "名前",
    tagPhone: "電話番号",
    tagPostalCode: "郵便番号",
    tagPrefecture: "都道府県",
    tagCity: "市区町村",
    tagTown: "番地",
    tagWard: "区（く）",
    tagBuilding: "建物名",
  },
  buttons: {
    cancel: "キャンセル",
    finish: "終了",
    confirm: "確認",
    save: "保存",
    reset: "組み直す",
    dashboard: "ダッシュボード",
    delete: "消去",
    accept: "承認",
    reject: "拒否",
  },
  texts: {
    warning: "警告",
    selection: "選択してください",
  },
  media: {
    texts: {
      title: "メディア",
      uploadMedia: "アップロードメディア",
      description:
        "動画や画像を使って、あなたのビジネスのストーリーを伝えましょう。そのユニークさを表現しています。",
    },
    buttons: {
      addMedia: "新規投稿",
    },
  },
  report: {
    texts: {
      chartDiscoverySearch: "検索",
      chartListingOnMaps: "マップ経由閲覧数",
      chartCustomerActions:
        "反応回数（サイトアクセス・ルート検索・通話・メッセージ送信・予約・注文数合計）",
      chartOwnerPhotosViews: "オーナー投稿写真 閲覧数",
      chartCustomerSearchBusiness: "ビジネスプロフィールを閲覧したユーザー数",
      chartCustomerViewBusiness: "ビジネスプロフィールの表示につながった検索数",
      chartPhotoViewsPeriod: "投稿写真閲覧数",
      chartCustomerActionsPriod: "反応回数",
      monthLastYear: "前年同月",
      monthThisYear: "直近",
      desktopMaps: "Googleマップ - パソコン",
      desktopSearch: "Google検索 - パソコン",
      mobileMaps: "Googleマップ - モバイル",
      mobileSearch: "Google検索 - モバイル",
      listingOnSearch: "検索での表示回数",
      listingOnMaps: "マップでの表示回数",
      specifyThePeriods: "期間を指定する:",
      listingVisitYourWebsite: "Webサイトアクセス",
      listingRequestDirections: "ルート検索",
      listingCallYou: "通話",
      listingConversations: "メッセージ総数",
      listingBookings: "予約",
      listingFoodOrders: "注文",
      listingOwnerPhotos: "オーナー投稿写真",
      listingCustomerPhotos: "顧客投稿写真",
      searches: "検索",
      views: "閲覧",
      actions: "反応",
      weekAgo: "直近1週間",
      monthAgo: "直近1ヶ月",
      threeMonthAgo: "直近3ヶ月",
      directionRequest: "ルート照会",
      chartCustomerCall: "お客様の通話数、通話するタイム",
      dayOfWeek: "曜日",
      timeOfDay: "時刻",
      chartPhotoQuantity: "写真枚数",
      searchTotal: "ビジネス プロフィールの表示につながった検索数",
      searchDescriptions: [
        "検索內容",
        "ビジネスプロフィールの表示につながった検索語句",
      ],
    },
    buttons: {
      pdf: "PDF",
      csv: "CSV",
    },
  },
  paymentRequired: {
    text: {
      title: "支払ってください",
      direct: "今すぐ転送",
      cancel: "キャンセル",
      content: [
        "無料期間は終了しました。",
        "継続してご利用される場合は、",
        "支払設定より支払い手続きを進めてください。",
      ],
      confirmEmail: [
        "支払いが確認できていないため機能制限中です。",
        "支払いに関して別途メールをご確認ください。",
      ],
      directLink: "支払いページに移動します",
    },
  },
  postList: {
    label: {
      dateTimePublish: "投稿日時",
      sns: "SNS",
      content: "投稿内容",
      media: "写真/動画",
      dateTimeStart: "投稿開始日時",
      dateTimeEnd: "投稿終了日時",
      action: "コピー",
    },
  },
  postSNS: {
    texts: {
      gbp: "※「Buy」ボタンがある投稿は同時にGoogle Business Profileに投稿できません。",
      mediaGBP: "※動画を投稿することはできません。",
      mediaFacebook: "※写真と動画を同時に投稿すると、投稿は分けられます。",
      mediaInstagram: [
        "※24時間内に、25投稿しか同期できません。",
        "※写真または動画がない投稿は同期できません。",
      ],
      mediaTwitter: "※写真と動画を同時に投稿すると、投稿は分けられます。",
      instagramDelete: "※投稿の削除はできません。",
    },
  },
  swalDeletePhoto: {
    title: "この写真を削除しますか？",
    text: "削除した写真は復元できません。この写真で作成された投稿は、引き続きリストに表示されます。",
    successText: "写真が削除されました。",
    failText: "写真の削除に失敗",
  },
  validate: {
    onlyNumber: "数字のみを入力してください",
    onlyUrl: "urlのみ入力してください。",
    onlyCredit: "16桁以上で入力してください。",
    required: "を入力してください。",
    noResult: "データなし",
    onlyEmail: "メールアドレスが正しくありません。",
  },
  agreeTerm: {
    texts: {
      title: "利用規約",
      checkbox: "利用規約に同意する",
    },
  },
  addNewsOptions: {
    none: "なし",
    book: "予約",
    orderOnline: "オンライン注文",
    learnMore: "詳細",
    signUp: "登録",
    callNow: "今すぐ電話",
  },
}
