import GroupsLeaderApi from "@/services/groupsLeaderApi"

const api = new GroupsLeaderApi()

const state = () => ({
  listGroupsByLeader: [],
  listStoresByLeader: [],
  listStoresByGroup: [],
  paginationByGroup: {},
  paginationByLeaderId: {},
})

const mutations = {
  setListGroupsByLeader(state, data) {
    state.listGroupsByLeader = data
  },

  setListStoresByLeader(state, data) {
    state.listStoresByLeader = data
  },

  setListStoresByGroup(state, data) {
    state.listStoresByGroup = data
  },

  setListStoreByLeaderId(state, data) {
    state.listStoresByLeader = data
  },

  setPaginationByGroup(state, data) {
    state.paginationByGroup = data
  },

  setPaginationByLeader(state, data) {
    state.paginationByLeaderId = data
  },
}

const actions = {
  async getListGroupsByLeader({ commit }, params) {
    await api.getListGroupsByLeader(params).then((response) => {
      commit("setListGroupsByLeader", response.data.data)
    })
  },

  async getListStoresByLeader({ commit }, params) {
    await api.getListStoresByLeader(params).then((response) => {
      commit("setListStoresByLeader", response.data.data)
    })
  },

  async getListStoresByGroup({ commit }, { group, params }) {
    await api.getListStoresByGroup({ group, params }).then((response) => {
      commit("setListStoresByGroup", response.data.data)
      commit("setPaginationByGroup", response.data.meta)
    })
  },

  async getListStoreByLeaderId({ commit }, params) {
    await api.getListStoreByLeaderId(params).then((response) => {
      commit("setListStoreByLeaderId", response.data.data)
      commit("setPaginationByLeader", response.data.meta)
    })
  },
}

export default { namespaced: true, state, mutations, actions }
