<template>
  <div>
    <h6 class="ml-3">
      {{ $t("screens.businessDetailsInformation.businessDetails") }}
    </h6>
    <ValidationObserver ref="core-information" v-slot="{ handleSubmit }">
      <form class="form-horizontal" @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <fieldset>
            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.description") }}
              </label>
              <div class="col-sm-10">
                <textarea
                  type="textarea"
                  class="form-control"
                  v-model="forms.description"
                />
                <p class="mt-2" style="color: #66615b">
                  {{ $t("screens.businessDetailsInformation.ruleTwitter") }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.hours") }}
              </label>
              <div style="padding: 0 15px">
                <p-checkbox v-model="isHours">
                  {{ $t("screens.dayOfWeek.specifyHours") }}
                </p-checkbox>
              </div>
              <div class="custom-specify-time" v-if="isHours">
                <div
                  class="row custom-specify-day"
                  v-for="(item, index) in forms.hours"
                  :key="index"
                >
                  <div
                    class="d-flex align-items-center justify-content-start col-sm-2 text-muted custom-label-day"
                  >
                    {{ item.label }}
                  </div>
                  <el-select
                    v-model="item.type"
                    :placeholder="
                      $t('screens.businessDetailsInformation.singleSelect')
                    "
                    size="large"
                    class="col-sm-2 mb-2 w-100 custom-options"
                  >
                    <el-option
                      v-for="option in timeOptions"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>

                  <div v-if="item.type === 0">
                    <el-time-select
                      :name="item.label"
                      v-model="item.value[0]"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      class="col-sm-4 custom-time-select"
                      :disabled="
                        item.type === timesType.ALL_DAYS ||
                        item.type === timesType.CLOSED
                      "
                    />
                    <span class="text-muted">
                      {{ $t("screens.dayOfWeek.to") }}
                    </span>
                    <el-time-select
                      :name="item.label"
                      v-model="item.value[1]"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      class="col-sm-4 custom-time-select"
                      :disabled="
                        item.type === timesType.ALL_DAYS ||
                        item.type === timesType.CLOSED
                      "
                    />
                    <label @click="addHours(item)">
                      <i class="nc-icon nc-simple-add"></i>
                      {{ $t("screens.dayOfWeek.addAnother") }}
                    </label>
                    <label @click="removeHours(index)" class="pointer ml-3">
                      <i class="nc-icon nc-simple-remove"></i>
                      {{ $t("screens.dayOfWeek.delete") }}
                    </label>
                  </div>

                  <span
                    class="text-primary d-flex align-items-center mb-1 ml-3"
                    v-if="item.type === timesType.ALL_DAYS"
                  >
                    Open 24 Hours
                  </span>

                  <span
                    class="text-danger d-flex align-items-center mb-1 ml-3"
                    v-if="item.type === timesType.CLOSED"
                  >
                    Closed
                  </span>
                </div>
              </div>
            </div>

            <!-- Product Backlog Item 33650: [20211114] Hide 1 số item ở màn hình Basic information
            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.hours") }}
              </label>
              <p-checkbox style="padding: 0 15px" v-model="isTempClose">{{
                $t("screens.businessDetailsInformation.temporarilyClosed")
              }}</p-checkbox>
              <div class="col-sm-10" v-if="isTempClose">
                <label>{{
                  $t("screens.businessDetailsInformation.reOpenDate")
                }}</label>

                <el-date-picker
                  :name="$t('screens.businessDetailsInformation.reOpenDate')"
                  v-model="forms.temporarilyClosed"
                  type="date"
                  :label="$t('screens.businessDetailsInformation.reOpenDate')"
                  :placeholder="
                    $t('screens.businessDetailsInformation.reOpenDate')
                  "
                  class="custom-date-select"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{
                  $t("screens.businessDetailsInformation.additionalHoursText")
                }}
              </label>
              <div class="col-sm-10">
                <fg-input
                  type="text"
                  v-model="forms.additionalHoursText"
                ></fg-input>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.paymentOptions") }}
              </label>
              <div class="col-sm-10">
                <fg-input>
                  <el-select
                    multiple
                    v-model="forms.paymentOptions"
                    :placeholder="
                      $t('screens.businessDetailsInformation.multipleSelect')
                    "
                    size="large"
                  >
                    <el-option
                      v-for="option in payOptions"
                      :value="option"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.priceRange") }}
              </label>
              <div class="col-sm-10">
                <fg-input>
                  <el-select
                    v-model="forms.priceRange"
                    :placeholder="
                      $t('screens.businessDetailsInformation.priceRange')
                    "
                    size="large"
                  >
                    <el-option
                      v-for="option in priceRangeOptions"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.impressum") }}
              </label>
              <div class="col-sm-10">
                <textarea
                  type="textarea"
                  class="form-control"
                  v-model="forms.impressum"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{
                  $t(
                    "screens.businessDetailsInformation.pickupAndDeliveryServices"
                  )
                }}
              </label>
              <div class="col-sm-10">
                <el-select
                  v-model="forms.pickupAndDeliveryServices"
                  clearable
                  filterable
                  :no-match-text="$t('screens.overviewAgency.label.noData')"
                  :placeholder="
                    $t('screens.businessDetailsInformation.priceRange')
                  "
                  size="large"
                >
                  <el-option
                    class="select-primary"
                    v-for="option in deliveryOptions"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.businessDetailsInformation.neighborhood") }}
              </label>
              <div class="col-sm-10">
                <fg-input type="text" v-model="forms.neighborhood"></fg-input>
              </div>
            </div> -->
          </fieldset>
        </div>

        <div class="text-left col-sm-10">
          <p-button native-type="submit" type="info" round>
            {{ $t("screens.basicInformation.buttons.updateInformation") }}
          </p-button>
        </div>
      </form>
    </ValidationObserver>

    <AdminAlert
      v-if="messages"
      :type="messages.type"
      :messages="messages.data"
    ></AdminAlert>
  </div>
</template>

<script>
import { convertMessage } from "src/util/commons"
import { messageType, timesType } from "src/util/constants"
// import { MultiListSelect } from "vue-search-select"
// import "vue-search-select/dist/VueSearchSelect.css"
import { Select, Option, DatePicker, TimeSelect, Checkbox } from "element-ui"
import moment from "moment"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    // MultiListSelect,

    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      forms: {
        description: "",
        hours: [],
        temporarilyClosed: "",
        additionalHoursText: "",
        paymentOptions: "",
        priceRange: "",
        impressum: "",
        pickupAndDeliveryServices: "",
        neighborhood: "",
      },
      timeOptions: [
        { value: 0, label: this.$t("screens.dayOfWeek.open") },
        { value: 1, label: this.$t("screens.dayOfWeek._24Hours") },
        { value: 2, label: this.$t("screens.dayOfWeek.closed") },
      ],
      payOptions: [
        { value: "Alipay", label: "Alipay" },
        { value: "American Express", label: "American Express" },
        { value: "Google Pay", label: "Google Pay" },
        { value: "Apple Pay", label: "Apple Pay" },
        { value: "Bank Pay", label: "Bank Pay" },
        { value: "Bitcoin", label: "Bitcoin" },
        { value: "Cash", label: "Cash" },
        { value: "Check", label: "Check" },
        { value: "Diners Club", label: "Diners Club" },
        { value: "Discover", label: "Discover" },
        { value: "Financing", label: "Financing" },
        { value: "Hayakaken", label: "Hayakaken" },
        { value: "IC Cards", label: "IC Cards" },
        { value: "Icoca", label: "Icoca" },
        { value: "iD", label: "iD" },
        { value: "Invoice", label: "Invoice" },
        { value: "JCB", label: "JCB" },
        { value: "J-Coin Pay", label: "J-Coin Pay" },
        { value: "JKO Pay", label: "JKO Pay" },
        { value: "Kitaca", label: "Kitaca" },
        { value: "LINE Pay", label: "LINE Pay" },
        { value: "Manaca", label: "Manaca" },
        { value: "MasterCard", label: "MasterCard" },
        { value: "MPay", label: "MPay" },
        { value: "メルPay", label: "メルPay" },
        { value: "nanaco", label: "nanaco" },
        { value: "Nimoca", label: "Nimoca" },
        { value: "Pasmo", label: "Pasmo" },
        { value: "PayPal", label: "PayPal" },
        { value: "PayPay", label: "PayPay" },
        { value: "QR Code Payment", label: "QR Code Payment" },
        { value: "QUICPay", label: "QUICPay" },
        { value: "Rakuten Edy", label: "Rakuten Edy" },
        { value: "楽天Pay", label: "楽天Pay" },
        { value: "Sugoca", label: "Sugoca" },
        { value: "Suica", label: "Suica" },
        { value: "Toica", label: "Toica" },
        { value: "Traveler's Check", label: "Traveler's Check" },
        { value: "TS CUBIC", label: "TS CUBIC" },
        { value: "China UnionPay", label: "China UnionPay" },
        { value: "Visa", label: "Visa" },
        { value: "WAON", label: "WAON" },
        { value: "WeChat Pay", label: "WeChat Pay" },
        { value: "ゆうちょPay", label: "ゆうちょPay" },
        { value: "auPay", label: "auPay" },
        { value: "d払い", label: "d払い" },
      ],
      priceRangeOptions: [
        {
          value: "Unspecified",
          label: this.$t("screens.businessDetailsInformation.unspecified"),
        },
        { value: "$", label: "$" },
        { value: "$$", label: "$$" },
        { value: "$$$", label: "$$$" },
        { value: "$$$$", label: "$$$$" },
      ],
      deliveryOptions: [
        {
          value: "In-Store Pickup",
          label: this.$t("screens.businessDetailsInformation.inStorePickup"),
        },
        {
          value: "Curbside Pickup",
          label: this.$t("screens.businessDetailsInformation.curbsidePickup"),
        },
        {
          value: "Pickup Not Offered",
          label: this.$t("screens.businessDetailsInformation.pickupNotOffered"),
        },
        {
          value: "Delivery",
          label: this.$t("screens.businessDetailsInformation.delivery"),
        },
        {
          value: "Same Day Delivery",
          label: this.$t("screens.businessDetailsInformation.sameDayDelivery"),
        },
        {
          value: "No-Contact Delivery",
          label: this.$t(
            "screens.businessDetailsInformation.noContactDelivery"
          ),
        },
        {
          value: "Delivery Not Offered",
          label: this.$t(
            "screens.businessDetailsInformation.deliveryNotOffered"
          ),
        },
      ],
      daysOfWeeks: [
        {
          label: "SUNDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "MONDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "TUESDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "WEDNESDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "THURSDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "FRIDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
        {
          label: "SATURDAY",
          value: ["", ""],
          type: timesType.CLOSED,
        },
      ],
      deliverySelected: [],
      isHours: false,
      isTempClose: false,
      messages: null,
      timesType: timesType,
    }
  },

  watch: {
    "$store.state.entity.businessDetails": {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.forms.description = value.description
            this.isHours = !!value.hours.length > 0
            this.forms.hours = value.hours
              ? value.hours.map(function (hour) {
                  return {
                    label: hour.openDay,
                    value: [
                      Array.isArray(hour.openTime)
                        ? "00:00"
                        : moment(hour.openTime).format("HH:mm"),
                      Array.isArray(hour.closeTime)
                        ? "00:00"
                        : moment(hour.closeTime).format("HH:mm"),
                    ],
                    type:
                      Array.isArray(hour.openTime) &&
                      hour.closeTime.hours === 24
                        ? this.timesType.ALL_DAYS
                        : this.timesType.OPEN,
                  }
                }, this)
              : []
            this.handleClosedDay()
            this.isTempClose = this.isTempClose = value.temporarily_closed_hours && value.temporarily_closed_hours.dateOff ? true : false;
            this.forms.temporarilyClosed = value.temporarily_closed_hours && value.temporarily_closed_hours.dateOff ? new Date(
              value.temporarily_closed_hours.dateOff
            ) : ''
            this.forms.additionalHoursText = value.additional_hours_text
            this.forms.paymentOptions = value.payment_options
              ? value.payment_options.map(function (payment) {
                  return {
                    label: payment.text,
                    value: payment.value,
                  }
                })
              : []

            this.forms.priceRange = value.price_range
            this.forms.impressum = value.impressum
            this.forms.pickupAndDeliveryServices =
              value.pickup_and_delivery_services
                ? value.pickup_and_delivery_services[0].value
                : ""
            this.deliverySelected = this.forms.pickupAndDeliveryServices
            this.forms.neighborhood = value.neighborhood
          })
        }
      },
      immediate: true,
    },
  },

  computed: {
    getPickupAndDeliveryServices() {
      return this.deliveryOptions.filter(
        (delivery) => delivery.value === this.forms.pickupAndDeliveryServices
      )
    },
  },

  methods: {
    removeArray(list, index) {
      list.splice(index, 1)
    },

    addAnotherDateTimeToTime(list) {
      list.push({ date: "", time: ["", ""], type: 0 })
    },

    onSelect(items) {
      this.deliverySelected = items
      this.forms.pickupAndDeliveryServices = items
    },

    handleClosedDay() {
      for (let index = this.daysOfWeeks.length - 1; index >= 0; index--) {
        const found = this.forms.hours.filter(
          (hour) => hour.label === this.daysOfWeeks[index].label
        )
        if (found.length === 0) {
          this.forms.hours.push(this.daysOfWeeks[index])
        }
      }
    },

    addHours(item) {
      const index = this.forms.hours.findIndex(
        (hour) => hour.label === item.label
      )
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index),
      ]
      this.forms.hours = insert(this.forms.hours, index + 1, {
        label: item.label,
        value: ["", ""],
        type: timesType.OPEN,
      })
    },

    removeHours(item) {
      this.forms.hours = this.forms.hours.filter(
        (hour, index) => index !== item
      )
    },

    removeClosedDay() {
      this.forms.hours.filter((hour) => hour.type !== 2)
    },

    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const hours = this.forms.hours.filter(
          (hour) => hour.type !== timesType.CLOSED
        )
        let data = {
          user_id: this.userIds[0],
          description: this.forms.description,
          hours: this.isHours
            ? "[" +
              hours.map(function (hour) {
                return JSON.stringify({
                  openDay: hour.label,
                  closeDay: hour.label,
                  openTime:
                    hour.type === timesType.ALL_DAYS
                      ? []
                      : {
                          hours: hour.value[0].split(":")[0],
                          minutes: hour.value[0].split(":")[1],
                        },
                  closeTime:
                    hour.type === timesType.ALL_DAYS
                      ? { hours: 24 }
                      : {
                          hours: hour.value[1].split(":")[0],
                          minutes: hour.value[1].split(":")[1],
                        },
                })
              }, this) +
              "]"
            : "[" + "]",
          // Product Backlog Item 33650: [20211114] Hide 1 số item ở màn hình Basic information
          // temporarily_closed_hours: this.isTempClose
          //   ? JSON.stringify({
          //       dateOff: new Date(
          //         this.forms.temporarilyClosed
          //       ).toLocaleDateString("en-us"),
          //     })
          //   : JSON.stringify({
          //       dateOff: null,
          //     }),
          // additional_hours_text: this.forms.additionalHoursText,
          // payment_options:
          //   this.forms.paymentOptions.length !== 0
          //     ? "[" +
          //       this.forms.paymentOptions.map(function (payment) {
          //         return JSON.stringify({
          //           text: payment.label,
          //           value: payment.value,
          //         })
          //       }) +
          //       "]"
          //     : "[" + "]",
          // price_range: this.forms.priceRange ? this.forms.priceRange : null,
          // impressum: this.forms.impressum,
          // pickup_and_delivery_services:
          //   this.getPickupAndDeliveryServices.length !== 0
          //     ? "[" +
          //       this.getPickupAndDeliveryServices.map(function (pickup) {
          //         return JSON.stringify({
          //           text: pickup.label,
          //           value: pickup.value,
          //         })
          //       }) +
          //       "]"
          //     : "[" + "]",
          // neighborhood: this.forms.neighborhood,
        }
        await this.$store.dispatch("entity/putBusinessDetails", {
          data,
        })
        data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("entity/getBasicInformation", {
          data,
        })
        this.messages = {
          data: this.$t("actions.messages.infoUpdateSuccess"),
          type: messageType.SUCCESS,
        }
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>

<style scoped>
.error {
  color: #ef8157;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}

.custom-specify-time {
  margin-left: 14px;
}

.custom-time-select >>> .el-input__prefix {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 10px;
}

.custom-time-select >>> .el-input__suffix-inner {
  margin-right: 8px;
}

.custom-time-select >>> .el-input__inner {
  padding-left: 30px;
}

.custom-date-select >>> .el-input__inner {
  padding-left: 30px;
}

@media screen and (max-width: 1024px) {
  .custom-specify-time {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .custom-label-day {
    margin-top: 15px;
  }

  .custom-specify-day {
    padding: 15px;
  }
}
</style>
