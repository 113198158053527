<template>
  <div class="col-lg-12 col-md-12 register-information-page">
    <card type="signup" class="register-information-card">
      <ValidationObserver ref="confirm-register" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <h3 slot="header" class="header text-center">
            {{ $t("screens.registerInformation.texts.confirmInformation") }}
          </h3>

          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <div class="form-group col-md-10 mr-auto ml-auto mb-1">
            <label>
              {{ $t("screens.registerAgency.label.fullName") }}
            </label>
            <fg-input
              v-model="form.full_name"
              type="text"
              addon-left-icon="nc-icon nc-single-02"
              disabled
            ></fg-input>
          </div>

          <div
            v-if="registerType === 1"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerAgency.label.companyName") }}
            </label>
            <fg-input
              v-model="form.client_company_name"
              type="text"
              addon-left-icon="nc-icon nc-briefcase-24"
              disabled
            ></fg-input>
          </div>

          <div
            v-if="registerType === 0"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerInformation.forms.companyName") }}
            </label>
            <fg-input
              v-model="form.company_name"
              type="text"
              addon-left-icon="nc-icon nc-briefcase-24"
              disabled
            ></fg-input>
          </div>

          <div
            v-if="registerType === 1"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerAgency.label.agencyName") }}
            </label>
            <fg-input
              v-model="form.agency_name"
              type="text"
              addon-left-icon="nc-icon nc-shop"
              disabled
            ></fg-input>
          </div>

          <div
            v-if="registerType === 0"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerInformation.forms.storeName") }}
            </label>
            <fg-input
              v-model="form.store_name"
              type="text"
              addon-left-icon="nc-icon nc-shop"
              disabled
            ></fg-input>
          </div>

          <div class="form-group col-md-10 mr-auto ml-auto mb-1">
            <label>
              {{ $t("screens.registerAgency.label.email") }}
            </label>
            <fg-input
              v-model="form.email"
              type="email"
              addon-left-icon="nc-icon nc-email-85"
              disabled
            ></fg-input>
          </div>

          <div class="form-group col-md-10 mr-auto ml-auto mb-1">
            <label>
              {{ $t("screens.registerAgency.label.phone") }}
            </label>
            <fg-input
              v-model="form.phone"
              type="text"
              addon-left-icon="nc-icon nc-tablet-2"
              disabled
            ></fg-input>
          </div>

          <div
            v-if="registerType === 1"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerAgency.label.code") }}
            </label>
            <fg-input v-model="form.agent_code" type="text" disabled></fg-input>
          </div>

          <div
            v-if="registerType === 0"
            class="form-group col-md-10 mr-auto ml-auto mb-1"
          >
            <label>
              {{ $t("screens.registerInformation.texts.question") }}
            </label>
            <el-select
              class="register-select"
              v-model="form.knowledge"
              disabled
            >
              <el-option
                v-for="option in knowledgeOptions"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="row justify-content-center">
            <p-button
              slot="footer"
              type="primary"
              outline
              round
              class="col-lg-3"
              @click="handelBack"
            >
              {{ $t("screens.registerInformation.buttons.back") }}
            </p-button>
            <p-button
              slot="footer"
              type="primary"
              outline
              round
              class="col-lg-3"
              @click="handelCancel"
            >
              {{ $t("screens.dashboard.buttons.cancel") }}
            </p-button>
            <p-button
              native-type="submit"
              slot="footer"
              type="primary"
              round
              class="col-lg-3"
            >
              {{ $t("screens.registerInformation.buttons.send") }}
            </p-button>
          </div>
        </form>
      </ValidationObserver>
    </card>
  </div>
</template>

<script>
import { Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import { Select, Option } from "element-ui"
import Swal from "sweetalert2"

export default {
  mixins: [formMixin],
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        full_name: "",
        client_company_name: "",
        company_name: "",
        agency_name: "",
        store_name: "",
        email: "",
        phone: "",
        agent_code: "",
        knowledge: "",
      },
      knowledgeOptions: [
        {
          label: this.$t("screens.registerInformation.forms.search"),
          value: "search",
        },
        {
          label: this.$t("screens.registerInformation.forms.article"),
          value: "article",
        },
        {
          label: this.$t("screens.registerInformation.forms.advertisement"),
          value: "advertisement",
        },
        {
          label: this.$t("screens.registerInformation.forms.acquaintance"),
          value: "acquaintance",
        },
        {
          label: this.$t("screens.registerInformation.forms.sale"),
          value: "sale",
        },
        {
          label: this.$t("screens.registerInformation.forms.other"),
          value: "other",
        },
      ],
      messages: null,
    }
  },
  props: {
    formConfirm: {
      type: Object,
      default: () => {},
    },
    registerType: {
      type: String,
      default: () => "",
    },
  },
  created() {
    this.form = this.formConfirm
  },
  methods: {
    handelBack() {
      this.$emit("closeModal")
    },
    handelCancel() {
      this.$router.go(0)
    },
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          ...this.form,
          register_type: this.registerType,
        }
        await this.$store.dispatch("auth/registrationForm", data)
        this.$store.commit("setShowLoading", false)
        this.resetForm("form", "register-information")
        this.handelBack()
        Swal.fire({
          icon: messageType.SUCCESS,
          text: this.$t("actions.messages.registerInformationSuccess"),
          showConfirmButton: true,
          confirmButtonText: this.$t(
            "screens.registerInformation.texts.backLogin"
          ),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/login"
          }
        })
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
