<template>
  <div class="not-found-page">
    <info-section
      class="mt-5"
      type="danger"
      title="Marketing"
      description="We've created the marketing campaign of the website. It was a very interesting collaboration."
      icon="nc-icon nc-alert-circle-i"
    >
      <h2 class="text-white mt-3">Page Not Found</h2>
      <small class="text-white"
        >Oops! It seems that this page does not exist.
        <br />
        You can navigate back
        <router-link to="/">to main page</router-link>
      </small>
    </info-section>
  </div>
</template>
<script>
import { InfoSection } from "src/components/UIComponents"

export default {
  components: {
    InfoSection,
  },
}
</script>
