var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.connectAccountTitle"))+" ")])]),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-sm-12 mt-2"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"header-row-class-name":"text-primary","data":_vm.list,"span-method":_vm.arraySpanMethod}},[_c('el-table-column',{attrs:{"min-width":"300","label":_vm.$t('screens.dashboard.texts.publisher')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{attrs:{"src":scope.row.logo_url,"width":"60","height":"60","alt":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(scope.row.publisher_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"300","label":_vm.$t('screens.dashboard.texts.entity')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.$index !== 2)?_c('div',[(scope.row.location_name)?_c('a',{staticClass:"text-info"},[_vm._v(" "+_vm._s(scope.row.location_name)+" ")]):_vm._e(),(scope.row.postal_code)?_c('p',{staticClass:"connect-account-list-text"},[_vm._v(" "+_vm._s(scope.row.postal_code)+" ")]):_vm._e(),(scope.row.address_lines)?_c('p',{staticClass:"connect-account-list-text"},[_vm._v(" "+_vm._s(scope.row.address_lines)+" ")]):_vm._e(),(scope.row.store_code)?_c('p',{staticClass:"connect-account-list-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t("screens.coreInformation.entityID"))+": "+_vm._s(scope.row.store_code)+" ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":_vm.$t('screens.dashboard.texts.status')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status === _vm.connectionStatusType.DISCONNECT)?_c('div',{staticClass:"text-danger"},[_c('i',{class:!_vm.FREETRIAL_ALLOW_SNS.includes(scope.row.publisher_id)
                      ? _vm.classTypeIcon
                      : 'fa fa-times text-danger',attrs:{"aria-hidden":"true"}}),(_vm.getUser() !== _vm.FREETRIAL_AVAILABILITY)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.disconnect"))+" ")]):(_vm.getUser() === _vm.FREETRIAL_AVAILABILITY)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.FREETRIAL_ALLOW_SNS.includes(scope.row.publisher_id) ? _vm.$t("screens.dashboard.texts.disconnect") : _vm.$t("screens.dashboard.texts.disable"))+" ")]):_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.disable"))+" ")])]):(
                  scope.row.status === _vm.connectionStatusType.CONNECTED
                )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times text-danger",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.disconnect"))+" ")])]):(scope.row.status === _vm.connectionStatusType.SYNC)?_c('div',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check text-success",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.sync"))+" ")])]):(
                  scope.row.status === _vm.connectionStatusType.SUBMITTED
                )?_c('div',{staticClass:"text-warning"},[_c('i',{staticClass:"fa fa-refresh text-warning",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.submitted"))+" ")])]):(scope.row.status === _vm.connectionStatusType.ERROR)?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa fa-times text-danger",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.error"))+" ")])]):(
                  scope.row.status === _vm.connectionStatusType.REQUESTING
                )?_c('div',{staticClass:"text-primary"},[_c('i',{staticClass:"fa fa fa-refresh text-primary",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.requesting"))+" ")])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"120","class-name":"action-buttons td-actions","label":_vm.$t('screens.dashboard.texts.actions')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.row.status === _vm.connectionStatusType.DISCONNECT ||
                  scope.row.status === _vm.connectionStatusType.ERROR
                )?_c('div',[_c('p-button',{attrs:{"type":"success","disabled":_vm.handleTrial(scope.row.publisher_id),"title":_vm.$t('screens.dashboard.buttons.sync'),"size":"sm"},on:{"click":function($event){return _vm.handleConnect(scope.row.publisher_key)}}},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.buttons.sync"))+" ")])],1):(
                  scope.row.status === _vm.connectionStatusType.SUBMITTED ||
                  scope.row.status === _vm.connectionStatusType.SYNC ||
                  scope.row.status === _vm.connectionStatusType.REQUESTING
                )?_c('p-button',{attrs:{"type":"success","disabled":_vm.handleTrial(scope.row.publisher_id),"title":_vm.$t('screens.dashboard.buttons.disconnect'),"size":"sm"},on:{"click":function($event){return _vm.handleDisconnect(
                    scope.row.publisher_id,
                    scope.row.entity_id
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.buttons.disconnect"))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"300","align":"left","label":_vm.$t('screens.dashboard.texts.statusDetail')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status === _vm.connectionStatusType.ERROR)?_c('div',_vm._l((scope.row.log),function(l,i){return _c('div',{key:i},[_c('p',[_vm._v(_vm._s(l))])])}),0):_vm._e(),_c('span')]}}])})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }