<template>
  <el-dialog
    class="custom-modal"
    width="60%"
    :visible.sync="isVerifyMarkup"
    headerClasses="justify-content-center"
  >
    <div class="verify-markup">
      <img
        src="https://www.yext.com/s/schema/public/images/icon-structured-data.svg"
        alt=""
      />
      <div class="content">
        <div class="heading">
          <div class="text">
            <h5>
              {{ $t("screens.knowledgeTag.verifySchemaMarkup") }}
            </h5>
            <p>
              {{ $t("screens.knowledgeTag.descriptionVerify") }}
            </p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <form>
            <span>{{ $t("screens.knowledgeTag.enterYourWebsiteURL") }}</span>
            <div class="schema-markup">
              <div class="url ml-2 p-0">
                <fg-input type="text" ref="url" v-model="url" />
              </div>
              <div class="btn-check">
                <button type="info" @click="openLink">
                  {{ $t("screens.knowledgeTag.buttons.CheckSchemaMarkup") }}
                  {{ $t("screens.knowledgeTag.buttons.onGoogle") }}
                </button>
              </div>
            </div>
          </form>
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Button } from "element-ui"
export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  props: {
    formModal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isVerifyMarkup: false,
      form: this.formModal,
      linkCheck: "https://search.google.com/test/rich-results?url=",
      url: null,
    }
  },
  methods: {
    handleCloseModal() {
      this.isVerifyMarkup = false
    },
    openLink() {
      window.open(`${this.linkCheck + this.url}`, "_blank")
    },
  },
}
</script>

<style></style>
