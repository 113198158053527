<template>
  <div>
    <div class="custom-upload-container">
      <ul
        v-for="(file, index) in medias"
        :key="index"
        class="el-upload-list el-upload-list--picture-card custom-upload-item"
        :style="{ order: index + 1 }"
      >
        <li
          v-if="
            file.raw.type.includes(mediaType.IMAGE) ||
            checkForHEICExtension(file.name)
          "
          tabindex="0"
          class="el-upload-list__item is-ready"
        >
          <img :src="file.url" alt="" class="el-upload-list__item-thumbnail" />
          <el-button
            type="info"
            class="custom-upload-remove"
            icon="el-icon-close"
            @click="handleRemove(index)"
            circle
          ></el-button>
          <el-button
            type="info"
            class="custom-upload-full-screen"
            icon="el-icon-crop"
            @click="handleOpen(file)"
            circle
          ></el-button>
        </li>
        <li
          v-else-if="file.raw.type.includes(mediaType.VIDEO)"
          tabindex="0"
          class="el-upload-list__item is-ready"
        >
          <video
            :src="file.url"
            alt=""
            class="el-upload-list__item-thumbnail"
          />
          <el-button
            type="info"
            class="custom-upload-remove"
            icon="el-icon-close"
            @click="handleRemove(index)"
            circle
          ></el-button>
          <el-button
            type="info"
            class="custom-upload-full-screen"
            icon="el-icon-crop"
            @click="handleOpen(file)"
            circle
          ></el-button>
        </li>
        <li v-else tabindex="0" class="el-upload-list__item is-ready">
          <div class="el-upload-list__item-thumbnail"></div>
          <el-button
            type="info"
            class="custom-upload-remove"
            icon="el-icon-close"
            @click="handleRemove(index)"
            circle
          ></el-button>
          <el-button
            type="info"
            class="custom-upload-full-screen"
            icon="el-icon-crop"
            @click="handleOpen(file)"
            circle
          ></el-button>
        </li>
      </ul>
      <div
        v-show="!isMax"
        class="custom-upload-item"
        :style="{
          order: medias.length + 1,
          flex: styleUpload,
        }"
      >
        <el-upload
          :limit="max"
          class="upload-demo"
          list-type="picture"
          drag
          multiple
          :auto-upload="false"
          action="#"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :show-file-list="false"
          :file-list="medias"
          :accept="rule"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <p>{{ $t("screens.dashboard.texts.dropFile") }}</p>
            <em>
              {{ $t("screens.dashboard.texts.clickUpload") }}
            </em>
          </div>
        </el-upload>
      </div>
      <el-dialog
        title=""
        :visible.sync="isOpen"
        :before-close="handleClose"
        fullscreen
      >
        <img
          v-if="imageType.includes(media.raw.type)"
          :src="media.url"
          alt=""
          class="modal-media"
        />
        <video
          v-else-if="videoType.includes(media.raw.type)"
          :src="media.url"
          controls
          class="modal-media"
        >
          <source :src="media.url" :type="media.raw.type" />
        </video>
      </el-dialog>
    </div>
    <div class="row">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <div class="pull-right">{{ medias.length }} / {{ max }}</div>
      </div>
    </div>
    <div>
      <label v-if="name === shareType.GBP" class="control-label">
        {{ $t("screens.postSNS.texts.mediaGBP") }}
      </label>
      <label v-if="name === shareType.FACEBOOK" class="control-label">
        {{ $t("screens.postSNS.texts.mediaFacebook") }}
      </label>
      <label v-if="name === shareType.INSTAGRAM" class="control-label">
        {{ $t("screens.postSNS.texts.mediaInstagram")[0] }} <br />
        {{ $t("screens.postSNS.texts.mediaInstagram")[1] }}
      </label>
      <label v-if="name === shareType.TWITTER" class="control-label">
        {{ $t("screens.postSNS.texts.mediaTwitter") }}
      </label>
    </div>
    <AdminErrors :errors="errors"></AdminErrors>
  </div>
</template>

<script>
import Vue from "vue"
import {
  imageType,
  videoType,
  shareType,
  messageType,
  snsExtensionType,
  fileLimitType,
  imageExtensionType,
  videoExtensionType,
  extensionType,
  mediaType,
} from "src/util/constants"
import {
  checkForHEICExtension,
  convertHEICToJPEG,
  checkForTIFFExtension,
  convertTIFF,
  getImageResolution,
  getVideoResolution,
  getExtFromName,
  getResolutionByUrl,
  getTIFFResolution,
} from "src/util/commons"
import { mapState, mapActions } from "vuex"
import { Upload, Button, Dialog } from "element-ui"
Vue.use(Button)
Vue.use(Dialog)

export default {
  components: {
    [Upload.name]: Upload,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: "",
    },
    rule: {
      type: String,
      default: "image/*, video/*",
    },
  },

  data() {
    return {
      medias: this.value,
      media: {
        raw: {},
      },
      imageType,
      videoType,
      shareType,
      messageType,
      snsExtensionType,
      imageExtensionType,
      videoExtensionType,
      extensionType,
      mediaType,
      isOpen: false,
      validate: [],
    }
  },

  computed: {
    ...mapState("post", ["files", "active", "errors"]),
    styleUpload() {
      return this.medias.length > 0 ? "0 1 49%" : "1 1 99%"
    },
    isMax() {
      return this.medias.length === this.max
    },
  },

  watch: {
    value(newValue) {
      this.validateMedia(newValue)
      this.medias = newValue
    },
  },

  methods: {
    ...mapActions("post", ["setFiles", "setErrors"]),
    async handleChange(file, fileList) {
      this.$store.commit("setShowLoading", true)
      for (const file of fileList) {
        // Handle file HEIC
        if (checkForHEICExtension(file.name) === "heic") {
          const urlHEIC = await convertHEICToJPEG(file.url)
          file.name = file.name.replace(/\.heic$/i, ".jpeg")
          file.url = urlHEIC
          const response = await fetch(file.url)
          const blobData = await response.blob()
          file.raw = new File([blobData], file.name, { type: "image/jpeg" })
          file.size = file.raw.size
        } else if (checkForTIFFExtension(file.name) === "tiff") {
          if (!file.url?.includes("s3.amazonaws.com")) {
            const urlTIFF = await convertTIFF(file.raw)
            file.url = urlTIFF
          }
        }
      }
      this.medias = fileList
      this.setFiles(this.medias)
      this.$emit("input", fileList)
      this.$store.commit("setShowLoading", false)
    },
    async handleRemove(index) {
      this.$store.commit("setShowLoading", true)
      this.medias = this.files
      let fileList = this.medias.filter((item, i) => i !== index)
      fileList = this.active === this.shareType.GBP ? [] : fileList
      this.medias = fileList
      this.setFiles(this.medias)
      this.$emit("input", fileList)
      this.$store.commit("setShowLoading", false)
    },
    handleOpen(file) {
      this.media = file
      this.isOpen = true
    },
    handleClose() {
      this.isOpen = false
    },
    async validateImage(file) {
      let f
      if (file.url?.includes("s3.amazonaws.com")) {
        f = await getResolutionByUrl(file.url)
      } else {
        if (file.raw.type === "image/tiff") {
          f = await getTIFFResolution(file.raw)
        } else {
          f = await getImageResolution(file.url)
        }
      }
      switch (this.active) {
        case this.shareType.GBP:
          if (file.raw.size < 10000)
            this.validate.push(
              this.validateSizeError(fileLimitType.MIN, 10, "KB")
            )
          else if (file.raw.size > 5e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 5, "MB")
            )
          else if (f.width < 250 || f.height < 250)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 250, 250)
            )
          break
        case this.shareType.FACEBOOK:
          if (file.raw.size > 15e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 15, "MB")
            )
          else if (file.raw.type === "image/png" && file.raw.size > 1e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 1, "MB")
            )
          else if (f.width < 479 && f.height < 246)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 479, 246)
            )
          else if (f.width < 320 && f.height < 320)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 320, 320)
            )
          break
        case this.shareType.INSTAGRAM:
          if (file.raw.size > 8e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 8, "MB")
            )
          else if (f.width < 600 || f.height < 500)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 600, 500)
            )
          else if (f.width > 1936 || f.height > 1936)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MAX, 1936, 1936)
            )
          break
        case this.shareType.TWITTER:
          if (file.raw.size > 5e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 5, "MB")
            )
          else if (
            (f.width < 1280 && f.height < 720) ||
            (f.width < 1200 && f.height < 900) ||
            (f.width < 1200 && f.height < 800) ||
            (f.width < 1200 && f.height < 600)
          )
            this.validate.push(
              `${this.$t(
                "actions.messages.fileResolutionSmallerError[0]"
              )} (1280px x 720px, 1200px x 900px, 1200px x 800px, 1200px x 600px)`
            )
          else if (
            (f.width > 1280 && f.height > 720) ||
            (f.width > 1200 && f.height > 900) ||
            (f.width > 1200 && f.height > 800) ||
            (f.width > 1200 && f.height > 600)
          )
            this.validate.push(
              `${this.$t(
                "actions.messages.fileResolutionBiggerError[0]"
              )} (1280px x 720px, 1200px x 900px, 1200px x 800px, 1200px x 600px)`
            )
          break
        default:
          break
      }
    },
    async validateVideo(file) {
      let f
      if (file.url?.includes("s3.amazonaws.com")) {
        f = await getResolutionByUrl(file.url)
      } else {
        f = await getVideoResolution(file.url)
      }
      switch (this.active) {
        case this.shareType.FACEBOOK:
          if (file.raw.size > 4e10)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 40, "GB")
            )
          else if (f.resolution.width > 1920 || f.resolution.height > 1080)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MAX, 1920, 1080)
            )
          else if (f.duration > 14400)
            this.validate.push(
              this.validateDurationError(fileLimitType.MAX, 14400)
            )
          break
        case this.shareType.INSTAGRAM:
          if (file.raw.size > 250e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 250, "MB")
            )
          else if (f.resolution.width < 1280 || f.resolution.height < 720)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 1280, 720)
            )
          else if (f.duration > 60)
            this.validate.push(
              this.validateDurationError(fileLimitType.MAX, 60)
            )
          break
        case this.shareType.TWITTER:
          if (file.raw.size > 512e6)
            this.validate.push(
              this.validateSizeError(fileLimitType.MAX, 512, "MB")
            )
          else if (f.resolution.width < 32 && f.resolution.height < 32)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MIN, 32, 32)
            )
          else if (f.resolution.width > 1920 && f.resolution.height > 1200)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MAX, 1920, 1200)
            )
          else if (f.resolution.width > 1200 && f.resolution.height > 1920)
            this.validate.push(
              this.validateResolutionError(fileLimitType.MAX, 1200, 1920)
            )
          else if (f.duration < 0.5)
            this.validate.push(
              this.validateDurationError(fileLimitType.MIN, 0.5)
            )
          else if (f.duration > 140)
            this.validate.push(
              this.validateDurationError(fileLimitType.MAX, 140)
            )
          break
        default:
          break
      }
    },
    async validateMedia(fileList) {
      this.$store.commit("setShowLoading", true)
      try {
        this.validate = []
        this.setErrors(this.validate)
        const validationPromises = fileList.map(async (element) => {
          let snsType
          let snsExtensionType

          switch (this.active) {
            case this.shareType.GBP:
              snsType = this.snsExtensionType.GBP
              snsExtensionType = extensionType.GBP
              break
            case this.shareType.FACEBOOK:
              snsType = this.snsExtensionType.FACEBOOK
              snsExtensionType = extensionType.FACEBOOK
              break
            case this.shareType.INSTAGRAM:
              snsType = this.snsExtensionType.INSTAGRAM
              snsExtensionType = extensionType.INSTAGRAM
              break
            case this.shareType.TWITTER:
              snsType = this.snsExtensionType.TWITTER
              snsExtensionType = extensionType.TWITTER
              break
            default:
              break
          }

          if (
            snsExtensionType.includes(
              getExtFromName(element.raw.name).toLowerCase()
            )
          ) {
            if (
              element.raw.type.includes(mediaType.IMAGE) ||
              checkForHEICExtension(element.name)
            ) {
              await this.validateImage(element)
            } else if (element.raw.type.includes(mediaType.VIDEO)) {
              await this.validateVideo(element)
            }
          } else {
            this.validate.push(
              `${this.$t("actions.messages.fileFormatError")} (${snsType})`
            )
          }
        })
        // Wait for all validation promises to complete
        await Promise.all(validationPromises)
        this.validate = [...new Set(this.validate.map(JSON.stringify))]
          .map(JSON.parse)
          .flat()
          .filter((item) => (Array.isArray(item) ? item.length > 0 : true))
        this.setErrors(this.validate)
      } catch (error) {
        console.error(error)
      }
      this.$store.commit("setShowLoading", false)
    },
    validateResolutionError(type, width, height) {
      switch (type) {
        case fileLimitType.MIN:
          return `${this.$t(
            "actions.messages.fileResolutionSmallerError[0]"
          )} (${width} x ${height} px${this.$t(
            "actions.messages.fileResolutionSmallerError[1]"
          )})`
        case fileLimitType.MAX:
          return `${this.$t(
            "actions.messages.fileResolutionBiggerError[0]"
          )} (${width} x ${height} px${this.$t(
            "actions.messages.fileResolutionBiggerError[1]"
          )})`
        case fileLimitType.NOT:
          return `${this.$t(
            "actions.messages.fileResolutionError"
          )} (${width} x ${height} px)`
        default:
          break
      }
    },
    validateDurationError(type, duration) {
      switch (type) {
        case fileLimitType.MIN:
          return `${this.$t(
            "actions.messages.fileDurationSmallerError[0]"
          )}${duration}${this.$t(
            "actions.messages.fileDurationSmallerError[1]"
          )}`
        case fileLimitType.MAX:
          return `${this.$t(
            "actions.messages.fileDurationSmallerError[0]"
          )}${duration}${this.$t(
            "actions.messages.fileDurationBiggerError[1]"
          )}`
        default:
          break
      }
    },
    validateSizeError(type, size, unit) {
      switch (type) {
        case fileLimitType.MIN:
          return `${this.$t(
            "actions.messages.fileSizeSmallerError[0]"
          )} (${size}${unit}${this.$t(
            "actions.messages.fileSizeSmallerError[1]"
          )})`
        case fileLimitType.MAX:
          return `${this.$t(
            "actions.messages.fileSizeBiggerError[0]"
          )} (${size}${unit}${this.$t(
            "actions.messages.fileSizeBiggerError[1]"
          )})`
        default:
          break
      }
    },
    getExtFromName(filename) {
      return getExtFromName(filename)
    },
    checkForHEICExtension(filename) {
      return checkForHEICExtension(filename)
    },
  },
}
</script>

<style lang="scss" scoped></style>
