<template>
  <div class="row">
    <div class="col-md-12 admin-information-container" :class="isType">
      <p class="admin-information-header">
        {{ header }}
      </p>

      <div class="admin-information-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    header: {
      type: String,
      default: "",
    },
  },
  computed: {
    isType() {
      switch (this.type) {
        case "primary":
          return "alert-primary"
          break
        case "info":
          return "alert-info"
          break
        case "success":
          return "alert alert-success"
          break
        case "warning":
          return "alert-warning"
          break
        case "error":
          return "alert-danger"
          break
        default:
          return "alert-primary"
      }
    },
  },
}
</script>
