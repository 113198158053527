import $axios from "@/plugins/axios"
export default class AuthApi {
  async login(data) {
    return await $axios.post("/login", data)
  }

  async refresh() {
    return await $axios.get("/refresh-token")
  }

  async logout() {
    return await $axios.get("/logout")
  }

  async forgotPassword(data) {
    return await $axios.post("/forgot-password", data)
  }

  async resetPassword(data) {
    return await $axios.post("/reset-password", data)
  }

  async registrationForm(data) {
    return await $axios.post("/registration-form", data)
  }

  async registerAccount(data) {
    return await $axios.post("/register", data)
  }

  async registerVerifyAccount(data) {
    return await $axios.post("/register/verify-token", data)
  }

  async changePassword(data) {
    return await $axios.put("/account-setting/password", data)
  }

  async getUserInfo() {
    return await $axios.get("/account-setting/information")
  }

  async changeInformation(data) {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    }
    return await $axios.post("/account-setting/information", data, config)
  }

  async confirmTerm(data) {
    return await $axios.post("/confirm-term", data)
  }
}
