var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 col-md-6 ml-auto mr-auto register-account-page"},[(!_vm.isValid)?_c('card',[_c('h3',{staticClass:"header text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.registerAccount.texts.title"))+" ")]),_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}),_c('p-button',{staticClass:"col-lg-6 col-md-6 ml-auto mr-auto",attrs:{"slot":"footer","type":"info","round":"","block":""},on:{"click":_vm.backToRegister},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("screens.registerAccount.buttons.register"))+" ("+_vm._s(_vm.$t("screens.registerAccount.buttons.redirect"))+" "+_vm._s(_vm.countDown)+") ")])],1):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('card',{staticClass:"register-account-card",attrs:{"type":"signup"}},[_c('h3',{staticClass:"header text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.registerAccount.texts.title"))+" ")]),(_vm.messages)?_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"email"}},[_c('fg-input',{staticClass:"col-lg-10 col-md-10 ml-auto mr-auto",attrs:{"name":"email","placeholder":_vm.$t('screens.registerAccount.forms.email'),"type":"email","addon-left-icon":"nc-icon nc-single-02","disabled":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8","vid":"newPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"col-lg-10 col-md-10 ml-auto mr-auto",attrs:{"name":"password","placeholder":_vm.$t('screens.registerAccount.forms.password'),"type":"password","hasSuccess":passed,"addon-left-icon":"nc-icon nc-key-25","error":failed
                ? _vm.$t('screens.registerAccount.forms.password') +
                  _vm.$t('screens.validate.required')
                : null},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|confirmed:newPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{staticClass:"col-lg-10 col-md-10 ml-auto mr-auto",attrs:{"name":"password-confirmation","placeholder":_vm.$t('screens.registerAccount.forms.passwordConfirmation'),"type":"password","addon-left-icon":"nc-icon nc-key-25","hasSuccess":passed,"error":failed
                ? _vm.$t('screens.registerAccount.forms.password') +
                  _vm.$t('screens.validate.required')
                : null},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c('br'),_c('p-button',{staticClass:"col-lg-4 col-md-4 ml-auto mr-auto",attrs:{"slot":"footer","native-type":"submit","type":"primary","round":"","block":""},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("screens.registerAccount.buttons.submit"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }