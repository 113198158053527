<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Google Business Profile</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 card-gmb-description text-muted">
          <p>
            {{ $t("screens.dashboard.texts.googleMyBusiness[0]") }}
          </p>
          <p>
            {{ $t("screens.dashboard.texts.googleMyBusiness[1]") }}
          </p>
        </div>
        <div class="col-12">
          <p-button @click="connect" type="warning" class="card-gmb-connect">
            {{ $t("screens.dashboard.buttons.connect") }}
          </p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import Swal from "sweetalert2"
import routerMixin from "@/mixins/routerMixin"

export default {
  mixins: [routerMixin],

  computed: {
    getUrl() {
      return this.$store.state.publisher.url
    },
  },

  methods: {
    async connect() {
      try {
        await this.checkAfterLogin()
        await this.$store.dispatch("auth/refresh")
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          publisher_key: "google_my_business",
          user_id: this.$store.state.auth.user.id,
        }
        await this.$store.dispatch("publisher/connect", { data })
        window.open(this.getUrl, "_self")
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response?.data?.errors) {
          Swal.fire({
            icon: messageType.ERROR,
            text: convertMessage(err.response.data.errors).join(", "),
            showConfirmButton: false,
            timer: 2000,
          })
        } else {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.errors.500"),
            showConfirmButton: false,
            timer: 2000,
          })
        }
      }
    },
  },
}
</script>
