<template>
  <div class="media-content-wrapper">
    <AdminAlert
      v-if="messages"
      :type="messages.type"
      :messages="messages.data"
    ></AdminAlert>
    <div class="media-content-container">
      <div class="card media-content-card ml-3">
        <div class="card-header media-content-header">
          <img
            class="media-content-image card-title"
            src="@/assets/images/media/video.png"
            alt=""
          />
        </div>
        <div class="card-body media-content-body">
          <p>
            {{ $t("screens.media.texts.title") }}
          </p>
          <small>
            {{ $t("screens.media.texts.description") }}
          </small>
          <p-button
            @click="openModalMedia"
            class="media-content-add"
            type="primary"
            round
          >
            {{ $t("screens.media.buttons.addMedia") }}
          </p-button>
        </div>
      </div>
    </div>
    <div class="media-content-album">
      <div
        v-for="(file, index) in listMedia"
        :key="file.id"
        @click="openCurrentMedia(index)"
        class="media-content-box"
      >
        <img
          v-if="
            imageExtensionType.includes(
              getExtension(file.photo_url).toLowerCase()
            )
              ? file.photo_url
              : ''
          "
          class="media-content-photos"
          :src="file.photo_url"
          alt=""
        />
        <div
          v-else-if="
            videoExtensionType.includes(
              getExtension(file.photo_url).toLowerCase()
            )
              ? {
                  mp4: file.photo_url,
                  props: {
                    autoplay: false,
                    controls: true,
                  },
                }
              : ''
          "
        >
          <video
            :src="`${file.photo_url}#t=0.5`"
            alt=""
            class="media-content-photos"
          />

          <div class="media-content-box-play">
            <i class="fa fa-play-circle-o" aria-hidden="true"></i>
          </div>
        </div>

        <!-- Google User Content -->
        <div v-else>
          <img
            v-if="file.gmb_media_type === mediaGmbType.PHOTO"
            :src="file.photo_url"
            class="media-content-photos"
            referrerpolicy="no-referrer"
          />
          <div v-else-if="file.gmb_media_type === mediaGmbType.VIDEO">
            <video
              :poster="file.photo_url"
              :src="file.photo_url"
              class="media-content-photos"
              referrerpolicy="no-referrer"
            ></video>
            <div class="media-content-box-play">
              <i class="fa fa-play-circle-o" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="modal">
      <div class="modal" v-if="isCurrentMedia">
        <div
          class="modal-overlay"
          v-if="isCurrentMedia"
          @click="isCurrentMedia = false"
        ></div>
        <div class="modal-wrapper">
          <button @click="isCurrentMedia = false" class="modal-close">
            <i
              class="fa fa-arrow-left modal-close--icon fa-xl"
              aria-hidden="true"
            ></i>
          </button>

          <button @click="showSwalDelete()" class="media-content-remove">
            <i
              class="fa fa-trash media-content-remove--icon fa-xl"
              aria-hidden="true"
            ></i>
          </button>

          <div class="modal-container">
            <swiper
              class="swiper media-content-slides"
              :options="swiperOption"
              ref="mySwiper"
            >
              <swiper-slide v-for="file in listMedia" :key="file.id">
                <img
                  v-if="
                    imageExtensionType.includes(
                      getExtension(file.photo_url).toLowerCase()
                    )
                      ? file.photo_url
                      : ''
                  "
                  class="media-content-slide"
                  :src="file.photo_url"
                  alt=""
                />
                <video
                  controls
                  v-else-if="
                    videoExtensionType.includes(
                      getExtension(file.photo_url).toLowerCase()
                    )
                      ? {
                          mp4: file.photo_url,
                        }
                      : ''
                  "
                  :src="`${file.photo_url}#t=0.5`"
                  alt=""
                  class="media-content-slide"
                />
                <!-- Google User Content -->
                <div v-else>
                  <img
                    v-if="file.gmb_media_type === mediaGmbType.PHOTO"
                    :src="file.photo_url"
                    class="media-content-slide"
                    referrerpolicy="no-referrer"
                  />
                  <video
                    v-else-if="file.gmb_media_type === mediaGmbType.VIDEO"
                    :poster="file.photo_url"
                    :src="file.photo_url"
                    class="media-content-slide"
                    referrerpolicy="no-referrer"
                  ></video>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </transition>

    <modal :show.sync="isMedia" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">
        {{ $t("screens.media.texts.uploadMedia") }}
      </h4>
      <div class="row">
        <el-upload
          :limit="10"
          class="upload-demo custom-upload col-md-12"
          list-type="picture"
          drag
          multiple
          :auto-upload="false"
          action="#"
          :on-change="handleChange"
          :show-file-list="false"
          :file-list="form.photos"
          accept="image/*,video/*"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <p>{{ $t("screens.dashboard.texts.dropFile") }}</p>
            <em>
              {{ $t("screens.dashboard.texts.clickUpload") }}
            </em>
          </div>
        </el-upload>
      </div>
    </modal>
  </div>
</template>

<script>
import Swal from "sweetalert2"
import { Upload } from "element-ui"
import { Modal } from "src/components/UIComponents"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { objToFormData, convertMessage } from "src/util/commons"
import {
  messageType,
  imageExtensionType,
  videoExtensionType,
  mediaGmbType,
} from "src/util/constants"
import "swiper/css/swiper.css"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  components: {
    [Upload.name]: Upload,
    Modal,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isMedia: false,
      isCurrentMedia: false,
      form: {
        photos: [],
      },
      messages: null,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      imageType: ["PHOTO"],
      videoType: ["VIDEO"],
      timer: "",
      imageExtensionType: imageExtensionType,
      videoExtensionType: videoExtensionType,
      mediaGmbType: mediaGmbType,
      listMedia: [],
    }
  },
  async created() {
    await this.getMedia()
    this.timer = setInterval(this.getMedia, 30000)
  },
  beforeDestroy() {
    this.cancelAutoUpdate()
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },

    openModalMedia() {
      this.isMedia = true
    },

    async handleChange(file, fileList) {
      if (file.size > 20000000) {
        this.messages = {
          data: this.$t("actions.messages.uploadImage20MbError"),
          type: messageType.ERROR,
        }
        this.isMedia = false
        return false
      }

      if (file.raw.type === "image/webp") {
        this.messages = {
          data: this.$t("actions.messages.uploadImageError"),
          type: messageType.ERROR,
        }
        this.isMedia = false
        return false
      } else {
        try {
          this.messages = null
          this.$store.commit("setShowLoading", true)

          this.form.photos = [file.raw]
          this.isMedia = false
          const postData = objToFormData({
            user_id: this.userIds[0],
            ...this.form,
          })

          await this.$store.dispatch("media/uploadMedia", postData)

          this.messages = {
            data: this.$t("actions.messages.uploadImageSuccess"),
            type: messageType.SUCCESS,
          }
          setTimeout(() => this.getMedia, 2000)

          this.$store.commit("setShowLoading", false)
          await this.getMedia()
        } catch (err) {
          this.messages = {
            data: this.$t("actions.errors.500"),
            type: messageType.ERROR,
          }

          if (err.response?.data?.errors) {
            this.messages = {
              data: convertMessage(err.response.data.errors),
              type: messageType.ERROR,
            }
          }
          this.$store.commit("setShowLoading", false)
        }
      }
    },

    async openCurrentMedia(index) {
      this.isCurrentMedia = true
      await this.toSlide(index)
    },

    closeCurrentMedia(event) {
      this.isCurrentMedia = event.value
    },

    async removeMedia() {
      try {
        this.$store.commit("setShowLoading", true)
        const currentId =
          this.$store.state.media.listMedia[
            this.$refs.mySwiper.$swiper.activeIndex
          ].photo_id
        await this.$store.dispatch("media/deleteMedia", {
          id: currentId,
          userId: this.userIds[0],
        })
        this.$store.commit("setShowLoading", false)

        const swalWithBootstrapButtons5 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        })

        swalWithBootstrapButtons5.fire({
          title: "Deleted!",
          text: this.$t("screens.swalDeletePhoto.successText"),
        })
        this.isCurrentMedia = false
        await this.getMedia()
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        const swalWithBootstrapButtons5 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false,
        })
        swalWithBootstrapButtons5.fire({
          title: "Error!",
          text: err.response?.data
            ? this.$t("screens.swalDeletePhoto.failText")
            : this.$t("actions.errors.500"),
        })
      }
    },

    toSlide(index) {
      this.$nextTick(function () {
        this.$refs.mySwiper.$swiper.slideTo(index, 0)
      })
    },

    getDateTime(date) {
      const formatDate = new Date(date)
      return formatDate.toLocaleString("ja", {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      })
    },

    showSwalDelete() {
      const swalWithBootstrapButtons4 = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons4
        .fire({
          title: this.$t("screens.swalDeletePhoto.title"),
          text: this.$t("screens.swalDeletePhoto.text"),
          confirmButtonText: this.$t("screens.buttons.delete"),
          cancelButtonText: this.$t("screens.buttons.cancel"),
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.removeMedia()
          }
        })
    },

    async getMedia() {
      await this.$store.dispatch("media/getListMedia", this.userIds[0])
      this.getListMedia()
    },

    getListMedia() {
      this.listMedia = this.$store.state.media.listMedia.filter(
        (media) => media.photo_url !== null
      )
    },
  },
}
</script>

<style scoped>
.custom-upload >>> .el-upload-dragger {
  width: 100%;
}

.custom-upload >>> .el-upload {
  display: block;
  width: 100%;
}

.swiper-button-prev {
  color: white;
}

.swiper-button-next {
  color: white;
}
</style>
