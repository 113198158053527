import $axios from "@/plugins/axios"
import { postType } from "@/util/constants"

export default class NewsApi {
  async addNews(data) {
    return await $axios({
      method: "POST",
      url: "/posts/news",
      data,
    })
  }

  async updateNews({ key, data }) {
    return await $axios({
      method: "POST",
      url: `/posts/news/${key}`,
      data,
    })
  }

  async deleteNew(newsId, userId) {
    return await $axios.delete(`/posts/news/${newsId}?user_id=` + userId)
  }

  async getNews(userId) {
    return await $axios({
      method: "GET",
      url: `/posts/${postType.NEWS}?user_id=${userId}`,
    })
  }

  async getNew(key) {
    return await $axios.get(`/posts/${postType.NEWS}/${key}`)
  }
}
