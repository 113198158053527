<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("screens.dashboard.texts.connectAccountLocationTitle") }}
      </h5>
    </div>

    <AdminAlert
      v-if="messages"
      :type="messages.type"
      :messages="messages.data"
    />

    <div class="card-body row">
      <div class="col-sm-12">
        <el-table
          header-row-class-name="text-primary"
          :data="queriedData"
          row-key="id"
        >
          <el-table-column width="55">
            <template slot-scope="scope">
              <p-radio
                style="top: 10px"
                v-model="selectedLocation"
                :label="String(scope.row.id)"
              >
              </p-radio>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('screens.dashboard.texts.connectAccountLocationName')"
          >
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column
            :label="
              $t('screens.dashboard.texts.connectAccountLocationOpenInfo')
            "
          >
            <template slot-scope="scope">
              <span v-if="scope.row.openInfo.canReopen" class="text-success">
                {{ scope.row.openInfo.status }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="
              $t('screens.dashboard.texts.connectAccountLocationPhoneNumber')
            "
          >
            <template slot-scope="scope">
              {{ scope.row.phoneNumbers.primaryPhone }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('screens.dashboard.texts.connectAccountLocationAddress')"
          >
            <template slot-scope="scope" v-if="scope.row.storefrontAddress">
              <span
                v-for="(address, index) in scope.row.storefrontAddress
                  .addressLines"
                :key="index"
              >
                {{ address }},
              </span>
              <span v-if="scope.row.storefrontAddress.locality">
                {{ scope.row.storefrontAddress.locality }} ,
              </span>
              <span v-if="scope.row.storefrontAddress.administrativeArea">
                {{ scope.row.storefrontAddress.administrativeArea }} ,
              </span>
              <span v-if="scope.row.storefrontAddress.regionCode">
                {{ scope.row.storefrontAddress.regionCode }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('screens.dashboard.texts.connectAccountLocationUrl')"
          >
            <template slot-scope="scope">
              <a :href="scope.row.websiteUri" target="_blank">
                {{ scope.row.websiteUri }}
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="col-sm-6 pagination-info mt-2">
        <p class="category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <div class="col-sm-6 mt-2">
        <pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </pagination>
      </div>

      <div class="col-sm-12">
        <p-button @click="goBack" type="default" round>
          {{ $t("screens.buttons.cancel") }}
        </p-button>
        <p-button
          :disabled="!selectedLocation"
          @click="postLocations"
          type="primary"
          round
          style="margin-left: 15px"
        >
          {{ $t("screens.buttons.confirm") }}
        </p-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Option } from "element-ui"
import { Pagination } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    Pagination,
  },

  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      list: [],
      selectedLocation: "",
      messages: null,
    }
  },

  computed: {
    pagedData() {
      return this.list.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pagination.total = this.list.length
        return this.pagedData
      }
      const result = this.list.filter((row) => {
        let isIncluded = false
        for (const key of this.propsToSearch) {
          const rowValue = row[key].toString()
          if (rowValue && rowValue.includes(this.searchQuery)) {
            isIncluded = true
          }
        }
        return isIncluded
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.list.length
      return this.list.length
    },
  },

  async created() {
    this.$store.commit("setShowLoading", true)
    await this.getLocations()
    this.$store.commit("setShowLoading", false)
  },

  methods: {
    async getLocations() {
      try {
        this.messages = null
        const data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("entity/getLocations", { data })
        this.list = this.$store.state.entity.locations
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },

    async postLocations() {
      try {
        this.$store.commit("setShowLoading", true)
        this.messages = null
        const params = {
          user_id: this.userIds[0],
          location_id: this.selectedLocation,
        }
        await this.$store.dispatch("entity/postLocations", { data: params })
        this.$router.push("/dashboard")
        this.$store.commit("setShowLoading", false)
        this.$router.go(0)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },

    goBack() {
      this.$router.push("/connect-account")
    },
  },
}
</script>

<style></style>
