<template>
  <div v-if="errors.length > 0">
    <p v-for="(err, index) in errors" :key="index" class="text-warning">
      {{ err }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
