import axios from "axios"
import store from "@/store"

const config = {
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 60000,
  headers: {
    accept: "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY,
  },
}

const $axios = axios.create(config)

$axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("token")

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response.status === 401 &&
      !window.location.pathname.includes("login")
    ) {
      store.dispatch("auth/postLogout")
      window.location.href = window.location.origin + "/login"
    }
    return Promise.reject(error)
  }
)

export default $axios
