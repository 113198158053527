<template>
  <div class="row change-method">
    <div class="col-md-12">
      <h4 class="card-title">
        {{ $t("screens.paymentSetting.changePaymentMethod") }}
      </h4>
    </div>
    <div class="col-md-12">
      <ValidationObserver ref="register-payment" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>
          <div class="row">
            <div class="col-md-2">
              <ValidationProvider
                :name="$t('screens.paymentSetting.paymentName')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input :error="errors[0]">
                  <el-select
                    v-model="form.payment_name"
                    :placeholder="$t('screens.dashboard.texts.pleaseChoose')"
                    :change="handleChange(form.payment_name)"
                  >
                    <el-option
                      v-for="item in paymentOptions"
                      :key="item.key"
                      :label="item.name"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import Swal from "sweetalert2"

export default {
  mixins: [formMixin],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      form: {
        payment_name: "",
        user_id: null,
      },
      messages: null,
    }
  },
  computed: {
    paymentOptions() {
      return this.$store.state.payment.list
    },
    isSelectedPlan() {
      return this.$store.state.payment.paymentSelectedPlan.key
    },
  },
  async created() {
    await this.paymentMethods()
    await this.showPayment()
  },
  methods: {
    handleChange(value) {
      if (value === "invoice") {
        this.form.payment_name = "請求書"
      } else {
        this.form.payment_name = value
      }
    },
    async paymentMethods() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        await this.$store.dispatch("payment/paymentMethods", this.userIds[0])

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        this.form.user_id = this.userIds[0]
        await this.$store.dispatch("payment/changePaymentMethods", this.form)
        this.$store.commit("setShowLoading", false)
        await Swal.fire({
          icon: messageType.SUCCESS,
          text: this.$t("actions.messages.changePaymentMethodSuccess"),
          showConfirmButton: false,
          timer: 2000,
        })
        this.$emit("selectValue", this.form.payment_name)
        this.$router.go(0)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }

        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
    async showPayment() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        await this.$store.dispatch("payment/getPaymentMethodSelected", {
          user_id: this.userIds,
        })
        const payment = this.$store.state.payment.paymentMethod
        this.form.payment_name = payment.key

        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
