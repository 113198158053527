<template>
  <div class="card">
    <modal
      :show.sync="isChangePassword"
      headerClasses="justify-content-center"
      class="custom-modal"
    >
      <h4 slot="header" class="title">
        {{ $t("screens.dashboard.texts.changePasswordTitle") }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <ValidationObserver ref="change-password" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <AdminAlert
                v-if="messages"
                :type="messages.type"
                :messages="messages.data"
              ></AdminAlert>

              <ValidationProvider
                :name="$t('screens.dashboard.forms.currentPassword')"
                rules="required|min:8"
                v-slot="{ errors }"
              >
                <fg-input
                  class="mb-3"
                  name="current-password"
                  type="password"
                  :label="$t('screens.dashboard.forms.currentPassword')"
                  :placeholder="$t('screens.dashboard.forms.currentPassword')"
                  v-model="form.current_password"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>

              <ValidationProvider
                :name="$t('screens.dashboard.forms.newPassword')"
                rules="required|min:8"
                v-slot="{ errors }"
                vid="newPass"
              >
                <fg-input
                  class="mb-3"
                  name="new-password"
                  type="password"
                  :label="$t('screens.dashboard.forms.newPassword')"
                  :placeholder="$t('screens.dashboard.forms.newPassword')"
                  v-model="form.password"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>

              <ValidationProvider
                :name="$t('screens.dashboard.forms.confirmPassword')"
                rules="required|min:8|confirmed:newPass"
                v-slot="{ errors }"
              >
                <fg-input
                  class="mb-3"
                  name="confirm-password"
                  type="password"
                  :label="$t('screens.dashboard.forms.confirmPassword')"
                  :placeholder="$t('screens.dashboard.forms.confirmPassword')"
                  v-model="form.password_confirmation"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>

              <div class="text-center">
                <p-button native-type="submit" type="info" round>
                  {{ $t("screens.dashboard.buttons.update") }}
                </p-button>
              </div>
              <div class="clearfix"></div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import { Modal } from "src/components/UIComponents"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"

export default {
  mixins: [formMixin],
  components: {
    Modal,
    PayRequired,
  },
  data() {
    return {
      form: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      messages: null,
      isChangePassword: false,
    }
  },
  computed: {
    getIsChangePassword() {
      return this.$store.state.isChangePassword
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)

        await this.$store.dispatch("auth/changePassword", this.form)
        this.messages = {
          data: this.$t("actions.messages.changePasswordSuccess"),
          type: messageType.SUCCESS,
        }

        this.$store.commit("setShowLoading", false)
        this.resetForm("form", "change-password")
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>

<style lang="css" scoped>
.custom-modal >>> .modal-content {
  width: 110%;
}

.custom-modal >>> .modal-body {
  padding: 20px 40px 10px 40px;
}

@media screen and (max-width: 767px) {
  .custom-modal >>> .modal-content {
    width: 100%;
  }
}
</style>
