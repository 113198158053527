import $axios from "@/plugins/axios"

export default class PaymentApi {
  async paymentMethods(userId) {
    return await $axios.get(`/payment-methods?user_id=${userId}`)
  }

  async changePaymentMethods(data) {
    return await $axios.post("/payment-methods", data)
  }

  async changePaymentPlan(data) {
    return await $axios.post("/payment-plans", data)
  }

  async registerCreditCard(data) {
    return await $axios.post("/payment/credit-card/register", data)
  }

  async showCreditCard(data) {
    return await $axios.get(
      "/payment/credit-card/reference?user_id=" + data.user_id
    )
  }

  async removeCreditCard(data) {
    return await $axios.get(
      "/payment/credit-card/remove?user_id=" + data.user_id
    )
  }

  async getPaymentMethodSelected(data) {
    return await $axios.get(
      "/payment/selected-payment-method?user_id=" + data.user_id
    )
  }

  async getPaymentSelectedPlan(data) {
    return await $axios.get(
      "/payment/selected-payment-plan?user_id=" + data.user_id
    )
  }

  async getPaymentPlan(data) {
    return await $axios.get("/payment-plans?user_id=" + data.user_id)
  }
}
