<template>
  <div class="card card-stats data-box">
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <div class="icon-big text-center icon-warning">
            <i class="nc-icon text-info" :class="data.icon"></i>
          </div>
        </div>
        <div class="col-7 col-md-8">
          <div class="numbers">
            <p class="card-category">{{ data.title }}</p>
            <p class="card-title">
              <span>
                {{ formatData(data.data) }}
              </span>
              <span
                v-if="typeof data.increase === 'string'"
                class="badge badge-pill badge-disabled"
              >
                {{ formatIncrease(data.increase) }}%
              </span>
              <span
                v-else
                class="badge badge-pill"
                :class="{
                  'badge-danger': data.increase < 0,
                  'badge-success': data.increase >= 0,
                }"
              >
                {{ formatIncrease(data.increase) }}%
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatData(number) {
      const numberStr = String(number)
      let formattedNumber = numberStr
      if (numberStr.startsWith("threshold_")) {
        formattedNumber = "< " + numberStr.substring(10)
      } else if (numberStr.startsWith("value_")) {
        formattedNumber = numberStr.substring(6)
      }
      const n = parseInt(formattedNumber, 10)
      if (!isNaN(n)) {
        return new Intl.NumberFormat("en-US").format(n)
      } else {
        return numberStr // Return the original value if it's not a valid number
      }
    },
    formatIncrease(number) {
      if (number >= 0) return "+" + number
      else return number
    },
  },
}
</script>

<style lang="scss">
.data-box {
  .badge {
    padding: 4px 8px;
    font-size: 0.7142em;
    border: 1px solid;
    line-height: 12px;
  }
}

.badge-disabled {
  background-color: #e3e3e3;
  color: #66615b;
  border: 1px solid rgb(227, 227, 227) !important;
}
</style>
