import $axios from "@/plugins/axios"
export default class PostApi {
  async getModels({ userId, params }) {
    return await $axios({
      method: "GET",
      url: `/posts?user_id=${userId}`,
      params,
    })
  }

  async getModel({ id, userId }) {
    return await $axios({
      method: "GET",
      url: `/posts/${id}?user_id=${userId}`,
    })
  }

  async create({ data }) {
    return await $axios({
      method: "POST",
      url: "/posts",
      data,
    })
  }
}
