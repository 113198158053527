import DashboardApi from "@/services/dashboardApi"

const api = new DashboardApi()

const state = () => ({
  boxData: {},
  customerSearch: {},
  customerCall: {},
  customerView: {},
  customerAction: {},
  searchKeywords: {},
  reactionsGraph: {},
  impressionsGraph: {},
})

const mutations = {
  setBoxData(state, data) {
    state.boxData = data
  },
  setCustomerSearch(state, data) {
    state.customerSearch = data
  },
  setCustomerCall(state, data) {
    state.customerCall = data
  },

  setCustomerView(state, data) {
    state.customerView = data
  },

  setSearchKeywords(state, data) {
    state.searchKeywords = data
  },

  setReationsGraph(state, data) {
    state.reactionsGraph = data
  },

  setImpressionsGraph(state, data) {
    state.impressionsGraph = data
  },
}

const actions = {
  async getBoxData({ commit }, data) {
    await api.getBoxData(data).then((response) => {
      commit("setBoxData", response.data.data)
    })
  },
  async getCustomerSearch({ commit }, { userId, params }) {
    await api.getCustomerSearch(userId, params).then((response) => {
      commit("setCustomerSearch", response.data.data)
    })
  },
  async getCustomerCall({ commit }, { userId, params }) {
    await api.getCustomerCall(userId, params).then((response) => {
      commit("setCustomerCall", response.data.data)
    })
  },

  async getCustomerView({ commit }, { timeRange, data }) {
    await api.getCustomerView({ timeRange, data }).then((response) => {
      commit("setCustomerView", response.data.data)
    })
  },

  async getReactionsGraph({ commit }, { timeRange, data }) {
    await api.getReactionsGraph({ timeRange, data }).then((response) => {
      commit("setReationsGraph", response.data.data)
    })
  },

  async getSearchKeywords({ commit }, { timeRange, data }) {
    await api.getSearchKeywords({ timeRange, data }).then((response) => {
      commit("setSearchKeywords", response.data.data)
    })
  },

  async getImpressionsGraph({ commit }, { timeRange, data }) {
    await api.getImpressionsGraph({ timeRange, data }).then((response) => {
      commit("setImpressionsGraph", response.data.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
