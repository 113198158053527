import $axios from "@/plugins/axios"
export default class AgencyApi {
  async getListGroup(params) {
    return await $axios.get("/agency/groups")
  }

  async getListStore(params) {
    return await $axios({
      method: "GET",
      url: "/agency/stores",
      params,
    })
  }

  async getListStoreByManager(params) {
    return await $axios({
      method: "GET",
      url: "/agency/stores",
      params,
    })
  }

  async getListStoreByGroup(groupId, params) {
    return await $axios({
      method: "GET",
      url: `/agency/groups/${groupId}/stores`,
      params,
    })
  }

  async createGroup(data) {
    return await $axios.post("/agency/groups", data)
  }

  async updateGroup(id, data) {
    return await $axios.put(`/agency/groups/${id}`, data)
  }

  async updateLogo({ data }) {
    return await $axios({
      method: "POST",
      url: "/agency/logo",
      data,
    })
  }

  async getAgencyPrefix({ prefix }) {
    return await $axios({
      method: "GET",
      url: `/domain/${prefix}/title`,
    })
  }
}
