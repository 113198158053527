<template>
  <div>
    <div class="row el-row--flex is-justify-end">
      <div class="col-md-12">
        <p-button
          class="el-row--flex is-align-middle pull-right w-175"
          type="default"
          outline
          @click="createPost"
        >
          <i class="fa fa-plus mr-2"></i>
          {{ $t("screens.media.buttons.addMedia") }}
        </p-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-9">
        <div class="row align-items-center">
          <div class="row col-sm-12 d-flex">
            <div class="form-group col-sm-2 mr-auto">
              <el-select
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="changeSelectPage(pagination.perPage)"
              >
                <el-option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <fg-input
          class="input-sm mb-0 w-175 pull-right"
          :placeholder="$t('screens.overviewManager.label.search')"
          v-model="searchQuery"
          addon-right-icon="nc-icon nc-zoom-split"
        >
        </fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <el-table
          ref="multipleTable"
          row-key="id"
          class="table-striped"
          :data="pagedData"
          style="width: 100%"
          :empty-text="$t('screens.dashboard.texts.noData')"
        >
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="100"
            :label="$t('screens.postList.label.dateTimePublish')"
          >
            <template slot-scope="scope">
              {{
                formatDate(scope.row.created_at) +
                " " +
                formatTime(scope.row.created_at)
              }}
            </template>
          </el-table-column>
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="150"
            :label="$t('screens.postList.label.sns')"
          >
            <template slot-scope="scope">
              <img
                :src="getSnsType(scope.row.shared_sns_type).url"
                width="30"
                height="30"
                alt=""
              />
              <span class="ml-2">
                {{ getSnsType(scope.row.shared_sns_type).label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="150"
            :label="$t('screens.postList.label.content')"
          >
            <template slot-scope="scope">
              {{ scope.row.content }}
            </template>
          </el-table-column>
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="120"
            :label="$t('screens.postList.label.media')"
          >
            <template slot-scope="scope">
              <el-carousel
                height="37.5px"
                indicator-position="none"
                :autoplay="false"
                arrow="always"
                class="w-37"
              >
                <el-carousel-item
                  v-for="(media, index) in scope.row.media_url"
                  :key="index"
                >
                  <div
                    v-if="isImageOrVideo(media) === 'video'"
                    class="media-video"
                  >
                    <video width="100%" height="100%" controls>
                      <source :src="media" />
                    </video>
                  </div>
                  <div
                    v-else-if="isImageOrVideo(media) === 'image'"
                    class="media-image"
                  >
                    <img :src="media" :alt="scope.row.content" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="100"
            :label="$t('screens.postList.label.dateTimeStart')"
          >
            <template slot-scope="scope">
              {{
                formatDate(scope.row.publish_date) +
                " " +
                formatTime(scope.row.publish_date)
              }}
            </template>
          </el-table-column>
          <el-table-column
            :sortable="true"
            :sort-method="sortMethod"
            min-width="100"
            :label="$t('screens.postList.label.dateTimeEnd')"
          >
            <template slot-scope="scope">
              {{
                formatDate(scope.row.delete_date) +
                " " +
                formatTime(scope.row.delete_date)
              }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('screens.postList.label.action')"
            :sortable="true"
            class-name="action-buttons td-actions"
            align="right"
          >
            <template slot-scope="scope">
              <p-button type="info" size="sm" icon @click="copyPost(scope.row)">
                <img class="" src="@/assets/images/copy.png" alt="" />
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="row mt-2 el-row--flex is-align-middle">
      <div class="col-sm-6 pagination-info">
        <p class="category">
          {{ paginationContent }}
        </p>
      </div>
      <div v-if="getPagination.total" class="col-sm-6">
        <pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
          :change-page="changePage(pagination.currentPage)"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import {
  Table,
  TableColumn,
  Select,
  Option,
  Carousel,
  CarouselItem,
} from "element-ui"
import { Pagination } from "src/components/UIComponents"
import {
  isImageOrVideo,
  convertMessage,
  formatDateTime,
  formatDate,
  formatTime,
} from "src/util/commons"
import { postType } from "src/util/constants"
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Carousel)
Vue.use(CarouselItem)

export default {
  components: {
    Pagination,
  },

  data() {
    return {
      groupSelection: null,
      multipleSelection: [],
      shopGroup: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      params: {
        limit: 5,
        page: 1,
        post_key_search: "",
      },
      valuePage: 1,
      perPage: 5,
      firstPage: 1,
      propsToSearch: [
        "date_time_publish",
        "sns",
        "content",
        "media",
        "date_time_start",
        "date_time_end",
      ],
      tableColumns: [
        {
          prop: "content",
          label: this.$t("screens.postList.label.content"),
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "media",
          label: this.$t("screens.postList.label.media"),
          sortable: true,
        },
        {
          prop: "date_time_start",
          label: this.$t("screens.postList.label.dateTimeStart"),
          sortable: true,
          minWidth: 150,
        },
        {
          prop: "date_time_end",
          label: this.$t("screens.postList.label.dateTimeEnd"),
          sortable: true,
          minWidth: 150,
        },
      ],
    }
  },

  computed: {
    ...mapState("post", ["list"]),
    userIds() {
      return this.$store.getters.userIds
    },
    pagedData() {
      return this.list
    },
    paginationContent() {
      const { from, to, total } = this.getPagination
      return total ? `Showing ${from} to ${to} of ${total} entries` : null
    },
    getPagination() {
      return this.$store.state.post.pagination
    },
  },

  watch: {
    valuePage(currentPage) {
      if (currentPage !== this.firstPage) {
        this.getPosts(currentPage, this.perPage, this.searchQuery)
      } else {
        this.getPosts(this.firstPage, this.perPage, this.searchQuery)
      }
    },
    getPagination: {
      handler(newPagination) {
        if (newPagination) {
          this.pagination.total = newPagination.total
        }
      },
      immediate: true,
    },
    async searchQuery(value) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        await this.getPosts(this.firstPage, this.perPage, value)
      }, 500)
    },
  },

  async mounted() {
    await this.initData()
  },

  methods: {
    ...mapActions("post", ["getModels", "resetState"]),
    async initData() {
      await this.getPosts(this.firstPage, this.perPage)
    },
    async getPosts(page, option, keySearch) {
      this.$store.commit("setShowLoading", true)
      try {
        this.params = {
          limit: option,
          page: page,
          post_key_search: keySearch,
        }
        await this.getModels({
          userId: this.userIds,
          params: this.params,
        })
      } catch (error) {
        if (error.response?.data?.errors) {
          this.$notify({
            message: convertMessage(error.response.data.errors).join("\r\n"),
            type: "danger",
          })
        } else {
          this.$notify({
            message: this.$t("actions.messages.postError"),
            type: "danger",
          })
        }
      } finally {
        this.$store.commit("setShowLoading", false)
      }
    },
    changePage(val) {
      this.valuePage = val
    },
    changeSelectPage(val) {
      this.perPage = val
      this.getPosts(this.valuePage, this.perPage, this.searchQuery)
    },
    isImageOrVideo(url) {
      return isImageOrVideo(url)
    },
    createPost() {
      this.resetState()
      this.$router.push("/create-post")
    },
    copyPost(row) {
      this.$router.push("/post/" + row.id)
    },
    getSnsType(id) {
      return postType.find((item) => item.name === id)
    },
    formatDateTime(dateTime) {
      return dateTime ? formatDateTime(dateTime) : ""
    },
    formatDate(date) {
      return date ? formatDate(date) : ""
    },
    formatTime(time) {
      return time ? formatTime(time) : ""
    },
    sortMethod(a, b) {
      const sortByProperties = [
        "created_at",
        "shared_sns_type",
        "content",
        "media_url",
        "publish_date",
        "delete_date",
      ]
      for (const property of sortByProperties) {
        if (a[property] && b[property]) {
          if (a[property] !== b[property]) {
            if (typeof a[property] === "string") {
              return a[property].localeCompare(b[property])
            } else {
              return a[property] < b[property] ? -1 : 1
            }
          }
        }
      }
      return 0
    },
  },
}
</script>
