<template>
  <el-dialog
    :title="$t('screens.agreeTerm.texts.title')"
    :visible.sync="show"
    width="50%"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    @close="close"
  >
    <div v-html="form.terms" class="agree-term-html"></div>
    <div class="mt-2 row justify-content-center">
      <el-checkbox v-model="form.checked">
        {{ $t("screens.agreeTerm.texts.checkbox") }}
      </el-checkbox>
    </div>
    <div class="mt-2 row justify-content-center">
      <el-button type="primary" @click="verifyTerm" :disabled="!form.checked">
        {{ $t("screens.buttons.confirm") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Button, Checkbox } from "element-ui"
import { mapState, mapActions } from "vuex"
import { domainType } from "src/util/constants"
import { convertMessage } from "src/util/commons"
import commons from "@/mixins/commons"

export default {
  mixins: [commons],
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      domainType,
      form: {
        terms: "",
        checked: false,
      },
      show: false,
    }
  },
  computed: {
    ...mapState("auth", ["agreeTerm", "user"]),
  },
  watch: {
    agreeTerm(newValue) {
      this.show = newValue
    },
  },
  mounted() {
    this.checkDomain()
  },
  methods: {
    ...mapActions("auth", ["postAgreeTerm", "confirmTerm", "logout"]),
    async checkDomain() {
      const fullUrl = window.location.toString()
      const { domainSystem } = this.getAgencyName(fullUrl)
      if (domainSystem === this.domainType.MEO_SYSTEM.key) {
        this.form.terms = this.$t("terms.domain.meoSystem")
      } else if (domainSystem === this.domainType.LIGHTEN.key) {
        this.form.terms = this.$t("terms.domain.lighten")
      } else {
        this.form.terms = this.$t("terms.domain.default")
      }
    },
    async verifyTerm() {
      this.$store.commit("setShowLoading", true)
      try {
        const data = {
          email: this.user.email,
          agreed_terms: this.form.checked ? 1 : 0,
        }
        await this.confirmTerm(data)
        this.$router.push("/")
        this.close()
      } catch (error) {
        if (error.response?.data?.errors) {
          this.$notify({
            message: convertMessage(error.response.data.errors).join("\r\n"),
            type: "danger",
          })
        } else {
          this.$notify({
            message: this.$t("actions.errors.500"),
            type: "danger",
          })
        }
      } finally {
        this.$store.commit("setShowLoading", false)
      }
    },
    async beforeClose() {
      await this.logout()
      this.close()
    },
    async close() {
      this.postAgreeTerm(false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
