<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">{{ $t("screens.addKnowledgeTag.title") }}</h4>
      <div>
        {{ $t("screens.addKnowledgeTag.description") }}
        <!-- <a href="#">{{ $t("screens.addKnowledgeTag.learnMore") }}</a> -->
      </div>
    </div>
    <div class="card-body">
      <h6 class="card-title">
        {{ $t("screens.addKnowledgeTag.knowledgeTagInformation") }}
      </h6>
      <collapse>
        <collapse-item
          :title="'1. ' + $t('screens.addKnowledgeTag.copyPasteScriptTag')"
          :name="$t('screens.addKnowledgeTag.copyPasteScriptTag')"
        >
          <label style="display: flex">
            {{ $t("screens.addKnowledgeTag.copyPasteScriptTagDescription[0]") }}
            <prism
              code="<script>"
              style="background: none; padding: 0; margin: 0"
            />
            {{ $t("screens.addKnowledgeTag.copyPasteScriptTagDescription[1]") }}
            <prism
              code="</head>"
              style="background: none; padding: 0; margin: 0"
            />
            {{ $t("screens.addKnowledgeTag.copyPasteScriptTagDescription[2]") }}
          </label>
          <prism :code="getScriptTag()"></prism>
        </collapse-item>
        <collapse-item
          :title="
            '2. ' + $t('screens.addKnowledgeTag.addEmbedCodesForRelevantFields')
          "
          :name="$t('screens.addKnowledgeTag.addEmbedCodesForRelevantFields')"
        >
          <label>
            {{
              $t(
                "screens.addKnowledgeTag.addEmbedCodesForRelevantFieldsDescription"
              )
            }}
          </label>
          <el-table class="table-striped" :data="modifiedTableData" border>
            <el-table-column
              prop="field"
              :label="$t('screens.addKnowledgeTag.entityField')"
              minWidth="150"
            >
            </el-table-column>
            <el-table-column
              prop="code"
              :label="$t('screens.addKnowledgeTag.embedCode')"
              minWidth="300"
            >
              <template slot-scope="props">
                <prism :code="props.row.code" style="background: none"></prism>
              </template>
            </el-table-column>
          </el-table>
        </collapse-item>
        <collapse-item
          :title="'3. ' + $t('screens.addKnowledgeTag.confirmInstallation')"
          :name="$t('screens.addKnowledgeTag.confirmInstallation')"
        >
          <label>
            {{ $t("screens.addKnowledgeTag.confirmInstallationDescription") }}
          </label>
          <div>
            <p-button type="primary" @click="openModalMarkup">
              {{ $t("screens.knowledgeTag.buttons.CheckSchemaMarkup") }}
            </p-button>
          </div>
        </collapse-item>
        <!-- <collapse-item
          :title="$t('screens.addKnowledgeTag.needMoreHelp')"
          :name="$t('screens.addKnowledgeTag.needMoreHelp')"
        >
          <div class="flex-column">
            <a href="#">
              {{ $t("screens.addKnowledgeTag.needMoreHelpDescription[0]") }}
            </a>
            <a href="#">
              {{ $t("screens.addKnowledgeTag.needMoreHelpDescription[1]") }}
            </a>
            <a href="#">
              {{ $t("screens.addKnowledgeTag.needMoreHelpDescription[2]") }}
            </a>
          </div>
        </collapse-item> -->
      </collapse>
    </div>
    <div class="card-footer">
      <p-button round @click="gotoHref('knowledge-tag')">
        {{ $t("screens.buttons.cancel") }}
      </p-button>
      <p-button type="info ml-2" round @click="gotoHref('knowledge-tag')">
        {{ $t("screens.buttons.finish") }}
      </p-button>
    </div>
    <modal :show.sync="isShowModal" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ modal.title }}</h4>
      <p style="white-space: pre-line">
        {{ $t("screens.addKnowledgeTag.modalDescription[0]") }}<br /><br />
        {{ modal.description }}<br /><br />
        {{ $t("screens.addKnowledgeTag.modalDescription[1]") }}
      </p>
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="isShowModal = false">
            {{ $t("screens.buttons.cancel") }}
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="info" link @click="modalConfirm(modal.type)">
            {{ $t("screens.buttons.confirm") }}
          </p-button>
        </div>
      </template>
    </modal>

    <knowledge-tag-modal-verify-markup
      ref="verifyMarkup"
    ></knowledge-tag-modal-verify-markup>
  </div>
</template>

<script>
import Prism from "vue-prismjs"
import "prismjs/themes/prism-tomorrow.css"
import Vue from "vue"
import { Button, Select, Option, Table, TableColumn } from "element-ui"
import { Collapse, CollapseItem, Modal } from "src/components/UIComponents"
import { scriptTagHead, scriptTagFooter, messageType } from "src/util/constants"
import { convertMessage } from "src/util/commons"
import KnowledgeTagModalVerifyMarkup from "./KnowledgeTagModalVerifyMarkup.vue"
import formMixin from "@/mixins/formMixin"
import { knowledgeAttribute } from "src/util/constants"

Vue.use(Table)
Vue.use(TableColumn)
export default {
  mixins: [formMixin],
  components: {
    CollapseItem,
    Collapse,
    Modal,
    Prism,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    KnowledgeTagModalVerifyMarkup,
  },
  data() {
    return {
      isShowModal: false,
      modal: {
        type: "",
        title: "",
        description: "",
      },
      modalType: [
        {
          type: 0,
          title: this.$t("screens.addKnowledgeTag.disablePixels"),
          description: this.$t(
            "screens.addKnowledgeTag.modalDescriptionCenter[0]"
          ),
        },
        {
          type: 1,
          title: this.$t("screens.addKnowledgeTag.enablePixels"),
          description: this.$t(
            "screens.addKnowledgeTag.modalDescriptionCenter[1]"
          ),
        },
        {
          type: 2,
          title: this.$t("screens.addKnowledgeTag.enablePixels"),
          description: this.$t(
            "screens.addKnowledgeTag.modalDescriptionCenter[2]"
          ),
        },
      ],
      field: {
        entity: "Porto株式会社",
        locale: 0,
        analytic: 0,
        cookies: 0,
      },
      localeOptions: [
        {
          value: 0,
          label: "en",
        },
        {
          value: 1,
          label: "ja",
        },
      ],
      analyticOptions: [
        {
          value: 0,
          label: this.$t("screens.addKnowledgeTag.alwaysOn"),
        },
        {
          value: 1,
          label: this.$t("screens.addKnowledgeTag.alwaysOff"),
        },
        {
          value: 2,
          label: this.$t("screens.addKnowledgeTag.perUserOptIn"),
        },
      ],
      tableData: [
        {
          field: this.$t("screens.addKnowledgeTag.tagName"),
          code: `<span data-lighten-field="location_name" data-lighten-id="store_code" id="name"></span>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagPhone"),
          code: `<a data-lighten-field="core_information.main_phone" data-lighten-id="store_code" id="phone"></a>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagPostalCode"),
          code: `<span data-lighten-field="address_lines.postalCode" data-lighten-id="store_code" id="postal_code"></span>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagPrefecture"),
          code: `<span data-lighten-field="address_lines.administrativeArea" data-lighten-id="store_code" id="region"></span>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagCity"),
          code: `<span data-lighten-field="address_lines.address1" data-lighten-id="store_code" id="address1"></span>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagTown"),
          code: `<span data-lighten-field="address_lines.address2" data-lighten-id="store_code" id="address2"></span>`,
        },
        {
          field: this.$t("screens.addKnowledgeTag.tagBuilding"),
          code: `<span data-lighten-field="address_lines.address3" data-lighten-id="store_code" id="address3"></span>`,
        },
      ],
      knowledgeAttribute,
    }
  },
  computed: {
    getEntityId() {
      return this.$store.state.entity.basicInformation?.id
    },
    getCorporateCode() {
      return this.$store.state.entity.basicInformation?.corporate_code
    },
    baseUrl(){
      const fullUrl = window.location.href;
      const urlObject = new URL(fullUrl);
      return urlObject.origin;
    },
    modifiedTableData() {
      const isLightenMeo = this.checkDomainContains(process.env.VUE_APP_PORTO_DOMAIN);
      return this.tableData.map((item) => {
        if (!isLightenMeo) {
          return {
            ...item,
            code: item.code
              .replace(this.knowledgeAttribute.LIGHTEN.data_field, this.knowledgeAttribute.MEO_SYSTEM.data_field)
              .replace(this.knowledgeAttribute.LIGHTEN.data_id, this.knowledgeAttribute.MEO_SYSTEM.data_id),
          };
        } else {
          return item;
        }
      });
    },
  },
  async created() {
    await this.getBasicInformation()
  },
  methods: {
    getScriptTag() {
      let html = ""

      html += scriptTagHead
      html += this.baseUrl + "/api/knowledge-tag/embed?"
      html += "api_key=" + process.env.VUE_APP_API_KEY
      html += "&app_key=" + process.env.VUE_APP_KNOWLEDGE_TAG_KEY
      html += "&entity_id=" + this.getEntityId

      html += scriptTagFooter
      return html
    },
    changeAnalytic(event) {
      this.modal = this.modalType[event]
      this.isShowModal = true
    },
    modalConfirm(type) {
      this.field.analytic = type
      this.isShowModal = false
    },
    gotoHref(href) {
      this.$router.push(href)
    },
    async getBasicInformation() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("entity/getBasicInformation", { data })
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
    openModalMarkup() {
      this.$refs.verifyMarkup.isVerifyMarkup = true
    },
    checkDomainContains(searchString) {
      const domain = window.location.hostname;
      return domain.includes(searchString);
    },
  },
}
</script>

<style>
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>
