import MediaApi from "@/services/mediaApi"

const api = new MediaApi()

const state = () => ({
  listMedia: "",
})

const mutations = {
  setListMedia(state, data) {
    state.listMedia = data
  },
  deleteMedia(state, id) {
    state.listMedia = state.listMedia.filter((e) => e.photo_id !== id)
  },
}

const actions = {
  async getListMedia({ commit }, userId) {
    await api.getListMedia(userId).then((response) => {
      commit("setListMedia", response.data.data)
    })
  },
  async uploadMedia({ commit }, data) {
    return await api.uploadMedia(data)
  },
  async deleteMedia({ commit }, { id, userId }) {
    await api.deleteMedia({ id, userId }).then(() => {
      commit("deleteMedia", id)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
