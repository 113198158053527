<template>
  <div class="card doughnut-chart">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-7">
          <fg-input>
            <el-select
              class="select-info"
              v-model="time"
              @change="handleChange"
            >
              <el-option
                v-for="option in timeOptions"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h6 class="big-title">
        {{ $t("screens.report.texts.chartCustomerSearchBusiness") }}
      </h6>
      <div v-if="message" class="text-danger center-error-message">
        {{ message }}
      </div>
      <canvas
        :id="id"
        class="ct-chart ct-perfect-fourth"
        width="400"
        height="300"
      ></canvas>
    </div>
    <div class="card-footer">
      <div class="legend labels-name">
        <div v-for="(label, index) in labels" :key="index">
          <template>
            <i class="fa fa-circle" :style="colorCircle(index)"></i>
            {{ label }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import Chart from "chart.js"
import formMixin from "@/mixins/formMixin"
import actions from "@/lang/ja/actions"

export default {
  mixins: [formMixin],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    timeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userId: "",
      cloneInputs: {},
      arrayData: [],
      time: 1,
      message: null,
      totalLabel: null,
      formatTotalLabel: null,
      colors: ["#0f9d58", "#ea4335", "#4285f4", "#ff9900"],
      colorsHover: ["#0d8042", "#ec4435", "#2a56c6", "#f68b2b"],
      labels: [
        this.$t("screens.report.texts.desktopMaps"),
        this.$t("screens.report.texts.mobileMaps"),
        this.$t("screens.report.texts.desktopSearch"),
        this.$t("screens.report.texts.mobileSearch"),
      ],
    }
  },
  created() {
    this.handleChange()
  },
  watch: {
    userIds(value) {
      if (value) {
        this.handleChange()
      }
    },
  },
  methods: {
    colorCircle(index) {
      return `color: ${this.colors[index]};`
    },
    async handleChange(time) {
      try {
        this.message = null
        this.arrayData = []
        const data = {
          user_id: this.userIds[0]
            ? this.userIds[0].toString()
            : this.$store.state.auth.user.id.toString(),
        }
        if (time) {
          this.time = time
        }
        const timeRange = this.time
        await this.$store.dispatch("dashboard/getImpressionsGraph", {
          timeRange: timeRange,
          data,
        })
        this.cloneInputs = this.$store.state.dashboard.impressionsGraph
        this.arrayData.push(this.cloneInputs.business_impressions_desktop_maps)
        this.arrayData.push(this.cloneInputs.business_impressions_mobile_maps)
        this.arrayData.push(
          this.cloneInputs.business_impressions_desktop_search
        )
        this.arrayData.push(this.cloneInputs.business_impressions_mobile_search)

        const ctx = document.getElementById(this.id).getContext("2d")
        if (this.myChart) {
          this.myChart.destroy()
        }

        const hoverLabel = {
          id: "hoverLabel",
          afterDraw(chart, args, options) {
            const { ctx } = chart
            ctx.save()

            const config = {
              fontSize: 14,
              x: (chart.width / 2).toFixed(2),
              y: (chart.height / 2).toFixed(2),
            }

            const numberUSLocale = Intl.NumberFormat("en-US")
            this.totalLabel =
              parseInt(chart.config.data.datasets[0].data[0]) +
              parseInt(chart.config.data.datasets[0].data[1]) +
              parseInt(chart.config.data.datasets[0].data[2]) +
              parseInt(chart.config.data.datasets[0].data[3])
            this.formatTotalLabel = numberUSLocale.format(this.totalLabel)

            if (chart.active && chart.active.length > 0) {
              const textLabel = chart.config.data.labels[chart.active[0]._index]
              const numberLabel =
                chart.config.data.datasets[chart.active[0]._datasetIndex].data[
                  chart.active[0]._index
                ]
              const formatNumberLabel = numberUSLocale.format(numberLabel)

              const percentLabel = (numberLabel / this.totalLabel) * 100
              const formatPercentLabel = parseFloat(percentLabel).toFixed(2)

              config.x = (chart.width / 2 + 5).toFixed(2)
              config.y = (chart.height / 2 + 30).toFixed(2)

              ctx.font = `bolder ${config.fontSize}px Arial`
              ctx.fillStyle = "#94a0af"
              ctx.textAlign = "center"
              ctx.fillText(`${textLabel}`, config.x, config.y - 60)
              ctx.font = `bolder ${config.fontSize + 8}px Arial`
              ctx.fillStyle = "#94a0af"
              ctx.textAlign = "center"
              ctx.fillText(`${formatNumberLabel}`, config.x, config.y - 30)
              ctx.font = `bolder ${config.fontSize + 8}px Arial`
              ctx.fillStyle = "#94a0af"
              ctx.textAlign = "center"
              ctx.fillText(`${formatPercentLabel}%`, config.x, config.y)
            } else {
              if (!isNaN(this.totalLabel)) {
                config.x = (chart.width / 2 + 5).toFixed(2)
                config.y = (chart.height / 2 + 20).toFixed(2)

                const textLabel = "All Searches"
                ctx.font = `bolder ${config.fontSize}px Arial`
                ctx.fillStyle = "#94a0af"
                ctx.textAlign = "center"
                ctx.fillText(`${textLabel}`, config.x, config.y - 40)
                ctx.font = `bolder ${config.fontSize + 8}px Arial`
                ctx.fillStyle = "#94a0af"
                ctx.textAlign = "center"
                ctx.fillText(
                  `${this.formatTotalLabel}`,
                  config.x,
                  config.y - 10
                )
              } else {
                config.y = (chart.height / 2 + 40).toFixed(2)

                const gradient = ctx.createLinearGradient(0, 0, 10, 0)
                gradient.addColorStop("0", "#ed7d55")
                ctx.font = `${config.fontSize + 4}px Arial`
                ctx.fillStyle = gradient
                ctx.fillText(
                  `${actions.messages.dashboardError}`,
                  config.x - 110,
                  config.y
                )
              }
            }
          },
        }

        const config = {
          type: "doughnut",
          data: {
            labels: this.labels,
            datasets: [
              {
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: this.colors,
                hoverBackgroundColor: this.colorsHover,
                borderWidth: 2,
                data: this.arrayData,
              },
            ],
          },
          options: {
            cutoutPercentage: 80, // this is the parameter to reduce the border size of the doughnut chart
            legend: {
              display: false,
            },

            scales: {
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    drawBorder: false,
                    zeroLineColor: "transparent",
                    color: "rgba(255,255,255,0.05)",
                  },
                },
              ],

              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(255,255,255,0.1)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          },
          plugins: [hoverLabel],
        }

        this.myChart = new Chart(ctx, config)
      } catch (error) {
        this.message = this.$t("actions.messages.dashboardError")
        if (this.myChart) {
          this.myChart.destroy()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.doughnut-chart {
  min-height: 550px;

  .card-header {
    padding: 25px 30px 0 !important;
  }

  .select-info.el-select .el-input input {
    padding: 5px 23px;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .select-info.el-select .el-input .el-input__icon {
    display: flex;
    height: 29px !important;
    justify-content: center;
    align-items: center;
  }

  .select-info {
    width: 170px !important;
  }

  .select-info.el-select-dropdown__item.selected {
    color: white !important;
  }

  .el-select-dropdown__item.selected.select-info {
    color: white !important;
  }

  .labels-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
</style>
