var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('modal',{staticClass:"custom-modal",attrs:{"show":_vm.isChangePassword,"headerClasses":"justify-content-center"},on:{"update:show":function($event){_vm.isChangePassword=$event}}},[_c('h4',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.texts.changePasswordTitle"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"change-password",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.messages)?_c('AdminAlert',{attrs:{"type":_vm.messages.type,"messages":_vm.messages.data}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.currentPassword'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{staticClass:"mb-3",attrs:{"name":"current-password","type":"password","label":_vm.$t('screens.dashboard.forms.currentPassword'),"placeholder":_vm.$t('screens.dashboard.forms.currentPassword'),"error":errors[0]},model:{value:(_vm.form.current_password),callback:function ($$v) {_vm.$set(_vm.form, "current_password", $$v)},expression:"form.current_password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.newPassword'),"rules":"required|min:8","vid":"newPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{staticClass:"mb-3",attrs:{"name":"new-password","type":"password","label":_vm.$t('screens.dashboard.forms.newPassword'),"placeholder":_vm.$t('screens.dashboard.forms.newPassword'),"error":errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('screens.dashboard.forms.confirmPassword'),"rules":"required|min:8|confirmed:newPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fg-input',{staticClass:"mb-3",attrs:{"name":"confirm-password","type":"password","label":_vm.$t('screens.dashboard.forms.confirmPassword'),"placeholder":_vm.$t('screens.dashboard.forms.confirmPassword'),"error":errors[0]},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('p-button',{attrs:{"native-type":"submit","type":"info","round":""}},[_vm._v(" "+_vm._s(_vm.$t("screens.dashboard.buttons.update"))+" ")])],1),_c('div',{staticClass:"clearfix"})],1)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }