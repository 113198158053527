<template>
  <div class="col-lg-6 col-md-6 ml-auto mr-auto login-page">
    <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <card type="signup" class="login-card">
          <h3 slot="header" class="header text-center">
            {{ $t("screens.login.texts.title") }}
          </h3>

          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <ValidationProvider
            :name="$t('screens.login.forms.email')"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="email"
              v-model="form.email"
              :placeholder="$t('screens.login.forms.email')"
              :hasSuccess="passed"
              type="email"
              addon-left-icon="nc-icon nc-single-02"
              class="col-lg-10 col-md-10 ml-auto mr-auto disable-dbl-tap-zoom"
              :error="
                failed
                  ? $t('screens.login.forms.email') +
                    $t('screens.validate.required')
                  : null
              "
            ></fg-input>
          </ValidationProvider>

          <ValidationProvider
            :name="$t('screens.login.forms.password')"
            rules="required|min:8"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="password"
              v-model="form.password"
              :placeholder="$t('screens.login.forms.password')"
              :hasSuccess="passed"
              type="password"
              addon-left-icon="nc-icon nc-key-25"
              class="col-lg-10 col-md-10 ml-auto mr-auto disable-dbl-tap-zoom"
              :error="
                failed
                  ? $t('screens.login.forms.password') +
                    $t('screens.validate.required')
                  : null
              "
            ></fg-input>
          </ValidationProvider>

          <br />

          <p-button
            native-type="submit"
            slot="footer"
            type="warning"
            class="col-lg-4 col-md-4 ml-auto mr-auto"
            round
            block
          >
            {{ $t("screens.login.buttons.submit") }}
          </p-button>
          <div slot="footer" class="mt-2 login-forgot-password">
            <router-link
              to="/forgot-password"
              class="login-forgot-password--text"
            >
              ForgotPassword？
            </router-link>
          </div>
          <div
            slot="footer"
            class="mt-5 login-register col-lg-8 col-md-8 ml-auto mr-auto"
          >
            <span class="login-register--text">
              {{ $t("screens.login.texts.haveAccount") }}
            </span>
            <router-link
              to="/register-information"
              class="login-register--link"
            >
              {{ $t("screens.login.texts.register") }}
            </router-link>
          </div>
        </card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Card, Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import { mapActions, mapState } from "vuex"

export default {
  components: {
    Card,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      messages: null,
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["postAgreeTerm"]),
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        await this.$store.dispatch("auth/login", this.form)
        this.$store.commit("setShowLoading", false)
        const userId = this.$store.state.auth.user.id
        this.$store.commit("setUserIds", userId)
        if (this.user.agreed_terms === 0) {
          this.postAgreeTerm(true)
        } else {
          this.$router.push("/")
        }
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>

<style scoped>
.disable-dbl-tap-zoom >>> input {
  touch-action: manipulation;
}
</style>
