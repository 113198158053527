<template>
  <div v-if="checkPaymentFail === true">
    <ConfirmMailPayRequired />
  </div>
  <div v-else>
    <PayRequired v-if="checkFreeTrial === true" />
    <div class="row" v-else>
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{ $t("screens.dashboard.texts.postTitle") }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <PostContent type="detail"></PostContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostContent from "./Post/PostContent"
import PayRequired from "src/components/Dashboard/Views/Components/PayRequired.vue"
import ConfirmMailPayRequired from "src/components/Dashboard/Views/Components/ConfirmMailPayRequired.vue"
import formMixin from "@/mixins/formMixin"
import { mapActions } from "vuex"

export default {
  components: {
    PostContent,
    PayRequired,
    ConfirmMailPayRequired,
  },
  mixins: [formMixin],
  methods: {
    ...mapActions("post", ["getModel", "setActive"]),
  },
  destroyed() {
    this.setActive(null)
  },
}
</script>
