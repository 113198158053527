import $axios from "@/plugins/axios"

const config = {
  headers: { "Content-Type": "multipart/form-data" },
}
export default class ProductApi {
  constructor(prefix) {
    this.prefix = prefix
  }

  async getProducts({ type, userId }) {
    return await $axios({
      method: "GET",
      url: `/posts/${type}?user_id=${userId}`,
    })
  }

  async getProductById({ type, key }) {
    return await $axios({
      method: "GET",
      url: `/posts/${type}/${key}`,
    })
  }

  async getProductCategories({ data }) {
    return await $axios({
      method: "GET",
      url: `/posts/products/categories?user_id=` + data.user_id,
    })
  }

  async create(data) {
    return await $axios({
      method: "POST",
      url: `/posts/products`,
      data,
      config,
    })
  }

  async update({ key, data }) {
    return await $axios({
      method: "POST",
      url: `/posts/products/${key}`,
      data,
      config,
    })
  }

  async delete(key, userId) {
    return await $axios({
      method: "DELETE",
      url: `/posts/products/${key}?user_id=` + userId,
    })
  }
}
