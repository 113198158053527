<template>
  <div class="col-lg-6 col-md-6 ml-auto mr-auto register-account-page">
    <card v-if="!isValid">
      <h3 slot="header" class="header text-center">
        {{ $t("screens.registerAccount.texts.title") }}
      </h3>

      <AdminAlert :type="messages.type" :messages="messages.data"></AdminAlert>

      <p-button
        slot="footer"
        type="info"
        class="col-lg-6 col-md-6 ml-auto mr-auto"
        round
        block
        @click="backToRegister"
      >
        {{ $t("screens.registerAccount.buttons.register") }}
        ({{ $t("screens.registerAccount.buttons.redirect") }} {{ countDown }})
      </p-button>
    </card>

    <ValidationObserver v-else v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <card type="signup" class="register-account-card">
          <h3 slot="header" class="header text-center">
            {{ $t("screens.registerAccount.texts.title") }}
          </h3>

          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>

          <ValidationProvider name="email">
            <fg-input
              name="email"
              v-model="form.email"
              :placeholder="$t('screens.registerAccount.forms.email')"
              type="email"
              addon-left-icon="nc-icon nc-single-02"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              disabled
            ></fg-input>
          </ValidationProvider>

          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ passed, failed }"
            vid="newPass"
          >
            <fg-input
              name="password"
              v-model="form.password"
              :placeholder="$t('screens.registerAccount.forms.password')"
              type="password"
              :hasSuccess="passed"
              addon-left-icon="nc-icon nc-key-25"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              :error="
                failed
                  ? $t('screens.registerAccount.forms.password') +
                    $t('screens.validate.required')
                  : null
              "
            ></fg-input>
          </ValidationProvider>

          <ValidationProvider
            name="password"
            rules="required|min:8|confirmed:newPass"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="password-confirmation"
              v-model="form.password_confirmation"
              :placeholder="
                $t('screens.registerAccount.forms.passwordConfirmation')
              "
              type="password"
              addon-left-icon="nc-icon nc-key-25"
              class="col-lg-10 col-md-10 ml-auto mr-auto"
              :hasSuccess="passed"
              :error="
                failed
                  ? $t('screens.registerAccount.forms.password') +
                    $t('screens.validate.required')
                  : null
              "
            ></fg-input>
          </ValidationProvider>

          <br />

          <p-button
            native-type="submit"
            slot="footer"
            type="primary"
            class="col-lg-4 col-md-4 ml-auto mr-auto"
            round
            block
          >
            {{ $t("screens.registerAccount.buttons.submit") }}
          </p-button>
        </card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Card, Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"

export default {
  components: {
    Card,
    [Button.name]: Button,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      messages: null,
      countDown: 10,
      isValid: false,
    }
  },
  created() {
    this.initScreen()
  },
  methods: {
    async initScreen() {
      try {
        this.$store.commit("setShowLoading", true)
        const data = {
          token: this.$route.query.token,
        }
        await this.$store.dispatch("auth/registerVerifyAccount", data)
        this.form.email = this.$store.state.auth.email
        this.$store.commit("setShowLoading", false)
        this.isValid = true
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
        this.isValid = false
        await this.countDownTimer()
      }
    },
    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          ...this.form,
          token: this.$route.query.token,
        }
        await this.$store.dispatch("auth/registerAccount", data)
        this.$router.replace("/login")
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: this.$t("actions.messages.accessTokenError"),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.$router.replace("/register-information")
      }
    },
    backToRegister() {
      this.$router.replace("/register-information")
    },
  },
}
</script>
