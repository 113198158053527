<template>
  <div>
    <h6 class="ml-3">
      {{ $t("screens.coreInformation.coreInformation") }}
    </h6>
    <ValidationObserver ref="core-information" v-slot="{ handleSubmit }">
      <form class="form-horizontal" @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <fieldset>
            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.dashboard.forms.name") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.dashboard.forms.name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.name"
                    :error="errors[0]"
                  ></fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.primaryCategory") }}
              </label>
              <div class="col-sm-10">
                <!-- <model-list-select
                  v-model="forms.primary_category"
                  :list="categories"
                  option-value="category_id"
                  option-text="display_name"
                /> -->
                <multiselect
                  v-model="forms.primary_category"
                  :options="categories"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="true"
                  :placeholder="
                    forms.primary_category
                      ? forms.primary_category.display_name
                      : $t('screens.texts.selection')
                  "
                  :show-labels="false"
                  label="display_name"
                  track-by="category_id"
                  :preselect-first="true"
                >
                  <span slot="noResult">
                    {{ $t("screens.validate.noResult") }}
                  </span>
                </multiselect>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.additionalCategories") }}
              </label>
              <div class="col-sm-10">
                <!-- <multi-list-select
                  :list="categories"
                  option-value="category_id"
                  option-text="display_name"
                  :selected-items="itemsCategory"
                  @select="onSelect($event, forms.additional_categories)"
                >
                </multi-list-select> -->
                <multiselect
                  v-model="forms.additional_categories"
                  :options="categories"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="true"
                  :placeholder="$t('screens.texts.selection')"
                  :show-labels="false"
                  label="display_name"
                  track-by="category_id"
                  :preselect-first="true"
                  tag-placeholder=""
                >
                  <span slot="noResult">
                    {{ $t("screens.validate.noResult") }}
                  </span>
                </multiselect>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.dashboard.texts.postalCode") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.dashboard.texts.postalCode')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.address.postalCode"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <!-- Handle region Vietnam or Japan -->
            <div
              class="form-group"
              v-if="getRegionCode === 'VN' || getRegionCode === 'JP'"
            >
              <label class="col-sm-6 control-label">
                {{ $t("screens.dashboard.texts.administrativeArea") }}
              </label>
              <div class="col-sm-10">
                <!-- <model-list-select
                  v-model="forms.address.administrativeArea"
                  :list="listPrefectures"
                  option-value="name"
                  option-text="name"
                /> -->
                <el-select
                  v-model="forms.address.administrativeArea"
                  clearable
                  size="large"
                  filterable
                  :no-match-text="$t('screens.overviewAgency.label.noData')"
                >
                  <el-option
                    class="select-primary"
                    v-for="option in listPrefectures"
                    :key="option.name"
                    :label="option.name"
                    :value="option.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div v-else class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.dashboard.texts.administrativeArea") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.dashboard.texts.administrativeArea')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.address.administrativeArea"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group" v-if="forms.address.locality">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.locality") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.coreInformation.locality')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.address.locality"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.address1") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.coreInformation.address1')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.address.address1"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.address2") }}
              </label>
              <div class="col-sm-10">
                <ValidationProvider
                  :name="$t('screens.coreInformation.address2')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    type="text"
                    v-model="forms.address.address2"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.address3") }}
              </label>
              <div class="col-sm-10">
                <fg-input type="text" v-model="forms.address.address3" />
              </div>
            </div>

            <div v-if="loaded" style="padding: 10px 15px" class="col-sm-10">
              <div id="regularMap" class="map"></div>
              <p class="mt-2" style="color: #66615b"><!--マップ修正注意-->
                {{ $t("screens.coreInformation.ruleMap") }}
              </p>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.mainPhone") }}
              </label>
              <div class="col-sm-10">
                <!-- <fg-input type="text" v-model="forms.main_phone"></fg-input> -->
                <VuePhoneNumberInput
                  v-model="forms.main_phone"
                  :translations="translations"
                  :default-country-code="getRegionCode"
                  @update="updatePhoneNumber"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-6 control-label">
                {{ $t("screens.coreInformation.entityID") }}
              </label>
              <div class="col-sm-10">
                <fg-input type="text" v-model="forms.corporate_code"></fg-input>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="text-left col-sm-10">
          <p-button native-type="submit" type="info" round>
            {{ $t("screens.basicInformation.buttons.updateInformation") }}
          </p-button>
        </div>
      </form>
    </ValidationObserver>

    <AdminAlert
      v-if="messages"
      :type="messages.type"
      :messages="messages.data"
    />
  </div>
</template>

<script>
import { Button } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
// import { ModelListSelect, MultiListSelect } from "vue-search-select"
// import "vue-search-select/dist/VueSearchSelect.css"
import { Loader } from "google-maps"
import formMixin from "@/mixins/formMixin"
import { Select, Option } from "element-ui"
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
import Multiselect from "vue-multiselect"
import parsePhoneNumber from "libphonenumber-js"
const loader = new Loader(process.env.VUE_APP_GOOGLE_MAP_API_KEY)

export default {
  mixins: [formMixin],
  components: {
    // ModelListSelect,
    // MultiListSelect,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    VuePhoneNumberInput,
    Multiselect,
  },
  data() {
    return {
      loaded: false,
      messages: null,
      forms: {
        name: "",
        primary_category: "",
        additional_categories: [],
        address: {
          locality: "",
          postalCode: "",
          administrativeArea: "",
          address1: "",
          address2: "",
          address3: "",
        },
        map_marker: { latitude: "", longitude: "" },
        main_phone: "",
        corporate_code: "",
        countryCode: "",
      },
      categories: [],
      itemsCategory: [],
      time: 0,
      translations: {
        countrySelectorLabel: this.$t(
          "screens.basicInformation.texts.countrySelectorLabel"
        ),
        countrySelectorError: this.$t(
          "screens.basicInformation.texts.countrySelectorError"
        ),
        phoneNumberLabel: this.$t(
          "screens.basicInformation.texts.phoneNumberLabel"
        ),
        example: this.$t("screens.basicInformation.texts.example"),
      },
    }
  },
  watch: {
    async "forms.address.administrativeArea"() {
      await this.updateAddressAndGeocode()
    },
    async "forms.address.address1"() {
      await this.updateAddressAndGeocode()
    },
    async "forms.address.address2"() {
      await this.updateAddressAndGeocode()
    },
    async "forms.address.address3"() {
      await this.updateAddressAndGeocode()
    },
  },
  computed: {
    getPrimaryCategory() {
      return this.categories.find(
        (category) => this.forms.primary_category === category.category_id
      )
    },
    getAdditionalCategory() {
      return this.categories.filter((category) =>
        this.forms.additional_categories.includes(category.category_id)
      )
    },
    getRegionCode() {
      return this.$store.state.entity.basicInformation.address?.regionCode
    },
    listPrefectures() {
      return this.$store.state.entity.prefectures
    },
  },
  async created() {
    this.$store.commit("setShowLoading", true)
    const data = {
      user_id: this.userIds[0],
    }
    await this.$store.dispatch("entity/getBasicInformation", { data })
    await this.$store.dispatch("entity/getCategories", { data })
    this.$store.commit("setShowLoading", false)
    if (this.$store.state.entity.basicInformation.id) this.getInformation()
    await this.getPrefectures()
    if (
      this.$store.state.entity.basicInformation.map_marker === null &&
      this.forms.address.postalCode !== ""
    ) {
      this.geocode({
        address:
          this.forms.address.administrativeArea +
          " " +
          this.forms.address.address1 +
          " " +
          this.forms.address.address2 +
          " " +
          this.forms.address.address3,
      })
    }
  },
  methods: {
    onSelect(items) {
      this.itemsCategory = items
      this.forms.additional_categories = items
    },

    getInformation() {
      this.forms.name = this.$store.state.entity.basicInformation.name ?? ""
      if (this.forms.name === "Entity") {
        this.forms.name = ""
      }
      this.forms.main_phone =
        this.$store.state.entity.basicInformation.main_phone ?? ""
      this.forms.corporate_code =
        this.$store.state.entity.basicInformation.corporate_code ?? null
      this.forms.additional_categories =
        this.$store.state.entity.basicInformation.additional_categories ?? []
      this.categories = this.$store.state.entity.categories
      this.forms.additional_categories = this.forms.additional_categories.map(
        function (category) {
          return {
            category_id: category.name,
            display_name: category.displayName,
          }
        }
      )
      this.itemsCategory = this.forms.additional_categories
      this.forms.primary_category = this.$store.state.entity.basicInformation
        .primary_category
        ? {
            category_id:
              this.$store.state.entity.basicInformation.primary_category.name,
            display_name:
              this.$store.state.entity.basicInformation.primary_category
                .displayName,
          }
        : ""
      this.forms.address = this.$store.state.entity.basicInformation
        .address ?? {
        postalCode: "",
        administrativeArea: "",
        address1: "",
        address2: "",
        address3: "",
      }
      this.forms.map_marker =
        this.$store.state.entity.basicInformation.map_marker ??
        this.forms.map_marker
      this.loadGoogleMap(this.forms.map_marker)
      this.loaded = true
      this.forms.corporate_code =
        this.$store.state.entity.basicInformation.corporate_code
      this.countryCode = this.getRegionCode
    },

    async onSubmit() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        const data = {
          user_id: this.userIds[0],
          name: this.forms.name,
          postal_code: this.forms.address.postalCode,
          prefecture: this.forms.address.administrativeArea,
          locality: this.forms.address.locality,
          address1: this.forms.address.address1,
          address2: this.forms.address.address2,
          address3: this.forms.address.address3,
          main_phone: this.forms.main_phone
            ? parsePhoneNumber(this.forms.main_phone, this.countryCode).number
            : null,
          primary_category_id: this.forms.primary_category.category_id,
          primary_category_name: this.forms.primary_category.display_name,
          additional_categorie_id: this.forms.additional_categories.map(
            function (a) {
              return a.category_id
            }
          ),
          additional_categorie_name: this.forms.additional_categories.map(
            function (a) {
              return a.display_name
            }
          ),
          latitude: this.forms.map_marker.latitude,
          longitude: this.forms.map_marker.longitude,
          corporate_code: this.forms.corporate_code,
        }
        await this.$store.dispatch("entity/putBasicInformation", { data })
        this.messages = {
          data: this.$t("actions.messages.infoUpdateSuccess"),
          type: messageType.SUCCESS,
        }
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },

    loadGoogleMap(item) {
      this.$nextTick(() => {
        loader.load().then(function (google) {
          const myCenter = new google.maps.LatLng(item.latitude, item.longitude)
          const map = new google.maps.Map(
            document.getElementById("regularMap"),
            {
              zoom: 17,
              center: myCenter,
              scrollwheel: false,
            }
          )

          const marker = new google.maps.Marker({
            position: myCenter,
          })
          marker.setMap(map)
          //ピンの更新を止める場合、ここを修正予定
          google.maps.event.addListener(map, "click", function (evt) {
            item.latitude = evt.latLng.lat()
            item.longitude = evt.latLng.lng()
            marker.setPosition(evt.latLng)
          })
        })
      })
    },

    async getPrefectures() {
      try {
        this.$store.commit("setShowLoading", true)
        const data = {
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("entity/getPrefectures", { data })
        this.$store.commit("setShowLoading", false)
      } catch (error) {
        this.$store.commit("setShowLoading", false)
      }
    },

    updatePhoneNumber(value) {
      this.countryCode = value.countryCode
    },

    geocode(request) {
      const _this = this
      this.$nextTick(() => {
        loader.load().then(function (google) {
          const geocoder = new google.maps.Geocoder()
          geocoder
            .geocode(request)
            .then((result) => {
              const { results } = result

              _this.loadGoogleMap({
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
              })

              return results
            })
            .catch(async (e) => {
              await _this.$store.dispatch("postalCode/getPostalCode", {
                code: _this.forms.address.postalCode,
              })
              _this.forms.map_marker = {
                latitude: _this.$store.state.postalCode.item.zip_lat,
                longitude: _this.$store.state.postalCode.item.zip_lng,
              }
              _this.loadGoogleMap(_this.forms.map_marker)
            })
        })
      })
    },

    async updateAddressAndGeocode() {
      const address = `${this.forms.address.administrativeArea} ${this.forms.address.address1} ${this.forms.address.address2} ${this.forms.address.address3}`
      await this.geocode({ address })
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.multiselect >>> .multiselect__tag {
  margin-bottom: 2px;
}
</style>
