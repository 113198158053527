<template>
  <div class="row">
    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
      <ValidationObserver ref="register-payment" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <AdminAlert
            v-if="messages"
            :type="messages.type"
            :messages="messages.data"
          ></AdminAlert>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.cardNumber')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input
                  :disabled="isCreditCard"
                  name="full-name"
                  type="text"
                  v-mask="'#### #### #### ####'"
                  :label="$t('screens.dashboard.forms.cardNumber')"
                  :placeholder="$t('screens.dashboard.forms.cardNumber')"
                  v-model="form.cardNumber"
                  :error="errors[0]"
                  data-card-field
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.cardName')"
                rules="required"
                v-slot="{ errors }"
              >
                <fg-input
                  :disabled="isCreditCard"
                  name="company-name"
                  type="text"
                  :label="$t('screens.dashboard.forms.cardName')"
                  :placeholder="$t('screens.dashboard.forms.cardName')"
                  v-model="form.cardName"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.cardYear')"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  {{ $t("screens.dashboard.forms.cardYear") }}
                </label>
                <fg-input :error="errors[0]">
                  <el-select
                    :disabled="isCreditCard"
                    size="large"
                    :placeholder="$t('screens.dashboard.forms.cardYear')"
                    v-model="form.cardYear"
                  >
                    <el-option
                      v-bind:value="$index + minCardYear"
                      v-for="(n, $index) in 48"
                      :key="n"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.cardMonth')"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  {{ $t("screens.dashboard.forms.cardMonth") }}
                </label>
                <fg-input :error="errors[0]">
                  <el-select
                    :disabled="isCreditCard"
                    size="large"
                    :placeholder="$t('screens.dashboard.forms.cardMonth')"
                    v-model="form.cardMonth"
                  >
                    <el-option
                      v-for="option in monthsOptions"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider
                :name="$t('screens.dashboard.forms.securityCode')"
                rules="required|min:3|max:4"
                v-slot="{ errors }"
              >
                <fg-input
                  :disabled="isCreditCard"
                  name="company-name"
                  type="text"
                  v-mask="'####'"
                  :label="$t('screens.dashboard.forms.securityCode')"
                  :placeholder="$t('screens.dashboard.forms.securityCode')"
                  v-model="form.cardCvv"
                  :error="errors[0]"
                >
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <p-button
            :disabled="isCreditCard"
            native-type="submit"
            type="info"
            round
          >
            {{ $t("screens.dashboard.buttons.register") }}
          </p-button>
        </form>
      </ValidationObserver>
    </div>

    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
      <vue-paycard class="custom" :value-fields="form" />
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui"
import { monthOptions, messageType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
import Swal from "sweetalert2"
import { mask } from "vue-the-mask"
import payment from "@/util/payment"
import { convertMessage } from "src/util/commons"

export default {
  mixins: [formMixin],
  directives: { mask },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      minCardYear: new Date().getFullYear(),
      form: {
        cardName: "",
        cardNumber: "",
        cardMonth: null,
        cardYear: null,
        cardCvv: null,
      },
      monthsOptions: monthOptions,
      messages: null,
    }
  },
  computed: {
    minCardMonth() {
      if (this.form.cardYear === this.minCardYear)
        return new Date().getMonth() + 1
      return 1
    },
    getToken() {
      return this.$store.state.auth.token
    },
    getUserObject() {
      return this.$store.state.userObject
    },
    getPaymentSelectedPlan() {
      return this.$store.state.payment.paymentSelectedPlan
    },
    formatGetPaymentSelectedPlan() {
      return Object.values(this.getPaymentSelectedPlan)
    },
  },
  mounted() {
    const payment = document.createElement("script")
    payment.setAttribute("src", process.env.VUE_APP_ORDER_TOKEN_URL)
    document.head.appendChild(payment)
  },
  methods: {
    async onSubmit() {
      this.$store.commit("setShowLoading", true)
      const data = {
        ccNumber: this.form.cardNumber.split(" ").join(""),
        ccName: this.form.cardName,
        ccExpiration: String(this.form.cardYear) + this.form.cardMonth,
        securityCode: this.form.cardCvv,
        merchantId: process.env.VUE_APP_PAYMENT_MERCHANT_ID_API_TYPE,
        serviceId: process.env.VUE_APP_PAYMENT_SERVICE_ID_API_TYPE,
      }
      // eslint-disable-next-line no-undef
      await com_sbps_system.generateToken(data, this.afterGenerateToken)
    },
    async afterGenerateToken(response) {
      try {
        this.messages = null
        const data = {
          token: response.tokenResponse.token,
          tokenKey: response.tokenResponse.tokenKey,
          user_id: this.userIds[0],
        }
        await this.$store.dispatch("payment/registerCreditCard", data)
        this.$store.commit("setShowLoading", false)
        await Swal.fire({
          icon: messageType.SUCCESS,
          text: this.$t("actions.messages.registerPaymentSuccess"),
          showConfirmButton: false,
          timer: 5000,
        })
        this.$router.go()
      } catch (err) {
        this.$store.commit("setShowLoading", false)
        if (err.response.status === 404) {
          Swal.fire({
            icon: messageType.ERROR,
            text: this.$t("actions.messages.registerPaymentCardError"),
            showConfirmButton: false,
            timer: 5000,
          })
        } else {
          if (err.response?.data?.errors) {
            Swal.fire({
              icon: messageType.ERROR,
              text: convertMessage(err.response.data.errors).join(", "),
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go()
              }
            })
          } else {
            Swal.fire({
              icon: messageType.ERROR,
              text: this.$t("actions.errors.500"),
              showConfirmButton: false,
              timer: 5000,
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="css" scoped>
.custom >>> .card-item__dateTitle {
  padding-bottom: 0 !important;
}

.custom >>> .card-item__dateItem {
  color: white;
}

@media screen and (max-width: 480px) {
  .custom {
    max-width: 100% !important;
    width: 100% !important;
  }
}
</style>
