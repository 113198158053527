import CRUD from "./CRUD"

const curd = new CRUD("stores")

const state = () => curd.state

const mutations = {
  ...curd.mutations,
}

const actions = {
  ...curd.actions,
}

export default { namespaced: true, state, mutations, actions }
