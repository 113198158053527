import { extend } from "vee-validate"
import {
  required as ruleRequired,
  email as ruleEmail,
  min as ruleMin,
  confirmed as ruleConfirmed,
  regex as ruleRegex,
  between as ruleBetween,
  alpha as ruleAlpha,
  integer as ruleInteger,
  digits as ruleDigits,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  length as ruleLength,
} from "vee-validate/dist/rules"
import {
  validatorNumber,
  validatorUrl,
  validatorCreditCard,
} from "./validators"
import screens from "../../lang/ja/screens"

export const required = extend("required", ruleRequired)

export const email = extend("email", ruleEmail)

export const min = extend("min", ruleMin)

export const confirmed = extend("confirmed", ruleConfirmed)

export const regex = extend("regex", ruleRegex)

export const between = extend("between", ruleBetween)

export const alpha = extend("alpha", ruleAlpha)

export const integer = extend("integer", ruleInteger)

export const digits = extend("digits", ruleDigits)

export const alphaDash = extend("alpha-dash", ruleAlphaDash)

export const alphaNum = extend("alpha-num", ruleAlphaNum)

export const length = extend("length", ruleLength)

export const number = extend("number", {
  validate: validatorNumber,
  message: screens.validate.onlyNumber,
})

export const url = extend("url", {
  validate: validatorUrl,
  message: screens.validate.onlyUrl,
})

export const credit = extend("credit", {
  validate: validatorCreditCard,
  message: screens.validate.onlyCredit,
})
