import $axios from "@/plugins/axios"
export default class CRUDApi {
  constructor(prefix) {
    this.prefix = prefix
  }

  async getList(params) {
    return await $axios({
      method: "GET",
      url: `/admin/${this.prefix}`,
      params,
    })
  }

  async getModel(key) {
    return await $axios({
      method: "GET",
      url: `/admin/${this.prefix}/${key}`,
    })
  }

  async create(data) {
    return await $axios({
      method: "POST",
      url: `/admin/${this.prefix}`,
      data,
    })
  }

  async update({ key, data }) {
    return await $axios({
      method: "PUT",
      url: `/admin/${this.prefix}/${key}`,
      data,
    })
  }

  async delete(key) {
    return await $axios({
      method: "DELETE",
      url: `/admin/${this.prefix}/${key}`,
    })
  }
}
