import PaymentApi from "@/services/paymentApi"

const api = new PaymentApi()

const state = () => ({
  list: [],
  item: null,
  paymentMethod: null,
  paymentSelectedPlan: {},
  plan: [],
})

const mutations = {
  setModels(state, data) {
    state.list = data
  },

  setModel(state, data) {
    state.item = data
  },

  setPayment(state, data) {
    state.paymentMethod = data
  },

  setPaymentSelectedPlan(state, data) {
    state.paymentSelectedPlan = data
  },

  setPaymentPlan(state, data) {
    state.plan = data
  },
}

const actions = {
  async paymentMethods({ commit }, userId) {
    await api.paymentMethods(userId).then((response) => {
      commit("setModels", response.data.data)
    })
  },

  async changePaymentMethods({ commit }, data) {
    await api.changePaymentMethods(data)
  },

  async changePaymentPlan({ commit }, data) {
    await api.changePaymentPlan(data)
  },

  async registerCreditCard({ commit }, data) {
    await api.registerCreditCard(data)
  },

  async showCreditCard({ commit }, data) {
    await api.showCreditCard(data).then((response) => {
      commit("setModel", response.data.data)
    })
  },

  async getPaymentMethodSelected({ commit }, data) {
    await api.getPaymentMethodSelected(data).then((response) => {
      commit("setPayment", response.data.data)
    })
  },

  async getPaymentSelectedPlan({ commit }, data) {
    await api.getPaymentSelectedPlan(data).then((response) => {
      commit("setPaymentSelectedPlan", response.data.data)
    })
  },

  async getPaymentPlan({ commit }, data) {
    await api.getPaymentPlan(data).then((response) => {
      commit("setPaymentPlan", response.data.data)
    })
  },

  async removeCreditCard({ commit }, data) {
    await api.removeCreditCard(data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
