<template>
  <ValidationObserver ref="create-form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submitForm)" class="form-horizontal">
      <div class="row">
        <label class="col-md-3 col-form-label">
          {{ $t("screens.dashboard.forms.groupName") }}
        </label>
        <div class="col-md-9">
          <ValidationProvider
            :name="$t('screens.dashboard.forms.groupName')"
            rules="required"
            v-slot="{ errors }"
          >
            <fg-input type="name" v-model="form.name" :error="errors[0]">
            </fg-input>
          </ValidationProvider>
        </div>

        <label class="col-md-3 col-form-label">
          {{ $t("screens.dashboard.forms.selectStore") }}
        </label>
        <div class="col-md-5">
          <ValidationProvider>
            <el-select
              style="width: 100%"
              v-model="storeSelected"
              :placeholder="$t('screens.dashboard.texts.pleaseChoose')"
              filterable
              :no-match-text="$t('screens.overviewAgency.label.noData')"
            >
              <el-option
                v-for="option in storesOptions"
                :value="option.id"
                :label="option.name"
                :disabled="checkStores(option)"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </ValidationProvider>
        </div>
        <div class="col-md-4">
          <p-button
            :disabled="!storeSelected"
            @click="addStores(storeSelected)"
            class="col-md-12"
            style="margin: 0"
            type="success"
            round
          >
            {{ $t("screens.dashboard.buttons.add") }}
          </p-button>
        </div>

        <div class="col-md-12">
          <el-table
            ref="multipleTable"
            :data="queriedData"
            row-key="id"
            type="expand"
            :empty-text="$t('screens.overviewAgency.label.noData')"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :prop="column.prop"
              :label="column.label"
              :min-width="column.minWidth"
              :sortable="column.sortable"
            >
            </el-table-column>
            <el-table-column :label="$t('screens.dashboard.texts.groupLeader')">
              <template slot-scope="scope">
                <p-radio
                  style="top: 10px"
                  v-model="groupLeader"
                  :label="String(scope.row.id)"
                >
                </p-radio>
              </template>
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions">
              <template slot-scope="props">
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  :title="$t('screens.dashboard.buttons.delete')"
                  @click="deleteStores(props.$index, props.row)"
                >
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="row pl-3 pr-3">
            <div class="col-sm-6 pagination-info mt-2">
              <p class="category">
                Showing {{ from }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <div class="col-sm-6 mt-2">
              <pagination
                class="pull-right"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
                :change-page="changePage(pagination.currentPage)"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>

      <p-button
        v-if="type === 'create'"
        native-type="submit"
        type="success"
        round
      >
        {{ $t("screens.buttons.confirm") }}
      </p-button>
      <p-button v-else native-type="submit" type="warning" round>
        {{ $t("screens.dashboard.buttons.update") }}
      </p-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui"
import { Pagination } from "src/components/UIComponents"

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Pagination,
  },
  props: {
    type: {
      type: String,
      default: "create",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      storesOptions: [],
      storeSelected: "",
      groupLeader: "",
      form: {
        groupID: null,
        name: "",
        stores: [],
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0,
      },
      valuePageGroup: 1,
      firstPage: 1,

      propsToSearch: ["name"],
      tableColumns: [
        {
          prop: "name",
          label: this.$t("screens.dashboard.texts.storeName"),
          minWidth: 70,
          sortable: true,
        },
      ],
    }
  },
  computed: {
    pagedData() {
      return this.form.stores
    },
    queriedData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.getPaginationByGroup?.total ?? 0
      return this.pagedData
    },
    to() {
      return this.getPaginationByGroup?.to
    },
    from() {
      return this.getPaginationByGroup?.from
    },
    total() {
      return this.getPaginationByGroup?.total
    },
    getPaginationByGroup() {
      return this.$store.state.agency.paginationByGroup
    },
  },
  async created() {
    this.storesOptions = this.$store.state.agency.listStore
    this.form = JSON.parse(JSON.stringify(this.data))
    if (this.type === "update")
      this.groupLeader = String(
        this.this.$store.state.agency.listGroup.find(
          (item) => String(item.id) === String(this.data.groupID)
        ).leader.id
      )
  },
  methods: {
    addStores(id) {
      this.form.stores.push(this.getGroup(id))
      this.storeSelected = ""
    },

    getGroup(id) {
      if (id) return this.storesOptions.find((item) => item.id === id)
      else return null
    },

    deleteStores(index, row) {
      if (String(row.id) === String(this.groupLeader)) this.groupLeader = ""
      this.form.stores.splice(index, 1)
    },

    checkStores(item) {
      const found = this.form.stores.find((element) => element.id === item.id)
      return !!found
    },

    async submitForm() {
      this.$emit("submit", this.type, this.form, this.groupLeader)
    },

    changePage(value) {
      this.valuePageGroup = value
      this.$emit("valuePageGroup", this.valuePageGroup)
    },
  },
}
</script>
