import { roleType } from "src/util/constants"

export default async function role({ next, to, router, store }) {
  // check roles
  const roles = [roleType.MANAGER, roleType.AGENCY]
  if (!roles.includes(store.state.auth.user.role)) {
    return next()
  }

  if (!window.localStorage.getItem("userIds")) {
    return next({
      name: "Dashboard",
    })
  }

  return next()
}
