<template>
  <div>
    <div
      v-for="(screen, idx) in screens.paymentRequired.text.content"
      :key="idx"
    >
      <h2>{{ $t(`screens.paymentRequired.text.content[${idx}]`) }}</h2>
    </div>
    <el-button type="warning" @click="handleUnlock()" icon="el-icon-unlock">{{
      $t("screens.paymentRequired.text.directLink")
    }}</el-button>
  </div>
</template>

<script>
import { Button } from "element-ui"
import formMixin from "@/mixins/formMixin"
import screens from "@/lang/ja/screens"

export default {
  components: {
    [Button.name]: Button,
  },
  mixins: [formMixin],
  data() {
    return {
      screens: screens,
    }
  },
  methods: {
    handleUnlock() {
      this.$router.replace("/register-payment")
    },
  },
}
</script>
