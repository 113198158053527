import $axios from "@/plugins/axios"
export default class DashboardApi {
  async getBoxData(data) {
    return await $axios.post("/dashboard/box-data", data)
  }

  async getCustomerSearch(userId, params) {
    return await $axios.post(`/dashboard/customer-search/${params}`, userId)
  }

  async getCustomerCall(userId, params) {
    return await $axios.post(`/dashboard/customer-call/${params}`, userId)
  }

  async getCustomerView({ timeRange, data }) {
    return await $axios({
      method: "POST",
      url: `/dashboard/customer-view/${timeRange}`,
      data,
    })
  }

  async getReactionsGraph({ timeRange, data }) {
    return await $axios({
      method: "POST",
      url: `/dashboard/reactions-graph/${timeRange}`,
      data,
    })
  }

  async getSearchKeywords({ timeRange, data }) {
    return await $axios({
      method: "POST",
      url: `/dashboard/search-keywords/${timeRange}`,
      data,
    })
  }

  async getImpressionsGraph({ timeRange, data }) {
    return await $axios({
      method: "POST",
      url: `/dashboard/impressions-graph/${timeRange}`,
      data,
    })
  }
}
