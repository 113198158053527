import Vue from "vue"
import VueLazyload from "vue-lazyload"

// hoặc khởi tạo với custom option
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "assets/images/gif/loading.gif",
  loading: "assets/images/gif/loading.gif",
  attempt: 1,
})
