import DashboardLayout from "src/components/Dashboard/Layout/DashboardLayout.vue"
import BlankLayout from "src/components/Dashboard/Layout/BlankLayout.vue"
import DefaultLayout from "src/components/Dashboard/Layout/DefaultLayout.vue"
// GeneralViews
import NotFound from "src/components/GeneralViews/NotFoundPage.vue"

// Customization
import Login from "src/components/Dashboard/Views/Auth/Login.vue"
import ForgotPassword from "src/components/Dashboard/Views/Auth/ForgotPassword.vue"
import ResetPassword from "src/components/Dashboard/Views/Auth/ResetPassword.vue"
import RegisterInformation from "src/components/Dashboard/Views/Auth/RegisterInformation.vue"
import RegisterAccount from "src/components/Dashboard/Views/Auth/RegisterAccount.vue"
import ConnectAccount from "src/components/Dashboard/Views/Dashboard/ConnectAccount.vue"
import ConnectAccountLocation from "src/components/Dashboard/Views/Dashboard/ConnectAccount/ConnectAccountLocation.vue"
import ManualDocument from "src/components/Dashboard/Views/Dashboard/ManualDocument.vue"
import ChangePaymentMethod from "src/components/Dashboard/Views/Dashboard/ChangePaymentMethod.vue"
import RegisterPayment from "src/components/Dashboard/Views/Dashboard/RegisterPayment.vue"
import Dashboard from "src/components/Dashboard/Views/Dashboard/Dashboard.vue"
import OverviewAgencyGroupStore from "src/components/Dashboard/Views/Dashboard/Overview/OverviewAgencyGroupStore.vue"
import Post from "src/components/Dashboard/Views/Dashboard/Post.vue"
import Posts from "src/components/Dashboard/Views/Dashboard/Posts.vue"
import PostDetail from "src/components/Dashboard/Views/Dashboard/PostDetail.vue"
import Products from "src/components/Dashboard/Views/Dashboard/Products.vue"
import BasicInformation from "src/components/Dashboard/Views/Dashboard/BasicInformation.vue"
import KnowledgeTag from "src/components/Dashboard/Views/Dashboard/KnowledgeTag.vue"
import AddKnowledgeTag from "src/components/Dashboard/Views/Dashboard/KnowledgeTag/AddKnowledgeTag.vue"
import AddKnowledgeTagSchemaMarkup from "src/components/Dashboard/Views/Dashboard/KnowledgeTag/AddKnowledgeTagSchemaMarkup.vue"
import Media from "src/components/Dashboard/Views/Dashboard/Media.vue"
import Report from "src/components/Dashboard/Views/Dashboard/Report.vue"
import ReportMultiple from "src/components/Dashboard/Views/Dashboard/ReportMultiple.vue"
import GoogleMyBusiness from "src/components/Dashboard/Views/Dashboard/GoogleMyBusiness.vue"
import Facebook from "src/components/Dashboard/Views/Dashboard/Facebook.vue"
import FacebookTermsAndPrivacy from "src/components/Dashboard/Views/Pages/FacebookTermsAndPrivacy.vue"

// check middleware
import { roleType } from "../util/constants"
import auth from "../middleware/auth"
import guest from "../middleware/guest"
import role from "../middleware/role"

// Dashboard pages
const Overview = () =>
  import(
    /* webpackChunkName: "widgets" */ "src/components/Dashboard/Views/Dashboard/Overview.vue"
  )

const OverviewChart = () =>
  import(
    /* webpackChunkName: "widgets" */ "src/components/Dashboard/Views/Dashboard/Dashboard.vue"
  )

const loginPage = {
  path: "/login",
  component: BlankLayout,
  children: [
    {
      path: "",
      name: "Login",
      component: Login,
      meta: {
        middleware: [guest],
      },
    },
  ],
}

const forgotPasswordPage = {
  path: "/forgot-password",
  component: BlankLayout,
  children: [
    {
      path: "",
      name: "Forgot Password",
      component: ForgotPassword,
      meta: {
        middleware: [guest],
      },
    },
  ],
}

const resetPasswordPage = {
  path: "/reset-password",
  component: BlankLayout,
  children: [
    {
      path: "",
      name: "Reset Password",
      component: ResetPassword,
      meta: {
        middleware: [guest],
      },
    },
  ],
}

const registerInformationPage = {
  path: "/register-information",
  component: BlankLayout,
  children: [
    {
      path: "",
      name: "Register Information",
      component: RegisterInformation,
      meta: {
        middleware: [guest],
      },
    },
  ],
}

const registerAccountPage = {
  path: "/register-account",
  component: BlankLayout,
  children: [
    {
      path: "",
      name: "Register Account",
      component: RegisterAccount,
      meta: {
        middleware: [guest],
      },
    },
  ],
}

const facebookTermsAndPrivacyPage = {
  path: "/facebook-terms-and-privacy",
  component: DefaultLayout,
  children: [
    {
      path: "",
      name: "Facebook Terms And Privacy",
      component: FacebookTermsAndPrivacy,
    },
  ],
}

const routes = [
  {
    home: "Admin",
    path: "/",
    component: DashboardLayout,
    redirect: "/overview",
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "google-my-business",
        name: "Google Business Profile",
        component: GoogleMyBusiness,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "facebook/connect-page",
        name: "Facebook",
        component: Facebook,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "dashboard-chart",
        name: "Dashboard Chart",
        component: OverviewChart,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "overview/agencies/:id",
        name: "Overview Agencies",
        component: OverviewAgencyGroupStore,
        meta: {
          middleware: [auth],
          roles: [roleType.MANAGER],
        },
      },
      {
        path: "change-payment-method",
        name: "Change Payment Method",
        component: ChangePaymentMethod,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "connect-account",
        name: "Connect Account",
        component: ConnectAccount,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "connect-location",
        name: "Connect Location",
        component: ConnectAccountLocation,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "manual-document",
        name: "Manual Document",
        component: ManualDocument,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "register-payment",
        name: "Register Payment",
        component: RegisterPayment,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "create-post",
        name: "Post",
        component: Post,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "posts",
        name: "Posts",
        component: Posts,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "post/:id",
        name: "PostDetail",
        component: PostDetail,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "products",
        name: "Products",
        component: Products,
      },
      {
        path: "basic-information",
        name: "Basic Information",
        component: BasicInformation,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "knowledge-tag",
        name: "Knowledge Tag",
        component: KnowledgeTag,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "add-knowledge-tag",
        name: "Add Knowledge Tag",
        component: AddKnowledgeTag,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "add-knowledge-tag/schema-markup",
        name: "Add Knowledge Tag Schema Markup",
        component: AddKnowledgeTagSchemaMarkup,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "media",
        name: "Media",
        component: Media,
        meta: {
          middleware: [auth, role],
        },
      },
      {
        path: "report",
        name: "Report",
        component: Report,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "report-multiple",
        name: "ReportMultiple",
        component: ReportMultiple,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  loginPage,
  forgotPasswordPage,
  resetPasswordPage,
  registerInformationPage,
  registerAccountPage,
  facebookTermsAndPrivacyPage,
  {
    path: "*",
    component: BlankLayout,
    children: [
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
]

export default routes
