const paymentData = {
  item_name: "Meo_Application",
  item_id: "Porto meo fee",
  success_url: process.env.VUE_APP_API_ENDPOINT + "payments/credit/success",
  error_url: process.env.VUE_APP_API_ENDPOINT + "payments/credit/fail",
  pagecon_url: process.env.VUE_APP_ORDER_PAGECON_URL,
  payment_url: process.env.VUE_APP_ORDER_PAYMENT_URL,
  dtl_item_id: "dtlItem_1",
  dtl_item_name: "Application",
}

export default {
  methods: {
    f_submit(data) {
      const order = new this.Order()
      order.pay_method = "credit"
      order.merchant_id = process.env.VUE_APP_PAYMENT_MERCHANT_ID
      order.service_id = process.env.VUE_APP_PAYMENT_SERVICE_ID
      order.hashkey = process.env.VUE_APP_PAYMENT_HASH_KEY
      order.cust_code = this.checkEmail(data.email)
      order.sps_cust_no = ""
      order.sps_payment_no = ""
      order.order_id = this.makeid(32)
      order.item_id = paymentData.item_id // application_id
      order.pay_item_id = ""
      order.item_name = paymentData.item_name
      order.tax = ""
      order.amount = data.amount
      order.pay_type = "0"
      order.auto_charge_type = ""
      order.service_type = "0"
      order.div_settele = ""
      order.last_charge_month = ""
      order.camp_type = ""
      order.tracking_id = ""
      order.terminal_type = "0"
      order.success_url = paymentData.success_url + "?user_id=" + data.user_id
      order.cancel_url = paymentData.error_url + "?user_id=" + data.user_id
      order.error_url = paymentData.error_url + "?user_id=" + data.user_id
      order.pagecon_url = paymentData.pagecon_url
      order.free1 = ""
      order.free2 = ""
      order.free3 = ""
      order.free_csv_input =
        data.user_id +
        "_" +
        "FULL_NAME=" +
        data.name +
        "_" +
        process.env.VUE_APP_MODE +
        "_" +
        data.created_at
      // "LAST_NAME=鈴木,FIRST_NAME=太郎,LAST_NAME_KANA=スズキ,FIRST_NAME_KANA=タロウ,FIRST_ZIP=210,SECOND_ZIP=0001,ADD1=岐阜県,ADD2=あああ市あああ町,ADD3=,TEL=12345679801,MAIL=aaaa@bb.jp,ITEM_NAME=TEST ITEM"
      order.request_date = this.getYYYYMMDDHHMMSS()
      order.limit_second = ""

      // フリーCSV
      order.free_csv = this.encode(order.free_csv_input)

      // チェックサム
      order.sps_hashcode = this.SHA1(order.toString())
      this.feppost(order)
    },

    // handle email template
    checkEmail(email) {
      const [name, domain] = email.split("@")
      const [label] = name.split("+")
      return label + "@" + domain
    },

    Order() {
      this.toString = function () {
        const result =
          this.pay_method +
          this.merchant_id +
          this.service_id +
          this.cust_code +
          this.sps_cust_no +
          this.sps_payment_no +
          this.order_id +
          this.item_id +
          this.pay_item_id +
          this.item_name +
          this.tax +
          this.amount +
          this.pay_type +
          this.auto_charge_type +
          this.service_type +
          this.div_settele +
          this.last_charge_month +
          this.camp_type +
          this.tracking_id +
          this.terminal_type +
          this.success_url +
          this.cancel_url +
          this.error_url +
          this.pagecon_url +
          this.free1 +
          this.free2 +
          this.free3 +
          this.free_csv +
          this.request_date +
          this.limit_second +
          this.hashkey
        return result
      }
    },

    getYYYYMMDDHHMMSS() {
      const now = new Date()
      const nowTZ = this.convertTZ(now, "Asia/Tokyo")
      return (
        nowTZ.getFullYear() +
        this.zeroPadding(nowTZ.getMonth() + 1) +
        this.zeroPadding(nowTZ.getDate()) +
        this.zeroPadding(nowTZ.getHours()) +
        this.zeroPadding(nowTZ.getMinutes()) +
        this.zeroPadding(nowTZ.getSeconds())
      )
    },

    convertTZ(date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: tzString }
        )
      )
    },

    zeroPadding(num) {
      if (num < 10) {
        num = "0" + num
      }
      return num + ""
    },

    feppost(order) {
      const url = paymentData.payment_url
      const form = document.createElement("form")

      form.method = "POST"
      form.action = url

      const payMethod = document.createElement("input")
      payMethod.value = order.pay_method
      payMethod.name = "pay_method"
      payMethod.type = "hidden"
      form.appendChild(payMethod)

      const merchantId = document.createElement("input")
      merchantId.value = order.merchant_id
      merchantId.name = "merchant_id"
      merchantId.type = "hidden"
      form.appendChild(merchantId)

      const serviceId = document.createElement("input")
      serviceId.value = order.service_id
      serviceId.name = "service_id"
      serviceId.type = "hidden"
      form.appendChild(serviceId)

      const custCode = document.createElement("input")
      custCode.value = order.cust_code
      custCode.name = "cust_code"
      custCode.type = "hidden"
      form.appendChild(custCode)

      const spsCustNo = document.createElement("input")
      spsCustNo.value = order.sps_cust_no
      spsCustNo.name = "sps_cust_no"
      spsCustNo.type = "hidden"
      form.appendChild(spsCustNo)

      const spsPaymentNo = document.createElement("input")
      spsPaymentNo.value = order.sps_payment_no
      spsPaymentNo.name = "sps_payment_no"
      spsPaymentNo.type = "hidden"
      form.appendChild(spsPaymentNo)

      const orderId = document.createElement("input")
      orderId.value = order.order_id
      orderId.name = "order_id"
      orderId.type = "hidden"
      form.appendChild(orderId)

      const itemId = document.createElement("input")
      itemId.value = order.item_id
      itemId.name = "item_id"
      itemId.type = "hidden"
      form.appendChild(itemId)

      const payItemId = document.createElement("input")
      payItemId.value = order.pay_item_id
      payItemId.name = "pay_item_id"
      payItemId.type = "hidden"
      form.appendChild(payItemId)

      const itemName = document.createElement("input")
      itemName.value = order.item_name
      itemName.name = "item_name"
      itemName.type = "hidden"
      form.appendChild(itemName)

      const tax = document.createElement("input")
      tax.value = order.tax
      tax.name = "tax"
      tax.type = "hidden"
      form.appendChild(tax)

      const amount = document.createElement("input")
      amount.value = order.amount
      amount.name = "amount"
      amount.type = "hidden"
      form.appendChild(amount)

      const payType = document.createElement("input")
      payType.value = order.pay_type
      payType.name = "pay_type"
      payType.type = "hidden"
      form.appendChild(payType)

      const autoChargeType = document.createElement("input")
      autoChargeType.value = order.auto_charge_type
      autoChargeType.name = "auto_charge_type"
      autoChargeType.type = "hidden"
      form.appendChild(autoChargeType)

      const serviceType = document.createElement("input")
      serviceType.value = order.service_type
      serviceType.name = "service_type"
      serviceType.type = "hidden"
      form.appendChild(serviceType)

      const divSettele = document.createElement("input")
      divSettele.value = order.div_settele
      divSettele.name = "div_settele"
      divSettele.type = "hidden"
      form.appendChild(divSettele)

      const lastChargeMonth = document.createElement("input")
      lastChargeMonth.value = order.last_charge_month
      lastChargeMonth.name = "last_charge_month"
      lastChargeMonth.type = "hidden"
      form.appendChild(lastChargeMonth)

      const campType = document.createElement("input")
      campType.value = order.camp_type
      campType.name = "camp_type"
      campType.type = "hidden"
      form.appendChild(campType)

      const trackingId = document.createElement("input")
      trackingId.value = order.tracking_id
      trackingId.name = "tracking_id"
      trackingId.type = "hidden"
      form.appendChild(trackingId)

      const terminalType = document.createElement("input")
      terminalType.value = order.terminal_type
      terminalType.name = "terminal_type"
      terminalType.type = "hidden"
      form.appendChild(terminalType)

      const successUrl = document.createElement("input")
      successUrl.value = order.success_url
      successUrl.name = "success_url"
      successUrl.type = "hidden"
      form.appendChild(successUrl)

      const cancelUrl = document.createElement("input")
      cancelUrl.value = order.cancel_url
      cancelUrl.name = "cancel_url"
      cancelUrl.type = "hidden"
      form.appendChild(cancelUrl)

      const errorUrl = document.createElement("input")
      errorUrl.value = order.error_url
      errorUrl.name = "error_url"
      errorUrl.type = "hidden"
      form.appendChild(errorUrl)

      const pageconUrl = document.createElement("input")
      pageconUrl.value = order.pagecon_url
      pageconUrl.name = "pagecon_url"
      pageconUrl.type = "hidden"
      form.appendChild(pageconUrl)

      const free1 = document.createElement("input")
      free1.value = order.free1
      free1.name = "free1"
      free1.type = "hidden"
      form.appendChild(free1)

      const free2 = document.createElement("input")
      free2.value = order.free2
      free2.name = "free2"
      free2.type = "hidden"
      form.appendChild(free2)

      const free3 = document.createElement("input")
      free3.value = order.free3
      free3.name = "free3"
      free3.type = "hidden"
      form.appendChild(free3)

      const freeCsv = document.createElement("input")
      freeCsv.value = order.free_csv
      freeCsv.name = "free_csv"
      freeCsv.type = "hidden"
      form.appendChild(freeCsv)

      const requestDate = document.createElement("input")
      requestDate.value = order.request_date
      requestDate.name = "request_date"
      requestDate.type = "hidden"
      form.appendChild(requestDate)

      const limitSecond = document.createElement("input")
      limitSecond.value = order.limit_second
      limitSecond.name = "limit_second"
      limitSecond.type = "hidden"
      form.appendChild(limitSecond)

      const hashkey = document.createElement("input")
      hashkey.value = order.hashkey
      hashkey.name = "hashkey"
      hashkey.type = "hidden"
      form.appendChild(hashkey)

      const spsHashcode = document.createElement("input")
      spsHashcode.value = order.sps_hashcode
      spsHashcode.name = "sps_hashcode"
      spsHashcode.type = "hidden"
      form.appendChild(spsHashcode)

      document.body.appendChild(form)

      form.submit()
    },

    makeid(length) {
      let result = ""
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },

    SHA1(msg) {
      function rotateLeft(n, s) {
        const t4 = (n << s) | (n >>> (32 - s))
        return t4
      }

      function cvtHex(val) {
        let str = ""
        let i
        let v
        for (i = 7; i >= 0; i--) {
          v = (val >>> (i * 4)) & 0x0f
          str += v.toString(16)
        }
        return str
      }

      function Utf8Encode(string) {
        string = string.replace(/\r\n/g, "\n")
        let utftext = ""
        for (let n = 0; n < string.length; n++) {
          const c = string.charCodeAt(n)
          if (c < 128) {
            utftext += String.fromCharCode(c)
          } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192)
            utftext += String.fromCharCode((c & 63) | 128)
          } else {
            utftext += String.fromCharCode((c >> 12) | 224)
            utftext += String.fromCharCode(((c >> 6) & 63) | 128)
            utftext += String.fromCharCode((c & 63) | 128)
          }
        }
        return utftext
      }
      let blockstart
      let i
      let j
      const W = new Array(80)
      let H0 = 0x67452301
      let H1 = 0xefcdab89
      let H2 = 0x98badcfe
      let H3 = 0x10325476
      let H4 = 0xc3d2e1f0
      let A, B, C, D, E
      let temp
      msg = Utf8Encode(msg)
      const msgLen = msg.length
      const wordArray = []
      for (i = 0; i < msgLen - 3; i += 4) {
        j =
          (msg.charCodeAt(i) << 24) |
          (msg.charCodeAt(i + 1) << 16) |
          (msg.charCodeAt(i + 2) << 8) |
          msg.charCodeAt(i + 3)
        wordArray.push(j)
      }
      switch (msgLen % 4) {
        case 0:
          i = 0x080000000
          break
        case 1:
          i = (msg.charCodeAt(msgLen - 1) << 24) | 0x0800000
          break
        case 2:
          i =
            (msg.charCodeAt(msgLen - 2) << 24) |
            (msg.charCodeAt(msgLen - 1) << 16) |
            0x08000
          break
        case 3:
          i =
            (msg.charCodeAt(msgLen - 3) << 24) |
            (msg.charCodeAt(msgLen - 2) << 16) |
            (msg.charCodeAt(msgLen - 1) << 8) |
            0x80
          break
      }
      wordArray.push(i)
      while (wordArray.length % 16 !== 14) wordArray.push(0)
      wordArray.push(msgLen >>> 29)
      wordArray.push((msgLen << 3) & 0x0ffffffff)
      for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
        for (i = 0; i < 16; i++) W[i] = wordArray[blockstart + i]
        for (i = 16; i <= 79; i++)
          W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)
        A = H0
        B = H1
        C = H2
        D = H3
        E = H4
        for (i = 0; i <= 19; i++) {
          temp =
            (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
            0x0ffffffff
          E = D
          D = C
          C = rotateLeft(B, 30)
          B = A
          A = temp
        }
        for (i = 20; i <= 39; i++) {
          temp =
            (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) &
            0x0ffffffff
          E = D
          D = C
          C = rotateLeft(B, 30)
          B = A
          A = temp
        }
        for (i = 40; i <= 59; i++) {
          temp =
            (rotateLeft(A, 5) +
              ((B & C) | (B & D) | (C & D)) +
              E +
              W[i] +
              0x8f1bbcdc) &
            0x0ffffffff
          E = D
          D = C
          C = rotateLeft(B, 30)
          B = A
          A = temp
        }
        for (i = 60; i <= 79; i++) {
          temp =
            (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) &
            0x0ffffffff
          E = D
          D = C
          C = rotateLeft(B, 30)
          B = A
          A = temp
        }
        H0 = (H0 + A) & 0x0ffffffff
        H1 = (H1 + B) & 0x0ffffffff
        H2 = (H2 + C) & 0x0ffffffff
        H3 = (H3 + D) & 0x0ffffffff
        H4 = (H4 + E) & 0x0ffffffff
      }
      temp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4)

      return temp.toLowerCase()
    },

    encode(input) {
      const _keyStr =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
      let output = ""
      let chr1, chr2, chr3, enc1, enc2, enc3, enc4
      let i = 0

      input = this._utf8_encode(input)

      while (i < input.length) {
        chr1 = input.charCodeAt(i++)
        chr2 = input.charCodeAt(i++)
        chr3 = input.charCodeAt(i++)

        enc1 = chr1 >> 2
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
        enc4 = chr3 & 63

        if (isNaN(chr2)) {
          enc3 = enc4 = 64
        } else if (isNaN(chr3)) {
          enc4 = 64
        }

        output =
          output +
          _keyStr.charAt(enc1) +
          _keyStr.charAt(enc2) +
          _keyStr.charAt(enc3) +
          _keyStr.charAt(enc4)
      }

      return output
    },

    _utf8_encode(string) {
      string = string.replace(/\r\n/g, "\n")
      let utftext = ""

      for (let n = 0; n < string.length; n++) {
        const c = string.charCodeAt(n)

        if (c < 128) {
          utftext += String.fromCharCode(c)
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192)
          utftext += String.fromCharCode((c & 63) | 128)
        } else {
          utftext += String.fromCharCode((c >> 12) | 224)
          utftext += String.fromCharCode(((c >> 6) & 63) | 128)
          utftext += String.fromCharCode((c & 63) | 128)
        }
      }

      return utftext
    },
  },
}
