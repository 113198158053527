<template>
  <div class="register-payment-credit-card-list-container">
    <div>
      <img
        :src="
          require('@/assets/images/credit-card/' + getCreditCardType() + '.png')
        "
        alt=""
      />
      <span class="ml-4">{{ getNumberCreditCard() }}</span>
      <span class="ml-4 register-payment-credit-card-list-expires">
        {{ $t("screens.dashboard.texts.expiresOn") }}
        {{ getDateExpiration() }}
      </span>
    </div>
    <a
      class="text-info register-payment-credit-card-list-remove"
      @click="removeCreditCard"
    >
      {{ $t("screens.dashboard.buttons.remove") }}
    </a>
  </div>
</template>

<script>
import { messageType, creditCardType } from "src/util/constants"
import { convertMessage } from "src/util/commons"
import formMixin from "@/mixins/formMixin"

export default {
  mixins: [formMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getDateExpiration() {
      if (this.item.cc_expiration) {
        const ccExpiration = this.item.cc_expiration
        const expirations = ccExpiration.match(/.{1,4}/g)
        return expirations[1] + "/" + expirations[0].match(/.{1,2}/g)[1]
      }
    },
    getCreditCardType() {
      switch (this.item.cardbrand_code) {
        case creditCardType.AMERICAN_EXPRESS:
          return "american-express"
          // eslint-disable-next-line no-unreachable
          break
        case creditCardType.DINERS_CLUB:
          return "diners-club"
          // eslint-disable-next-line no-unreachable
          break
        case creditCardType.JCB:
          return "jcb"
          // eslint-disable-next-line no-unreachable
          break
        case creditCardType.MASTERCARD:
          return "mastercard"
          // eslint-disable-next-line no-unreachable
          break
        case creditCardType.VISA:
          return "visa"
          // eslint-disable-next-line no-unreachable
          break
        default:
          return "other"
      }
    },
    getNumberCreditCard() {
      if (this.item.cc_number) {
        return this.item.cc_number.match(/.{1,4}/g).join(" ")
      }
    },
    async removeCreditCard() {
      try {
        this.messages = null
        this.$store.commit("setShowLoading", true)
        await this.$store.dispatch("payment/removeCreditCard", {
          user_id: this.userIds[0],
        })
        this.$router.go(0)
        this.$store.commit("setShowLoading", false)
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
        this.$store.commit("setShowLoading", false)
      }
    },
  },
}
</script>
